import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox, Form } from 'semantic-ui-react'

import { precision } from '../../utils/formatters'

const renderer = ({ data }) => (data ? `Wetland area: ${precision(data.area, 1)} acres` : 'No data found')

const table = (dataset, data) => [['Area (acres)'], [data ? data.area : '']]

class Filter extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      avoid_wetlands: props.initValues.length > 0,
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.useDefaultValues && !this.props.useDefaultValues) {
      this.setState({ avoid_wetlands: false }, this.props.onChange({ [this.props.dataset.dataset_id]: null }))
    }
  }

  handleValueChange = () => {
    this.props.onChange({
      [this.props.dataset.dataset_id]: this.state.avoid_wetlands
        ? [[`${this.props.dataset.dataset_id}.orig_class`, '==', null]]
        : null,
    })
  }

  render() {
    return (
      <Form.Field>
        <Checkbox
          label="Avoid wetlands"
          checked={this.state.avoid_wetlands}
          onChange={(e, props) => this.setState({ avoid_wetlands: props.checked }, () => this.handleValueChange())}
        />
      </Form.Field>
    )
  }
}

Filter.defaultValue = null
Filter.ignoreReset = true

Filter.propTypes = {
  dataset: PropTypes.shape({}).isRequired,
  useDefaultValues: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  initValues: PropTypes.arrayOf(PropTypes.array).isRequired,
  isStatic: PropTypes.bool.isRequired,
}

export default {
  renderer,
  table,
  filter: Filter,
}
