import React from 'react'

export const MAP_STYLES = {
  boundary: {
    color: 'black',
    weight: 3,
    dashArray: '10 5',
    opacity: 0.9,
    fill: false,
  },
  freestallBarn: {
    color: '#49006a',
    weight: 2,
    fill: true,
    fillOpacity: 0.5,
  },
  openLotCorral: {
    color: '#ffeda0',
    weight: 2,
    fill: false,
  },
  pasture: {
    color: '#238443',
    weight: 2,
    fill: false,
  },
  croplandIrrigated: {
    color: '#004529',
    weight: 2,
    fill: false,
  },
  milkingParlor: {
    color: '#f768a1',
    weight: 2,
    fill: true,
    fillOpacity: 0.5,
  },
  feedStorageArea: {
    color: '#6baed6',
    weight: 2,
  },
  commodityBarn: {
    color: '#2171b5',
    weight: 2,
    fill: true,
    fillOpacity: 0.5,
  },
  hospitalBarn: {
    color: '#08306b',
    weight: 2,
    fill: true,
    fillOpacity: 0.5,
  },
  calfHousing: {
    color: '#fcc5c0',
    weight: 2,
    fill: true,
    fillOpacity: 0.5,
  },
  otherCovered: {
    color: '#c6dbef',
    weight: 2,
    fill: true,
    fillOpacity: 0.5,
  },
  separatorNonMechanical: {
    color: '#333333',
    weight: 2,
    fill: false,
  },
  settlingBasin: {
    color: '#67000d',
    weight: 2,
    fill: false,
  },
  wastewaterStorage: {
    color: '#cb181d',
    weight: 2,
    fill: false,
  },
  processingPit: {
    color: '#662506',
    weight: 2,
    fill: false,
  },
  compostBeddedBarn: {
    color: '#ae017e',
    weight: 2,
    fill: true,
    fillOpacity: 0.5,
  },
  slattedFloorStorage: {
    color: '#fe9929',
    weight: 2,
    fill: false,
  },
  manureStorageArea: {
    color: '#cc4c02',
    weight: 2,
  },
  flushLane: {
    color: '#cb181d',
    weight: 2,
  },
  flowDirection: {
    color: '#333333',
    weight: 3,
  },
  pipeline: {
    color: '#ce1256',
    weight: 2,
  },
  culvert: {
    color: '#1d91c0',
    weight: 2,
  },
  scrapedLane: {
    color: '#fd8d3c',
    weight: 3,
  },
  vacuumLane: {
    color: '#4eb3d3',
    weight: 3,
  },
  sandTrapLane: {
    color: '#a50f15',
    weight: 3,
  },
  otherArea: {
    color: '#333333',
    weight: 2,
  },
  otherLine: {
    color: '#333333',
    weight: 3,
  },
  default: {
    color: 'orange',
    weight: 2,
    fill: true,
    fillOpacity: 0.5,
  },
}

export const POINT_SYMBOLS = {
  pump: {
    url: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAA90lEQVQ4T2NkwA7qGRgYGtCkQPxGdOWMSALcDAwMJTCNexnUUNQ6M9yC8UEG9TAwMHwFCcAM4GFgYNjMwMDggKwRpgmL2AEGBgYfkCEwA8BOxmUrDnGwl2AG/EdXBHKeZFoY2NnPZ63CCCmo6xhBBmDYzqGuwKDUW8Eg4e0I1vhi636Ge8UdDD9uPoAbBDWgAWQAhu1aW2aANTOCzWdg+P//P9iQaz4ZGAELNwAWYNfSahg0ZzbDNcN0gAy5nl7LoDWrBSwE8jJID/UMQHYbqV6gOBDB4URJNIIMoDghUZyUQa4AGVJMZGbqZWBg+IKcmdCTKtHZGQC1+YEL01ArEAAAAABJRU5ErkJggg==',
  },
  drain: {
    url: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAxklEQVQ4T62TOw7CMAyGv8xwp0btym1gp+xwGuZW7Z1gLvoDQYkJUkXwFj8+P2I7ynIEemPS+2TdXaLYAIdCoI0R6AzcZYiALXAF/JeKrHoCdoJEQKlklmUJgc6lhb5ZoaVoeXq+pG1bhmHIsnZdxziOHyMQIMseg21WVVOA9AJk2eWo4KZpmCa1Ct575nkOLVlwAMRe5aggOUmnt8TqIlh+/wGkk/mlheohqoCqbxSgepGqV1lVCLJfeUwX4JYek13R1ef8AOEQfQtJvYFkAAAAAElFTkSuQmCC',
  },
  well: {
    url: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAzElEQVQ4T62TvRHCMAyFn2tYAVbB6ZkFCugJPRSwCgMkrAIrQG3uiejQyeYunFHlSHqffmIHlG0HoHUhfu99ejCOCYCtCmenHvd1lLA9D/EDgCdjCpgCuACITFazAOdj0pIQBUjLVkxBCWD8MpICkhcz8bZaSOH5+ZptaoAHAorVKe779zgxxgwyAFoCsuoqbppGAF3XfYUIwPaXUpLKKtaYQoI0/bH/APwCfx2heokcqOo3ElB9kaqvMrsgZDPyMR0BPOxj8ld19HN+AUlHiQsTdeiaAAAAAElFTkSuQmCC',
  },
  weirBox: {
    url: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAeUlEQVQ4T2OUnXjgPwMFgBHZgEd59kQZJTfpIFwd2ACQRpggIUOQ1YHYcANARhIyBF0ewwB8hmAzHKsB2AzB5TKcBiAbAgstbGGD1wBiooO2BiDHNcg1JHkBW3RhM4T60UhRQiKkGRYrWJMyvrjGFp0YmYmYOMelBgAVyobhMkGOzQAAAABJRU5ErkJggg==',
  },
  separatorMechanical: {
    url: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAe0lEQVQ4T61SyQ3AMAhLZoL9J2CnVjyIHApFDeVJjOUjk4iu0ZipBCJyRMHMYyPQhU5G6N8fBHpsICSKdoZNLeBRpiq0gPIjS7ozTJlB5NlbWxa85KoWVbEpaBNgA+gxauM1g1aI6PuoRiPI8vA/9P8Qq+r8+1Lw9RDxN+Y+hZFjVpMjAAAAAElFTkSuQmCC',
  },
  separatorNonMechanical: {
    url: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAT0lEQVQ4T2M0Njb+z0ABYAQZcObMGbKMMDExYaCuASATiQEwF2O4AGYAsgKQgfj4KF6gmgGEvDHqBdRYGUSxQGwqhEUxKCqplxcIJRx88gAOc4iR0V2BuAAAAABJRU5ErkJggg==',
  },
  composter: {
    url: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAeElEQVQ4T7XSQRKAIAgFULlOWzhDh+4MrLuOjc3QoAExVq79T/QLiFjLiwUNYOYpgohKCMC6nHDddvMAF5DgmBqhG+AFPagDsmHB2jT/A3Jnazp3Au/FZXTdjHkFDVg1poCoxhQQfctHIPunv68xe7Led7UwE5bMAb7BfJF2SkrqAAAAAElFTkSuQmCC',
  },
  otherPoint: {
    url: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAA2ElEQVQ4T62TwRHCIBBFP2e90EJaCAVYgN3o3XjXamzAAqAFWuCiZ5y/ZjPI4Ewc5ETY/Y+/G9agvU4ApirE73OdboqDDYCjCnPOCCFIeBxHGLOkEnQB8GRMT7cAbgB2FHJR7JyTvfdeICJ4g+4A9oQoQCy3xOqwAZGSFJBrMQWlA+4bEPGz3K62mWitxTAMYiDGiJSSABUylzIRILe3xNo4xr9BBFDa5Y0UFF0XF/WZav4D6C2hu4lziZ8P6JffSED3Q+p+ynRByGHlMF0BPMphqqd09Ti/AMID0QuKDRszAAAAAElFTkSuQmCC',
  },
}

export const LINE_SYMBOLS = {
  flushLane: {
    angleCorrection: 45,
    repeat: '30px',
    url: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAAAXNSR0IArs4c6QAAADRJREFUKFNjTGfg/c+ABzDiUzCT4TMjTgUgSZDBWBXAJLEqQJbEUIAuiaIAmyRcAS5JkAIAbq8WfBBcKqsAAAAASUVORK5CYII=',
  },
  flowDirection: {
    angleCorrection: 45,
    offset: '100%',
    iconAnchor: [4, 4],
    url: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAAXNSR0IArs4c6QAAADZJREFUKFNjNDY2/s9ABGDEp/Ds2bOMMDNwKkRWBFKMoRBdAVYTcSnCMJEohfgUwU0kpIg2CgEA7SWj0J2E9wAAAABJRU5ErkJggg==',
  },
  sandTrapLane: {
    repeat: '30px',
    angleCorrection: 90,
    iconAnchor: [11, 8],
    url: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAbElEQVQ4T2NkoBAwUqifAWzA////p5FqECMjYxZID9yADEa+TGINmfH/03QMAwhphlmArBnuAnyaQd7DpZmgAYQ04zWAGM04DSBWM1YDSNGMYQCpmlEMQNdMKFrpn5DQXYTiAkLOxSdPcW4EALvUWBHcJtPXAAAAAElFTkSuQmCC',
  },
  scrapedLane: {
    repeat: '30px',
    angleCorrection: 90,
    iconAnchor: [11, 8],
    url: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAA3ElEQVQ4T6WTMQ6CMBSG3ysM3MGTkMDGOViQQRaMXsALQEJMdJDFe7CxeCRigGdabVNRhGLHv+mX77V/EQaL2qbsiyBiu/qMiAnf/pWhfp6ITl3u3xDgKgFT2Qegz/0NYxDCtna5AQeMZUQQvgPa5tIXwZqlVYm2E3O77t6UcAyi0UyOMKU6OpIOMNW39rWrRqAl+rYTC8BifcREARbpK4ChPqRVab1eCf/R5+MLgIm+XrInwFBfL5QAtJnH6zi7+/onE4Au82hu94f6EnBgabWS3Vd3ov2Hb5ls8APSznp/oD6s9wAAAABJRU5ErkJggg==',
  },
  vacuumLane: {
    repeat: '30px',
    angleCorrection: 90,
    iconAnchor: [11, 8],
    url: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAM0lEQVQ4T2NkoBAwgvT///9/mv+WK5kbfXSmMzIyZpHEHzVg+mgg6oyGASjvUCUvUJKjAWyRuio9bR6YAAAAAElFTkSuQmCC',
  },
  pipeline: {
    repeat: '30px',
    angleCorrection: 0,
    iconAnchor: [8, 8],
    url: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAA8ElEQVQ4T82SMW7CQBBF/2xOAJuLeInShS6KktpaF6bkEPgCJHeAEqKA6SMlFWWQbF+ENSdgPwIpEhgHBdGw7f55mqf5ggufXDiPKwVkjTAWUR0AercjZUmux63VbFxVPlIobqOYRFcg34H76AOQTIeJeHkUYBCs0vd9yBEgb9pPCOfGpW/7wULbhOSDKdOX0wBtF8ZN72uuI3nT/pjy8K9ug0U19AvLa+B/KMjcuElFIUxI1Tbl9PmkQtGIYsJ3veLXnZu9bsO5jnri/ZO/UcPWcnJwidoiZdp2hIgh0CBA0ClyFPznjOdW+0qrfI7GBmYuXBHmCqreAAAAAElFTkSuQmCC',
  },
  culvert: {
    repeat: '30px',
    angleCorrection: 270,
    iconAnchor: [11, 8],
    url: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAASElEQVQ4T2NkoBAwUqifYZAZYGxs/P/s2bN4XYWuBkUxSBIUJrgMwSY/XA3AFg4w/6PLYQ0DQokLOZCpawAhm7HJD7KkTI4XAE4ROBFk7MxnAAAAAElFTkSuQmCC',
  },
  otherLine: {
    repeat: '30px',
    angleCorrection: 90,
    iconAnchor: [11, 8],
    url: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAnklEQVQ4T6WRzQ2AIAyFKzcPDOAcXUmn0mG4k+7hCN40GGtK+REiF+AlfPS9N8CzEPEkooHvYQ+atXZ0zh1Sl+f3QQmgoRoUAQBgIaJNTqW1GmAGgFX+iIiJVgSwZ2PM5L3f5RRay2bAgFyQtRx06vfI0veXjaQ2Ho9/De1orWrhF4Bz6KkzsvAAuupMAL1tFAGtNkqAZhtZQI+N34ALwmCKEWhD/SYAAAAASUVORK5CYII=',
  },
}

export const FILL_PATTERNS = {
  croplandIrrigated: {
    url: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAICAYAAADA+m62AAAAAXNSR0IArs4c6QAAADlJREFUKFNjZMAGXDX/M+y+zogshcKBS4AUwgBUA3aFIEVopkIUolsFMxHJeoSJyIpJciPRniFXIQDrPh4JY43IdwAAAABJRU5ErkJggg==',
  },
  feedStorageArea: {
    url: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAAAXNSR0IArs4c6QAAAC5JREFUKFNjZCAAGEHy2euu/Z8apAVmowOsgsgasCpANoV4BbjcgdMEmAaCVgAANQQQCTcKYFQAAAAASUVORK5CYII=',
  },
  manureStorageArea: {
    url: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAAXNSR0IArs4c6QAAAFJJREFUKFNjZGBgYDjjw/TfZMs/RhAbGSCLM4I4IEl0hejiGKagmwrjoyjEZTpIMVwhTBHMBHSnkK4Q5ntsHkOxGpcnYE4i6GtYWIIV4vMtzCYADlQqJDHrEL0AAAAASUVORK5CYII=',
  },
  openLotCorral: {
    url: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAAAXNSR0IArs4c6QAAAC9JREFUKFNjZCAAGEHy/98t+A+iGYUSwHxkQJwCfLZgGInVCnRBZDdhNYGgAmQTAVekEAn+3wKkAAAAAElFTkSuQmCC',
  },
  pasture: {
    url: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAAAXNSR0IArs4c6QAAAC1JREFUKFNjZCAAGEHyyi3O/+/W7AWzYQAmhiKIzTCsupAVYpiAbh1xVlDkSAA0OBgJytQ+zQAAAABJRU5ErkJggg==',
  },
  processingPit: {
    url: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAAXNSR0IArs4c6QAAAENJREFUKFNjZEADaaps/0FCs27/YkSWYgRJoAti0wzWhU8xTA5uPDbFyGIoViNLoLOJVwhzOEGrifYMScGDHG64AhwAsHNOS1DwcggAAAAASUVORK5CYII=',
  },
  settlingBasin: {
    url: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAAXNSR0IArs4c6QAAAEBJREFUKFNjZCACpDPw/mckpA6kaCbDZ0a8CmGKQIbhVIisCKdCdEVYFWJThKEQlyIUhfgUwRUSUgRWSIwikEIAlEgcfpb0/p0AAAAASUVORK5CYII=',
  },
  slattedFloorStorage: {
    url: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAAXNSR0IArs4c6QAAADRJREFUKFNjZICCfzM1/zOlX2eE8UE0shhcgjYKka2FsWHOoYHVIE+gW4McEjA2DawmNsABF0tKyrYkMD4AAAAASUVORK5CYII=',
  },
  separatorNonMechanical: {
    url: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAAXNSR0IArs4c6QAAADRJREFUKFNjNDY2/n/27FlGBjwApAasAJ9imBzcJGyKkcVQrESWQNc4GBQS5RmigofYAAcA6kpLOytDipgAAAAASUVORK5CYII=',
  },
  wastewaterStorage: {
    url: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAAAXNSR0IArs4c6QAAAClJREFUGFdjZMABGCmTOC0h+9/0xWOwKXCjQIIwY0GSjDABmEqQJEgMAMFHDga93jUqAAAAAElFTkSuQmCC',
  },
  otherArea: {
    url: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAAXNSR0IArs4c6QAAADBJREFUKFNjNDY2/n/27FlGBiSATQysgBjFcJMIKSZoJcwAFIX4nMFIyEqYH4eCZwB1CEs7Y4ZwmwAAAABJRU5ErkJggg==',
  },
}

export const LIVESTOCK_TYPE = [
  'Lactating Dairy Cows (freestall)',
  'Lactating Dairy Cows (open lot)',
  'Cattle: dry cows',
  'Cattle: heifers (on feed)',
  'Cattle: bulls',
  'Cattle: calves (grazing)',
  'Cattle: cows (grazing)',
  'Cattle: heifers (grazing)',
  'Swine: nursery swine',
  'Swine: grow/finish swine',
  'Swine: breeding swine',
  'Sheep',
  'Goats',
  'Horses',
  'Poultry: Layer Hens',
  'Poultry: Other Chickens',
  'Poultry: Pullets',
  'Poultry: Broilers',
  'Poultry: Turkeys',
]

export const PRACTICES = [
  '1a - Pasture based management - conversion to pasture management or increased time at pasture',
  '2a - Installation of compost bedded pack barn',
  '2b - Installation of slatted floor pit storage manure collection cleaned out at least monthly',
  '3a - Solid separation with open solar drying',
  '3b - Solid separation with closed solar drying',
  '3c - Solid separation with forced evaporation drying',
  '3d - Solid separation with daily spread',
  '3e - Solid separation with solid storage',
  '3f - Solid separation with composting (in vessel or aerated static pile)',
  '3g - Solid separation with composting (passive or intensive windrow)',
  '3h - Solid separation with aerated vermifiltration system',
  '4a - Scrape conversion with open solar drying',
  '4b - Scrape conversion with closed solar drying',
  '4c - Scrape conversion with forced evaporation drying',
  '4d - Scrape conversion with daily spread',
  '4e - Scrape conversion with solid storage',
  '4f - Scrape conversion with  composting (in vessel or aerated static pile)',
  '4g - Scrape conversion with composting (passive or intensive windrow)',
]

export const COLLECTION_SYSTEMS = [
  'Flush',
  'Partial scrape / partial flush / vacuum truck',
  'Scrape / vacuum truck',
  'Compost bedded pack barn',
  'Pit storage below animal confinements',
  'Aerated vermifiltration system',
]

export const SEPARATION_METHOD = [
  'none',
  'weeping wall',
  'stationary screen',
  'vibrating screen',
  'screw press',
  'centrifuge',
  'roller drum',
  'belt press/screen',
]

export const BASELINE_SOLID_STORAGE = [
  'none',
  'pasture / dirt',
  'open solar drying',
  'closed solar drying',
  'forced evaporation drying',
  'daily spread',
  'solid storage',
  'dry lot',
  'liquid slurry (with natural crust cover)',
  'liquid slurry (without natural crust cover)',
  'pit storage below animal confinements (< 1 month)',
  'pit storage below animal confinements (>1 month)',
  'cattle and swine deep bedding (<1 month)',
  'cattle and swine deep bedding (>1 month)',
  'composting - in vessel or aerated static pile',
  'composting - windrow',
]

export const mapItemCategories = [
  {
    id: 'structuresAreas',
    label: 'Structures/Areas',
    items: [
      'freestallBarn',
      'openLotCorral',
      'pasture',
      'croplandIrrigated',
      'milkingParlor',
      'feedStorageArea',
      'commodityBarn',
      'hospitalBarn',
      'calfHousing',
      'otherCovered',
    ],
  },
  {
    id: 'waterFlowStorage',
    label: 'Water Flow/Storage',
    items: [
      'settlingBasin',
      'wastewaterStorage',
      'flushLane',
      'flowDirection',
      'pipeline',
      'pump',
      'drain',
      'well',
      'weirBox',
    ],
  },
  {
    id: 'manureManagement',
    label: 'Manure Management',
    items: [
      'processingPit',
      'compostBeddedBarn',
      'slattedFloorStorage',
      'manureStorageArea',
      'separatorMechanical',
      'separatorNonMechanical',
      'scrapedLane',
      'culvert',
      'vacuumLane',
      'sandTrapLane',
      'composter',
    ],
  },
  {
    id: 'other',
    label: 'Other',
    items: ['otherPoint', 'otherLine', 'otherArea'],
  },
]

const livestockUserData = [
  {
    id: 'label',
    component: 'input',
    componentProps: {
      label: (
        <div style={{ fontWeight: 'bold', fontSize: '0.92857143em' }}>
          Would you like to give a more descriptive label to this element?{' '}
          <span style={{ fontWeight: 'normal' }}>(optional)</span>
        </div>
      ),
      placeholder: 'Label',
      maxLength: '210',
    },
  },
  {
    id: 'livestock',
    component: 'livestock',
    componentProps: {},
  },
  {
    id: 'notes',
    component: 'textArea',
    componentProps: {
      label: (
        <div style={{ fontWeight: 'bold', fontSize: '0.92857143em' }}>
          Additional notes <span style={{ fontWeight: 'normal' }}>(optional)</span>
        </div>
      ),
      placeholder: 'Notes',
      maxLength: '1600',
    },
  },
]

const defaultUserData = livestockUserData.filter(d => d.id !== 'livestock')

const otherUserData = defaultUserData.map(d => {
  if (d.id === 'label') {
    return {
      ...d,
      validation: {
        required: {
          value: true,
          message: 'This field is required',
        },
      },
      componentProps: {
        label: 'Please provide a label for this item',
        placeholder: 'Label',
      },
    }
  }
  return d
})

export const mapElementsConfig = {
  freestallBarn: {
    label: 'Freestall barn',
    geometryType: 'polygon',
    layerStyle: 'freestallBarn',
    userData: livestockUserData,
  },
  openLotCorral: {
    label: 'Open lot / Corral',
    geometryType: 'polygon',
    layerStyle: 'openLotCorral',
    userData: livestockUserData,
  },
  pasture: {
    label: 'Pasture',
    geometryType: 'polygon',
    layerStyle: 'pasture',
    userData: livestockUserData,
  },
  croplandIrrigated: {
    label: 'Cropland / irrigated field',
    geometryType: 'polygon',
    layerStyle: 'croplandIrrigated',
    userData: defaultUserData,
  },
  milkingParlor: {
    label: 'Milking parlor',
    geometryType: 'polygon',
    layerStyle: 'milkingParlor',
    userData: livestockUserData,
  },
  feedStorageArea: {
    label: 'Feed storage area',
    geometryType: 'polygon',
    layerStyle: 'feedStorageArea',
    userData: defaultUserData,
  },
  commodityBarn: {
    label: 'Hay/commodity barn',
    geometryType: 'polygon',
    layerStyle: 'commodityBarn',
    userData: defaultUserData,
  },
  hospitalBarn: {
    label: 'Maternity/hospital barn',
    geometryType: 'polygon',
    layerStyle: 'hospitalBarn',
    userData: livestockUserData,
  },
  calfHousing: {
    label: 'Calf housing',
    geometryType: 'polygon',
    layerStyle: 'calfHousing',
    userData: livestockUserData,
  },
  otherCovered: {
    label: 'Other covered structure',
    geometryType: 'polygon',
    layerStyle: 'otherCovered',
    userData: livestockUserData,
  },
  settlingBasin: {
    label: 'Settling basin',
    geometryType: 'polygon',
    layerStyle: 'settlingBasin',
    userData: defaultUserData,
  },
  wastewaterStorage: {
    label: 'Wastewater storage',
    geometryType: 'polygon',
    layerStyle: 'wastewaterStorage',
    userData: defaultUserData,
  },
  processingPit: {
    label: 'Processing/reception/manure pit',
    geometryType: 'polygon',
    layerStyle: 'processingPit',
    userData: defaultUserData,
  },
  compostBeddedBarn: {
    label: 'Compost bedded pack barn',
    geometryType: 'polygon',
    layerStyle: 'compostBeddedBarn',
    userData: defaultUserData,
  },
  slattedFloorStorage: {
    label: 'Slatted floor pit storage',
    geometryType: 'polygon',
    layerStyle: 'slattedFloorStorage',
    userData: defaultUserData,
  },
  manureStorageArea: {
    label: 'Manure storage area',
    geometryType: 'polygon',
    layerStyle: 'manureStorageArea',
    userData: defaultUserData,
  },
  pump: {
    label: 'Pump',
    geometryType: 'point',
    layerStyle: 'default',
    userData: defaultUserData,
  },
  drain: {
    label: 'Drain',
    geometryType: 'point',
    layerStyle: 'default',
    userData: defaultUserData,
  },
  well: {
    label: 'Well',
    geometryType: 'point',
    layerStyle: 'default',
    userData: defaultUserData,
  },
  separatorMechanical: {
    label: 'Solid separator (mechanical)',
    geometryType: 'point',
    layerStyle: 'separatorMechanical',
    userData: defaultUserData,
  },
  separatorNonMechanical: {
    label: 'Solid separator (non-mechanical)',
    geometryType: 'polygon',
    layerStyle: 'separatorNonMechanical',
    userData: defaultUserData,
  },
  composter: {
    label: 'In-vessel composter',
    geometryType: 'point',
    layerStyle: 'composter',
    userData: defaultUserData,
  },
  otherPoint: {
    label: "'Other point'",
    geometryType: 'point',
    layerStyle: 'otherPoint',
    userData: otherUserData,
  },
  weirBox: {
    label: 'Weir box',
    geometryType: 'point',
    layerStyle: 'default',
    userData: defaultUserData,
  },
  flushLane: {
    label: 'Flush lane',
    geometryType: 'polyline',
    layerStyle: 'flushLane',
    userData: defaultUserData,
  },
  flowDirection: {
    label: 'Flow direction (drainage)',
    geometryType: 'polyline',
    layerStyle: 'flowDirection',
    userData: defaultUserData,
  },
  pipeline: {
    label: 'Pipeline',
    geometryType: 'polyline',
    layerStyle: 'pipeline',
    userData: defaultUserData,
  },
  culvert: {
    label: 'Culvert/gutter',
    geometryType: 'polyline',
    layerStyle: 'culvert',
    userData: defaultUserData,
  },
  scrapedLane: {
    label: 'Scraped lane(s)',
    geometryType: 'polyline',
    layerStyle: 'scrapedLane',
    userData: defaultUserData,
  },
  vacuumLane: {
    label: 'Vacuum lane(s)',
    geometryType: 'polyline',
    layerStyle: 'vacuumLane',
    userData: defaultUserData,
  },
  sandTrapLane: {
    label: 'Sand trap / lane',
    geometryType: 'polyline',
    layerStyle: 'sandTrapLane',
    userData: defaultUserData,
  },
  otherArea: {
    label: "'Other area'",
    geometryType: 'polygon',
    layerStyle: 'otherArea',
    userData: otherUserData,
  },
  otherLine: {
    label: "'Other linear element'",
    geometryType: 'polyline',
    layerStyle: 'otherLine',
    userData: otherUserData,
  },
}
