export const ACTIONS = {
  UPDATE_LOADING_STATUS: 'UPDATE_LOADING_STATUS',
  ADVANCE_ONE_STEP: 'ADVANCE_ONE_STEP',
  BACK_ONE_STEP: 'BACK_ONE_STEP',
  UPDATE_ACTIVE_STEP: 'UPDATE_ACTIVE_STEP',
  UPDATE_SCREEN_STEP_STATUS: 'UPDATE_SCREEN_STEP_STATUS',
  UPDATE_QUERY_STATUS: 'UPDATE_QUERY_STATUS',
  UPDATE_MODAL: 'UPDATE_MODAL',
}

export const STEPS = {
  INTRO: 0,
  REGION: 1,
  RCA: 2,
  AOI: 3,
  ADD_SITES: 4,
  SELECT_SITES: 5,
  ANALYZE: 6,
  SURVEY: 7,
}

// CDFA alternative to `STEPS`
export const CDFAVIEWS = {
  intro: 1,
  list: 2,
  edit: 3,
  download: 4,
}

export function advanceOneStep() {
  return {
    type: ACTIONS.ADVANCE_ONE_STEP,
  }
}

export function backOneStep() {
  return {
    type: ACTIONS.BACK_ONE_STEP,
  }
}

export function updateLoadingStatus(isLoading, hideSpinner = false, loadingMessage = '') {
  return {
    type: ACTIONS.UPDATE_LOADING_STATUS,
    isLoading,
    hideSpinner,
    loadingMessage,
  }
}

export function updateActiveStep(activeStep) {
  return {
    type: ACTIONS.UPDATE_ACTIVE_STEP,
    activeStep,
  }
}

export function updateScreenStepStatus(skippedScreen) {
  return {
    type: ACTIONS.UPDATE_SCREEN_STEP_STATUS,
    skippedScreen,
  }
}

export function updateQueryStatus(isQuerying) {
  return {
    type: ACTIONS.UPDATE_QUERY_STATUS,
    isQuerying,
  }
}

export function updateModal(modal) {
  return {
    type: ACTIONS.UPDATE_MODAL,
    modal,
  }
}
