import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox, Form, List, Table } from 'semantic-ui-react'

import { percent, precision } from '../../utils/formatters'

const FARMLAND_CODE = {
  P: 'Prime Farmland',
  S: 'Farmland of Statewide Importance',
  L: 'Farmland of Local Importance',
  U: 'Unique Farmland',
  G: 'Grazing Land',
  Cl: 'Confined Animal Agriculture',
  V: 'Vacant or Disturbed Land',
  Z: 'Area Not Mapped',
}

const renderer = ({ id, data }, { shapeMeta }) => {
  if (!data) {
    return 'No data found.'
  }

  return (
    <Table textAlign="center">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Type</Table.HeaderCell>
          <Table.HeaderCell>Area (acres)</Table.HeaderCell>
          <Table.HeaderCell>%</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {Object.entries(FARMLAND_CODE).map(([code, label]) => {
          if (code in data) {
            return (
              <Table.Row key={`${id}_${code}`}>
                <Table.Cell>{label}</Table.Cell>
                <Table.Cell>{precision(data[code], 1)}</Table.Cell>
                <Table.Cell>{percent((100 * data[code]) / shapeMeta[id].area)}</Table.Cell>
              </Table.Row>
            )
          }
          return null
        })}
      </Table.Body>
    </Table>
  )
}

const table = (dataset, data) => {
  const rows = [['Code', 'Type', 'Area (acres)']]
  if (data) {
    Object.entries(data).forEach(([code, area]) => rows.push([code, FARMLAND_CODE[code], precision(area, 1)]))
  }
  return rows
}

class Filter extends React.Component {
  constructor(props) {
    super(props)

    this.defaultValues = {
      P: false,
      S: false,
      L: false,
      U: false,
      G: false,
      Cl: false,
      V: false,
    }
    const initValues = (props.initValues[0] && props.initValues[0][2]) || []
    this.state = {
      P: initValues.indexOf('P') !== -1,
      S: initValues.indexOf('S') !== -1,
      L: initValues.indexOf('L') !== -1,
      U: initValues.indexOf('U') !== -1,
      G: initValues.indexOf('G') !== -1,
      Cl: initValues.indexOf('Cl') !== -1,
      V: initValues.indexOf('V') !== -1,
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.useDefaultValues && !this.props.useDefaultValues) {
      this.setState({ ...this.defaultValues }, () =>
        this.props.onChange({
          farmland: null,
        }),
      )
    }
  }

  handleFarmlandTypeChange = (e, props) => {
    const updatedFarmlandTypes = {
      [props.value]: props.checked,
    }

    this.setState(
      state => ({ ...state, ...updatedFarmlandTypes }),
      () => this.handleValueChange(),
    )
  }

  handleValueChange() {
    const conditions = {
      farmland: null,
    }

    const polygon_ty = []
    Object.entries(this.state).forEach(([label, value]) => {
      if (value) {
        polygon_ty.push(label)
      }
    })
    if (polygon_ty.length) {
      conditions.farmland = [['farmland.polygon_ty', '!=', polygon_ty]]
    }

    this.props.onChange(conditions)
  }

  render() {
    return (
      <Form.Field>
        <small className="fieldHint">
          Avoid areas with the selected <b>farmland type</b>
        </small>
        <List>
          <List.Item>
            <Checkbox
              checked={this.props.isStatic ? this.props.initValues[0][2].indexOf('P') !== -1 : this.state.P}
              value="P"
              label="Prime Farmland"
              onChange={this.handleFarmlandTypeChange}
            />
          </List.Item>
          <List.Item>
            <Checkbox
              checked={this.props.isStatic ? this.props.initValues[0][2].indexOf('S') !== -1 : this.state.S}
              value="S"
              label="Farmland of Statewide Importance"
              onChange={this.handleFarmlandTypeChange}
            />
          </List.Item>
          <List.Item>
            <Checkbox
              checked={this.props.isStatic ? this.props.initValues[0][2].indexOf('L') !== -1 : this.state.L}
              value="L"
              label="Farmland of Local Importance"
              onChange={this.handleFarmlandTypeChange}
            />
          </List.Item>
          <List.Item>
            <Checkbox
              checked={this.props.isStatic ? this.props.initValues[0][2].indexOf('U') !== -1 : this.state.U}
              value="U"
              label="Unique Farmland"
              onChange={this.handleFarmlandTypeChange}
            />
          </List.Item>
          <List.Item>
            <Checkbox
              checked={this.props.isStatic ? this.props.initValues[0][2].indexOf('G') !== -1 : this.state.G}
              value="G"
              label="Grazing Land"
              onChange={this.handleFarmlandTypeChange}
            />
          </List.Item>
          <List.Item>
            <Checkbox
              checked={this.props.isStatic ? this.props.initValues[0][2].indexOf('Cl') !== -1 : this.state.Cl}
              value="Cl"
              label="Confined Animal Agriculture"
              onChange={this.handleFarmlandTypeChange}
            />
          </List.Item>
          <List.Item>
            <Checkbox
              checked={this.props.isStatic ? this.props.initValues[0][2].indexOf('V') !== -1 : this.state.V}
              value="V"
              label="Vacant or Disturbed Land"
              onChange={this.handleFarmlandTypeChange}
            />
          </List.Item>
        </List>
      </Form.Field>
    )
  }
}

Filter.defaultValue = null
Filter.ignoreReset = true

Filter.propTypes = {
  dataset: PropTypes.shape({}).isRequired,
  useDefaultValues: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  initValues: PropTypes.arrayOf(PropTypes.array).isRequired,
  isStatic: PropTypes.bool.isRequired,
}

export default {
  renderer,
  table,
  filter: Filter,
}
