import React from 'react'
import { format, scaleLinear, select } from 'd3'
import PropTypes from 'prop-types'

import { percent } from '../utils/formatters'

const DEFAULT_META = {
  width: 450,
  barHeight: 20,
  paddingBottom: 0,
  gap: 25,
  labelPosDistance: 5,
  posX: 0,
  posY: 20,
  showTooltip: true,
  tooltipTemplate:
    '<div class="ui card">' +
    '<div class="content"><h4 class="ui header">$title</h4></div>' +
    '<div class="content">' +
    '<span style="display:inline-block;background-color:$color;width:20px;height:20px;"></span>' +
    '<span>$leftValue</span>' +
    '<span style="float:right;">$rightValue</span></div>' +
    '<div class="extra content">$extraContent</div>' +
    '</div>',
}

const VerticalBarChart = ({ data, meta }) => {
  const barHeight = meta.barHeight || DEFAULT_META.barHeight
  const paddingBottom = meta.paddingBottom || DEFAULT_META.paddingBottom
  const gap = meta.barHeight || DEFAULT_META.gap
  const labelPosDistance = meta.barHeight || DEFAULT_META.labelPosDistance
  const posX = meta.barHeight || DEFAULT_META.posX
  const posY = meta.barHeight || DEFAULT_META.posY

  const chartHeight = (data.length + 1 + paddingBottom) * (barHeight + gap)

  const toPct = meta.convertToPercent
  const x = scaleLinear()
    .range([0, (meta.width || DEFAULT_META.width) - 20])
    .domain(toPct ? [-10, 120] : meta.range)

  const sumTotal = meta.convertToPercent ? data.reduce((d1, d2) => ({ value: d1.value + d2.value })).value : 0

  const showTooltip = meta.showTooltip || (meta.showTooltip !== false && DEFAULT_META.showTooltip)
  const tooltipRef = React.useRef(null)

  return (
    <>
      <svg width={meta.width || DEFAULT_META.width} height={chartHeight}>
        <g>
          {data.map((d, idx) => {
            const { value } = d

            const barX = x(posX)
            const barY = idx * (barHeight + gap) + barHeight

            let barWidth
            let label = `${parseFloat(format('.1f')(value)).toLocaleString()}${` ${meta.unit || ''}`}`
            if (toPct) {
              const pct = (100 * d.value) / sumTotal || 0
              barWidth = x(pct - 10)
              label = `${label} (${percent(pct)})`
              d.pct = pct
            } else {
              barWidth = x(value)
            }
            return (
              <React.Fragment key={d.label}>
                <text x={barX + 5} y={barY - labelPosDistance}>
                  {d.label}:&nbsp;
                  <tspan fill="#888">{label}</tspan>
                </text>
                <rect
                  x={barX}
                  y={barY}
                  width={barWidth}
                  height={barHeight}
                  fill={d.color}
                  stroke="#555"
                  strokeWidth="0.5"
                  onMouseEnter={e => {
                    if (showTooltip) {
                      const extraContent =
                        typeof meta.tooltipContent === 'function' ? meta.tooltipContent(d) : meta.tooltipContent || ''
                      const tooltip = select(tooltipRef.current)
                      tooltip
                        .style('opacity', 0.9)
                        .style('left', `${barWidth}px`)
                        .style('top', `${barY + 30}px`)
                      tooltip.select('.content').html(
                        DEFAULT_META.tooltipTemplate
                          .replace('$title', d.label)
                          .replace('$color', d.color)
                          .replace('$leftValue', `${parseFloat(format('.1f')(d.value)).toLocaleString()} ${meta.unit}`)
                          .replace('$rightValue', d.pct ? percent(d.pct) : '')
                          .replace('$extraContent', extraContent),
                      )
                    }
                  }}
                  onMouseLeave={() => {
                    const tooltip = select(tooltipRef.current)
                    tooltip.style('opacity', 0)
                    tooltip.select('.content').html('')
                  }}
                />
              </React.Fragment>
            )
          })}
        </g>
        <line
          x1={x(posX)}
          y1={posY}
          x2={x(posX)}
          y2={posY + (chartHeight - paddingBottom * (barHeight + gap)) - 2 * gap - barHeight}
          stroke="#444"
          strokeWidth="0.5"
        />
        {meta.xLabel ? (
          <text
            x={(x.range()[0] + x.range()[1]) / 3}
            y={posY + (chartHeight - paddingBottom * (barHeight + gap)) - barHeight - gap}
          >
            {meta.xLabel}
          </text>
        ) : null}
        {meta.yLabel ? (
          <text
            x={-posY - (chartHeight - paddingBottom * (barHeight + gap) - gap) / 2}
            y={x(posX) - 10}
            transform="rotate(-90)"
          >
            {meta.yLabel}
          </text>
        ) : null}
      </svg>
      <div ref={tooltipRef} className="verticalChartTooltip">
        <div className="content" />
      </div>
    </>
  )
}

VerticalBarChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  meta: PropTypes.shape({
    range: PropTypes.array,
    paddingBottom: PropTypes.number,
    convertToPercent: PropTypes.bool,
    unit: PropTypes.string,
    tooltipContent: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    showTooltip: PropTypes.bool,
    barHeight: PropTypes.number,
    width: PropTypes.number,
    xLabel: PropTypes.string,
    yLabel: PropTypes.string,
  }),
}

VerticalBarChart.defaultProps = {
  meta: {},
}

export default VerticalBarChart
