import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox, Form } from 'semantic-ui-react'

import { percent, precision } from '../../utils/formatters'

const renderer = ({ id, area }, { shapeMeta }) =>
  area
    ? `${precision(area, 1)} acres intersect with SWAP areas. (~${percent(100 * (area / shapeMeta[id].area))})`
    : 'No intersection with SWAP areas.'

const table = (dataset, data) => [['Area (acres)'], [data ? data.area : '']]

class Filter extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      avoid_SWAP: props.initValues.length > 0,
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.useDefaultValues && !this.props.useDefaultValues) {
      this.setState(
        { avoid_SWAP: false },
        this.props.onChange({ [`${this.props.dataset.dataset_id}.conservati`]: null }),
      )
    }
  }

  handleValueChange = () => {
    this.props.onChange({
      [this.props.dataset.dataset_id]: this.state.avoid_SWAP
        ? [[`${this.props.dataset.dataset_id}.conservati`, '==', null]]
        : null,
    })
  }

  render() {
    return (
      <Form.Field>
        <Checkbox
          label="Avoid SWAP Area"
          checked={this.state.avoid_SWAP}
          onChange={(e, props) => this.setState({ avoid_SWAP: props.checked }, () => this.handleValueChange())}
        />
      </Form.Field>
    )
  }
}

Filter.defaultValue = null
Filter.ignoreReset = true

Filter.propTypes = {
  dataset: PropTypes.shape({
    dataset_id: PropTypes.string,
  }).isRequired,
  useDefaultValues: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  initValues: PropTypes.arrayOf(PropTypes.array).isRequired,
  isStatic: PropTypes.bool.isRequired,
}

export default {
  renderer,
  table,
  filter: Filter,
}
