import axios from 'axios'

export function fetchRCAResults(tasks, callback) {
  tasks.forEach(task =>
    axios
      .get(Urls['ncdjango:geoprocessing:processingjob-detail'](task.taskId))
      .then(response => {
        switch (response.data.status) {
          case 'success':
            callback(response.data.status, response.data.outputs, task.shapeId)
            break
          case 'started':
          case 'pending':
            setTimeout(() => fetchRCAResults([task], callback), 1000)
            break
          case 'error':
          default:
            callback('error')
            break
        }
      })
      .catch(() => ({ status: 'error' })),
  )
}

export function fetchQueryResults(taskIds, callback) {
  taskIds.forEach(taskId =>
    axios
      .post(Urls['api:query'](), {
        action: 'results',
        task_id: taskId,
      })
      .then(response => {
        switch (response.data.status) {
          case 'success':
            callback(response.data.status, response.data.results)
            break
          case 'started':
          case 'pending':
            setTimeout(() => fetchQueryResults([taskId], callback), 1000)
            break
          case 'error':
          default:
            callback('error')
            break
        }
      })
      .catch(() => callback('error')),
  )
}

export function getSubmissions(callback) {
  axios
    .get(Urls['propositions:get-submissions'](window.PROPOSITION.slug))
    .then(response => {
      const submissions = JSON.parse(response.data)
      submissions.sort((s1, s2) => {
        if (s1.survey.proposal_number > s2.survey.proposal_number) {
          return 1
        }
        if (s1.survey.proposal_number < s2.survey.proposal_number) {
          return -1
        }
        return 0
      })
      callback(submissions)
    })
    .catch(error => {
      console.log(error)
    })
}

export function updateSubmission(submissionId, parameters, callback) {
  const formData = new FormData()
  Object.entries(parameters).forEach(([key, value]) => {
    formData.set(key, value)
  })
  axios
    .post(Urls['propositions:edit-submission'](submissionId), formData, {
      headers: { 'Content-Type': 'form/multipart' },
    })
    .then(() => {
      if (callback) {
        getSubmissions(callback)
      }
    })
    .catch(error => {
      console.log(error)
    })
}

export function deleteSubmission(submissionId, callback) {
  axios
    .post(Urls['propositions:delete-submission'](submissionId))
    .then(() => getSubmissions(callback))
    .catch(error => {
      console.log(error)
    })
}
