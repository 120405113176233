import { polygon as turfPolygon } from '@turf/helpers'
import turfIntersect from '@turf/intersect'

import { RCA_POLYGONS } from '../containers/Map'

export const ACTIONS = {
  RESET_INPUTS: 'RESET_INPUTS',
  MERGE_INPUTS: 'MERGE_INPUTS',
  UPDATE_REGIONS: 'UPDATE_REGIONS',
  UPDATE_SELECTED_SITES: 'UPDATE_SELECTED_SITES',
}

export function resetInputs() {
  return {
    type: ACTIONS.RESET_INPUTS,
  }
}

export function mergeInputs(key, inputs) {
  return {
    type: ACTIONS.MERGE_INPUTS,
    key,
    inputs,
  }
}

export function updateRegions(selectedRegions, customRegionLayer) {
  const rcas = new Set()
  const customRegions = customRegionLayer.getLayers()

  Object.entries(RCA_POLYGONS).forEach(([rcaValue, rcaPolygon]) => {
    for (let i = customRegions.length; i > 0; i -= 1) {
      const layer = customRegions[i - 1].toGeoJSON()
      const layerType = layer.properties.HUC4 ? 'watershed' : 'ecoregion'
      let intersects = false

      if (rcaValue === layer.properties.value) {
        rcas.add(rcaValue)
        intersects = true
        break
      } else if (layer.geometry.type === 'MultiPolygon') {
        for (let j = layer.geometry.coordinates; j > 0; j -= 1) {
          const selectedRegion = turfPolygon(layer.geometry.coordinates[j])
          if (turfIntersect(selectedRegion, rcaPolygon) && layerType !== rcaPolygon.properties.type) {
            rcas.add(rcaValue)
            intersects = true
            break
          }
        }
      } else {
        const selectedRegion = turfPolygon(layer.geometry.coordinates)
        if (turfIntersect(selectedRegion, rcaPolygon) && layerType !== rcaPolygon.properties.type) {
          rcas.add(rcaValue)
          intersects = true
        }
      }
      if (intersects) {
        break
      }
    }
  })
  return {
    type: ACTIONS.UPDATE_REGIONS,
    selectedRegions,
    rcas: Array.from(rcas),
  }
}

export function updateSelectedSites(selectedSites, rcas) {
  return {
    type: ACTIONS.UPDATE_SELECTED_SITES,
    selectedSites,
    rcas,
  }
}
