import React from 'react'
import { Table, Checkbox, Form } from 'semantic-ui-react'
import PropTypes from 'prop-types'

const renderer = ({ data }) => {
  if (!data) {
    return 'No data found.'
  }

  return (
    <Table textAlign="center">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Easement holder</Table.HeaderCell>
          <Table.HeaderCell>Type</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {data.map(({ holder, type }, idx) => (
          <Table.Row key={idx}>
            <Table.Cell>{holder}</Table.Cell>
            <Table.Cell>{type}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  )
}

const table = (dataset, data) => {
  const rows = [['Easement holder', 'Type']]
  if (data) {
    Object.values(data).forEach(d => rows.push([d.holder, d.type]))
  }
  return rows
}

class Filter extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      avoid_ces: props.initValues.length > 0,
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.useDefaultValues && !this.props.useDefaultValues) {
      this.setState({ avoid_ces: false }, this.props.onChange({ [this.props.dataset.dataset_id]: null }))
    }
  }

  handleValueChange = () => {
    this.props.onChange({
      [this.props.dataset.dataset_id]: this.state.avoid_ces
        ? [[`${this.props.dataset.dataset_id}.ease_label`, '==', null]]
        : null,
    })
  }

  render() {
    return (
      <Form.Field>
        <Checkbox
          label="Avoid Conservation Easements"
          checked={this.state.avoid_ces}
          onChange={(e, props) => this.setState({ avoid_ces: props.checked }, this.handleValueChange)}
        />
      </Form.Field>
    )
  }
}

Filter.defaultValue = null
Filter.ignoreReset = true

Filter.propTypes = {
  dataset: PropTypes.shape({
    dataset_id: PropTypes.string,
  }).isRequired,
  useDefaultValues: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  initValues: PropTypes.arrayOf(PropTypes.array).isRequired,
  isStatic: PropTypes.bool.isRequired,
}

export default {
  renderer,
  table,
  filter: Filter,
}
