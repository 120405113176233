import React from 'react'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import { connect } from 'react-redux'
import { Dimmer, Grid, Label, Loader, Segment, Sidebar } from 'semantic-ui-react'

import { resetInputs, updateSelectedSites } from '../actions/inputs'
import { updateActiveStep, updateLoadingStatus } from '../actions/page'
import { updateIdentifyTarget } from '../actions/map'
import { loadState } from '../actions/save'
import Layers from './Layers'
import { initMap } from './Map'
import CaveatModal from './CaveatModal'
import UnsupportedBrowser from './UnsupportedBrowser'
import { isUnsupported } from '../utils/browser'
import LayoutContext from '../context'

class Layout extends React.Component {
  constructor(props) {
    super(props)

    this.extraSidebars = [
      {
        isOpen: false,
        label: {
          id: 'LayersSidebarTab',
          text: 'Layers',
        },
        component: Layers,
      },
    ].concat(props.extraSidebars)

    const sidebarsOpenStatus = {}
    this.extraSidebars.forEach(({ isOpen, label: { id } }) => {
      sidebarsOpenStatus[id] = isOpen
    })

    this.state = {
      sidebarsOpenStatus,
      isMapReady: false,
    }
  }

  componentDidMount() {
    if (Object.keys(this.props.datasets).length && Object.keys(this.props.targets).length) {
      this.map = initMap(this.props.store, this.props.datasets, this.props.targets)
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({ isMapReady: true }, () => {
        // Load state from unique hash in the query string (e.g., '?s=17Vhue8Io28')
        const { s } = queryString.parse(window.location.search)
        if (s) {
          this.props.loadState(s, this.map)
        }
      })
    }
  }

  componentDidUpdate(nextProps) {
    if (!this.state.isMapReady && Object.keys(nextProps.datasets).length && Object.keys(nextProps.targets).length) {
      this.map = initMap(this.props.store, nextProps.datasets, nextProps.targets)
      this.setState({ isMapReady: true }, () => {
        // Load state from unique hash in the query string (e.g., '?s=17Vhue8Io28')
        const { s } = queryString.parse(window.location.search)
        if (s) {
          this.props.loadState(s, this.map)
        }
      })
    }
  }

  handleExtraSidebarTabsClick = (id, action = 'toggle') => {
    this.setState(state => {
      Object.keys(state.sidebarsOpenStatus).forEach(sidebar => {
        if (sidebar === id) {
          switch (action) {
            case 'open':
              state.sidebarsOpenStatus[sidebar] = true
              break
            case 'close':
              state.sidebarsOpenStatus[sidebar] = false
              break
            case 'toggle':
            default:
              state.sidebarsOpenStatus[sidebar] = !state.sidebarsOpenStatus[sidebar]
          }
        } else {
          state.sidebarsOpenStatus[sidebar] = false
        }
      })
      return state
    })
  }

  render() {
    const isIdentifyActive = this.props.identifyTarget.length > 0
    const SidebarContent = this.props.sidebarContent
    return (
      <LayoutContext.Provider value={{ map: this.map, handleExtraSidebarVisibility: this.handleExtraSidebarTabsClick }}>
        <Segment id="Layout" basic disabled={this.props.isPageLoading}>
          {isUnsupported ? <UnsupportedBrowser /> : null}
          <Dimmer inverted active={this.props.isPageLoading}>
            <Loader
              inverted
              active={this.props.isPageLoading}
              className={this.props.hideSpinner ? 'hideSpinner' : null}
            >
              {this.props.loadingMessage}
            </Loader>
          </Dimmer>

          {this.props.header}

          <Grid className="page-content no-padding">
            <Grid.Row>
              <Grid.Column className="main-sidebar">
                {this.state.isMapReady ? <SidebarContent store={this.props.store} /> : null}
              </Grid.Column>
              <Grid.Column id="MapContainer" className={isIdentifyActive ? 'isIdentifyActive' : ''}>
                <Sidebar.Pushable>
                  <Sidebar
                    className="second-sidebar"
                    animation="overlay"
                    visible={Object.values(this.state.sidebarsOpenStatus).some(isOpen => isOpen)}
                  >
                    {this.state.isMapReady ? (
                      <Segment basic>
                        {this.extraSidebars.map(
                          ({ label: { id }, component: Component }) =>
                            this.state.sidebarsOpenStatus[id] && <Component key={id} />,
                        )}
                      </Segment>
                    ) : null}
                  </Sidebar>
                  <Sidebar.Pusher>
                    {this.extraSidebars.map(({ label: { id, text } }) => (
                      <Label
                        key={id}
                        as="a"
                        id={id}
                        className={
                          Object.values(this.state.sidebarsOpenStatus).some(isOpen => isOpen) ? 'openSidebar' : null
                        }
                        color="grey"
                        onClick={() => this.handleExtraSidebarTabsClick(id)}
                      >
                        <h3>{text}</h3>
                      </Label>
                    ))}
                    <div id="Map" />
                  </Sidebar.Pusher>
                </Sidebar.Pushable>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <CaveatModal active={this.props.modal.active} text={this.props.modal.text} />
            </Grid.Row>
          </Grid>

          {this.props.footer}
        </Segment>
      </LayoutContext.Provider>
    )
  }
}

Layout.propTypes = {
  // parent props
  store: PropTypes.shape({}).isRequired,
  header: PropTypes.node,
  footer: PropTypes.node,
  sidebarContent: PropTypes.object.isRequired,
  extraSidebars: PropTypes.arrayOf(PropTypes.shape({})),
  // redux props
  targets: PropTypes.shape({}).isRequired,
  datasets: PropTypes.shape({}).isRequired,
  isPageLoading: PropTypes.bool.isRequired,
  hideSpinner: PropTypes.bool.isRequired,
  loadingMessage: PropTypes.string.isRequired,
  identifyTarget: PropTypes.arrayOf(PropTypes.string).isRequired,
  // redux actions
  loadState: PropTypes.func.isRequired,
}

Layout.defaultProps = {
  header: null,
  footer: null,
  extraSidebars: [],
}

const mapStateToProps = state => ({
  skipHome: state.getIn(['page', 'skipHome']),
  targets: state.getIn(['config', 'targets']).toJS(),
  datasets: state.getIn(['config', 'datasets']).toJS(),
  isPageLoading: state.getIn(['page', 'isLoading']),
  hideSpinner: state.getIn(['page', 'hideSpinner']),
  loadingMessage: state.getIn(['page', 'loadingMessage']),
  identifyTarget: state.getIn(['map', 'identifyTarget']).toJS(),
  modal: state.getIn(['page', 'modal']),
  modalDisplayed: state.getIn(['page', 'modalDisplayed']),
})

export default connect(mapStateToProps, {
  updateActiveStep,
  updateSelectedSites,
  resetInputs,
  updateLoadingStatus,
  loadState,
  updateIdentifyTarget,
})(Layout)
