import React from 'react'
import { Header, Table } from 'semantic-ui-react'

import { precision } from '../../utils/formatters'

const renderer = ({ data }) => {
  if (!data) {
    return 'No data found.'
  }

  let currentTheme
  return (
    <Table>
      <Table.Body>
        {data.map(d => {
          const rows = []
          if (d.theme !== currentTheme) {
            rows.push(
              <Table.Row key={d.theme} style={{ backgroundColor: '#eee' }}>
                <Table.Cell colSpan="2" textAlign="center">
                  <Header>{d.theme}</Header>
                </Table.Cell>
              </Table.Row>,
            )
          }
          currentTheme = d.theme

          rows.push(
            <Table.Row key={d.cname}>
              <Table.Cell>
                <Header size="small">
                  {d.cname}
                  <Header.Subheader>{d.sname}</Header.Subheader>
                </Header>
              </Table.Cell>
              <Table.Cell>
                {precision(d.measurement, 1)}
                &nbsp;
                {d.type === 'line' ? 'miles' : 'acres'}
              </Table.Cell>
            </Table.Row>,
          )
          return rows
        })}
      </Table.Body>
    </Table>
  )
}

const table = (dataset, data) => {
  const rows = [['Theme', 'Species', 'Scientific Name', 'Measurement', 'Value']]
  if (data) {
    data.forEach(d =>
      rows.push([
        d.theme,
        d.cname,
        d.sname,
        d.type === 'line' ? 'Length (miles)' : 'Area (acres)',
        precision(d.measurement, 1),
      ]),
    )
  }
  return rows
}

export default {
  renderer,
  table,
}
