export const ACTIONS = {
  UPDATE_USER: 'UPDATE_USER',
}

export function updateUser(user) {
  return {
    type: ACTIONS.UPDATE_USER,
    user,
  }
}
