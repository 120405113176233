import ACE from './ACE'
import AquaticConservationBlueprint from './AquaticConservationBlueprint'
import Boundaries from './Boundaries'
import Carbon from './Carbon'
import CCE from './CCE'
import CCED from './CCED'
import CNDDB from './CNDDB'
import CSS from './CSS'
import DCH from './DCH'
import DisadvantagedCommunities from './DisadvantagedCommunities'
import Farmland from './Farmland'
import GroundwaterDependentEcosystems from './GroundwaterDependentEcosystems'
import IBA from './IBA'
import Intersects from './Intersects'
import GeneralPlans from './GeneralPlans'
import Marxan from './Marxan'
import NativeFreshwaterSpecies from './NativeFreshwaterSpecies'
import PAD_GAP from './PAD_GAP'
import PAD_OWNER from './PAD_OWNER'
import RCA from './RCA'
import RCA_DWR from './RCA_DWR'
import SAGBI from './SAGBI'
import SWAP from './SWAP'
import TI from './TI'
import Wetlands from './Wetlands'

export default {
  ab1550_sb535: DisadvantagedCommunities,
  ace_aqua_biodiversity: ACE,
  ace_aqua_fish_ntv: ACE,
  ace_aqua_fish_rar: ACE,
  ace_aqua_invt_ntv: ACE,
  ace_aqua_rept_ntv: ACE,
  ace_aqua_rept_rar: ACE,
  ace_terr_biodiversity: ACE,
  ace_terr_amph_ntv: ACE,
  ace_terr_amph_rar: ACE,
  ace_terr_bird_ntv: ACE,
  ace_terr_bird_rar: ACE,
  ace_terr_mamm_ntv: ACE,
  ace_terr_mamm_rar: ACE,
  ace_terr_rept_ntv: ACE,
  ace_terr_rept_rar: ACE,
  ace_terr_plnt_ntv: ACE,
  ace_terr_plnt_rar: ACE,
  aquatic_conservation_blueprint: AquaticConservationBlueprint,
  carbon: Carbon,
  cce: CCE,
  cced: CCED,
  cdfw_connectivity: ACE,
  cnddb: CNDDB,
  counties: Boundaries,
  css: CSS,
  cv_aquatic_rca_sac: RCA_DWR,
  cv_aquatic_rca_san_j: RCA_DWR,
  cv_aquatic_rca_tulare_buena: RCA_DWR,
  cv_chinook_fall_esu: Intersects,
  cv_chinook_spring_ch: Intersects,
  cv_chinook_spring_esu: Intersects,
  cv_steelhead_ch: Intersects,
  cv_steelhead_esu: Intersects,
  cv_terr_rca_all_great_valley: RCA_DWR,
  cv_terr_rca_legal_great_valley: RCA_DWR,
  dch: DCH,
  delta_smelt_ch: Intersects,
  farmland: Farmland,
  general_plans: GeneralPlans,
  green_sturgeon_ch: Intersects,
  groundwater_dependent_ecosystems: GroundwaterDependentEcosystems,
  iba: IBA,
  marxan: Marxan,
  native_freshwater_species: NativeFreshwaterSpecies,
  pad_gap_status: PAD_GAP,
  pad_owner_type: PAD_OWNER,
  rca: RCA,
  sac_chinook_winter_esu: Intersects,
  sac_chinook_winter_ch: Intersects,
  sagbi: SAGBI,
  swap: SWAP,
  ti: TI,
  wetlands: Wetlands,
}
