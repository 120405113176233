// Boundaries can be used for those datasets that only report a list of one attribute of all the intersecting geometries
import React from 'react'
import { List } from 'semantic-ui-react'

const renderer = ({ data }) => {
  if (!data) {
    return 'No data found.'
  }
  return (
    <List bulleted>
      {data.map(d => (
        <List.Item key={d}>{d}</List.Item>
      ))}
      ))}
    </List>
  )
}

const table = (dataset, data) => {
  const rows = [[dataset.label]]
  if (data) {
    Object.values(data).forEach(d => rows.push([d]))
  }
  return rows
}

export default { renderer, table }
