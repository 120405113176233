import React from 'react'
import PropTypes from 'prop-types'
import { Grid, List, Image } from 'semantic-ui-react'

import logo from '../images/logo_cbi_sm.svg'

const BaseFooter = ({ children }) => (
  <Grid padded>
    <Grid.Row id="Footer">
      <Grid.Column verticalAlign="middle">
        <List horizontal>
          {children}
        </List>

        <List horizontal floated="right">
          <List.Item>
            <List.Content>
              Created by the&nbsp;
              <a href="http://consbio.org" target="_blank" rel="noopener noreferrer">
                Conservation Biology Institute
              </a>
            </List.Content>
          </List.Item>

          <List.Item as="a" href="http://consbio.org" target="_blank">
            <Image id="LogoCBI" src={logo} />
          </List.Item>
        </List>
      </Grid.Column>
    </Grid.Row>
  </Grid>
)

BaseFooter.propTypes = {
  children: PropTypes.node,
}

BaseFooter.defaultProps = {
  children: null,
}

export default BaseFooter
