import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'rc-slider'
import { Form } from 'semantic-ui-react'

import RangeHandle from '../../components/RangeHandle'
import VerticalBarchart from '../../components/VerticalBarchart'
import { percent, precision } from '../../utils/formatters'

const COLORS = ['#536779', '#5aa39d', '#9dd16f', '#c8e680', '#fff79d']

const renderer = ({ id, breaks, data }, { dataset, shapeMeta }) => {
  if (!data) {
    return 'No data found.'
  }

  const chartData = []
  let totalIntersectedArea = 0
  breaks.forEach((br, idx) => {
    totalIntersectedArea += data[br] || 0
    chartData.push({
      label: br,
      value: data[br] || 0,
      color: COLORS[idx],
    })
  })

  const intersectionPercent = percent((100 * totalIntersectedArea) / shapeMeta[id].area)
  const intersectionAcres = precision(totalIntersectedArea, 2)

  const yLabel = dataset.dataset_id === 'ace_terr_biodiversity' ? 'Biodiversity Rank' : 'Species count'

  return (
    <div>
      <div className="resultHelp">
        {`${intersectionPercent} (${intersectionAcres.toLocaleString()} acres)`} of this shape intersects with this
        dataset.
      </div>
      <VerticalBarchart
        meta={{
          unit: 'acres',
          convertToPercent: true,
          showTooltip: false,
          xLabel: 'Area percentage',
          yLabel,
        }}
        data={chartData}
      />
    </div>
  )
}

const table = (dataset, data, shapeMeta, shape) => {
  const rows = [['Richness', 'Area']]
  if (data) {
    shape.breaks.forEach(br => {
      rows.push([br, data[br] || 0])
    })
  }
  return rows
}

class Filter extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      minValue: (props.initValues[0] && props.initValues[0][2]) || 0,
      maxValue: (props.initValues[1] && props.initValues[1][2]) || 5,
    }

    this.handleValueChange = this.handleValueChange.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.useDefaultValues && !this.props.useDefaultValues) {
      this.setState({ minValue: 0, maxValue: 5 }, () => this.props.onChange({ [this.props.dataset.dataset_id]: null }))
    }
  }

  handleValueChange([minValue, maxValue]) {
    this.setState({ minValue, maxValue }, () => {
      this.props.onChange({
        [this.props.dataset.dataset_id]:
          this.state.minValue > 0 || this.state.maxValue < 5 || this.state.minValue === this.state.maxValue
            ? [
                [`${this.props.dataset.dataset_id}.native`, '>', this.state.minValue],
                [`${this.props.dataset.dataset_id}.native`, '<=', this.state.maxValue],
              ]
            : null,
      })
    })
  }

  render() {
    let value
    if (this.props.isStatic) {
      value = [this.props.initValues[0][2], this.props.initValues[1][2]]
    } else {
      value = [this.state.minValue, this.state.maxValue]
    }
    return (
      <Form.Field className="rangeACE">
        <Slider.Range
          min={0}
          max={5}
          step={1}
          handle={RangeHandle}
          onChange={this.handleValueChange}
          value={value}
          marks={{
            0.5: '1',
            1.5: '2',
            2.5: '3',
            3.5: '4',
            4.5: '5',
          }}
        />
      </Form.Field>
    )
  }
}

Filter.defaultValue = null

Filter.propTypes = {
  dataset: PropTypes.shape({}).isRequired,
  useDefaultValues: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  initValues: PropTypes.arrayOf(PropTypes.array).isRequired,
  isStatic: PropTypes.bool.isRequired,
}

export default {
  renderer,
  table,
  filter: Filter,
}
