import { fromJS } from 'immutable'
import { ACTIONS } from '../actions/account'

const INITIAL_STATE = fromJS(CONFIG.USER)

const account = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.UPDATE_USER:
      return state.merge(action.user)
    default:
      return state
  }
}

export default account
