import React from 'react'
import { fromJS, Map as ImmMap, OrderedMap } from 'immutable'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Divider, Header, Icon } from 'semantic-ui-react'

import { precision } from '../../utils/formatters'
import { updateModal } from '../../actions/page'
import LayoutContext from '../../context'
import { RCA_VECTORS } from '../../utils/rca'

const Section = props => {
  const { data: shapes } = props
  shapes.sort(
    (s1, s2) => props.selectedSites[parseInt(s1.id, 10)].mapId - props.selectedSites[parseInt(s2.id, 10)].mapId,
  )
  if (props.data) {
    return (
      <LayoutContext.Consumer>
        {({ map }) => (
          <React.Fragment>
            <div style={{ marginBottom: 40, overflow: 'auto' }}>
              {props.dataset.overlays.map(overlay => {
                const layerName = RCA_VECTORS[overlay.url] || overlay.name
                return (
                  <Icon
                    key={layerName}
                    link
                    name="map"
                    color={map.overlays[layerName] && map.overlays[layerName].isActive ? 'orange' : 'grey'}
                    style={{ float: 'right' }}
                    onClick={() => map.toggleOverlay(layerName)}
                  />
                )
              })}
              {props.dataset.url ? (
                <a href={props.dataset.url} target="_blank" rel="noopener noreferrer">
                  <Icon
                    name="external"
                    title="View in Data Basin"
                    style={{
                      float: 'right',
                      marginRight: 10,
                    }}
                  />
                </a>
              ) : null}
              <Header floated="left" as="h3">
                {props.dataset.label}
              </Header>
              {props.dataset.disclaimer ? (
                <a
                  style={{ color: 'orange', pointer: 'cursor' }}
                  onClick={() => props.updateModal({ active: true, text: props.dataset.disclaimer })}
                >
                  {`DISCLAIMER: ${props.dataset.disclaimer.substring(0, 101)} ...[read more]`}
                </a>
              ) : null}
            </div>

            {shapes.map(shape => {
              const labels = props.selectedSites[parseInt(shape.id, 10)]
              if (labels && props.shapeMeta[shape.id]) {
                let siteLabel
                if (labels.verbose.toString() === labels.mapId.toString()) {
                  siteLabel = `Shape ${labels.verbose}`
                } else {
                  siteLabel = `${labels.verbose} (shape ${labels.mapId})`
                }
                return (
                  <div key={shape.id} className="analysis-card">
                    <div className={`analysis-card-label ${window.PROPOSITION ? 'dwr' : ''}`}>
                      <Header as="h3" className={window.PROPOSITION ? 'blue-darker' : ''}>
                        {siteLabel}
                        <Header.Subheader>{`${precision(props.shapeMeta[shape.id].area, 1)} acres`}</Header.Subheader>
                      </Header>
                    </div>
                    <div className="analysis-card-visual">
                      {props.renderer({ meta: { paddingBottom: -1 }, ...shape }, props)}
                    </div>
                  </div>
                )
              }
              return null
            })}
          </React.Fragment>
        )}
      </LayoutContext.Consumer>
    )
  }
  return null
}

Section.propTypes = {
  // parent props
  dataset: PropTypes.shape({
    dataset_id: PropTypes.string,
    label: PropTypes.string,
    overlays: PropTypes.arrayOf(PropTypes.shape({})),
    url: PropTypes.string,
    disclaimer: PropTypes.string,
  }).isRequired,
  renderer: PropTypes.func.isRequired,
  // parent actions
  // redux props
  target: PropTypes.string, // passed down to renderers
  data: PropTypes.arrayOf(PropTypes.shape([])).isRequired,
  selectedSites: PropTypes.shape({}).isRequired,
  shapeMeta: PropTypes.shape({}).isRequired, // passed down to renderers
  // redux actions
  updateModal: PropTypes.func.isRequired,
}

Section.defaultProps = {
  target: null,
}

const mapStateToProps = (state, ownProps) => {
  const species = state.getIn(['config', 'models', ownProps.ecoregion, 'species'])
  const ecosystems = state.getIn(['config', 'models', ownProps.ecoregion, 'ecosystems'])
  const specialHabitats = state.getIn(['config', 'models', ownProps.ecoregion, 'special_habitats'])
  return {
    species: species && species.toJS(),
    ecosystems: ecosystems && ecosystems.toJS(),
    specialHabitats: specialHabitats && specialHabitats.toJS(),
    data: state.getIn(['analysis', 'queryResults', ownProps.dataset.dataset_id], fromJS([])).toJS(),
    selectedSites: state.getIn(['inputs', 'sites', 'selectedSites']).toJS(),
    selectedSitesHash: state.getIn(['inputs', 'sites', 'selectedSitesHash']),
    shapeMeta: state.getIn(['analysis', 'queryResults', 'meta'], ImmMap()).toJS(),
  }
}

export default connect(mapStateToProps, { updateModal })(Section)
