import axios from 'axios'
import difference from '@turf/difference'
import union from '@turf/union'

export const cdfaQuery = (data, callback, errorCallback = () => null) => {
  // data has shape: {field_id, practice_id, geoJSON, buffer, system}
  axios
    .post(Urls['cdfa:cdfa-query'](), data)
    .then(response => {
      callback(response)
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error)
      } else {
        console.error(error)
      }
    })
}

export const geocode = (searchType, searchTerms, callback, errorCallback = () => null) => {
  let url = '/cdfa/parcels/'
  if (searchType === 'address') {
    url += `address?query=${encodeURI(searchTerms.address)}`
  } else if (searchType === 'apn') {
    url += `apn?fips=${encodeURI(searchTerms.fips)}&apn=${encodeURI(searchTerms.apn)}`
  }
  axios
    .get(url)
    .then(response => callback(response))
    .catch(error => {
      if (errorCallback) {
        errorCallback(error)
      } else {
        console.error(error)
      }
    })
}

export const downloadReport = (reportUrl, filename) => {
  const node = document.createElement('a')
  node.setAttribute('href', `${reportUrl}`)
  node.setAttribute('download', filename)
  document.body.appendChild(node)
  node.click()
  document.body.removeChild(node)
}

export const pollReport = (taskId, callback, errorCallback) => {
  axios
    .get(`/cdfa/background/tasks/${taskId}/`)
    .then(response => {
      const { status, outputs } = response.data
      if (status === 'succeeded') {
        callback()
        downloadReport(outputs.url, outputs.filename)
      } else if (status === 'failed') {
        if (errorCallback) {
          errorCallback(response.data)
        } else {
          console.error(response.data)
        }
      } else {
        setTimeout(() => pollReport(taskId, callback, errorCallback), 800)
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error)
      } else {
        console.error(error)
      }
    })
}

export const initReport = (reportType, reportData, callback, errorCallback = () => null) => {
  const reportTask = { task: reportType === 'pdf' ? 'cdfa_report' : 'cdfa_hsp_xlsx', inputs: reportData }
  axios
    .post('/cdfa/background/tasks/', reportTask)
    .then(response => {
      pollReport(response.data.uuid, callback, errorCallback)
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error)
      } else {
        console.error(error)
      }
    })
}

export const formatNumber = (number, decimals = null) => {
  const absNumber = Math.abs(number)
  let targetDecimals = decimals
  if (targetDecimals === null) {
    // guess number of decimals based on magnitude
    if (absNumber > 100 || Math.round(absNumber) === absNumber) {
      targetDecimals = 0
    } else if (absNumber > 10) {
      targetDecimals = 1
    } else if (absNumber > 1) {
      targetDecimals = 2
    } else {
      targetDecimals = 3
    }
  }

  // override targetDecimals for integer values
  if (Math.round(absNumber) === absNumber) {
    targetDecimals = 0
  }

  const factor = 10 ** targetDecimals

  // format to localeString, and manually set the desired number of decimal places
  const output = (Math.round(number * factor) / factor).toLocaleString(undefined, {
    minimumFractionDigits: targetDecimals,
    maximumFractionDigits: targetDecimals,
  })

  return output === '0.000' ? '0.001' : output
}

/**
 * Subtract cutouts from field boundary.
 *
 * @param {Object} geoJSON - FeatureSet
 * @returns GeoJSON geometry object
 */
export const cutOutCutouts = geoJSON => {
  // only the boundary is present, return it
  if (geoJSON.features.length === 1) {
    return geoJSON.features[0].geometry
  }

  const boundary = geoJSON.features.filter(({ properties: { type } }) => type === 'boundary')[0].geometry
  const cutouts = geoJSON.features
    .filter(({ properties: { type } }) => type === 'cutOut')
    .map(({ geometry }) => geometry)

  // do a rolling union to aggregate all cutouts together into a single geometry (MultiPolygon)
  const mergedCutouts =
    cutouts.length > 1
      ? cutouts.slice(1, geoJSON.features.length).reduce((prev, cur) => union(prev, cur), cutouts[0]).geometry
      : cutouts[0]

  // calculate geometric difference of boundary vs cutouts to cut them out
  const afterCutouts = difference(boundary, mergedCutouts)

  // if there was an error, possibly because of topology error or because cutouts
  // extended outside boundary, just return the boundary
  if (afterCutouts === null) {
    console.error('Error: could not calculate difference between field and cutouts')
    console.log('geometry:', JSON.stringify(geoJSON))
    return boundary
  }

  return afterCutouts.geometry
}

export const randomNumber = () => {
  return Math.floor(Math.random() * 10000000000)
}

// use with `dollars.format(<number>)`
export const dollars = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

export const dehydrateCdfa = cdfa => {
  cdfa.practices.data = Object.fromEntries(
    Object.entries(cdfa.practices.data).map(([pracUuid, prac]) => {
      // reduce configurations saved in application state to just their labels/ids
      prac.practice = prac.practiceConfig.practice
      if (prac.paymentScenarioConfig) {
        prac.paymentScenario = prac.paymentScenarioConfig.label
      }

      delete prac.speciesRecommended
      delete prac.practiceConfig
      delete prac.paymentScenarioConfig
      return [pracUuid, prac]
    }),
  )

  cdfa.fields.data = Object.fromEntries(
    Object.entries(cdfa.fields.data).map(([fieldUuid, field]) => {
      delete field.acres
      delete field.totalAcres
      delete field.feet
      delete field.longitude
      delete field.latitude
      delete field.parcels
      delete field.priorityPopulation
      return [fieldUuid, field]
    }),
  )

  return cdfa
}
