import React from 'react'
import { fromJS } from 'immutable'
import Slider from 'rc-slider'
import { Form, Table } from 'semantic-ui-react'

import PropTypes from 'prop-types'
import RangeHandle from '../../components/RangeHandle'
import { precision } from '../../utils/formatters'

const MAPPING_DATA = fromJS({
  total: {
    color: '#666',
    label: 'Total',
    value: 0,
  },
})

const renderer = ({ data }) => {
  if (!data) {
    return 'No data found.'
  }

  return (
    <Table textAlign="center">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Type</Table.HeaderCell>
          <Table.HeaderCell>Mega grams of Carbon</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row>
          <Table.Cell>Total Carbon</Table.Cell>
          <Table.Cell>{precision(data.total, 0).toLocaleLowerCase()}</Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  )
}

const table = (dataset, data = {}) => {
  const rows = [['', 'Mega grams of Carbon']]

  MAPPING_DATA.entrySeq().forEach(([k, v]) => {
    rows.push([v.get('label'), precision(data[k] || 0, 1)])
  })
  return rows
}

class Filter extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      minValue: (props.initValues[0] && this.valueToRange(props.initValues[0][2])) || 0,
      maxValue: (props.initValues[1] && this.valueToRange(props.initValues[1][2])) || 98,
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.useDefaultValues && !this.props.useDefaultValues) {
      this.setState(
        {
          minValue: 0,
          maxValue: 98,
        },
        () => this.props.onChange({ [this.props.dataset.dataset_id]: null }),
      )
    }
  }

  linearScaleAtEachBreak = input => {
    const sliderGapSize = 14
    const naturalBreaks = [0, 41, 90, 147, 222, 306, 510, 1108]
    const sliderBreaks = new Array(naturalBreaks.length).fill(0).map((discard, idx) => idx * sliderGapSize)
    if (input === sliderBreaks[sliderBreaks.length - 1]) {
      return naturalBreaks[naturalBreaks.length - 1]
    }
    const diffs = naturalBreaks.map((br, idx) => (naturalBreaks[idx + 1] ? naturalBreaks[idx + 1] - br : null))
    diffs.pop()
    const closestBreakToInput = sliderBreaks.map(el => el <= input).indexOf(false) - 1
    return Math.floor(
      naturalBreaks[closestBreakToInput] +
        ((input - sliderBreaks[closestBreakToInput]) / sliderGapSize) * diffs[closestBreakToInput],
    )
  }

  valueToRange = input => {
    const sliderGapSize = 14
    const naturalBreaks = [0, 41, 90, 147, 222, 306, 510, 1108]
    if (input === naturalBreaks[naturalBreaks.length - 1]) {
      return 98
    }
    const diffs = naturalBreaks.map((br, idx) => (naturalBreaks[idx + 1] ? naturalBreaks[idx + 1] - br : null))
    diffs.pop()
    const closestBreakToInput = naturalBreaks.map(el => el <= input).indexOf(false) - 1
    return Math.floor(
      sliderGapSize * ((input - naturalBreaks[closestBreakToInput]) / diffs[closestBreakToInput]) +
        closestBreakToInput * sliderGapSize,
    )
  }

  handleValueChange = ([minValue, maxValue]) => {
    this.setState(
      {
        minValue,
        maxValue,
      },
      () => {
        this.props.onChange({
          [this.props.dataset.dataset_id]:
            this.state.minValue > 0 || this.state.maxValue < 98
              ? [
                  [
                    `${this.props.dataset.dataset_id}.carbon_per_ha`,
                    '>=',
                    this.linearScaleAtEachBreak(this.state.minValue),
                  ],
                  [
                    `${this.props.dataset.dataset_id}.carbon_per_ha`,
                    '<=',
                    this.linearScaleAtEachBreak(this.state.maxValue),
                  ],
                ]
              : null,
        })
      },
    )
  }

  render() {
    let value
    if (this.props.isStatic) {
      value = [this.valueToRange(this.props.initValues[0][2]), this.valueToRange(this.props.initValues[1][2])]
    } else {
      value = [this.state.minValue, this.state.maxValue]
    }
    return (
      <Form.Field className="rangeCarbon">
        <p
          style={{
            position: 'relative',
            top: '-5px',
            width: '80%',
          }}
        >
          (Mega grams of Carbon per Hectare)
        </p>
        <Slider.Range
          min={0}
          max={98}
          step={1}
          handle={RangeHandle}
          onChange={this.handleValueChange}
          value={value}
          marks={{
            0: '0',
            13: '41',
            27: '90',
            41: '147',
            55: '222',
            69: '306',
            83: '510',
            96: '1108',
          }}
        />
      </Form.Field>
    )
  }
}

Filter.defaultValue = null

Filter.propTypes = {
  dataset: PropTypes.shape({
    dataset_id: PropTypes.string,
  }).isRequired,
  useDefaultValues: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  initValues: PropTypes.arrayOf(PropTypes.array).isRequired,
  isStatic: PropTypes.bool.isRequired,
}

export default {
  renderer,
  table,
  filter: Filter,
}
