import React from 'react'
import { Table } from 'semantic-ui-react'

import { percent, precision } from '../../utils/formatters'

const MAPPING_DATA = {
  State: { label: 'State' },
  Global: { label: 'Global' },
}

const renderer = ({ id, data }, { shapeMeta }) => {
  if (!data) {
    return 'No data found.'
  }

  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Status</Table.HeaderCell>
          <Table.HeaderCell>Area (acre)</Table.HeaderCell>
          <Table.HeaderCell>%</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {Object.entries(MAPPING_DATA).map(([key, value]) => (
          <Table.Row key={key}>
            <Table.Cell>{value.label}</Table.Cell>
            <Table.Cell>{precision(data[key] || 0, 1)}</Table.Cell>
            <Table.Cell>{percent((100 * (data[key] || 0)) / shapeMeta[id].area)}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  )
}

const table = (dataset, data, shapeMeta) => {
  const rows = [['', 'Area (area)', 'Percent (%)']]

  if (!data) {
    data = {}
  }

  Object.entries(MAPPING_DATA).forEach(([k, v]) => {
    rows.push([v.label, precision(data[k] || 0, 2), precision((100 * (data[k] || 0)) / shapeMeta.area, 2)])
  })

  return rows
}

export default {
  renderer,
  table,
}
