import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox, Form, List, Table } from 'semantic-ui-react'

import { precision } from '../../utils/formatters'

const LAND_USE_CODES = {
  '-1': 'Other, Not determined',
  '1': 'Agricultural, which includes intensive agriculture, extensive agriculture, general agriculture, grazing land',
  '2': 'Industrial, which includes heavy industry and light industry',
  '3':
    'High density commercial, which includes downtown commercial, office commercial, highway commercial, ' +
    'civic centers, regional commercial, and other land use with the features of high density commercial',
  '4':
    'Low density commercial, which includes neighborhood commercial, general commercial, community commercial, ' +
    'schools, institutional, public/quasi-public facilities and the commercial land use that is not included in ' +
    'high density commercial',
  '5': 'High density residential, of which the density is more than eight dwelling units per acre',
  '6':
    'Medium density residential, of which the density is equal to or less than eight dwelling units per acre and ' +
    'bigger than 0.5 dwelling units per acre',
  '7': 'Low density residential, of which the lot size is more than two acres and less than or equal to twenty acres',
  '8': 'Open space and public lands',
  '9': 'Water',
  '10': 'Urban reserve',
  '11': 'Planned development, specific plan area, study area',
  '12': 'Mixed use of residential and commercial',
  '13': 'Very low density residential, of which the lot size is bigger than twenty acres and smaller than 160 acres',
}

const renderer = ({ id, data }) => {
  if (!data) {
    return 'No data found.'
  }

  return (
    <Table textAlign="center">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Land Use Code</Table.HeaderCell>
          <Table.HeaderCell>Description</Table.HeaderCell>
          <Table.HeaderCell>Area (acres)</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {Object.entries(data).map(([luc, area]) => (
          <Table.Row key={`${id}_${luc}`}>
            <Table.Cell>{luc}</Table.Cell>
            <Table.Cell>{LAND_USE_CODES[luc]}</Table.Cell>
            <Table.Cell>{precision(area, 1)}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  )
}

const table = (dataset, data) => {
  const rows = [['Land Use Code', 'Description', 'Area (acres)']]
  if (data) {
    Object.entries(data).forEach(([code, area]) => rows.push([code, LAND_USE_CODES[code], area]))
  }
  return rows
}

class Filter extends React.Component {
  constructor(props) {
    super(props)

    this.defaultValues = {
      1: false,
      2: false,
      3: false,
      4: false,
      5: false,
      6: false,
      7: false,
      8: false,
      9: false,
      10: false,
      11: false,
      12: false,
      13: false,
    }
    const initValues = (props.initValues[0] && props.initValues[0][2]) || []
    this.state = {
      1: initValues.indexOf(1) !== -1,
      2: initValues.indexOf(2) !== -1,
      3: initValues.indexOf(3) !== -1,
      4: initValues.indexOf(4) !== -1,
      5: initValues.indexOf(5) !== -1,
      6: initValues.indexOf(6) !== -1,
      7: initValues.indexOf(7) !== -1,
      8: initValues.indexOf(8) !== -1,
      9: initValues.indexOf(9) !== -1,
      10: initValues.indexOf(10) !== -1,
      11: initValues.indexOf(11) !== -1,
      12: initValues.indexOf(12) !== -1,
      13: initValues.indexOf(13) !== -1,
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.useDefaultValues && !this.props.useDefaultValues) {
      this.setState({ ...this.defaultValues }, () =>
        this.props.onChange({
          general_plans: null,
        }),
      )
    }
  }

  handleLUCTypeChange = (e, props) => {
    const updatedLUCTypes = {
      [props.value]: props.checked,
    }

    this.setState(
      state => ({ ...state, ...updatedLUCTypes }),
      () => this.handleValueChange(),
    )
  }

  handleValueChange = () => {
    const conditions = {
      general_plans: null,
    }

    const luc = []
    Object.entries(this.state).forEach(([label, value]) => {
      if (value) {
        luc.push(parseInt(label, 10))
      }
    })
    if (luc.length) {
      conditions.general_plans = [['general_plans.luc', '!=', luc]]
    }

    this.props.onChange(conditions)
  }

  render() {
    return (
      <Form.Field>
        <small className="fieldHint">
          Avoid areas with the selected <b>land use plan</b>
        </small>
        <List>
          <List.Item>
            <Checkbox
              checked={this.props.isStatic ? this.props.initValues[0][2].indexOf(1) !== -1 : this.state[1]}
              value={1}
              label="Agricultural"
              onChange={this.handleLUCTypeChange}
            />
          </List.Item>
          <List.Item>
            <Checkbox
              checked={this.props.isStatic ? this.props.initValues[0][2].indexOf(2) !== -1 : this.state[2]}
              value={2}
              label="Industrial"
              onChange={this.handleLUCTypeChange}
            />
          </List.Item>
          <List.Item>
            <Checkbox
              checked={this.props.isStatic ? this.props.initValues[0][2].indexOf(3) !== -1 : this.state[3]}
              value={3}
              label="High density commercial"
              onChange={this.handleLUCTypeChange}
            />
          </List.Item>
          <List.Item>
            <Checkbox
              checked={this.props.isStatic ? this.props.initValues[0][2].indexOf(4) !== -1 : this.state[4]}
              value={4}
              label="Low density commercial"
              onChange={this.handleLUCTypeChange}
            />
          </List.Item>
          <List.Item>
            <Checkbox
              checked={this.props.isStatic ? this.props.initValues[0][2].indexOf(5) !== -1 : this.state[5]}
              value={5}
              label="High density residential"
              onChange={this.handleLUCTypeChange}
            />
          </List.Item>
          <List.Item>
            <Checkbox
              checked={this.props.isStatic ? this.props.initValues[0][2].indexOf(6) !== -1 : this.state[6]}
              value={6}
              label="Medium density residential"
              onChange={this.handleLUCTypeChange}
            />
          </List.Item>
          <List.Item>
            <Checkbox
              checked={this.props.isStatic ? this.props.initValues[0][2].indexOf(7) !== -1 : this.state[7]}
              value={7}
              label="Low density residential"
              onChange={this.handleLUCTypeChange}
            />
          </List.Item>
          <List.Item>
            <Checkbox
              checked={this.props.isStatic ? this.props.initValues[0][2].indexOf(8) !== -1 : this.state[8]}
              value={8}
              label="Open space and public lands"
              onChange={this.handleLUCTypeChange}
            />
          </List.Item>
          <List.Item>
            <Checkbox
              checked={this.props.isStatic ? this.props.initValues[0][2].indexOf(9) !== -1 : this.state[9]}
              value={9}
              label="Water"
              onChange={this.handleLUCTypeChange}
            />
          </List.Item>
          <List.Item>
            <Checkbox
              checked={this.props.isStatic ? this.props.initValues[0][2].indexOf(10) !== -1 : this.state[10]}
              value={10}
              label="Urban reserve"
              onChange={this.handleLUCTypeChange}
            />
          </List.Item>
          <List.Item>
            <Checkbox
              checked={this.props.isStatic ? this.props.initValues[0][2].indexOf(11) !== -1 : this.state[11]}
              value={11}
              label="Planned development"
              onChange={this.handleLUCTypeChange}
            />
          </List.Item>
          <List.Item>
            <Checkbox
              checked={this.props.isStatic ? this.props.initValues[0][2].indexOf(12) !== -1 : this.state[12]}
              value={12}
              label="Mixed use residential and commercial"
              onChange={this.handleLUCTypeChange}
            />
          </List.Item>
          <List.Item>
            <Checkbox
              checked={this.props.isStatic ? this.props.initValues[0][2].indexOf(13) !== -1 : this.state[13]}
              value={13}
              label="Very low density residential"
              onChange={this.handleLUCTypeChange}
            />
          </List.Item>
        </List>
      </Form.Field>
    )
  }
}

Filter.defaultValue = null
Filter.ignoreReset = true

Filter.propTypes = {
  dataset: PropTypes.shape({}).isRequired,
  useDefaultValues: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  initValues: PropTypes.arrayOf(PropTypes.array).isRequired,
  isStatic: PropTypes.bool.isRequired,
}

export default {
  renderer,
  table,
  filter: Filter,
}
