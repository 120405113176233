import { fromJS, hash, Map as ImmMap } from 'immutable'

import { ACTIONS } from '../actions/analysis'

const INITIAL_STATE = fromJS({
  queryResults: {},
  queryResultsHash: {},
})

function updateResults(state, results, action) {
  let newState

  if (action === 'replace') {
    newState = state.set('queryResults', ImmMap()).set('queryResultsHash', ImmMap())
  } else if (action === 'merge') {
    newState = state
  } else {
    throw Error('Invalid action')
  }

  Object.entries(results).forEach(([category, d]) => {
    const immutableD = fromJS(d)
    newState = newState
      .setIn(['queryResults', category], immutableD)
      .setIn(['queryResultsHash', category], hash(immutableD))
  })

  return newState
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.UPDATE_RESULTS:
      return updateResults(state, action.results, action.action)
    default:
      return state
  }
}
