import React from 'react'
import ReactDOM from 'react-dom'
import axios from 'axios'
import querystring from 'querystring'
import polylabel from 'polylabel'
import turfArea from '@turf/area'
import turfBuffer from '@turf/buffer'
import { polygon as turfPolygon } from '@turf/helpers'
import turfIntersect from '@turf/intersect'

import L from 'leaflet'
import 'leaflet/dist/leaflet.css'

import omnivore from 'leaflet-omnivore'
import { RCA_VECTORS, REGION_LAYER_NAMES } from '../utils/rca'

import 'leaflet-draw'
import 'leaflet-draw/dist/leaflet.draw.css'

import 'leaflet-geonames/L.Control.Geonames'
import 'leaflet-geonames/L.Control.Geonames.css'

import 'leaflet-html-legend'
import 'leaflet-html-legend/src/L.Control.HtmlLegend.css'

import 'leaflet-basemaps/L.Control.Basemaps'
import 'leaflet-basemaps/L.Control.Basemaps.css'

import 'leaflet-zoombox/L.Control.ZoomBox'
import 'leaflet-zoombox/L.Control.ZoomBox.css'

import labelMarkerImage from '../images/label_marker.png'
import ammpMarker from '../images/marker-icon-ammp.png'

import layerBoundaryCA from '../files/geojson/ca.topojson'
import layerBoundaryEcoRegions from '../files/geojson/ecoregions.topojson'
import layerBoundaryCounties from '../files/geojson/counties.topojson'
import layerBoundaryWatersheds from '../files/geojson/huc4.topojson'

import { updateResults } from '../actions/analysis'
import { updateRegions, updateSelectedSites } from '../actions/inputs'
import {
  updateOverlaysStatus,
  updateMapImagesStatus,
  updateDrawStatus,
  updateGeometriesSource,
  GEOMETRY_SOURCES,
  addShapefile as addShapefileToStore,
  removeShapefile as removeShapefileFromStore,
} from '../actions/map'

import {
  SPECIES_INFO,
  SPECIES_BY_TARGET,
  SPECIAL_HABITATS,
  SPECIAL_HABITATS_BY_TARGET,
  ECOSYSTEMS,
  ECOSYSTEMS_BY_TARGET,
  AQUATIC_ATTRIBUTES,
} from '../species'
import { ACTIONS } from '../actions/config'
import { STEPS } from '../actions/page'
import { FILL_PATTERNS, MAP_STYLES, POINT_SYMBOLS } from './AMMP/constants'

// This is a workaround for a webpack-leaflet incompatibility
delete L.Icon.Default.prototype._getIconUrl
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

const BASEMAPS = [
  L.tileLayer('https://services.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}', {
    attribution:
      'Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ, TomTom, Intermap, iPC, USGS, FAO, NPS, NRCAN, GeoBase, Kadaster NL, Ordnance Survey, Esri Japan, METI, Esri China (Hong Kong), and the GIS User Community',
    label: 'ESRI Topo',
    maxZoom: 21,
    maxNativeZoom: 19,
  }),
  L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
    attribution:
      'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
    label: 'ESRI Imagery',
    maxZoom: 21,
    maxNativeZoom: 19,
  }),
]

const LAYER_STYLES = {
  default: {
    stroke: true,
    color: '#f8981d',
    weight: 2,
    dashArray: 0,
    opacity: 1,
    fill: false,
  },
  selected: {
    stroke: true,
    color: '#871a24',
    weight: 3,
    dashArray: 0,
    opacity: 1,
    fill: false,
  },
  unselected: {
    stroke: true,
    color: '#00ffff',
    weight: 2,
    dashArray: 0,
    opacity: 0.85,
    fill: false,
  },
  rcaAvailable: {
    stroke: true,
    color: '#f8981d',
    weight: 2,
    dashArray: 0,
    fillColor: '#7d4d9a',
    fillOpacity: 0.5,
    fill: true,
  },
  rcaUnavailable: {
    stroke: true,
    color: '#f8981d',
    weight: 2,
    dashArray: 0,
    fill: false,
  },
  counties: {
    stroke: true,
    color: '#000',
    weight: 2,
    dashArray: 0,
    fill: false,
  },
  watersheds: {
    stroke: true,
    color: '#496b9f',
    weight: 2,
    dashArray: 0,
    fill: false,
  },
  watershedsRCA: {
    stroke: true,
    color: '#496b9f',
    weight: 2,
    dashArray: 0,
    fillColor: '#7d4d9a',
    fillOpacity: 0.5,
    fill: true,
  },
  mouseover: {
    fillColor: '#21a840',
    fill: true,
  },
  customRegion: {
    stroke: true,
    color: '#FFFF00',
    fill: false,
    weight: 3,
    dashArray: 0,
  },
  hidden: {
    stroke: false,
    fill: false,
  },
}

const CATEGORIES_LABELS = {
  env: 'Environmental',
  land_use: 'Land Designation and Land Use',
  climate: 'Climate Change Criteria',
  ecosystem_services: 'Ecosystem Services',
  none: 'none',
}

const OVERLAYS = {
  'Boundaries': [
    {
      overlays: [
        {
          name: 'Ecoregions (USDA Section)',
          url: layerBoundaryEcoRegions,
        },
      ],
    },
    {
      overlays: [
        {
          name: 'Counties',
          url: layerBoundaryCounties,
        },
      ],
    },
    {
      overlays: [
        {
          name: 'Watersheds (HUC 4)',
          url: layerBoundaryWatersheds,
        },
      ],
    },
  ],
  'Environmental': [],
  'Land Designation and Land Use': [],
  'Climate Change Criteria': [],
  'Ecosystem Services': [],
  'none': [],
}

const RCA_POLYGONS = {}

const labelMarkerBackground = new Image()
labelMarkerBackground.setAttribute('crossOrigin', 'anonymous')
labelMarkerBackground.onload = () => {
  labelMarkerBackground.isReady = true
}
labelMarkerBackground.src = labelMarkerImage

const initBaseControls = map => {
  map.basemapControl = L.control
    .basemaps({
      basemaps: window.APPLICATION === 'ammp' ? BASEMAPS.reverse() : BASEMAPS,
      tileX: 0,
      tileY: 0,
      tileZ: 1,
      position: 'bottomright',
    })
    .addTo(map)

  L.Mask = L.Polygon.extend({
    options: {
      clickable: false,
      className: 'mask',
      outerBounds: new L.LatLngBounds([-90, -360], [90, 360]),
    },
    initialize(latLngs, options) {
      const outerBoundsLatLngs = [
        this.options.outerBounds.getSouthWest(),
        this.options.outerBounds.getNorthWest(),
        this.options.outerBounds.getNorthEast(),
        this.options.outerBounds.getSouthEast(),
      ]
      L.Polygon.prototype.initialize.call(this, [outerBoundsLatLngs, latLngs], options)
    },
  })

  omnivore.topojson(layerBoundaryCA).on('ready', e => {
    const mask = new L.Mask(e.target.getLayers()[0].getLatLngs())
    mask.addTo(map)
  })

  if (window.APPLICATION === 'ammp') {
    const zoomControl = new L.control.zoom({ position: 'topright' })
    zoomControl.addTo(map)
  } else {
    map.addControl(
      L.control.geonames({
        username: 'cbi.test',
        position: 'topright',
        bbox: {
          west: -124.4096,
          north: 42.0095,
          east: -114.1308,
          south: 32.5343,
        },
      }),
    )
  }

  map.addControl(
    L.control.zoomBox({
      modal: false,
      position: 'topright',
    }),
  )

  map.addControl(
    L.control.scale({
      position: 'bottomleft',
    }),
  )

  L.Control.FeatureContent = L.Control.extend({
    options: {
      position: 'bottomleft',
    },
    onAdd() {
      this._container = L.DomUtil.create('div')
      this._container.id = 'FeatureContentContainer'

      return this._container
    },
  })
  map.featureContentContainer = new L.Control.FeatureContent()
  map.featureContentContainer.addTo(map)

  map.selectedSitesMarkers = {}

  map.customRegionLayer = L.geoJSON(null, { interactive: false })
  map.customRegionLayer.addTo(map)

  if (window.APPLICATION === 'ammp') {
    L.drawLocal.draw.handlers.marker.tooltip.start = 'Click to place.'
    L.drawLocal.draw.handlers.polygon.tooltip.end = 'Click first point to finish your drawing.'
    L.drawLocal.edit.handlers.edit.tooltip = {
      text: 'Drag vertices or marker to edit.',
      subtext: 'Click cancel to undo changes.',
    }
    const linepane = map.createPane('linepane')
    linepane.style.zIndex = 401
    const pointpane = map.createPane('pointpane')
    pointpane.style.zIndex = 402
    const hovermarkerpane = map.createPane('hovermarkerpane')
    hovermarkerpane.style.zIndex = 601
    map.boundaryLayer = L.geoJSON(null, { interactive: false })
    map.polygonLayer = L.geoJSON(null, {
      style: feature => MAP_STYLES[feature.properties.layerStyle],
      interactive: false,
      renderer: L.svg(),
      onEachFeature: (feature, layer) => {
        if (FILL_PATTERNS[feature.properties.type]) {
          layer.options.className = `svg-fill-${feature.properties.type}`
        }
      },
    })
    map.lineLayerGroup = L.layerGroup()
    const POINT_ICONS = Object.entries(POINT_SYMBOLS).reduce((prev, curr) => {
      const [id, { url }] = curr
      return {
        ...prev,
        ...{ [id]: L.icon({ iconUrl: url, iconAnchor: [10, 10], className: 'cursor-grab', iconSize: [20, 20] }) },
      }
    }, {})
    map.pointLayer = L.geoJSON(null, {
      pane: 'pointpane',
      interactive: false,
      onEachFeature: (feature, layer) => {
        layer.options.icon = POINT_ICONS[feature.properties.type]
      },
    })
    map.editDrawingLayer = L.geoJSON(null, { interactive: false })
    const ammpIcon = L.icon({
      iconUrl: ammpMarker,
      iconSize: [24, 41],
      iconAnchor: [12, 41],
    })
    map.hoverMarker = L.marker([0, 0], { icon: ammpIcon, interactive: false, pane: 'hovermarkerpane' })
    map.hoverMarker.setOpacity(0)
    map.hoverMarker.addTo(map)

    map.boundaryLayer.addTo(map)
    map.polygonLayer.addTo(map)
    map.lineLayerGroup.addTo(map)
    map.pointLayer.addTo(map)
    map.editDrawingLayer.addTo(map)
  }
  map.markerLayer = L.featureGroup()
  map.markerLayer.addTo(map)
  map.sitesLayer = L.geoJSON(null, { style: LAYER_STYLES.unselected }).on('layeradd', e => {
    e.layer
      .on('click', map.handleSiteClick)
      .on('mouseover', map.handleSiteMouseover)
      .on('mouseout', map.handleSiteMouseout)
  })
  map.sitesLayer.addTo(map)
}

const initOverlays = (map, datasets, targets) => {
  Object.entries(datasets).forEach(([category, categoryDatasets]) => {
    categoryDatasets.forEach(dataset => {
      if (CATEGORIES_LABELS[category]) {
        OVERLAYS[CATEGORIES_LABELS[category]].push(dataset)
      }
    })
  })

  map.overlaysGroup = L.layerGroup()
  map.overlaysGroup.addTo(map)

  map.overlays = {}
  const legends = []

  let totalOverlays = 0
  let processedOverlays = 0
  let totalLegendFetches = 0
  let returnedLegendFetches = 0

  Object.values(OVERLAYS).forEach(categoryDatasets =>
    categoryDatasets.forEach(dataset => {
      totalOverlays += dataset.overlays.length
    }),
  )

  const initLegends = () => {
    map.legendControl = L.control.htmllegend({
      legends,
      position: 'bottomright',
      collapseSimple: true,
      detectStretched: true,
      defaultOpacity: 0.7,
      visibleIcon: 'transparency-full-percent icon',
      hiddenIcon: 'transparency-none-percent icon',
    })
    map.legendControl.addTo(map)
  }

  const overlayLoadCallback = (category, dataset, overlay, layer) => {
    const featureContentContainer = map.featureContentContainer.getContainer()
    if (overlay.name === 'Ecoregions (USDA Section)' || overlay.name === 'Watersheds (HUC 4)') {
      map.store.dispatch({
        type: ACTIONS.UPDATE_STUDY_AREA_OPTIONS,
        data: layer.getLayers().map(l => l.feature.properties),
        label: 'ecoregions',
      })
      layer.getLayers().forEach((l, idx) => {
        l.feature.properties.idx = idx
        if (!l.feature.properties.value) {
          l.setStyle(LAYER_STYLES.rcaUnavailable)
        } else {
          const feature = turfPolygon(l.feature.geometry.coordinates)
          feature.properties.type = overlay.name.startsWith('Ecoregions') ? 'ecoregion' : 'watershed'
          RCA_POLYGONS[l.feature.properties.value] = feature
          l.setStyle(LAYER_STYLES.rcaAvailable)
        }
        l.on('mouseover', () => {
          const state = map.store.getState()
          if (state.getIn(['page', 'activeStep']) > 1) {
            return
          }
          l.setStyle(LAYER_STYLES.mouseover)
          if (!l.feature.properties.value) {
            featureContentContainer.innerText = l.feature.properties.name
          } else {
            featureContentContainer.innerText = `${l.feature.properties.name}\n(RCA Available)`
          }
        })
        l.on('mouseout', () => {
          const state = map.store.getState()
          if (state.getIn(['page', 'activeStep']) === STEPS.REGION) {
            if (!l.feature.properties.value) {
              l.setStyle(LAYER_STYLES.rcaUnavailable)
            } else {
              l.setStyle(LAYER_STYLES.rcaAvailable)
            }
          } else {
            l.setStyle(LAYER_STYLES.rcaUnavailable)
          }
          featureContentContainer.innerText = ''
        })

        if (overlay.name === 'Ecoregions (USDA Section)') {
          l.on('click', () => {
            const state = map.store.getState()
            if (state.getIn(['map', 'identifyTarget']).size) {
              return
            }
            if (
              state.getIn(['page', 'activeStep']) === STEPS.REGION &&
              state.getIn(['inputs', 'region', 'region']) === 'ecoregion'
            ) {
              const ecoregions = state.getIn(['inputs', 'region', 'ecoregions']).toJS()
              if (ecoregions[idx]) {
                delete ecoregions[idx]
              } else {
                ecoregions[idx] = l.feature.properties.name
              }
              map.customRegionLayer.clearLayers()
              Object.keys(ecoregions).forEach(ecoregion =>
                map.customRegionLayer.addData(layer.getLayers()[ecoregion].toGeoJSON()),
              )
              map.customRegionLayer.setStyle(LAYER_STYLES.customRegion)
              map.store.dispatch(updateRegions({ ecoregions }, map.customRegionLayer))
            }
          })
        } else {
          l.on('click', () => {
            const state = map.store.getState()
            if (state.getIn(['map', 'identifyTarget']).size) {
              return
            }
            if (
              state.getIn(['page', 'activeStep']) === STEPS.REGION &&
              state.getIn(['inputs', 'region', 'region']) === 'watershed'
            ) {
              const watersheds = map.store.getState().getIn(['inputs', 'region', 'watersheds']).toJS()
              if (watersheds[idx]) {
                delete watersheds[idx]
              } else {
                watersheds[idx] = l.feature.properties.name
              }
              map.customRegionLayer.clearLayers()
              Object.keys(watersheds).forEach(watershed =>
                map.customRegionLayer.addData(layer.getLayers()[watershed].toGeoJSON()),
              )
              map.customRegionLayer.setStyle(LAYER_STYLES.customRegion)
              map.store.dispatch(updateRegions({ watersheds }, map.customRegionLayer))
            }
          })
        }
      })
    }
    if (overlay.name === 'Counties') {
      map.store.dispatch({
        type: ACTIONS.UPDATE_STUDY_AREA_OPTIONS,
        data: layer.getLayers().map(l => l.feature.properties),
        label: 'counties',
      })
      layer.getLayers().forEach((l, idx) => {
        l.feature.properties.idx = idx
        l.setStyle(LAYER_STYLES.counties)
        l.on('mouseover', () => {
          const state = map.store.getState()
          if (state.getIn(['page', 'activeStep']) > 1) {
            return
          }
          l.setStyle(LAYER_STYLES.mouseover)
          featureContentContainer.innerText = l.feature.properties.name
        })
        l.on('mouseout', () => {
          l.setStyle(LAYER_STYLES.counties)
          featureContentContainer.innerText = ''
        })
        l.on('click', () => {
          const state = map.store.getState()
          if (state.getIn(['map', 'identifyTarget']).size) {
            return
          }
          if (
            state.getIn(['page', 'activeStep']) === STEPS.REGION &&
            state.getIn(['inputs', 'region', 'region']) === 'county'
          ) {
            const counties = map.store.getState().getIn(['inputs', 'region', 'counties']).toJS()
            if (counties[idx]) {
              delete counties[idx]
            } else {
              counties[idx] = l.feature.properties.name
            }
            map.customRegionLayer.clearLayers()
            Object.keys(counties).forEach(county =>
              map.customRegionLayer.addData(layer.getLayers()[county].toGeoJSON()),
            )
            map.customRegionLayer.setStyle(LAYER_STYLES.customRegion)
            map.store.dispatch(updateRegions({ counties }, map.customRegionLayer))
          }
        })
      })
    }

    if (dataset.url) {
      overlay.externalUrl = dataset.url
    }

    processedOverlays += 1
    layer.name = overlay.name
    map.overlays[overlay.name] = {
      layer,
      isActive: false,
    }

    if (overlay.legend) {
      overlay.legend.layer = layer
      legends.push(overlay.legend)
    } else {
      // This else block loads legend information from metadata on the tile service if it exists
      if (
        // Don't run for RCA datasets or topojson's, which cause network errors:
        !Object.keys(RCA_VECTORS).includes(overlay.url) &&
        overlay.url &&
        overlay.url.substring(overlay.url.length - 8) !== 'topojson'
      ) {
        totalLegendFetches += 1
        axios
          .get(`${CONFIG.LAYERS_URL}/${overlay.url}`)
          .then(response => {
            const mbtileLegend = JSON.parse(response.data.legend)
            if (mbtileLegend[0]) {
              const legend = {
                name: layer.name,
                layer,
                elements: mbtileLegend[0].elements.map(element => {
                  return {
                    label: element.label,
                    html: `<img style='width:10px; height:10px;' src='${element.imageData}'/>`,
                  }
                }),
              }
              overlay.legend = legend
              legends.push(overlay.legend)
            }
          })
          .catch(error => {})
          .finally(() => {
            returnedLegendFetches += 1
            if (processedOverlays === totalOverlays && totalLegendFetches === returnedLegendFetches) {
              initLegends()
            }
          })
      }
    }
    if (processedOverlays === totalOverlays && totalLegendFetches === returnedLegendFetches) {
      initLegends()
    }
  }

  Object.entries(targets).forEach(([ecoregion, regionTargets]) =>
    regionTargets.forEach(target => {
      const layer = L.tileLayer(`/tiles/${target.map_server}/{z}/{x}/{y}.png`)
      const layerName = `${ecoregion}_${target.value}`
      layer.name = layerName
      map.overlays[layerName] = {
        layer,
        isActive: false,
      }

      const { legend } = target
      if (legend) {
        legend.layer = layer
        legends.push(legend)
      }
    }),
  )

  Object.entries(OVERLAYS).forEach(([category, categoryDatasets]) =>
    categoryDatasets.forEach(dataset => {
      dataset.overlays.forEach(overlay => {
        if (overlay.url.indexOf('.topojson') > -1) {
          omnivore
            .topojson(overlay.url, null, L.geoJson(null, { style: overlay.style || LAYER_STYLES.default }))
            .on('ready', e => overlayLoadCallback(category, dataset, overlay, e.target))
        } else {
          const layerOptions =
            window.APPLICATION === 'ammp'
              ? { maxNativeZoom: 16 }
              : { maxNativeZoom: 14 }

          overlayLoadCallback(
            category,
            dataset,
            overlay,
            L.tileLayer(`${CONFIG.LAYERS_URL}/${overlay.url}/tiles/{z}/{x}/{y}.png`, layerOptions),
          )
        }
      })
    }),
  )
}

const initEvents = map => {
  map.on('baselayerchange', () => map.store.dispatch(updateMapImagesStatus(false)))

  map.handleSiteClick = e => {
    const state = map.store.getState()
    if (state.getIn(['page', 'activeStep']) === STEPS.SELECT_SITES && !state.getIn(['map', 'identifyTarget']).size) {
      L.DomEvent.stopPropagation(e)
      const layer = e.target
      const layerId = layer._leaflet_id

      const selectedSites = map.store.getState().getIn(['inputs', 'sites', 'selectedSites']).asMutable()
      if (selectedSites.has(layerId)) {
        selectedSites.delete(layerId)
        layer.setStyle(LAYER_STYLES.unselected)
      } else {
        selectedSites.set(layerId, {})
        layer.setStyle(LAYER_STYLES.selected)
      }

      map.updateSelectedSitesAttrs(selectedSites)

      map.store.dispatch(updateSelectedSites(selectedSites, map.store.getState().getIn(['config', 'targets'])))
    }
  }

  map.handleSiteMouseover = e => {
    e.target.setStyle(LAYER_STYLES.mouseover)
  }

  map.handleSiteMouseout = e => {
    const selectedSites = map.store.getState().getIn(['inputs', 'sites', 'selectedSites'])
    if (!selectedSites.has(e.target._leaflet_id)) {
      e.target.setStyle(LAYER_STYLES.unselected)
    } else {
      e.target.setStyle(LAYER_STYLES.selected)
    }
  }
}

const initDrawControl = map => {
  L.drawLocal.edit.handlers.remove.tooltip.text = `
        ${L.drawLocal.edit.handlers.remove.tooltip.text}
        <br>
        Click <i>Save</i> button in the sidebar to confirm your changes.
    `
  if (window.APPLICATION !== 'ammp') {
    map.drawControl = new L.Control.Draw({
      position: 'topleft',
      draw: {
        polyline: {
          showLength: false,
          allowIntersection: false,
          drawError: {
            color: '#e1e100',
            message: '<strong>Error!<strong> Study area cannot intersect itself',
          },
          shapeOptions: LAYER_STYLES.unselected,
        },
        circle: false,
        marker: true,
        polygon: {
          allowIntersection: false,
          drawError: {
            color: '#e1e100',
            message: '<strong>Error!<strong> Study area cannot intersect itself',
          },
          shapeOptions: LAYER_STYLES.unselected,
        },
        rectangle: {
          shapeOptions: LAYER_STYLES.unselected,
        },
      },
      edit: {
        featureGroup: map.customRegionLayer,
        poly: {
          allowIntersection: false,
        },
      },
    })
    map.drawControl.addTo(map)

    map
      .on('draw:created', event => {
        let { layer } = event
        switch (event.layerType) {
          case 'marker':
          case 'polyline':
            const geojson = event.layer.toGeoJSON()
            if (geojson.geometry.coordinates.length < 2) {
              map.store.dispatch(updateDrawStatus(false))
              return null
            }
            layer = L.GeoJSON.geometryToLayer(
              map.handleBuffer(geojson, map.store.getState().getIn(['inputs', 'sites', 'bufferValue']), 'miles'),
            )
            break
        }

        map.customRegionLayer.addLayer(layer)
        if (window.PROPOSITION && window.USER_FLOW === 'review') {
          map.customRegionLayer.setStyle(LAYER_STYLES.selected)
        } else {
          map.customRegionLayer.setStyle(LAYER_STYLES.customRegion)
        }
        map.store.dispatch(updateRegions({ hasCustomRegion: true }, map.customRegionLayer))
        map.store.dispatch(updateDrawStatus(false))
        map.store.dispatch(updateGeometriesSource(GEOMETRY_SOURCES.draw))
        map.store.dispatch(updateResults({}, 'replace'))
        map.store.dispatch(updateMapImagesStatus(false))
      })
      .on('draw:edited', () => {
        map.store.dispatch(updateDrawStatus(false))
        map.store.dispatch(updateMapImagesStatus(false))
      })
      .on('draw:editvertex', () => {
        map.store.dispatch(updateResults({}, 'replace'))
        map.store.dispatch(updateMapImagesStatus(false))
      })
      .on('draw:deleted', e => {
        const deletedLayers = e.layers.getLayers()
        const selectedSites = map.store.getState().getIn(['inputs', 'sites', 'selectedSites']).asMutable()
        deletedLayers.forEach(layerId => selectedSites.delete(layerId))
        map.store.dispatch(updateSelectedSites(selectedSites, map.store.getState().getIn(['config', 'targets'])))
        map.store.dispatch(updateResults({}, 'replace'))
        map.store.dispatch(updateMapImagesStatus(false))
      })
  }
}

const initWorkers = map => {
  map.workers = {}
  // TODO add worker support

  /* if (window.Worker) {
     bufferWorker.onmessage = (e) => {
     map.workers.buffer.callbacks[e.data.callbackId](e.data.bufferedShape)
     delete map.workers.buffer.callbacks[e.data.callbackId]
     }
     map.workers.buffer = { caller: bufferWorker, callbacks: {} }
     } */
}

const initIdentify = map => {
  let timeout = null

  map.on('dblclick dragstart zoomstart', () => {
    if (timeout !== null) {
      clearTimeout(timeout)
      timeout = null
    }
  })

  map.on('click', e => {
    const identifyTarget = map.store.getState().getIn(['map', 'identifyTarget'])
    if (!identifyTarget.size || timeout !== null || e.target !== map) {
      return
    }
    const [ecoregion, target] = identifyTarget.toJS()

    timeout = setTimeout(() => {
      timeout = null

      if (!e.latlng) {
        return
      }

      const node = document.createElement('div')
      node.innerText = 'Loading results...'
      node.className = 'species-popup'

      L.popup({ maxWidth: 350 }).setLatLng(e.latlng).setContent(node).openOn(map)

      axios
        .get(
          `${Urls['api:identify'](REGION_LAYER_NAMES[ecoregion].service)}?${querystring.stringify({
            point: `${e.latlng.lng},${e.latlng.lat}`,
          })}`,
        )
        .then(response => {
          node.innerText = ''

          if (target === 'aquatic') {
            const content = (
              <>
                {AQUATIC_ATTRIBUTES.map(({ category, attributes }) => {
                  return (
                    <>
                      <h4>{category}</h4>
                      <table className="identify-table">
                        <tbody>
                          {attributes.map(({ label, variable }) => {
                            return (
                              <tr>
                                <td>{label}</td>
                                <td>{response.data.results.find(result => result.variable === variable).value}</td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </>
                  )
                })}
              </>
            )

            ReactDOM.render(content, node)
          } else {
            let targetSpecies

            if (target === 'custom') {
              targetSpecies = map.store
                .getState()
                .getIn(['config', 'models', ecoregion, 'species'])
                .toJS()
                .map(item => item.toLowerCase())
            } else {
              targetSpecies = SPECIES_BY_TARGET[ecoregion][target]
            }

            let targetEcosystems
            if (target === 'custom') {
              targetEcosystems = map.store.getState().getIn(['config', 'models', ecoregion, 'ecosystems']).toJS()
            } else {
              targetEcosystems = ECOSYSTEMS_BY_TARGET[ecoregion][target]
            }
            const ecosystemsMap = new Map(
              targetEcosystems.map(item => [item.toLowerCase(), ECOSYSTEMS[ecoregion][item]]),
            )

            let targetHabitats
            if (target === 'custom') {
              targetHabitats = map.store.getState().getIn(['config', 'models', ecoregion, 'special_habitats']).toJS()
            } else {
              targetHabitats = SPECIAL_HABITATS_BY_TARGET[ecoregion][target]
            }
            const specialhabitats = new Map(
              targetHabitats.map(item => [item.toLowerCase(), SPECIAL_HABITATS[ecoregion][item]]),
            )

            const species = response.data.results.filter(item => targetSpecies.indexOf(item.variable) >= 0)

            const createList = (itemList, label, makeItemLabel) => {
              const header = document.createElement('h4')
              header.innerText = label
              node.appendChild(header)
              const ul = document.createElement('ul')
              node.appendChild(ul)
              itemList
                .map(item => makeItemLabel(item))
                .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
                .forEach(itemLabel => {
                  if (!itemLabel) {
                    return
                  }

                  const li = document.createElement('li')
                  li.innerText = itemLabel
                  ul.appendChild(li)
                })
            }

            const makeSpeciesLabel = s => {
              const speciesInfo = SPECIES_INFO[ecoregion][s]
              if (speciesInfo === undefined) {
                return undefined
              }

              return `${speciesInfo.common_name} (${speciesInfo.scientific_name}), ${speciesInfo.status_code}`
            }

            const makeHabitatLabel = f => specialhabitats.get(f)

            const makeEcosystemLabel = f => ecosystemsMap.get(f)

            // Species Observation
            const observation = species.filter(item => item.value === 1.0).map(item => item.variable)
            if (observation.length) {
              createList(observation, 'Species Observation', makeSpeciesLabel)
            }

            // Modeled Species Habitat
            const modeled = species.filter(item => item.value === 0.75).map(item => item.variable)
            if (modeled.length) {
              createList(modeled, 'Modeled Species Habitat', makeSpeciesLabel)
            }

            // Species Range/Habitat
            const range = species.filter(item => item.value === 0.5).map(item => item.variable)
            if (range.length) {
              createList(range, 'Species Range/Habitat', makeSpeciesLabel)
            }

            // Ecosystems
            const ecosystems = response.data.results
              .filter(item => item.value === 1 && ecosystemsMap.has(item.variable))
              .map(item => item.variable)
            if (ecosystems.length) {
              createList(ecosystems, 'Ecosystems', makeEcosystemLabel)
            }

            // Special Habitats
            const habitats = response.data.results
              .filter(item => item.value === 1 && specialhabitats.has(item.variable))
              .map(item => item.variable)
            if (habitats.length) {
              createList(habitats, 'Special Habitats', makeHabitatLabel)
            }
          }

          if (!node.innerText) {
            node.innerText = 'No record found.'
          }
        })
    }, 300)
  })
}

function handleBuffer(map, geojson, bufferValue, unit, callback) {
  if (map.workers.buffer) {
    const worker = map.workers.buffer.caller
    let callbackId
    while (true) {
      callbackId = new Date().toISOString() // generating unique ids!
      if (!map.workers.buffer.callbacks[callbackId]) {
        map.workers.buffer.callbacks[callbackId] = callback
        break
      }
    }
    worker.postMessage({
      staticUrl: CONFIG.STATIC_URL,
      geojson,
      bufferValue,
      unit,
      callbackId,
    })
    return null
  }
  return turfBuffer(geojson, bufferValue, { units: unit })
}

function toggleOverlay(map, overlayName) {
  if (map.overlays[overlayName]) {
    if (map.overlays[overlayName].isActive) {
      map.overlaysGroup.removeLayer(map.overlays[overlayName].layer)
      map.overlays[overlayName].isActive = false
    } else {
      map.overlaysGroup.clearLayers()
      Object.entries(map.overlays)
        .filter(([name, attrs]) => attrs.isActive || name === overlayName)
        .sort(([, v1], [, v2]) => (v2.order || Infinity) - (v1.order || Infinity))
        .forEach(([name, attrs]) => {
          map.overlaysGroup.addLayer(attrs.layer)
          map.overlays[name].isActive = true
        })
    }
    map.customRegionLayer.bringToFront()
    map.sitesLayer.bringToFront()
    map.store.dispatch(updateOverlaysStatus())
  }
}

function addShapefile(map, layers) {
  // layers is an array of geojsons
  const preliminaryLayerIds = Object.keys(map.customRegionLayer._layers)
  layers.forEach(layer => {
    if (layer.geometry.type.indexOf('Polygon') === -1) {
      map.customRegionLayer.addData(
        map.handleBuffer(layer, map.store.getState().getIn(['inputs', 'sites', 'bufferValue']), 'miles'),
      )
    } else {
      map.customRegionLayer.addData(layer)
    }
  })
  if (window.PROPOSITION && window.USER_FLOW === 'review') {
    map.customRegionLayer.setStyle(LAYER_STYLES.selected)
  } else {
    map.customRegionLayer.setStyle(LAYER_STYLES.customRegion)
  }
  const addedLayerId = Object.keys(map.customRegionLayer._layers).find(lyr => !preliminaryLayerIds.includes(lyr))
  const shapefile = {
    geom: layers[0].geometry,
    label: layers[0].properties.shapefileName,
    leafletId: parseInt(addedLayerId),
  }
  map.store.dispatch(addShapefileToStore(shapefile))
  return addedLayerId
}

function removeShapefile(map, leafletId) {
  const layer = map.customRegionLayer._layers[leafletId]
  map.customRegionLayer.removeLayer(layer)
  map.store.dispatch(removeShapefileFromStore(leafletId))
}

function addToSitesLayer(map, layers) {
  // layers is an array of geojsons
  layers.forEach(layer => {
    map.sitesLayer.addData(layer)
  })
  map.sitesLayer.setStyle(LAYER_STYLES.unselected)
}

function createLabeledMarkerIcon(label) {
  const labelCanvas = document.createElement('canvas')
  labelCanvas.width = 30
  labelCanvas.height = 45
  const ctx = labelCanvas.getContext('2d')
  ctx.drawImage(labelMarkerBackground, 0, 0)
  ctx.textBaseline = 'middle'
  ctx.textAlign = 'center'
  const labelStr = label.toString()
  let fontSize = 16
  if (labelStr.length === 2) {
    fontSize = 14
  } else if (labelStr.length === 3) {
    fontSize = 12
  } else if (labelStr.length > 3) {
    fontSize = 10
  }
  ctx.font = `${fontSize}px sans serif`
  ctx.fillText(labelStr, 15, 19)

  return labelCanvas.toDataURL()
}

function updateSelectedSitesAttrs(map, selectedSites) {
  if (!labelMarkerBackground.isReady) {
    setTimeout(() => map.updateSelectedSitesAttrs(selectedSites), 1000)
  }

  Object.values(map.selectedSitesMarkers).forEach(marker => marker.remove())
  map.selectedSitesMarkers = {}

  let idx = 0
  selectedSites.forEach((layerAttrs, layerId) => {
    const siteRCAs = {}
    const siteGeoJSON = map._layers[layerId].toGeoJSON()
    if (siteGeoJSON.geometry.type === 'MultiPolygon') {
      let siteArea = 0
      const intersectedAreas = {}
      for (let i = siteGeoJSON.geometry.coordinates.length; i > 0; i -= 1) {
        const sitePolygon = turfPolygon(siteGeoJSON.geometry.coordinates[i - 1])
        siteArea += turfArea(sitePolygon)
        Object.entries(RCA_POLYGONS).forEach(([rcaName, rcaPolygon]) => {
          const intersection = turfIntersect(sitePolygon, rcaPolygon)
          if (intersection) {
            intersectedAreas[rcaName] = (intersectedAreas[rcaName] || 0) + turfArea(intersection)
          }
        })
      }
      Object.entries(intersectedAreas).forEach(([rcaName, intersectedArea]) => {
        siteRCAs[rcaName] = intersectedArea / siteArea
      })
    } else {
      const sitePolygon = turfPolygon(siteGeoJSON.geometry.coordinates)
      Object.entries(RCA_POLYGONS).forEach(([rcaName, rcaPolygon]) => {
        const intersection = turfIntersect(sitePolygon, rcaPolygon)
        if (intersection) {
          siteRCAs[rcaName] = turfArea(intersection) / turfArea(sitePolygon)
        }
      })
    }

    idx += 1

    const layerGeoJSON = map._layers[layerId].toGeoJSON()
    let center
    if (layerGeoJSON.geometry.type === 'MultiPolygon') {
      center = map._layers[layerId].getCenter()
    } else {
      center = polylabel(layerGeoJSON.geometry.coordinates, 1).reverse()
    }

    const marker = L.marker(center, {
      icon: L.icon({
        iconUrl: createLabeledMarkerIcon(idx),
        iconSize: [30, 45],
        iconAnchor: [15, 45],
      }),
    })
    marker.on('click', () => map._layers[layerId].fire('click'))
    marker.addTo(map)

    const layer = map.sitesLayer.getLayer(layerId)

    // rename label to match idx unless manually changed by user
    if (!layer.label || layer.label <= Object.keys(map.sitesLayer._layers).length) {
      layer.label = idx
    }
    selectedSites.set(layerId, {
      mapId: idx,
      verbose: layer.label,
      RCAs: siteRCAs,
    })
    map.selectedSitesMarkers[layerId] = marker
  })
}

function removeAllAreas(map) {
  map.sitesLayer.clearLayers()
  const selectedSites = map.store.getState().getIn(['inputs', 'sites', 'selectedSites']).asMutable()
  selectedSites.clear()
  map.updateSelectedSitesAttrs(selectedSites)
  map.store.dispatch(updateSelectedSites(selectedSites, map.store.getState().getIn(['config', 'targets'])))
}

function prepareSelectedSitesForAnalysis(map) {
  const geoms = []
  const selectedSites = map.store.getState().getIn(['inputs', 'sites', 'selectedSites'])
  selectedSites.forEach(({ mapId }, shapeId) =>
    geoms.push({
      id: shapeId,
      geom: map._layers[shapeId].toGeoJSON().geometry,
      label: mapId,
    }),
  )
  return geoms
}

const initMap = (store, datasets, targets) => {
  const map = L.map('Map', {
    layers: [],
    minZoom: 0,
    maxZoom: window.APPLICATION === 'ammp' ? 21 : 16,
    zoomControl: false,
    preferCanvas: true,
  })
  map.fitBounds([
    [32.534156, -124.409591],
    [42.009518, -114.131211],
  ])
  map.createPane('draw')
  map.getPane('draw').style.zIndex = 450

  map.store = store

  initBaseControls(map)
  initWorkers(map)
  initEvents(map)

  initOverlays(map, datasets, targets)
  initDrawControl(map)
  initIdentify(map)

  map.handleBuffer = handleBuffer.bind(null, map)
  map.addShapefile = addShapefile.bind(null, map)
  map.removeShapefile = removeShapefile.bind(null, map)
  map.addToSitesLayer = addToSitesLayer.bind(null, map)
  map.updateSelectedSitesAttrs = updateSelectedSitesAttrs.bind(null, map)
  map.removeAllAreas = removeAllAreas.bind(null, map)
  map.prepareSelectedSitesForAnalysis = prepareSelectedSitesForAnalysis.bind(null, map)
  map.toggleOverlay = toggleOverlay.bind(null, map)

  window.map = map
  return map
}

export { initMap, createLabeledMarkerIcon, LAYER_STYLES, CATEGORIES_LABELS, OVERLAYS, RCA_POLYGONS }
