import { fromJS } from 'immutable'

import { ACTIONS } from '../actions/config'

const INITIAL_STATE = fromJS({
  counties: [],
  datasets: {},
  ecoregions: [],
  targets: {},
  models: {},
})

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.UPDATE_STUDY_AREA_OPTIONS:
      return state.set(action.label, fromJS(action.data))
    case ACTIONS.UPDATE_DATASETS:
      return state.set('datasets', fromJS(action.datasets))
    case ACTIONS.UPDATE_TARGETS:
      return state.set('targets', fromJS(action.targets)).set('models', fromJS(action.models))
    default:
      return state
  }
}
