import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Container, Dropdown, Header, Message, Segment } from 'semantic-ui-react'

import { REGION_LAYER_NAMES, getEcoregionAndTarget } from '../../utils/rca'
import { runAnalysis } from '../../actions/analysis'
import renderers from '../renderers'
import { CATEGORIES_LABELS } from '../Map'
import LayoutContext from '../../context'
import Report from './Report'
import Section from './Section'

class Analyze extends React.Component {
  constructor(props) {
    super(props)

    const history = {}
    this.datasets = {}

    this.categoriesDropdownOptions = []
    this.datasetDropdownOptions = {
      ace: [],
    }

    if (Object.keys(props.availableRCAs).length) {
      history.rca = ''
      this.categoriesDropdownOptions.push({
        value: 'rca',
        text: 'Weighted Conservation Target Models',
      })
      this.datasetDropdownOptions.rca = []
      Object.entries(props.availableRCAs).forEach(([ecoregion, targets]) => {
        targets.forEach(t => {
          const targetValue = `rca_${ecoregion}_${t.value}`
          this.datasetDropdownOptions.rca.push({
            value: targetValue,
            text: `${REGION_LAYER_NAMES[ecoregion].label} - ${t.label}`,
          })
        })
      })
    }

    Object.entries(CATEGORIES_LABELS)
      .filter(([, label]) => label !== 'none')
      .forEach(([category, label]) => {
        history[category] = ''
        this.categoriesDropdownOptions.push({
          value: category,
          text: label,
        })
        if (category === 'env') {
          history.ace = ''
          this.categoriesDropdownOptions.push({
            value: 'ace',
            text: 'CDFW ACE III',
          })
        }
      })

    Object.entries(props.datasets).forEach(([category, datasets]) => {
      this.datasetDropdownOptions[category] = []
      datasets.forEach(dataset => {
        if (dataset.has_query || (window.PROPOSITION && dataset.scoring[window.PROPOSITION.slug])) {
          if (dataset.sub_categories && dataset.sub_categories[0] === 'CDFW ACE III') {
            if (dataset.sub_categories[1]) {
              dataset.label = `${dataset.sub_categories[1]} - ${dataset.label}`
            }
            this.datasetDropdownOptions.ace.push({
              value: dataset.dataset_id,
              text: dataset.label,
            })
          } else {
            this.datasetDropdownOptions[category].push({
              value: dataset.dataset_id,
              text: dataset.label,
            })
          }
          this.datasets[dataset.dataset_id] = dataset
        }
      })
      this.datasetDropdownOptions[category].sort((d1, d2) => (d1.text.toLowerCase() > d2.text.toLowerCase() ? 1 : -1))
    })
    this.datasetDropdownOptions.ace.sort((d1, d2) => (d1.text.toLowerCase() > d2.text.toLowerCase() ? 1 : -1))

    this.propositionDropdownOptions = window.PROPOSITION
      ? window.PROPOSITION.datasets
          .filter(datasetId => {
            const dataset = Object.values(this.props.datasets)
              .flat()
              .find(ds => ds.dataset_id === datasetId)
            return dataset.has_query
          })
          .reduce((output, datasetId) => {
            const dataset = Object.values(this.props.datasets)
              .flat()
              .find(ds => ds.dataset_id === datasetId)
            return output.concat({
              value: datasetId,
              text: dataset.label,
            })
          }, [])
      : {}

    const activeCategory = this.categoriesDropdownOptions[0].value

    this.state = {
      history,
      activeCategory,
      selectedDataset: '',
    }
  }

  fetchData = (dataset, context) => {
    if (dataset.indexOf('rca') === 0) {
      const [ecoregion, target] = getEcoregionAndTarget(dataset)
      this.props.runAnalysis('rca', context.map.prepareSelectedSitesForAnalysis(), [
        [ecoregion, this.props.availableRCAs[ecoregion].find(t => t.value === target)],
      ])
    } else {
      this.props.runAnalysis('analyze', context.map.prepareSelectedSitesForAnalysis(), [dataset])
    }
  }

  handleCategoryChange = (e, { value: category }) => {
    if (this.state.activeCategory !== category) {
      this.setState(state => {
        const selectedDataset = state.history[category] || this.datasetDropdownOptions[category][0].value
        if (!this.props.queryResults[selectedDataset]) {
          this.fetchData(selectedDataset, this.context)
        }
        state.history[state.activeCategory] = state.selectedDataset
        state.activeCategory = category
        state.selectedDataset = selectedDataset
        return state
      })
    }
  }

  handleDatasetChange = (e, props) => {
    this.setState(state => {
      state.history[state.activeCategory] = state.selectedDataset
      state.selectedDataset = props.value
      return state
    })
    if (props.value && !this.props.queryResults[props.value]) {
      this.fetchData(props.value, this.context)
    }
  }

  renderSections = () => {
    const sections = {}
    Object.values(this.datasets).forEach(dataset => {
      if (!sections[dataset.dataset_id] && renderers[dataset.dataset_id]) {
        sections[dataset.dataset_id] = (
          <Segment
            key={dataset.dataset_id}
            className={this.state.selectedDataset !== dataset.dataset_id ? 'hidden' : ''}
            basic
            style={{ padding: 0 }}
          >
            <Section dataset={dataset} renderer={renderers[dataset.dataset_id].renderer} />
          </Segment>
        )
      }
    })
    Object.entries(this.props.availableRCAs).forEach(([ecoregion, targets]) => {
      targets.forEach(target => {
        const dataset_id = `rca_${ecoregion}_${target.value}`
        if (!sections[dataset_id]) {
          sections[dataset_id] = (
            <Segment
              key={dataset_id}
              className={this.state.selectedDataset !== dataset_id ? 'hidden' : ''}
              basic
              style={{ padding: 0 }}
            >
              <Section
                dataset={{
                  dataset_id,
                  label: `${REGION_LAYER_NAMES[ecoregion].label} - ${target.label}`,
                  overlays: [],
                }}
                ecoregion={ecoregion}
                target={target.value}
                renderer={renderers.rca.renderer}
              />
            </Segment>
          )
        }
      })
    })
    return sections
  }

  render() {
    const Sections = this.renderSections()
    return (
      <Segment basic style={{ height: '100%' }}>
        <Header as="h2" textAlign="center">
          Analysis Results
        </Header>
        <Message positive size="small">
          <p>
            Under each category, results for individual datasets can be visualized. First, select a category and then
            select each dataset of interest to see the results on-screen.
          </p>
          Click <i>Download Analysis Report</i> to generate a PDF or Excel report.
        </Message>
        {window.PROPOSITION ? (
          <div>
            <Header size="small">Select Dataset:</Header>
            <Dropdown
              placeholder="Select Individual Dataset"
              fluid
              search
              floating
              selection
              options={this.propositionDropdownOptions}
              value={this.state.selectedDataset}
              onChange={this.handleDatasetChange}
            />
          </div>
        ) : (
          <div>
            <Header size="small">Select Category</Header>
            <Dropdown
              placeholder="Select Category"
              fluid
              search
              floating
              selection
              options={this.categoriesDropdownOptions}
              value={this.state.activeCategory}
              onChange={this.handleCategoryChange}
            />

            <Header size="small">Select Dataset:</Header>
            <Dropdown
              placeholder="Select Individual Dataset"
              fluid
              search
              floating
              selection
              options={this.datasetDropdownOptions[this.state.activeCategory]}
              value={this.state.selectedDataset}
              onChange={this.handleDatasetChange}
            />
          </div>
        )}
        <Segment basic className="dataContainer">
          <Container>{Object.values(Sections)}</Container>
        </Segment>

        {/* showReportDialog prop is only fed into this component in RePlan tool, and <Report/> refers to */}
        {/* RePlan report generation. Report dialogues for other tools are brought up in other components */}
        {/* (for example, in Proposition/index.jsx) */}
        {this.props.showReportDialog ? <Report postReportCallback={this.props.closeReportDialog} /> : null}
      </Segment>
    )
  }
}

Analyze.contextType = LayoutContext

Analyze.propTypes = {
  // parent props
  showReportDialog: PropTypes.bool,
  // parent actions
  closeReportDialog: PropTypes.func,
  // redux props
  datasets: PropTypes.shape({}).isRequired,
  availableRCAs: PropTypes.shape({}).isRequired,
  queryResults: PropTypes.shape({}).isRequired,
  // redux actions
  runAnalysis: PropTypes.func.isRequired,
}

Analyze.defaultProps = {
  showReportDialog: false,
  closeReportDialog: () => {},
}

const mapStateToProps = state => ({
  isLoading: state.getIn(['page', 'isLoading']),
  availableRCAs: state.getIn(['inputs', 'rca', 'sitesTargets']).toJS(),
  datasets: state.getIn(['config', 'datasets']).toJS(),
  queryResults: state.getIn(['analysis', 'queryResults']).toJS(),
})

export default connect(mapStateToProps, { runAnalysis })(Analyze)
