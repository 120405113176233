/* eslint-disable import/prefer-default-export */
import axios from 'axios'

import { updateLoadingStatus } from './page'

export function createReport(data, reportType) {
  return dispatch => {
    dispatch(updateLoadingStatus(true))

    let blobType
    switch (reportType) {
      case 'pdf':
        blobType = 'application/pdf'
        break
      case 'xlsx':
        blobType = 'octet/stream'
        break
    }
    axios
      .post(Urls['api:report-analysis'](reportType), data, {
        responseType: 'blob',
        headers: { 'Content-Type': 'application/json' },
      })
      .then(response => {
        const blob = new Blob([response.data], { type: blobType })
        const reader = new FileReader()
        const filename = `report_${new Date().toLocaleDateString()}.${reportType}`
        reader.addEventListener('loadend', e => {
          if (navigator.msSaveBlob) {
            // IE11 & Edge
            navigator.msSaveBlob(e.target.result, filename)
          } else {
            const node = document.createElement('a')
            node.setAttribute('href', e.target.result)
            node.setAttribute('download', filename)
            document.body.appendChild(node)
            node.click()
            document.body.removeChild(node)
          }

          dispatch(updateLoadingStatus(false))
        })
        reader.readAsDataURL(blob)
      })
      .catch(() => {
        dispatch(updateLoadingStatus(false))
      })
  }
}
