import { fromJS, List } from 'immutable'

import { ACTIONS } from '../actions/map'

const INITIAL_STATE = fromJS({
  overlaysUpdatedOn: new Date().toString(),
  hasMapImages: false,
  identifyTarget: [],
  isDrawing: false,
  isUploading: false,
  geometriesSource: '',
  shapefiles: [],
})

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.UPDATE_OVERLAYS_STATUS:
      return state.set('overlaysUpdatedOn', new Date().toString())
    case ACTIONS.UPDATE_MAP_IMAGES_STATUS:
      return state.set('hasMapImages', action.hasMapImages)
    case ACTIONS.UPDATE_DRAW_STATUS:
      return state
        .set('isDrawing', action.isDrawing)
        .set('identifyTarget', action.isDrawing ? fromJS([]) : state.get('identifyTarget'))
    case ACTIONS.UPDATE_UPLOAD_STATUS:
      return state.set('isUploading', action.isUploading)
    case ACTIONS.UPDATE_IDENTIFY_TARGET:
      return state.set('identifyTarget', fromJS(action.target))
    case ACTIONS.UPDATE_GEOMETRIES_SOURCE:
      return state.set('geometriesSource', action.source)
    case ACTIONS.ADD_SHAPEFILE:
      return state.update('shapefiles', arr => arr.push(fromJS(action.shapefile)))
    case ACTIONS.REMOVE_SHAPEFILE:
      const index = state
        .get('shapefiles')
        .toJS()
        .findIndex(sf => sf.leafletId === action.leafletId)
      return state.removeIn(['shapefiles', index])
    case ACTIONS.CLEAR_SHAPEFILES:
      return state.set('shapefiles', List())
    default:
      return state
  }
}
