export const SPECIES_INFO = {
  mojave: {
    astragalus_lentiginosus_var_antonius: {
      common_name: 'San Antonio milk-vetch',
      scientific_name: 'Astragalus lentiginosus var antonius',
      taxon_group: 'Dicots',
      status_code: 'G3T3QS1',
    },
    astragalus_nyensis: {
      common_name: 'Nye milk-vetch',
      scientific_name: 'Astragalus nyensis',
      taxon_group: 'Dicots',
      status_code: 'G4S1',
    },
    astragalus_platytropis: {
      common_name: 'broad-keeled milk-vetch',
      scientific_name: 'Astragalus platytropis',
      taxon_group: 'Dicots',
      status_code: 'G3?S1',
    },
    astragalus_tidestromii: {
      common_name: "Tidestrom's milk-vetch",
      scientific_name: 'Astragalus tidestromii',
      taxon_group: 'Dicots',
      status_code: 'G5S1S2',
    },
    astrolepis_cochisensis_ssp_cochisensis: {
      common_name: 'scaly cloak fern',
      scientific_name: 'Astrolepis cochisensis ssp cochisensis',
      taxon_group: 'Ferns',
      status_code: 'G4S1',
    },
    ayenia_compacta: {
      common_name: 'California ayenia',
      scientific_name: 'Ayenia compacta',
      taxon_group: 'Dicots',
      status_code: 'G4T4S1',
    },
    berberis_harrisoniana: {
      common_name: 'Kofa Mountain barberry',
      scientific_name: 'Berberis harrisoniana',
      taxon_group: 'Dicots',
      status_code: 'G5T1S1',
    },
    boechera_lincolnensis: {
      common_name: 'Lincoln rockcress',
      scientific_name: 'Boechera lincolnensis',
      taxon_group: 'Dicots',
      status_code: 'G5S1',
    },
    bombus_crotchii: {
      common_name: 'Crotch bumble bee',
      scientific_name: 'Bombus crotchii',
      taxon_group: 'Insects',
      status_code: 'G3G4S1S2',
    },
    bombus_morrisoni: {
      common_name: 'Morrison bumble bee',
      scientific_name: 'Bombus morrisoni',
      taxon_group: 'Insects',
      status_code: 'G4G5S1S2',
    },
    calochortus_striatus: {
      common_name: 'alkali mariposa-lily',
      scientific_name: 'Calochortus striatus',
      taxon_group: 'Monocots',
      status_code: 'G4?T1S1',
    },
    cardinalis_cardinalis: {
      common_name: 'northern cardinal',
      scientific_name: 'Cardinalis cardinalis',
      taxon_group: 'Birds',
      status_code: 'G5S1',
    },
    castela_emoryi: {
      common_name: "Emory's crucifixion-thorn",
      scientific_name: 'Castela emoryi',
      taxon_group: 'Dicots',
      status_code: 'G5T3S1',
    },
    catostomus_latipinnis: {
      common_name: 'flannelmouth sucker',
      scientific_name: 'Catostomus latipinnis',
      taxon_group: 'Fish',
      status_code: 'G3G4S1',
    },
    chloropyron_tecopense: {
      common_name: "Tecopa bird's-beak",
      scientific_name: 'Chloropyron tecopense',
      taxon_group: 'Dicots',
      status_code: 'G5T1S1',
    },
    coccyzus_americanus_occidentalis: {
      common_name: 'western yellow-billed cuckoo',
      scientific_name: 'Coccyzus americanus occidentalis',
      taxon_group: 'Birds',
      status_code: 'G5T2T3S1',
    },
    colaptes_chrysoides: {
      common_name: 'gilded flicker',
      scientific_name: 'Colaptes chrysoides',
      taxon_group: 'Birds',
      status_code: 'G5S1',
    },
    cordylanthus_parviflorus: {
      common_name: "small-flowered bird's-beak",
      scientific_name: 'Cordylanthus parviflorus',
      taxon_group: 'Dicots',
      status_code: 'G5S1S2',
    },
    cymopterus_multinervatus: {
      common_name: 'purple-nerve cymopterus',
      scientific_name: 'Cymopterus multinervatus',
      taxon_group: 'Dicots',
      status_code: 'G5S1?',
    },
    diplacus_mohavensis: {
      common_name: 'Mojave monkeyflower',
      scientific_name: 'Diplacus mohavensis',
      taxon_group: 'Dicots',
      status_code: 'G5S1',
    },
    dipodomys_merriami_parvus: {
      common_name: 'San Bernardino kangaroo rat',
      scientific_name: 'Dipodomys merriami parvus',
      taxon_group: 'Mammals',
      status_code: 'G5T1S1',
    },
    dipodomys_panamintinus_argusensis: {
      common_name: 'Argus Mountains kangaroo rat',
      scientific_name: 'Dipodomys panamintinus argusensis',
      taxon_group: 'Mammals',
      status_code: 'G5T1T3S1S3',
    },
    empidonax_traillii_extimus: {
      common_name: 'southwestern willow flycatcher',
      scientific_name: 'Empidonax traillii extimus',
      taxon_group: 'Birds',
      status_code: 'G5T2S1',
    },
    eremarionta_rowelli_bakerensis: {
      common_name: "Baker's desertsnail",
      scientific_name: 'Eremarionta rowelli bakerensis',
      taxon_group: 'Mollusks',
      status_code: 'G3G4T1S1',
    },
    eremothera_boothii_ssp_boothii: {
      common_name: "Booth's evening-primrose",
      scientific_name: 'Eremothera boothii ssp boothii',
      taxon_group: 'Dicots',
      status_code: 'G5T1S1',
    },
    eriastrum_harwoodii: {
      common_name: "Harwood's eriastrum",
      scientific_name: 'Eriastrum harwoodii',
      taxon_group: 'Dicots',
      status_code: 'G5T2?S1',
    },
    erigeron_calvus: {
      common_name: 'bald daisy',
      scientific_name: 'Erigeron calvus',
      taxon_group: 'Dicots',
      status_code: 'G4G5T3T4S1',
    },
    eriogonum_intrafractum: {
      common_name: 'jointed buckwheat',
      scientific_name: 'Eriogonum intrafractum',
      taxon_group: 'Dicots',
      status_code: 'G4T1S1',
    },
    eriogonum_thornei: {
      common_name: "Thorne's buckwheat",
      scientific_name: 'Eriogonum thornei',
      taxon_group: 'Dicots',
      status_code: 'G4S1',
    },
    eschscholzia_minutiflora_ssp_twisselmannii: {
      common_name: 'Red Rock poppy',
      scientific_name: 'Eschscholzia minutiflora ssp twisselmannii',
      taxon_group: 'Dicots',
      status_code: 'G5T5?S1',
    },
    grusonia_parishii: {
      common_name: "Parish's club-cholla",
      scientific_name: 'Grusonia parishii',
      taxon_group: 'Dicots',
      status_code: 'G3T1QS1',
    },
    gulo_gulo: {
      common_name: 'California wolverine',
      scientific_name: 'Gulo gulo',
      taxon_group: 'Mammals',
      status_code: 'G4S1',
    },
    heloderma_suspectum_cinctum: {
      common_name: 'banded Gila monster',
      scientific_name: 'Heloderma suspectum cinctum',
      taxon_group: 'Reptiles',
      status_code: 'G4T4S1',
    },
    jaffueliobryum_wrightii: {
      common_name: "Wright's jaffueliobryum moss",
      scientific_name: 'Jaffueliobryum wrightii',
      taxon_group: 'Bryophytes',
      status_code: 'G5T5?S1',
    },
    junco_hyemalis_caniceps: {
      common_name: 'gray-headed junco',
      scientific_name: 'Junco hyemalis caniceps',
      taxon_group: 'Birds',
      status_code: 'G5T5S1',
    },
    lilium_parryi: {
      common_name: 'lemon lily',
      scientific_name: 'Lilium parryi',
      taxon_group: 'Monocots',
      status_code: 'G4G5S1',
    },
    linanthus_bernardinus: {
      common_name: 'Pioneertown linanthus',
      scientific_name: 'Linanthus bernardinus',
      taxon_group: 'Dicots',
      status_code: 'G4S1S2',
    },
    melanerpes_uropygialis: {
      common_name: 'Gila woodpecker',
      scientific_name: 'Melanerpes uropygialis',
      taxon_group: 'Birds',
      status_code: 'G5S1',
    },
    micrathene_whitneyi: {
      common_name: 'elf owl',
      scientific_name: 'Micrathene whitneyi',
      taxon_group: 'Birds',
      status_code: 'G5S1',
    },
    microtus_californicus_mohavensis: {
      common_name: 'Mohave river vole',
      scientific_name: 'Microtus californicus mohavensis',
      taxon_group: 'Mammals',
      status_code: 'G5T1S1',
    },
    microtus_californicus_scirpensis: {
      common_name: 'Amargosa vole',
      scientific_name: 'Microtus californicus scirpensis',
      taxon_group: 'Mammals',
      status_code: 'G5T1S1',
    },
    muhlenbergia_alopecuroides: {
      common_name: 'wolftail',
      scientific_name: 'Muhlenbergia alopecuroides',
      taxon_group: 'Monocots',
      status_code: 'G5?S1',
    },
    myotis_velifer: {
      common_name: 'cave myotis',
      scientific_name: 'Myotis velifer',
      taxon_group: 'Mammals',
      status_code: 'G5S1',
    },
    myriopteris_wootonii: {
      common_name: "Wooton's lace fern",
      scientific_name: 'Myriopteris wootonii',
      taxon_group: 'Ferns',
      status_code: 'G5S1',
    },
    nama_dichotoma_var_dichotoma: {
      common_name: 'forked purple mat',
      scientific_name: 'Nama dichotoma var dichotoma',
      taxon_group: 'Dicots',
      status_code: 'G3G4T3QS1',
    },
    neotamias_panamintinus_acrus: {
      common_name: 'Kingston Mountain chipmunk',
      scientific_name: 'Neotamias panamintinus acrus',
      taxon_group: 'Mammals',
      status_code: 'G4T1T2S1S2',
    },
    onychomys_torridus_tularensis: {
      common_name: 'Tulare grasshopper mouse',
      scientific_name: 'Onychomys torridus tularensis',
      taxon_group: 'Mammals',
      status_code: 'G5T1T2S1S2',
    },
    opuntia_basilaris_var_treleasei: {
      common_name: 'Bakersfield cactus',
      scientific_name: 'Opuntia basilaris var treleasei',
      taxon_group: 'Dicots',
      status_code: 'G5T1S1',
    },
    opuntia_wigginsii: {
      common_name: "Wiggins' cholla",
      scientific_name: 'Opuntia wigginsii',
      taxon_group: 'Dicots',
      status_code: 'G3?QS1?',
    },
    orobanche_valida_ssp_valida: {
      common_name: 'Rock Creek broomrape',
      scientific_name: 'Orobanche valida ssp valida',
      taxon_group: 'Dicots',
      status_code: 'G4S1',
    },
    perognathus_parvus_xanthonotus: {
      common_name: 'yellow-eared pocket mouse',
      scientific_name: 'Perognathus parvus xanthonotus',
      taxon_group: 'Mammals',
      status_code: 'G5T2T3S1S2',
    },
    phacelia_anelsonii: {
      common_name: "Aven Nelson's phacelia",
      scientific_name: 'Phacelia anelsonii',
      taxon_group: 'Dicots',
      status_code: 'G4S1S2',
    },
    physalis_lobata: {
      common_name: 'lobed ground-cherry',
      scientific_name: 'Physalis lobata',
      taxon_group: 'Dicots',
      status_code: 'G5T2S1',
    },
    physocarpus_alternans: {
      common_name: 'Nevada ninebark',
      scientific_name: 'Physocarpus alternans',
      taxon_group: 'Dicots',
      status_code: 'G4T3T4S1',
    },
    piranga_flava: {
      common_name: 'hepatic tanager',
      scientific_name: 'Piranga flava',
      taxon_group: 'Birds',
      status_code: 'G5S1',
    },
    piranga_rubra: {
      common_name: 'summer tanager',
      scientific_name: 'Piranga rubra',
      taxon_group: 'Birds',
      status_code: 'G5S1',
    },
    polygala_heterorhyncha: {
      common_name: 'notch-beaked milkwort',
      scientific_name: 'Polygala heterorhyncha',
      taxon_group: 'Dicots',
      status_code: 'G4?S1',
    },
    puccinellia_simplex: {
      common_name: 'California alkali grass',
      scientific_name: 'Puccinellia simplex',
      taxon_group: 'Monocots',
      status_code: 'G4T2S1',
    },
    rallus_obsoletus_yumanensis: {
      common_name: "Yuma Ridgway's rail",
      scientific_name: 'Rallus obsoletus yumanensis',
      taxon_group: 'Birds',
      status_code: 'G5T3S1S2',
    },
    rhinichthys_osculus_ssp_1: {
      common_name: 'Amargosa Canyon speckled dace',
      scientific_name: 'Rhinichthys osculus ssp 1',
      taxon_group: 'Fish',
      status_code: 'G5T1QS1',
    },
    rhinichthys_osculus_ssp_3: {
      common_name: 'Santa Ana speckled dace',
      scientific_name: 'Rhinichthys osculus ssp 3',
      taxon_group: 'Fish',
      status_code: 'G5T1S1',
    },
    siphateles_bicolor_mohavensis: {
      common_name: 'Mohave tui chub',
      scientific_name: 'Siphateles bicolor mohavensis',
      taxon_group: 'Fish',
      status_code: 'G4T1S1',
    },
    siphateles_bicolor_snyderi: {
      common_name: 'Owens tui chub',
      scientific_name: 'Siphateles bicolor snyderi',
      taxon_group: 'Fish',
      status_code: 'G4T1S1',
    },
    trifolium_dedeckerae: {
      common_name: "Dedecker's clover",
      scientific_name: 'Trifolium dedeckerae',
      taxon_group: 'Dicots',
      status_code: 'G5S1',
    },
    vireo_bellii_arizonae: {
      common_name: "Arizona bell's vireo",
      scientific_name: 'Vireo bellii arizonae',
      taxon_group: 'Birds',
      status_code: 'G5T4S1S2',
    },
    abutilon_parvulum: {
      common_name: 'dwarf abutilon',
      scientific_name: 'Abutilon parvulum',
      taxon_group: 'Dicots',
      status_code: 'G4S2',
    },
    acleisanthes_nevadensis: {
      common_name: 'desert wing-fruit',
      scientific_name: 'Acleisanthes nevadensis',
      taxon_group: 'Dicots',
      status_code: 'G4S2',
    },
    acmispon_argyraeus_var_multicaulis: {
      common_name: 'scrub lotus',
      scientific_name: 'Acmispon argyraeus var multicaulis',
      taxon_group: 'Dicots',
      status_code: 'G3S2',
    },
    acmispon_argyraeus_var_notitius: {
      common_name: 'Providence Mountains lotus',
      scientific_name: 'Acmispon argyraeus var notitius',
      taxon_group: 'Dicots',
      status_code: 'G5T2S2',
    },
    aliciella_ripleyi: {
      common_name: "Ripley's aliciella",
      scientific_name: 'Aliciella ripleyi',
      taxon_group: 'Dicots',
      status_code: 'G3G4T3?S2',
    },
    arizona_elegans_occidentalis: {
      common_name: 'California glossy snake',
      scientific_name: 'Arizona elegans occidentalis',
      taxon_group: 'Reptiles',
      status_code: 'G5T2S2',
    },
    astragalus_allochrous_var_playanus: {
      common_name: 'playa milk-vetch',
      scientific_name: 'Astragalus allochrous var playanus',
      taxon_group: 'Dicots',
      status_code: 'G5S2',
    },
    astragalus_funereus: {
      common_name: 'black milk-vetch',
      scientific_name: 'Astragalus funereus',
      taxon_group: 'Dicots',
      status_code: 'G5T2S2',
    },
    astragalus_sabulonum: {
      common_name: 'gravel milk-vetch',
      scientific_name: 'Astragalus sabulonum',
      taxon_group: 'Dicots',
      status_code: 'G4T2S2',
    },
    atriplex_argentea_var_hillmanii: {
      common_name: "Hillman's silverscale",
      scientific_name: 'Atriplex argentea var hillmanii',
      taxon_group: 'Dicots',
      status_code: 'G5T4S2',
    },
    boechera_parishii: {
      common_name: "Parish's rockcress",
      scientific_name: 'Boechera parishii',
      taxon_group: 'Dicots',
      status_code: 'G5T5?S2',
    },
    bouteloua_trifida: {
      common_name: 'three-awned grama',
      scientific_name: 'Bouteloua trifida',
      taxon_group: 'Monocots',
      status_code: 'G3S2',
    },
    california_macrophylla: {
      common_name: 'round-leaved filaree',
      scientific_name: 'California macrophylla',
      taxon_group: 'Dicots',
      status_code: 'G3S2',
    },
    carnegiea_gigantea: {
      common_name: 'saguaro',
      scientific_name: 'Carnegiea gigantea',
      taxon_group: 'Dicots',
      status_code: 'G3S2',
    },
    castilleja_lasiorhyncha: {
      common_name: "San Bernardino Mountains owl's-clover",
      scientific_name: 'Castilleja lasiorhyncha',
      taxon_group: 'Dicots',
      status_code: 'G4S2',
    },
    charadrius_alexandrinus_nivosus: {
      common_name: 'western snowy plover',
      scientific_name: 'Charadrius alexandrinus nivosus',
      taxon_group: 'Birds',
      status_code: 'G3T3S2S3',
    },
    charadrius_montanus: {
      common_name: 'mountain plover',
      scientific_name: 'Charadrius montanus',
      taxon_group: 'Birds',
      status_code: 'G3S2S3',
    },
    chorizanthe_parryi_var_parryi: {
      common_name: "Parry's spineflower",
      scientific_name: 'Chorizanthe parryi var parryi',
      taxon_group: 'Dicots',
      status_code: 'G5S2',
    },
    chorizanthe_xanti_var_leucotheca: {
      common_name: 'white-bracted spineflower',
      scientific_name: 'Chorizanthe xanti var leucotheca',
      taxon_group: 'Dicots',
      status_code: 'G4G5T3T4S2',
    },
    chrysothamnus_greenei: {
      common_name: "Greene's rabbitbrush",
      scientific_name: 'Chrysothamnus greenei',
      taxon_group: 'Dicots',
      status_code: 'G4G5S2S3',
    },
    cirsium_arizonicum_var_tenuisectum: {
      common_name: 'desert mountain thistle',
      scientific_name: 'Cirsium arizonicum var tenuisectum',
      taxon_group: 'Dicots',
      status_code: 'G3T2S2',
    },
    cladium_californicum: {
      common_name: 'California saw-grass',
      scientific_name: 'Cladium californicum',
      taxon_group: 'Monocots',
      status_code: 'G4T3S2',
    },
    corynorhinus_townsendii: {
      common_name: "Townsend's big-eared bat",
      scientific_name: 'Corynorhinus townsendii',
      taxon_group: 'Mammals',
      status_code: 'G3G4S2',
    },
    cymopterus_deserticola: {
      common_name: 'desert cymopterus',
      scientific_name: 'Cymopterus deserticola',
      taxon_group: 'Dicots',
      status_code: 'G4S2',
    },
    cymopterus_ripleyi_var_saniculoides: {
      common_name: 'sanicle cymopterus',
      scientific_name: 'Cymopterus ripleyi var saniculoides',
      taxon_group: 'Dicots',
      status_code: 'G5T2S2',
    },
    delphinium_recurvatum: {
      common_name: 'recurved larkspur',
      scientific_name: 'Delphinium recurvatum',
      taxon_group: 'Dicots',
      status_code: 'G5S2',
    },
    diadophis_punctatus_regalis: {
      common_name: 'regal ringneck snake',
      scientific_name: 'Diadophis punctatus regalis',
      taxon_group: 'Reptiles',
      status_code: 'GNRS2S3',
    },
    digitaria_californica_var_californica: {
      common_name: 'Arizona cottontop',
      scientific_name: 'Digitaria californica var californica',
      taxon_group: 'Monocots',
      status_code: 'G4T4S2',
    },
    enneapogon_desvauxii: {
      common_name: 'nine-awned pappus grass',
      scientific_name: 'Enneapogon desvauxii',
      taxon_group: 'Monocots',
      status_code: 'G4G5S2?',
    },
    ephedra_torreyana: {
      common_name: "Torrey's Mormon-tea",
      scientific_name: 'Ephedra torreyana',
      taxon_group: 'Gymnosperms',
      status_code: 'G4S2S3',
    },
    eremogone_congesta_var_charlestonensis: {
      common_name: 'Charleston sandwort',
      scientific_name: 'Eremogone congesta var charlestonensis',
      taxon_group: 'Dicots',
      status_code: 'G5T4S2',
    },
    eremothera_boothii_ssp_intermedia: {
      common_name: "Booth's hairy evening-primrose",
      scientific_name: 'Eremothera boothii ssp intermedia',
      taxon_group: 'Dicots',
      status_code: 'G5T2S2',
    },
    erigeron_uncialis_var_uncialis: {
      common_name: 'limestone daisy',
      scientific_name: 'Erigeron uncialis var uncialis',
      taxon_group: 'Dicots',
      status_code: 'G5T5?S2',
    },
    eriogonum_bifurcatum: {
      common_name: 'forked buckwheat',
      scientific_name: 'Eriogonum bifurcatum',
      taxon_group: 'Dicots',
      status_code: 'G4G5S2',
    },
    eriogonum_contiguum: {
      common_name: "Reveal's buckwheat",
      scientific_name: 'Eriogonum contiguum',
      taxon_group: 'Dicots',
      status_code: 'G3S2',
    },
    eriogonum_umbellatum_var_juniporinum: {
      common_name: 'juniper sulphur-flowered buckwheat',
      scientific_name: 'Eriogonum umbellatum var juniporinum',
      taxon_group: 'Dicots',
      status_code: 'G5T2S2',
    },
    eriophyllum_mohavense: {
      common_name: 'Barstow woolly sunflower',
      scientific_name: 'Eriophyllum mohavense',
      taxon_group: 'Dicots',
      status_code: 'G4G5T2S2',
    },
    erythranthe_rhodopetra: {
      common_name: 'Red Rock Canyon monkeyflower',
      scientific_name: 'Erythranthe rhodopetra',
      taxon_group: 'Dicots',
      status_code: 'G5S2',
    },
    euphorbia_abramsiana: {
      common_name: "Abrams' spurge",
      scientific_name: 'Euphorbia abramsiana',
      taxon_group: 'Dicots',
      status_code: 'G5S2S3',
    },
    euphorbia_jaegeri: {
      common_name: 'Orocopia Mountains spurge',
      scientific_name: 'Euphorbia jaegeri',
      taxon_group: 'Dicots',
      status_code: 'G4T2S2',
    },
    euphorbia_platysperma: {
      common_name: 'flat-seeded spurge',
      scientific_name: 'Euphorbia platysperma',
      taxon_group: 'Dicots',
      status_code: 'G3S2',
    },
    galium_proliferum: {
      common_name: 'desert bedstraw',
      scientific_name: 'Galium proliferum',
      taxon_group: 'Dicots',
      status_code: 'G5T5S2',
    },
    gopherus_agassizii: {
      common_name: 'desert tortoise',
      scientific_name: 'Gopherus agassizii',
      taxon_group: 'Reptiles',
      status_code: 'G3S2S3',
    },
    grimmia_vaginulata: {
      common_name: 'vaginulate grimmia',
      scientific_name: 'Grimmia vaginulata',
      taxon_group: 'Bryophytes',
      status_code: 'G5S2',
    },
    hackelia_sharsmithii: {
      common_name: "Sharsmith's stickseed",
      scientific_name: 'Hackelia sharsmithii',
      taxon_group: 'Dicots',
      status_code: 'G3?S2',
    },
    hedeoma_drummondii: {
      common_name: "Drummond's false pennyroyal",
      scientific_name: 'Hedeoma drummondii',
      taxon_group: 'Dicots',
      status_code: 'G3?T2S2',
    },
    imperata_brevifolia: {
      common_name: 'California satintail',
      scientific_name: 'Imperata brevifolia',
      taxon_group: 'Monocots',
      status_code: 'G4G5S2',
    },
    ivesia_arizonica_var_arizonica: {
      common_name: 'yellow ivesia',
      scientific_name: 'Ivesia arizonica var arizonica',
      taxon_group: 'Dicots',
      status_code: 'G5S2',
    },
    ivesia_jaegeri: {
      common_name: "Jaeger's ivesia",
      scientific_name: 'Ivesia jaegeri',
      taxon_group: 'Dicots',
      status_code: 'G3T2S2',
    },
    ivesia_patellifera: {
      common_name: 'Kingston Mountains ivesia',
      scientific_name: 'Ivesia patellifera',
      taxon_group: 'Dicots',
      status_code: 'G5T2S2',
    },
    ixobrychus_exilis: {
      common_name: 'least bittern',
      scientific_name: 'Ixobrychus exilis',
      taxon_group: 'Birds',
      status_code: 'G5S2',
    },
    jaffueliobryum_raui: {
      common_name: "Rau's jaffueliobryum moss",
      scientific_name: 'Jaffueliobryum raui',
      taxon_group: 'Bryophytes',
      status_code: 'G5S2',
    },
    johanneshowellia_puberula: {
      common_name: 'downy buckwheat',
      scientific_name: 'Johanneshowellia puberula',
      taxon_group: 'Dicots',
      status_code: 'G4S2S3',
    },
    juncus_interior: {
      common_name: 'inland rush',
      scientific_name: 'Juncus interior',
      taxon_group: 'Monocots',
      status_code: 'G4S2',
    },
    layia_heterotricha: {
      common_name: 'pale-yellow layia',
      scientific_name: 'Layia heterotricha',
      taxon_group: 'Dicots',
      status_code: 'G4S2',
    },
    linum_puberulum: {
      common_name: 'plains flax',
      scientific_name: 'Linum puberulum',
      taxon_group: 'Dicots',
      status_code: 'G4S2',
    },
    loeflingia_squarrosa_var_artemisiarum: {
      common_name: 'sagebrush loeflingia',
      scientific_name: 'Loeflingia squarrosa var artemisiarum',
      taxon_group: 'Dicots',
      status_code: 'G5T5S2',
    },
    lupinus_magnificus_var_magnificus: {
      common_name: 'Panamint Mountains lupine',
      scientific_name: 'Lupinus magnificus var magnificus',
      taxon_group: 'Dicots',
      status_code: 'G5S2',
    },
    mammillaria_grahamii_var_grahamii: {
      common_name: 'Graham fishhook cactus',
      scientific_name: 'Mammillaria grahamii var grahamii',
      taxon_group: 'Dicots',
      status_code: 'G5S2',
    },
    matelea_parvifolia: {
      common_name: 'spear-leaf matelea',
      scientific_name: 'Matelea parvifolia',
      taxon_group: 'Dicots',
      status_code: 'G4S2S3',
    },
    maurandella_antirrhiniflora: {
      common_name: 'violet twining snapdragon',
      scientific_name: 'Maurandella antirrhiniflora',
      taxon_group: 'Dicots',
      status_code: 'G5S2',
    },
    melozone_crissalis_eremophilus: {
      common_name: 'Inyo California towhee',
      scientific_name: 'Melozone crissalis eremophilus',
      taxon_group: 'Birds',
      status_code: 'G4G5T2S2',
    },
    menodora_scabra_var_scabra: {
      common_name: 'rough menodora',
      scientific_name: 'Menodora scabra var scabra',
      taxon_group: 'Dicots',
      status_code: 'G5T5S2',
    },
    menodora_spinescens_var_mohavensis: {
      common_name: 'Mojave menodora',
      scientific_name: 'Menodora spinescens var mohavensis',
      taxon_group: 'Dicots',
      status_code: 'G3G4S2',
    },
    mentzelia_tricuspis: {
      common_name: 'spiny-hair blazing star',
      scientific_name: 'Mentzelia tricuspis',
      taxon_group: 'Dicots',
      status_code: 'G5S2',
    },
    mirabilis_coccinea: {
      common_name: "red four o'clock",
      scientific_name: 'Mirabilis coccinea',
      taxon_group: 'Dicots',
      status_code: 'G5S2',
    },
    monarda_pectinata: {
      common_name: 'plains bee balm',
      scientific_name: 'Monarda pectinata',
      taxon_group: 'Dicots',
      status_code: 'G4S2',
    },
    monardella_eremicola: {
      common_name: 'Clark Mountain monardella',
      scientific_name: 'Monardella eremicola',
      taxon_group: 'Dicots',
      status_code: 'G5S2S3',
    },
    muhlenbergia_appressa: {
      common_name: 'appressed muhly',
      scientific_name: 'Muhlenbergia appressa',
      taxon_group: 'Monocots',
      status_code: 'G5S2',
    },
    navarretia_setiloba: {
      common_name: 'Piute Mountains navarretia',
      scientific_name: 'Navarretia setiloba',
      taxon_group: 'Dicots',
      status_code: 'G5S2',
    },
    nemacaulis_denudata_var_gracilis: {
      common_name: 'slender cottonheads',
      scientific_name: 'Nemacaulis denudata var gracilis',
      taxon_group: 'Dicots',
      status_code: 'G4T2S2',
    },
    nemacladus_secundiflorus_var_robbinsii: {
      common_name: "Robbins' nemacladus",
      scientific_name: 'Nemacladus secundiflorus var robbinsii',
      taxon_group: 'Dicots',
      status_code: 'G3T2S2',
    },
    nitrophila_mohavensis: {
      common_name: 'Amargosa nitrophila',
      scientific_name: 'Nitrophila mohavensis',
      taxon_group: 'Dicots',
      status_code: 'G3S2',
    },
    oenothera_cavernae: {
      common_name: 'cave evening-primrose',
      scientific_name: 'Oenothera cavernae',
      taxon_group: 'Dicots',
      status_code: 'G3S2',
    },
    oliarces_clara: {
      common_name: 'cheeseweed owlfly (cheeseweed moth lacewing)',
      scientific_name: 'Oliarces clara',
      taxon_group: 'Insects',
      status_code: 'G1G3S2',
    },
    opuntia_basilaris_var_brachyclada: {
      common_name: 'short-joint beavertail',
      scientific_name: 'Opuntia basilaris var brachyclada',
      taxon_group: 'Dicots',
      status_code: 'G3S2',
    },
    opuntia_xcurvispina: {
      common_name: 'curved-spine beavertail',
      scientific_name: 'Opuntia xcurvispina',
      taxon_group: 'Dicots',
      status_code: 'G3S2',
    },
    oreothlypis_luciae: {
      common_name: "Lucy's warbler",
      scientific_name: 'Oreothlypis luciae',
      taxon_group: 'Birds',
      status_code: 'G5S2S3',
    },
    oreothlypis_virginiae: {
      common_name: "Virginia's warbler",
      scientific_name: 'Oreothlypis virginiae',
      taxon_group: 'Birds',
      status_code: 'G5S2',
    },
    ovis_canadensis_sierrae: {
      common_name: 'Sierra Nevada bighorn sheep',
      scientific_name: 'Ovis canadensis sierrae',
      taxon_group: 'Mammals',
      status_code: 'G4T2S2',
    },
    panicum_hirticaule_ssp_hirticaule: {
      common_name: 'roughstalk witch grass',
      scientific_name: 'Panicum hirticaule ssp hirticaule',
      taxon_group: 'Monocots',
      status_code: 'G4T2S2',
    },
    pediomelum_castoreum: {
      common_name: 'Beaver Dam breadroot',
      scientific_name: 'Pediomelum castoreum',
      taxon_group: 'Dicots',
      status_code: 'G3T3S2?',
    },
    pellaea_truncata: {
      common_name: 'spiny cliff-brake',
      scientific_name: 'Pellaea truncata',
      taxon_group: 'Ferns',
      status_code: 'G4S2',
    },
    penstemon_albomarginatus: {
      common_name: 'white-margined beardtongue',
      scientific_name: 'Penstemon albomarginatus',
      taxon_group: 'Dicots',
      status_code: 'G4S2',
    },
    penstemon_calcareus: {
      common_name: 'limestone beardtongue',
      scientific_name: 'Penstemon calcareus',
      taxon_group: 'Dicots',
      status_code: 'G4S2',
    },
    penstemon_fruticiformis_var_amargosae: {
      common_name: 'Amargosa beardtongue',
      scientific_name: 'Penstemon fruticiformis var amargosae',
      taxon_group: 'Dicots',
      status_code: 'G5S2S3',
    },
    penstemon_thompsoniae: {
      common_name: "Thompson's beardtongue",
      scientific_name: 'Penstemon thompsoniae',
      taxon_group: 'Dicots',
      status_code: 'G5S2S3',
    },
    perognathus_longimembris_bangsi: {
      common_name: 'Palm Springs pocket mouse',
      scientific_name: 'Perognathus longimembris bangsi',
      taxon_group: 'Mammals',
      status_code: 'G5T2S2',
    },
    phacelia_barnebyana: {
      common_name: "Barneby's phacelia",
      scientific_name: 'Phacelia barnebyana',
      taxon_group: 'Dicots',
      status_code: 'G4T4S2',
    },
    phacelia_nashiana: {
      common_name: "Charlotte's phacelia",
      scientific_name: 'Phacelia nashiana',
      taxon_group: 'Dicots',
      status_code: 'G5T2S2',
    },
    pholistoma_auritum_var_arizonicum: {
      common_name: 'Arizona pholistoma',
      scientific_name: 'Pholistoma auritum var arizonicum',
      taxon_group: 'Dicots',
      status_code: 'G4T2S2',
    },
    poa_lettermanii: {
      common_name: "Letterman's blue grass",
      scientific_name: 'Poa lettermanii',
      taxon_group: 'Monocots',
      status_code: 'G5T2S2',
    },
    polygala_acanthoclada: {
      common_name: 'thorny milkwort',
      scientific_name: 'Polygala acanthoclada',
      taxon_group: 'Dicots',
      status_code: 'G4S2',
    },
    polygala_intermontana: {
      common_name: 'intermountain milkwort',
      scientific_name: 'Polygala intermontana',
      taxon_group: 'Dicots',
      status_code: 'G3G4T2S2',
    },
    populus_angustifolia: {
      common_name: 'narrow-leaved cottonwood',
      scientific_name: 'Populus angustifolia',
      taxon_group: 'Dicots',
      status_code: 'G5S2',
    },
    puccinellia_parishii: {
      common_name: "Parish's alkali grass",
      scientific_name: 'Puccinellia parishii',
      taxon_group: 'Monocots',
      status_code: 'G5T3S2',
    },
    pyrocephalus_rubinus: {
      common_name: 'vermilion flycatcher',
      scientific_name: 'Pyrocephalus rubinus',
      taxon_group: 'Birds',
      status_code: 'G5S2S3',
    },
    sanvitalia_abertii: {
      common_name: "Abert's sanvitalia",
      scientific_name: 'Sanvitalia abertii',
      taxon_group: 'Dicots',
      status_code: 'G5T2T3S2',
    },
    sarcobatus_baileyi: {
      common_name: "Bailey's greasewood",
      scientific_name: 'Sarcobatus baileyi',
      taxon_group: 'Dicots',
      status_code: 'G4S2',
    },
    setophaga_petechia_sonorana: {
      common_name: 'Sonoran yellow warbler',
      scientific_name: 'Setophaga petechia sonorana',
      taxon_group: 'Birds',
      status_code: 'G5T2T3S2',
    },
    stipa_divaricata: {
      common_name: 'small-flowered rice grass',
      scientific_name: 'Stipa divaricata',
      taxon_group: 'Monocots',
      status_code: 'G5S2S3',
    },
    tortella_alpicola: {
      common_name: 'alpine crisp-moss',
      scientific_name: 'Tortella alpicola',
      taxon_group: 'Bryophytes',
      status_code: 'G4?T2S2',
    },
    vireo_bellii_pusillus: {
      common_name: "least Bell's vireo",
      scientific_name: 'Vireo bellii pusillus',
      taxon_group: 'Birds',
      status_code: 'G5T2S2',
    },
    vireo_vicinior: {
      common_name: 'gray vireo',
      scientific_name: 'Vireo vicinior',
      taxon_group: 'Birds',
      status_code: 'G4S2',
    },
    acanthoscyphus_parishii_var_goodmaniana: {
      common_name: 'Cushenbury oxytheca',
      scientific_name: 'Acanthoscyphus parishii var goodmaniana',
      taxon_group: 'Dicots',
      status_code: 'G4G5S3',
    },
    aliciella_triodon: {
      common_name: 'coyote gilia',
      scientific_name: 'Aliciella triodon',
      taxon_group: 'Dicots',
      status_code: 'G4G5S3',
    },
    allium_atrorubens_var_atrorubens: {
      common_name: 'Great Basin onion',
      scientific_name: 'Allium atrorubens var atrorubens',
      taxon_group: 'Monocots',
      status_code: 'G4T3S3',
    },
    ammodramus_savannarum: {
      common_name: 'grasshopper sparrow',
      scientific_name: 'Ammodramus savannarum',
      taxon_group: 'Birds',
      status_code: 'G5S3',
    },
    androstephium_breviflorum: {
      common_name: 'small-flowered androstephium',
      scientific_name: 'Androstephium breviflorum',
      taxon_group: 'Monocots',
      status_code: 'G5T4?S3',
    },
    antrozous_pallidus: {
      common_name: 'pallid bat',
      scientific_name: 'Antrozous pallidus',
      taxon_group: 'Mammals',
      status_code: 'G5S3',
    },
    aquila_chrysaetos: {
      common_name: 'golden eagle',
      scientific_name: 'Aquila chrysaetos',
      taxon_group: 'Birds',
      status_code: 'G5S3',
    },
    artemisiospiza_belli_belli: {
      common_name: "Bell's sage sparrow",
      scientific_name: 'Artemisiospiza belli belli',
      taxon_group: 'Birds',
      status_code: 'G5T2T4S3',
    },
    asio_flammeus: {
      common_name: 'short-eared owl',
      scientific_name: 'Asio flammeus',
      taxon_group: 'Birds',
      status_code: 'G5S3',
    },
    asio_otus: {
      common_name: 'long-eared owl',
      scientific_name: 'Asio otus',
      taxon_group: 'Birds',
      status_code: 'G5S3?',
    },
    aspidoscelis_tigris_stejnegeri: {
      common_name: 'coastal whiptail',
      scientific_name: 'Aspidoscelis tigris stejnegeri',
      taxon_group: 'Reptiles',
      status_code: 'G5T5S3',
    },
    astragalus_albens: {
      common_name: 'Cushenbury milk-vetch',
      scientific_name: 'Astragalus albens',
      taxon_group: 'Dicots',
      status_code: 'G4G5S3',
    },
    astragalus_bernardinus: {
      common_name: 'San Bernardino milk-vetch',
      scientific_name: 'Astragalus bernardinus',
      taxon_group: 'Dicots',
      status_code: 'G4G5T4S3',
    },
    athene_cunicularia: {
      common_name: 'burrowing owl',
      scientific_name: 'Athene cunicularia',
      taxon_group: 'Birds',
      status_code: 'G4S3',
    },
    bahia_neomexicana: {
      common_name: 'many-flowered bahia',
      scientific_name: 'Bahia neomexicana',
      taxon_group: 'Dicots',
      status_code: 'G5S3',
    },
    buteo_regalis: {
      common_name: 'ferruginous hawk',
      scientific_name: 'Buteo regalis',
      taxon_group: 'Birds',
      status_code: 'G4S3S4',
    },
    buteo_swainsoni: {
      common_name: "Swainson's hawk",
      scientific_name: 'Buteo swainsoni',
      taxon_group: 'Birds',
      status_code: 'G5S3',
    },
    calochortus_excavatus: {
      common_name: 'Inyo County star-tulip',
      scientific_name: 'Calochortus excavatus',
      taxon_group: 'Monocots',
      status_code: 'G5S3',
    },
    chaetodipus_fallax_pallidus: {
      common_name: 'pallid San Diego pocket mouse',
      scientific_name: 'Chaetodipus fallax pallidus',
      taxon_group: 'Mammals',
      status_code: 'G5T34S3S4',
    },
    circus_cyaneus: {
      common_name: 'northern harrier',
      scientific_name: 'Circus cyaneus',
      taxon_group: 'Birds',
      status_code: 'G5S3',
    },
    coryphantha_chlorantha: {
      common_name: 'desert pincushion',
      scientific_name: 'Coryphantha chlorantha',
      taxon_group: 'Dicots',
      status_code: 'G4T3S3',
    },
    crotalus_ruber: {
      common_name: 'red-diamond rattlesnake',
      scientific_name: 'Crotalus ruber',
      taxon_group: 'Reptiles',
      status_code: 'G4S3',
    },
    diplacus_parryi: {
      common_name: "Parry's monkeyflower",
      scientific_name: 'Diplacus parryi',
      taxon_group: 'Dicots',
      status_code: 'G5S3',
    },
    elymus_salina: {
      common_name: 'Salina Pass wild-rye',
      scientific_name: 'Elymus salina',
      taxon_group: 'Monocots',
      status_code: 'G5S3',
    },
    ensatina_klauberi: {
      common_name: 'large-blotched salamander',
      scientific_name: 'Ensatina klauberi',
      taxon_group: 'Amphibians',
      status_code: 'G2G3S3',
    },
    eriogonum_hoffmannii_var_hoffmannii: {
      common_name: "Hoffmann's buckwheat",
      scientific_name: 'Eriogonum hoffmannii var hoffmannii',
      taxon_group: 'Dicots',
      status_code: 'G5T3T4S3',
    },
    euderma_maculatum: {
      common_name: 'spotted bat',
      scientific_name: 'Euderma maculatum',
      taxon_group: 'Mammals',
      status_code: 'G4S3',
    },
    eumops_perotis_californicus: {
      common_name: 'western mastiff bat',
      scientific_name: 'Eumops perotis californicus',
      taxon_group: 'Mammals',
      status_code: 'G5T4S3S4',
    },
    euphorbia_exstipulata_var_exstipulata: {
      common_name: 'Clark Mountain spurge',
      scientific_name: 'Euphorbia exstipulata var exstipulata',
      taxon_group: 'Dicots',
      status_code: 'G5S3',
    },
    euphorbia_parryi: {
      common_name: "Parry's spurge",
      scientific_name: 'Euphorbia parryi',
      taxon_group: 'Dicots',
      status_code: 'G5T4T5S3',
    },
    falco_columbarius: {
      common_name: 'merlin',
      scientific_name: 'Falco columbarius',
      taxon_group: 'Birds',
      status_code: 'G5S3S4',
    },
    frasera_albomarginata_var_induta: {
      common_name: 'Clark Mountain green-gentian',
      scientific_name: 'Frasera albomarginata var induta',
      taxon_group: 'Dicots',
      status_code: 'G4S3',
    },
    galium_hilendiae_ssp_kingstonense: {
      common_name: 'Kingston Mountains bedstraw',
      scientific_name: 'Galium hilendiae ssp kingstonense',
      taxon_group: 'Dicots',
      status_code: 'G5T4S3',
    },
    galium_wrightii: {
      common_name: "Wright's bedstraw",
      scientific_name: 'Galium wrightii',
      taxon_group: 'Dicots',
      status_code: 'G4G5T3?S3?',
    },
    grindelia_fraxinipratensis: {
      common_name: 'Ash Meadows gumplant',
      scientific_name: 'Grindelia fraxinipratensis',
      taxon_group: 'Dicots',
      status_code: 'G4S3',
    },
    haliaeetus_leucocephalus: {
      common_name: 'bald eagle',
      scientific_name: 'Haliaeetus leucocephalus',
      taxon_group: 'Birds',
      status_code: 'G5S3',
    },
    heuchera_parishii: {
      common_name: "Parish's alumroot",
      scientific_name: 'Heuchera parishii',
      taxon_group: 'Dicots',
      status_code: 'G5T4T5S3',
    },
    hymenopappus_filifolius_var_eriopodus: {
      common_name: 'hairy-podded fine-leaf hymenopappus',
      scientific_name: 'Hymenopappus filifolius var eriopodus',
      taxon_group: 'Dicots',
      status_code: 'G5S3',
    },
    icteria_virens: {
      common_name: 'yellow-breasted chat',
      scientific_name: 'Icteria virens',
      taxon_group: 'Birds',
      status_code: 'G5S3',
    },
    juncus_nodosus: {
      common_name: 'knotted rush',
      scientific_name: 'Juncus nodosus',
      taxon_group: 'Monocots',
      status_code: 'G5S3',
    },
    lasiurus_xanthinus: {
      common_name: 'western yellow bat',
      scientific_name: 'Lasiurus xanthinus',
      taxon_group: 'Mammals',
      status_code: 'G5S3',
    },
    lasthenia_glabrata_ssp_coulteri: {
      common_name: "Coulter's goldfields",
      scientific_name: 'Lasthenia glabrata ssp coulteri',
      taxon_group: 'Dicots',
      status_code: 'G5S3',
    },
    linanthus_maculatus_ssp_maculatus: {
      common_name: 'Little San Bernardino Mtns. linanthus',
      scientific_name: 'Linanthus maculatus ssp maculatus',
      taxon_group: 'Dicots',
      status_code: 'G5T5S3',
    },
    macrotus_californicus: {
      common_name: 'California leaf-nosed bat',
      scientific_name: 'Macrotus californicus',
      taxon_group: 'Mammals',
      status_code: 'G4S3',
    },
    mentzelia_torreyi: {
      common_name: "Torrey's blazing star",
      scientific_name: 'Mentzelia torreyi',
      taxon_group: 'Dicots',
      status_code: 'G4T3S3',
    },
    microtus_californicus_vallicola: {
      common_name: 'Owens Valley vole',
      scientific_name: 'Microtus californicus vallicola',
      taxon_group: 'Mammals',
      status_code: 'G5T3S3',
    },
    muhlenbergia_arsenei: {
      common_name: 'tough muhly',
      scientific_name: 'Muhlenbergia arsenei',
      taxon_group: 'Monocots',
      status_code: 'G5S3',
    },
    munroa_squarrosa: {
      common_name: 'false buffalo-grass',
      scientific_name: 'Munroa squarrosa',
      taxon_group: 'Monocots',
      status_code: 'G5S3',
    },
    myiarchus_tyrannulus: {
      common_name: 'brown-crested flycatcher',
      scientific_name: 'Myiarchus tyrannulus',
      taxon_group: 'Birds',
      status_code: 'G5S3',
    },
    myotis_evotis: {
      common_name: 'long-eared myotis',
      scientific_name: 'Myotis evotis',
      taxon_group: 'Mammals',
      status_code: 'G5S3',
    },
    myotis_thysanodes: {
      common_name: 'fringed myotis',
      scientific_name: 'Myotis thysanodes',
      taxon_group: 'Mammals',
      status_code: 'G4S3',
    },
    myotis_volans: {
      common_name: 'long-legged myotis',
      scientific_name: 'Myotis volans',
      taxon_group: 'Mammals',
      status_code: 'G5S3',
    },
    nyctinomops_femorosaccus: {
      common_name: 'pocketed free-tailed bat',
      scientific_name: 'Nyctinomops femorosaccus',
      taxon_group: 'Mammals',
      status_code: 'G4S3',
    },
    nyctinomops_macrotis: {
      common_name: 'big free-tailed bat',
      scientific_name: 'Nyctinomops macrotis',
      taxon_group: 'Mammals',
      status_code: 'G5S3',
    },
    oenothera_longissima: {
      common_name: 'long-stem evening-primrose',
      scientific_name: 'Oenothera longissima',
      taxon_group: 'Dicots',
      status_code: 'G4T3S3',
    },
    onychomys_torridus_ramona: {
      common_name: 'southern grasshopper mouse',
      scientific_name: 'Onychomys torridus ramona',
      taxon_group: 'Mammals',
      status_code: 'G5T3S3',
    },
    ovis_canadensis_nelsoni: {
      common_name: 'desert bighorn sheep',
      scientific_name: 'Ovis canadensis nelsoni',
      taxon_group: 'Mammals',
      status_code: 'G4T4S3',
    },
    penstemon_stephensii: {
      common_name: "Stephens' beardtongue",
      scientific_name: 'Penstemon stephensii',
      taxon_group: 'Dicots',
      status_code: 'G5T4?S3',
    },
    petalonyx_thurberi_ssp_gilmanii: {
      common_name: 'Death Valley sandpaper-plant',
      scientific_name: 'Petalonyx thurberi ssp gilmanii',
      taxon_group: 'Dicots',
      status_code: 'G5S3',
    },
    plagiobothrys_salsus: {
      common_name: 'desert popcornflower',
      scientific_name: 'Plagiobothrys salsus',
      taxon_group: 'Dicots',
      status_code: 'G5S3',
    },
    polioptila_melanura: {
      common_name: 'black-tailed gnatcatcher',
      scientific_name: 'Polioptila melanura',
      taxon_group: 'Birds',
      status_code: 'G5S3S4',
    },
    psorothamnus_fremontii_var_attenuatus: {
      common_name: 'narrow-leaved psorothamnus',
      scientific_name: 'Psorothamnus fremontii var attenuatus',
      taxon_group: 'Dicots',
      status_code: 'G4S3',
    },
    scleropogon_brevifolius: {
      common_name: 'burro grass',
      scientific_name: 'Scleropogon brevifolius',
      taxon_group: 'Monocots',
      status_code: 'G5S3',
    },
    setophaga_petechia: {
      common_name: 'yellow warbler',
      scientific_name: 'Setophaga petechia',
      taxon_group: 'Birds',
      status_code: 'G5S3S4',
    },
    taxidea_taxus: {
      common_name: 'American badger',
      scientific_name: 'Taxidea taxus',
      taxon_group: 'Mammals',
      status_code: 'G5S3',
    },
    thamnophis_hammondii: {
      common_name: 'two-striped gartersnake',
      scientific_name: 'Thamnophis hammondii',
      taxon_group: 'Reptiles',
      status_code: 'G4S3S4',
    },
    toxostoma_bendirei: {
      common_name: "Bendire's thrasher",
      scientific_name: 'Toxostoma bendirei',
      taxon_group: 'Birds',
      status_code: 'G4G5S3',
    },
    toxostoma_crissale: {
      common_name: 'Crissal thrasher',
      scientific_name: 'Toxostoma crissale',
      taxon_group: 'Birds',
      status_code: 'G5S3',
    },
    toxostoma_lecontei: {
      common_name: "Le Conte's thrasher",
      scientific_name: 'Toxostoma lecontei',
      taxon_group: 'Birds',
      status_code: 'G4S3',
    },
    tripterocalyx_micranthus: {
      common_name: 'small-flowered sand-verbena',
      scientific_name: 'Tripterocalyx micranthus',
      taxon_group: 'Dicots',
      status_code: 'G4S3',
    },
    accipiter_cooperii: {
      common_name: "Cooper's hawk",
      scientific_name: 'Accipiter cooperii',
      taxon_group: 'Birds',
      status_code: 'G5S4',
    },
    eremophila_alpestris_actia: {
      common_name: 'California horned lark',
      scientific_name: 'Eremophila alpestris actia',
      taxon_group: 'Birds',
      status_code: 'G5T4QS4',
    },
    erigeron_utahensis: {
      common_name: 'Utah daisy',
      scientific_name: 'Erigeron utahensis',
      taxon_group: 'Dicots',
      status_code: 'G2G3SH',
    },
    falco_mexicanus: {
      common_name: 'prairie falcon',
      scientific_name: 'Falco mexicanus',
      taxon_group: 'Birds',
      status_code: 'G5S4',
    },
    hydromantes_platycephalus: {
      common_name: 'Mount Lyell salamander',
      scientific_name: 'Hydromantes platycephalus',
      taxon_group: 'Amphibians',
      status_code: 'G4S4',
    },
    lanius_ludovicianus: {
      common_name: 'loggerhead shrike',
      scientific_name: 'Lanius ludovicianus',
      taxon_group: 'Birds',
      status_code: 'G4S4',
    },
    pandion_haliaetus: {
      common_name: 'osprey',
      scientific_name: 'Pandion haliaetus',
      taxon_group: 'Birds',
      status_code: 'G5S4',
    },
    woodsia_plummerae: {
      common_name: "Plummer's woodsia",
      scientific_name: 'Woodsia plummerae',
      taxon_group: 'Ferns',
      status_code: 'G5SH',
    },
    allium_nevadense: {
      common_name: 'Nevada onion',
      scientific_name: 'Allium nevadense',
      taxon_group: 'Monocots',
      status_code: 'G3S3',
    },
    anniella_pulchra: {
      common_name: 'northern California legless lizard',
      scientific_name: 'Anniella pulchra',
      taxon_group: 'Reptiles',
      status_code: 'G3S3',
    },
    asclepias_nyctaginifolia: {
      common_name: 'Mojave milkweed',
      scientific_name: 'Asclepias nyctaginifolia',
      taxon_group: 'Dicots',
      status_code: 'G3S3',
    },
    astragalus_jaegerianus: {
      common_name: 'Lane Mountain milk-vetch',
      scientific_name: 'Astragalus jaegerianus',
      taxon_group: 'Dicots',
      status_code: 'G3S3',
    },
    astragalus_preussii_var_preussii: {
      common_name: "Preuss' milk-vetch",
      scientific_name: 'Astragalus preussii var preussii',
      taxon_group: 'Dicots',
      status_code: 'G3QS3',
    },
    atriplex_argentea_var_longitrichoma: {
      common_name: 'Pahrump orache',
      scientific_name: 'Atriplex argentea var longitrichoma',
      taxon_group: 'Dicots',
      status_code: 'G3S3',
    },
    boechera_shockleyi: {
      common_name: "Shockley's rockcress",
      scientific_name: 'Boechera shockleyi',
      taxon_group: 'Dicots',
      status_code: 'G3S3',
    },
    calochortus_palmeri_var_palmeri: {
      common_name: "Palmer's mariposa-lily",
      scientific_name: 'Calochortus palmeri var palmeri',
      taxon_group: 'Monocots',
      status_code: 'G3S3',
    },
    catostomus_fumeiventris: {
      common_name: 'Owens sucker',
      scientific_name: 'Catostomus fumeiventris',
      taxon_group: 'Fish',
      status_code: 'G3G4S3',
    },
    coryphantha_alversonii: {
      common_name: "Alverson's foxtail cactus",
      scientific_name: 'Coryphantha alversonii',
      taxon_group: 'Dicots',
      status_code: 'G3S3',
    },
    coryphantha_vivipara_var_rosea: {
      common_name: 'viviparous foxtail cactus',
      scientific_name: 'Coryphantha vivipara var rosea',
      taxon_group: 'Dicots',
      status_code: 'G3S3',
    },
    crepis_runcinata: {
      common_name: 'fiddleleaf hawksbeard',
      scientific_name: 'Crepis runcinata',
      taxon_group: 'Dicots',
      status_code: 'G3G4S3',
    },
    dudleya_abramsii_ssp_affinis: {
      common_name: 'San Bernardino Mountains dudleya',
      scientific_name: 'Dudleya abramsii ssp affinis',
      taxon_group: 'Dicots',
      status_code: 'G3?S3?',
    },
    echinocereus_engelmannii_var_howei: {
      common_name: "Howe's hedgehog cactus",
      scientific_name: 'Echinocereus engelmannii var howei',
      taxon_group: 'Dicots',
      status_code: 'G3S3',
    },
    elgaria_panamintina: {
      common_name: 'Panamint alligator lizard',
      scientific_name: 'Elgaria panamintina',
      taxon_group: 'Reptiles',
      status_code: 'G3S3',
    },
    emys_marmorata: {
      common_name: 'western pond turtle',
      scientific_name: 'Emys marmorata',
      taxon_group: 'Reptiles',
      status_code: 'G3G4S3',
    },
    eriastrum_tracyi: {
      common_name: "Tracy's eriastrum",
      scientific_name: 'Eriastrum tracyi',
      taxon_group: 'Dicots',
      status_code: 'G3QS3',
    },
    erigeron_parishii: {
      common_name: "Parish's daisy",
      scientific_name: 'Erigeron parishii',
      taxon_group: 'Dicots',
      status_code: 'G3T3S3',
    },
    glossopetalon_pungens: {
      common_name: 'pungent glossopetalon',
      scientific_name: 'Glossopetalon pungens',
      taxon_group: 'Dicots',
      status_code: 'G3S3',
    },
    lithospermum_incisum: {
      common_name: 'plains stoneseed',
      scientific_name: 'Lithospermum incisum',
      taxon_group: 'Dicots',
      status_code: 'G3S3',
    },
    navarretia_peninsularis: {
      common_name: 'Baja navarretia',
      scientific_name: 'Navarretia peninsularis',
      taxon_group: 'Dicots',
      status_code: 'G3S3',
    },
    penstemon_bicolor_ssp_roseus: {
      common_name: 'rosy two-toned beardtongue',
      scientific_name: 'Penstemon bicolor ssp roseus',
      taxon_group: 'Dicots',
      status_code: 'G3S3',
    },
    phacelia_perityloides_var_jaegeri: {
      common_name: "Jaeger's phacelia",
      scientific_name: 'Phacelia perityloides var jaegeri',
      taxon_group: 'Dicots',
      status_code: 'G3T3S3',
    },
    phrynosoma_blainvillii: {
      common_name: 'coast horned lizard',
      scientific_name: 'Phrynosoma blainvillii',
      taxon_group: 'Reptiles',
      status_code: 'G3G4S3S4',
    },
    plagiobothrys_parishii: {
      common_name: "Parish's popcornflower",
      scientific_name: 'Plagiobothrys parishii',
      taxon_group: 'Dicots',
      status_code: 'G3S3',
    },
    sclerocactus_johnsonii: {
      common_name: "Johnson's bee-hive cactus",
      scientific_name: 'Sclerocactus johnsonii',
      taxon_group: 'Dicots',
      status_code: 'G3S3',
    },
    senna_covesii: {
      common_name: "Cove's cassia",
      scientific_name: 'Senna covesii',
      taxon_group: 'Dicots',
      status_code: 'G3S3',
    },
    sisyrinchium_funereum: {
      common_name: 'Death Valley blue-eyed grass',
      scientific_name: 'Sisyrinchium funereum',
      taxon_group: 'Monocots',
      status_code: 'G3S3',
    },
    stipa_arida: {
      common_name: 'Mormon needle grass',
      scientific_name: 'Stipa arida',
      taxon_group: 'Monocots',
      status_code: 'G3S3',
    },
    symphyotrichum_defoliatum: {
      common_name: 'San Bernardino aster',
      scientific_name: 'Symphyotrichum defoliatum',
      taxon_group: 'Dicots',
      status_code: 'G3?S3?',
    },
    uma_scoparia: {
      common_name: 'Mojave fringe-toed lizard',
      scientific_name: 'Uma scoparia',
      taxon_group: 'Reptiles',
      status_code: 'G3G4S3S4',
    },
    agave_utahensis_var_eborispina: {
      common_name: 'ivory-spined agave',
      scientific_name: 'Agave utahensis var eborispina',
      taxon_group: 'Monocots',
      status_code: 'G2S2',
    },
    ageratina_herbacea: {
      common_name: 'desert ageratina',
      scientific_name: 'Ageratina herbacea',
      taxon_group: 'Dicots',
      status_code: 'G2S2',
    },
    ammospermophilus_nelsoni: {
      common_name: "Nelson's antelope squirrel",
      scientific_name: 'Ammospermophilus nelsoni',
      taxon_group: 'Mammals',
      status_code: 'G2S2S3',
    },
    anaxyrus_californicus: {
      common_name: 'arroyo toad',
      scientific_name: 'Anaxyrus californicus',
      taxon_group: 'Amphibians',
      status_code: 'G2G3S2S3',
    },
    arctomecon_merriamii: {
      common_name: 'white bear poppy',
      scientific_name: 'Arctomecon merriamii',
      taxon_group: 'Dicots',
      status_code: 'G2S2',
    },
    astragalus_hornii_var_hornii: {
      common_name: "Horn's milk-vetch",
      scientific_name: 'Astragalus hornii var hornii',
      taxon_group: 'Dicots',
      status_code: 'G2S2',
    },
    astragalus_preussii_var_laxiflorus: {
      common_name: 'Lancaster milk-vetch',
      scientific_name: 'Astragalus preussii var laxiflorus',
      taxon_group: 'Dicots',
      status_code: 'G2S2',
    },
    astragalus_tricarinatus: {
      common_name: 'triple-ribbed milk-vetch',
      scientific_name: 'Astragalus tricarinatus',
      taxon_group: 'Dicots',
      status_code: 'G2S2',
    },
    atriplex_parishii: {
      common_name: "Parish's brittlescale",
      scientific_name: 'Atriplex parishii',
      taxon_group: 'Dicots',
      status_code: 'G2S2',
    },
    batrachoseps_stebbinsi: {
      common_name: 'Tehachapi slender salamander',
      scientific_name: 'Batrachoseps stebbinsi',
      taxon_group: 'Amphibians',
      status_code: 'G2S2S3',
    },
    berberis_fremontii: {
      common_name: 'Fremont barberry',
      scientific_name: 'Berberis fremontii',
      taxon_group: 'Dicots',
      status_code: 'G2S2',
    },
    boechera_dispar: {
      common_name: 'pinyon rockcress',
      scientific_name: 'Boechera dispar',
      taxon_group: 'Dicots',
      status_code: 'G2G3S2?',
    },
    colubrina_californica: {
      common_name: 'Las Animas colubrina',
      scientific_name: 'Colubrina californica',
      taxon_group: 'Dicots',
      status_code: 'G2S2',
    },
    cymopterus_gilmanii: {
      common_name: "Gilman's cymopterus",
      scientific_name: 'Cymopterus gilmanii',
      taxon_group: 'Dicots',
      status_code: 'G2S2',
    },
    deinandra_arida: {
      common_name: 'Red Rock tarplant',
      scientific_name: 'Deinandra arida',
      taxon_group: 'Dicots',
      status_code: 'G2S2',
    },
    deinandra_mohavensis: {
      common_name: 'Mojave tarplant',
      scientific_name: 'Deinandra mohavensis',
      taxon_group: 'Dicots',
      status_code: 'G2S2',
    },
    dodecahema_leptoceras: {
      common_name: 'slender-horned spineflower',
      scientific_name: 'Dodecahema leptoceras',
      taxon_group: 'Dicots',
      status_code: 'G2S2',
    },
    dudleya_saxosa_ssp_saxosa: {
      common_name: 'Panamint dudleya',
      scientific_name: 'Dudleya saxosa ssp saxosa',
      taxon_group: 'Dicots',
      status_code: 'G2S2',
    },
    erigeron_oxyphyllus: {
      common_name: 'wand-like fleabane daisy',
      scientific_name: 'Erigeron oxyphyllus',
      taxon_group: 'Dicots',
      status_code: 'G2S2',
    },
    eriodictyon_angustifolium: {
      common_name: 'narrow-leaved yerba santa',
      scientific_name: 'Eriodictyon angustifolium',
      taxon_group: 'Dicots',
      status_code: 'G2T2S2',
    },
    erioneuron_pilosum: {
      common_name: 'hairy erioneuron',
      scientific_name: 'Erioneuron pilosum',
      taxon_group: 'Monocots',
      status_code: 'G2S2',
    },
    gilmania_luteola: {
      common_name: 'golden-carpet gilmania',
      scientific_name: 'Gilmania luteola',
      taxon_group: 'Dicots',
      status_code: 'G2S2',
    },
    linanthus_concinnus: {
      common_name: 'San Gabriel linanthus',
      scientific_name: 'Linanthus concinnus',
      taxon_group: 'Dicots',
      status_code: 'G2S2',
    },
    mentzelia_polita: {
      common_name: 'polished blazing star',
      scientific_name: 'Mentzelia polita',
      taxon_group: 'Dicots',
      status_code: 'G2S2',
    },
    mentzelia_puberula: {
      common_name: "Darlington's blazing star",
      scientific_name: 'Mentzelia puberula',
      taxon_group: 'Dicots',
      status_code: 'G2S2',
    },
    mentzelia_tridentata: {
      common_name: 'creamy blazing star',
      scientific_name: 'Mentzelia tridentata',
      taxon_group: 'Dicots',
      status_code: 'G2S2',
    },
    miloderes_nelsoni: {
      common_name: "Nelson's miloderes weevil",
      scientific_name: 'Miloderes nelsoni',
      taxon_group: 'Insects',
      status_code: 'G2S2',
    },
    monardella_boydii: {
      common_name: "Boyd's monardella",
      scientific_name: 'Monardella boydii',
      taxon_group: 'Dicots',
      status_code: 'G2S2',
    },
    muhlenbergia_pauciflora: {
      common_name: 'few-flowered muhly',
      scientific_name: 'Muhlenbergia pauciflora',
      taxon_group: 'Monocots',
      status_code: 'G2S2',
    },
    navarretia_fossalis: {
      common_name: 'spreading navarretia',
      scientific_name: 'Navarretia fossalis',
      taxon_group: 'Dicots',
      status_code: 'G2S2',
    },
    oreonana_vestita: {
      common_name: 'woolly mountain-parsley',
      scientific_name: 'Oreonana vestita',
      taxon_group: 'Dicots',
      status_code: 'G2S2',
    },
    penstemon_pseudospectabilis_ssp_pseudospectabilis: {
      common_name: 'desert beardtongue',
      scientific_name: 'Penstemon pseudospectabilis ssp pseudospectabilis',
      taxon_group: 'Dicots',
      status_code: 'G2?S2?',
    },
    penstemon_utahensis: {
      common_name: 'Utah beardtongue',
      scientific_name: 'Penstemon utahensis',
      taxon_group: 'Dicots',
      status_code: 'G2S2',
    },
    perognathus_inornatus: {
      common_name: 'San Joaquin Pocket Mouse',
      scientific_name: 'Perognathus inornatus',
      taxon_group: 'Mammals',
      status_code: 'G2G3S2S3',
    },
    peteria_thompsoniae: {
      common_name: 'spine-noded milk vetch',
      scientific_name: 'Peteria thompsoniae',
      taxon_group: 'Dicots',
      status_code: 'G2S2',
    },
    petrophytum_caespitosum_ssp_acuminatum: {
      common_name: 'marble rockmat',
      scientific_name: 'Petrophytum caespitosum ssp acuminatum',
      taxon_group: 'Dicots',
      status_code: 'G2G3S2S3',
    },
    phacelia_parishii: {
      common_name: "Parish's phacelia",
      scientific_name: 'Phacelia parishii',
      taxon_group: 'Dicots',
      status_code: 'G2S2',
    },
    physaria_chambersii: {
      common_name: "Chambers' physaria",
      scientific_name: 'Physaria chambersii',
      taxon_group: 'Dicots',
      status_code: 'G2S2',
    },
    pyrgulopsis_wongi: {
      common_name: "Wong's springsnail",
      scientific_name: 'Pyrgulopsis wongi',
      taxon_group: 'Mollusks',
      status_code: 'G2S2',
    },
    rana_draytonii: {
      common_name: 'California red-legged frog',
      scientific_name: 'Rana draytonii',
      taxon_group: 'Amphibians',
      status_code: 'G2G3S2S3',
    },
    ranunculus_hydrocharoides: {
      common_name: "frog's-bit buttercup",
      scientific_name: 'Ranunculus hydrocharoides',
      taxon_group: 'Dicots',
      status_code: 'G2?S2?',
    },
    robinia_neomexicana: {
      common_name: 'New Mexico locust',
      scientific_name: 'Robinia neomexicana',
      taxon_group: 'Dicots',
      status_code: 'G2S2',
    },
    salvia_greatae: {
      common_name: 'Orocopia sage',
      scientific_name: 'Salvia greatae',
      taxon_group: 'Dicots',
      status_code: 'G2S2S3',
    },
    schoenus_nigricans: {
      common_name: 'black bog-rush',
      scientific_name: 'Schoenus nigricans',
      taxon_group: 'Monocots',
      status_code: 'G2S2',
    },
    scutellaria_bolanderi_ssp_austromontana: {
      common_name: 'southern mountains skullcap',
      scientific_name: 'Scutellaria bolanderi ssp austromontana',
      taxon_group: 'Dicots',
      status_code: 'G2S2',
    },
    sidalcea_covillei: {
      common_name: 'Owens Valley checkerbloom',
      scientific_name: 'Sidalcea covillei',
      taxon_group: 'Dicots',
      status_code: 'G2S2',
    },
    sphaeralcea_rusbyi_var_eremicola: {
      common_name: "Rusby's desert-mallow",
      scientific_name: 'Sphaeralcea rusbyi var eremicola',
      taxon_group: 'Dicots',
      status_code: 'G2S2',
    },
    thysanocarpus_rigidus: {
      common_name: 'rigid fringepod',
      scientific_name: 'Thysanocarpus rigidus',
      taxon_group: 'Dicots',
      status_code: 'G2S2',
    },
    xerospermophilus_mohavensis: {
      common_name: 'Mohave ground squirrel',
      scientific_name: 'Xerospermophilus mohavensis',
      taxon_group: 'Mammals',
      status_code: 'G2G3S2S3',
    },
    agelaius_tricolor: {
      common_name: 'tricolored blackbird',
      scientific_name: 'Agelaius tricolor',
      taxon_group: 'Birds',
      status_code: 'G2G3S1S2',
    },
    bombus_occidentalis: {
      common_name: 'western bumble bee',
      scientific_name: 'Bombus occidentalis',
      taxon_group: 'Insects',
      status_code: 'G2G3S1',
    },
    cryptantha_clokeyi: {
      common_name: "Clokey's cryptantha",
      scientific_name: 'Cryptantha clokeyi',
      taxon_group: 'Dicots',
      status_code: 'G2S1',
    },
    cyprinodon_nevadensis_amargosae: {
      common_name: 'Amargosa pupfish',
      scientific_name: 'Cyprinodon nevadensis amargosae',
      taxon_group: 'Fish',
      status_code: 'G2T1T2S1S2',
    },
    cyprinodon_nevadensis_nevadensis: {
      common_name: 'Saratoga Springs pupfish',
      scientific_name: 'Cyprinodon nevadensis nevadensis',
      taxon_group: 'Fish',
      status_code: 'G2T1S1',
    },
    eriastrum_rosamondense: {
      common_name: 'Rosamond eriastrum',
      scientific_name: 'Eriastrum rosamondense',
      taxon_group: 'Dicots',
      status_code: 'G2G3S1',
    },
    eriogonum_hoffmannii_var_robustius: {
      common_name: "robust Hoffmann's buckwheat",
      scientific_name: 'Eriogonum hoffmannii var robustius',
      taxon_group: 'Dicots',
      status_code: 'G2G3S1',
    },
    frasera_albomarginata_var_albomarginata: {
      common_name: 'desert green-gentian',
      scientific_name: 'Frasera albomarginata var albomarginata',
      taxon_group: 'Dicots',
      status_code: 'G2T1S1',
    },
    lupinus_magnificus_var_hesperius: {
      common_name: 'Mcgee Meadows lupine',
      scientific_name: 'Lupinus magnificus var hesperius',
      taxon_group: 'Dicots',
      status_code: 'G2G3S1',
    },
    oryctes_nevadensis: {
      common_name: 'Nevada oryctes',
      scientific_name: 'Oryctes nevadensis',
      taxon_group: 'Dicots',
      status_code: 'G2G3S1',
    },
    phacelia_coerulea: {
      common_name: 'sky-blue phacelia',
      scientific_name: 'Phacelia coerulea',
      taxon_group: 'Dicots',
      status_code: 'G2G3S1',
    },
    phacelia_pulchella_var_gooddingii: {
      common_name: "Goodding's phacelia",
      scientific_name: 'Phacelia pulchella var gooddingii',
      taxon_group: 'Dicots',
      status_code: 'G2T1S1',
    },
    prunus_eremophila: {
      common_name: 'Mojave Desert plum',
      scientific_name: 'Prunus eremophila',
      taxon_group: 'Dicots',
      status_code: 'G2S1',
    },
    teucrium_glandulosum: {
      common_name: 'desert germander',
      scientific_name: 'Teucrium glandulosum',
      taxon_group: 'Dicots',
      status_code: 'G2S1',
    },
    wislizenia_refracta_ssp_refracta: {
      common_name: 'jackass-clover',
      scientific_name: 'Wislizenia refracta ssp refracta',
      taxon_group: 'Dicots',
      status_code: 'G2S1',
    },
    agabus_rumppi: {
      common_name: 'Death Valley agabus diving beetle',
      scientific_name: 'Agabus rumppi',
      taxon_group: 'Insects',
      status_code: 'G1G3S1',
    },
    ambrysus_funebris: {
      common_name: 'Nevares Spring naucorid bug',
      scientific_name: 'Ambrysus funebris',
      taxon_group: 'Insects',
      status_code: 'G1S1',
    },
    ammopelmatus_kelsoensis: {
      common_name: 'Kelso jerusalem cricket',
      scientific_name: 'Ammopelmatus kelsoensis',
      taxon_group: 'Insects',
      status_code: 'G1G2S1S2',
    },
    assiminea_infima: {
      common_name: 'Badwater snail',
      scientific_name: 'Assiminea infima',
      taxon_group: 'Mollusks',
      status_code: 'G1S1',
    },
    astragalus_cimae_var_cimae: {
      common_name: 'Cima milk-vetch',
      scientific_name: 'Astragalus cimae var cimae',
      taxon_group: 'Dicots',
      status_code: 'G1G2S1',
    },
    astragalus_insularis_var_harwoodii: {
      common_name: "Harwood's milk-vetch",
      scientific_name: 'Astragalus insularis var harwoodii',
      taxon_group: 'Dicots',
      status_code: 'G1S1',
    },
    astragalus_lentiginosus_var_sesquimetralis: {
      common_name: 'Sodaville milk-vetch',
      scientific_name: 'Astragalus lentiginosus var sesquimetralis',
      taxon_group: 'Dicots',
      status_code: 'G1S1',
    },
    belostoma_saratogae: {
      common_name: 'Saratoga Springs belostoman bug',
      scientific_name: 'Belostoma saratogae',
      taxon_group: 'Insects',
      status_code: 'G1S1',
    },
    blepharidachne_kingii: {
      common_name: "King's eyelash grass",
      scientific_name: 'Blepharidachne kingii',
      taxon_group: 'Monocots',
      status_code: 'G1S1',
    },
    chaetadelpha_wheeleri: {
      common_name: "Wheeler's dune-broom",
      scientific_name: 'Chaetadelpha wheeleri',
      taxon_group: 'Dicots',
      status_code: 'G1G2S1S2',
    },
    cryptochia_denningi: {
      common_name: "Denning's cryptic caddisfly",
      scientific_name: 'Cryptochia denningi',
      taxon_group: 'Insects',
      status_code: 'G1G2S1S2',
    },
    cyprinodon_radiosus: {
      common_name: 'Owens pupfish',
      scientific_name: 'Cyprinodon radiosus',
      taxon_group: 'Fish',
      status_code: 'G1S1',
    },
    delphinium_scaposum: {
      common_name: 'bare-stem larkspur',
      scientific_name: 'Delphinium scaposum',
      taxon_group: 'Dicots',
      status_code: 'G1S1',
    },
    ditaxis_claryana: {
      common_name: 'glandular ditaxis',
      scientific_name: 'Ditaxis claryana',
      taxon_group: 'Dicots',
      status_code: 'G1S1',
    },
    enceliopsis_covillei: {
      common_name: 'Panamint daisy',
      scientific_name: 'Enceliopsis covillei',
      taxon_group: 'Dicots',
      status_code: 'G1S1',
    },
    eriogonum_ovalifolium_var_vineum: {
      common_name: 'Cushenbury buckwheat',
      scientific_name: 'Eriogonum ovalifolium var vineum',
      taxon_group: 'Dicots',
      status_code: 'G1S1',
    },
    fimbristylis_thermalis: {
      common_name: 'hot springs fimbristylis',
      scientific_name: 'Fimbristylis thermalis',
      taxon_group: 'Monocots',
      status_code: 'G1QS1',
    },
    galium_hilendiae_ssp_carneum: {
      common_name: 'Panamint Mountains bedstraw',
      scientific_name: 'Galium hilendiae ssp carneum',
      taxon_group: 'Dicots',
      status_code: 'G1S1',
    },
    gymnogyps_californianus: {
      common_name: 'California condor',
      scientific_name: 'Gymnogyps californianus',
      taxon_group: 'Birds',
      status_code: 'G1S1',
    },
    helminthoglypta_greggi: {
      common_name: 'Mohave shoulderband',
      scientific_name: 'Helminthoglypta greggi',
      taxon_group: 'Mollusks',
      status_code: 'G1S1',
    },
    hubbardia_shoshonensis: {
      common_name: 'Shoshone Cave whip-scorpion',
      scientific_name: 'Hubbardia shoshonensis',
      taxon_group: 'Arachnids',
      status_code: 'G1S1',
    },
    hyalella_muerta: {
      common_name: 'Texas Spring amphipod',
      scientific_name: 'Hyalella muerta',
      taxon_group: 'Crustaceans',
      status_code: 'G1S1',
    },
    hyalella_sandra: {
      common_name: 'Death Valley amphipod',
      scientific_name: 'Hyalella sandra',
      taxon_group: 'Crustaceans',
      status_code: 'G1S1',
    },
    ipnobius_robustus: {
      common_name: 'robust tryonia',
      scientific_name: 'Ipnobius robustus',
      taxon_group: 'Mollusks',
      status_code: 'G1G2S1',
    },
    lupinus_holmgrenianus: {
      common_name: "Holmgren's lupine",
      scientific_name: 'Lupinus holmgrenianus',
      taxon_group: 'Dicots',
      status_code: 'G1?S1?',
    },
    mentzelia_pterosperma: {
      common_name: 'wing-seed blazing star',
      scientific_name: 'Mentzelia pterosperma',
      taxon_group: 'Dicots',
      status_code: 'G1G2S1',
    },
    microcylloepus_formicoideus: {
      common_name: 'Furnace Creek riffle beetle',
      scientific_name: 'Microcylloepus formicoideus',
      taxon_group: 'Insects',
      status_code: 'G1S1',
    },
    monardella_robisonii: {
      common_name: "Robison's monardella",
      scientific_name: 'Monardella robisonii',
      taxon_group: 'Dicots',
      status_code: 'G1S1',
    },
    muhlenbergia_fragilis: {
      common_name: 'delicate muhly',
      scientific_name: 'Muhlenbergia fragilis',
      taxon_group: 'Monocots',
      status_code: 'G1G2S1S2',
    },
    paranomada_californica: {
      common_name: 'California cuckoo bee',
      scientific_name: 'Paranomada californica',
      taxon_group: 'Insects',
      status_code: 'G1S1',
    },
    pelocoris_shoshone: {
      common_name: 'Amargosa naucorid bug',
      scientific_name: 'Pelocoris shoshone',
      taxon_group: 'Insects',
      status_code: 'G1G3S1S2',
    },
    perognathus_alticolus_inexpectatus: {
      common_name: 'Tehachapi pocket mouse',
      scientific_name: 'Perognathus alticolus inexpectatus',
      taxon_group: 'Mammals',
      status_code: 'G1G2T1T2S1S2',
    },
    phacelia_inyoensis: {
      common_name: 'Inyo phacelia',
      scientific_name: 'Phacelia inyoensis',
      taxon_group: 'Dicots',
      status_code: 'G1S1',
    },
    phacelia_mustelina: {
      common_name: 'Death Valley round-leaved phacelia',
      scientific_name: 'Phacelia mustelina',
      taxon_group: 'Dicots',
      status_code: 'G1G2S1S2',
    },
    plebulina_emigdionis: {
      common_name: 'San Emigdio blue butterfly',
      scientific_name: 'Plebulina emigdionis',
      taxon_group: 'Insects',
      status_code: 'G1G2S1S2',
    },
    polyphylla_erratica: {
      common_name: 'Death Valley June beetle',
      scientific_name: 'Polyphylla erratica',
      taxon_group: 'Insects',
      status_code: 'G1G2S1S2',
    },
    rhopalolemma_robertsi: {
      common_name: "Roberts' rhopalolemma bee",
      scientific_name: 'Rhopalolemma robertsi',
      taxon_group: 'Insects',
      status_code: 'G1S1',
    },
    saltugilia_latimeri: {
      common_name: "Latimer's woodland-gilia",
      scientific_name: 'Saltugilia latimeri',
      taxon_group: 'Dicots',
      status_code: 'G1S1',
    },
    sphenopholis_obtusata: {
      common_name: 'prairie wedge grass',
      scientific_name: 'Sphenopholis obtusata',
      taxon_group: 'Monocots',
      status_code: 'G1?QS1?',
    },
    texella_kokoweef: {
      common_name: 'Kokoweef Crystal Cave harvestman',
      scientific_name: 'Texella kokoweef',
      taxon_group: 'Arachnids',
      status_code: 'G1S1',
    },
    texella_shoshone: {
      common_name: 'Shoshone Cave harvestman',
      scientific_name: 'Texella shoshone',
      taxon_group: 'Arachnids',
      status_code: 'G1S1',
    },
    thelypodium_integrifolium_ssp_complanatum: {
      common_name: 'foxtail thelypodium',
      scientific_name: 'Thelypodium integrifolium ssp complanatum',
      taxon_group: 'Dicots',
      status_code: 'G1S1',
    },
  },
  modoc: {
    accipiter_gentilis: {
      common_name: 'northern goshawk',
      scientific_name: 'Accipiter gentilis',
      taxon_group: 'Birds',
      status_code: 'G5S3',
    },
    agelaius_tricolor: {
      common_name: 'tricolored blackbird',
      scientific_name: 'Agelaius tricolor',
      taxon_group: 'Birds',
      status_code: 'G2G3S1S2',
    },
    alisma_gramineum: {
      common_name: 'grass alisma',
      scientific_name: 'Alisma gramineum',
      taxon_group: 'Monocots',
      status_code: 'G5S3',
    },
    allium_punctum: {
      common_name: 'dotted onion',
      scientific_name: 'Allium punctum',
      taxon_group: 'Monocots',
      status_code: 'G3S1',
    },
    ambystoma_macrodactylum_sigillatum: {
      common_name: 'southern long-toed salamander',
      scientific_name: 'Ambystoma macrodactylum sigillatum',
      taxon_group: 'Amphibians',
      status_code: 'G5T4S3',
    },
    antrozous_pallidus: {
      common_name: 'pallid bat',
      scientific_name: 'Antrozous pallidus',
      taxon_group: 'Mammals',
      status_code: 'G5S3',
    },
    aquila_chrysaetos: {
      common_name: 'golden eagle',
      scientific_name: 'Aquila chrysaetos',
      taxon_group: 'Birds',
      status_code: 'G5S3',
    },
    arnica_fulgens: {
      common_name: 'hillside arnica',
      scientific_name: 'Arnica fulgens',
      taxon_group: 'Dicots',
      status_code: 'G5S3',
    },
    asio_flammeus: {
      common_name: 'short-eared owl',
      scientific_name: 'Asio flammeus',
      taxon_group: 'Birds',
      status_code: 'G5S3',
    },
    asio_otus: {
      common_name: 'long-eared owl',
      scientific_name: 'Asio otus',
      taxon_group: 'Birds',
      status_code: 'G5S3?',
    },
    astragalus_agrestis: {
      common_name: 'field milk-vetch',
      scientific_name: 'Astragalus agrestis',
      taxon_group: 'Dicots',
      status_code: 'G5S2',
    },
    astragalus_anxius: {
      common_name: 'Ash Valley milk-vetch',
      scientific_name: 'Astragalus anxius',
      taxon_group: 'Dicots',
      status_code: 'G1S1',
    },
    astragalus_lemmonii: {
      common_name: "Lemmon's milk-vetch",
      scientific_name: 'Astragalus lemmonii',
      taxon_group: 'Dicots',
      status_code: 'G2S2',
    },
    astragalus_pulsiferae_var_pulsiferae: {
      common_name: "Pulsifer's milk-vetch",
      scientific_name: 'Astragalus pulsiferae var. pulsiferae',
      taxon_group: 'Dicots',
      status_code: 'G4T2S2',
    },
    athene_cunicularia: {
      common_name: 'burrowing owl',
      scientific_name: 'Athene cunicularia',
      taxon_group: 'Birds',
      status_code: 'G4S3',
    },
    atriplex_gardneri_var_falcata: {
      common_name: 'falcate saltbush',
      scientific_name: 'Atriplex gardneri var. falcata',
      taxon_group: 'Dicots',
      status_code: 'G4T4QS2S3',
    },
    balsamorhiza_serrata: {
      common_name: 'serrated balsamroot',
      scientific_name: 'Balsamorhiza serrata',
      taxon_group: 'Dicots',
      status_code: 'G5S2',
    },
    betula_glandulosa: {
      common_name: 'dwarf resin birch',
      scientific_name: 'Betula glandulosa',
      taxon_group: 'Dicots',
      status_code: 'G5S2',
    },
    bombus_crotchii: {
      common_name: 'Crotch bumble bee',
      scientific_name: 'Bombus crotchii',
      taxon_group: 'Insects',
      status_code: 'G3G4S1S2',
    },
    bombus_morrisoni: {
      common_name: 'Morrison bumble bee',
      scientific_name: 'Bombus morrisoni',
      taxon_group: 'Insects',
      status_code: 'G4G5S1S2',
    },
    bombus_occidentalis: {
      common_name: 'western bumble bee',
      scientific_name: 'Bombus occidentalis',
      taxon_group: 'Insects',
      status_code: 'G2G3S1',
    },
    botrychium_ascendens: {
      common_name: 'upswept moonwort',
      scientific_name: 'Botrychium ascendens',
      taxon_group: 'Ferns',
      status_code: 'G3G4S2',
    },
    botrychium_crenulatum: {
      common_name: 'scalloped moonwort',
      scientific_name: 'Botrychium crenulatum',
      taxon_group: 'Ferns',
      status_code: 'G4S3',
    },
    botrychium_lunaria: {
      common_name: 'common moonwort',
      scientific_name: 'Botrychium lunaria',
      taxon_group: 'Ferns',
      status_code: 'G5S2',
    },
    botrychium_minganense: {
      common_name: 'Mingan moonwort',
      scientific_name: 'Botrychium minganense',
      taxon_group: 'Ferns',
      status_code: 'G4G5S3',
    },
    botrychium_montanum: {
      common_name: 'western goblin',
      scientific_name: 'Botrychium montanum',
      taxon_group: 'Ferns',
      status_code: 'G3S2',
    },
    botrychium_pinnatum: {
      common_name: 'northwestern moonwort',
      scientific_name: 'Botrychium pinnatum',
      taxon_group: 'Ferns',
      status_code: 'G4?S2',
    },
    brachylagus_idahoensis: {
      common_name: 'pygmy rabbit',
      scientific_name: 'Brachylagus idahoensis',
      taxon_group: 'Mammals',
      status_code: 'G4S3',
    },
    buteo_regalis: {
      common_name: 'ferruginous hawk',
      scientific_name: 'Buteo regalis',
      taxon_group: 'Birds',
      status_code: 'G4S3S4',
    },
    buxbaumia_viridis: {
      common_name: 'buxbaumia moss',
      scientific_name: 'Buxbaumia viridis',
      taxon_group: 'Bryophytes',
      status_code: 'G4G5S1',
    },
    calochortus_longebarbatus_var_longebarbatus: {
      common_name: 'long-haired star-tulip',
      scientific_name: 'Calochortus longebarbatus var. longebarbatus',
      taxon_group: 'Monocots',
      status_code: 'G4T3S3',
    },
    carex_atherodes: {
      common_name: 'wheat sedge',
      scientific_name: 'Carex atherodes',
      taxon_group: 'Monocots',
      status_code: 'G5S1',
    },
    carex_limosa: {
      common_name: 'mud sedge',
      scientific_name: 'Carex limosa',
      taxon_group: 'Monocots',
      status_code: 'G5S3',
    },
    carex_petasata: {
      common_name: "Liddon's sedge",
      scientific_name: 'Carex petasata',
      taxon_group: 'Monocots',
      status_code: 'G5S3',
    },
    carex_sheldonii: {
      common_name: "Sheldon's sedge",
      scientific_name: 'Carex sheldonii',
      taxon_group: 'Monocots',
      status_code: 'G4S2',
    },
    carex_vallicola: {
      common_name: 'western valley sedge',
      scientific_name: 'Carex vallicola',
      taxon_group: 'Monocots',
      status_code: 'G5S2',
    },
    catostomus_microps: {
      common_name: 'Modoc sucker',
      scientific_name: 'Catostomus microps',
      taxon_group: 'Fish',
      status_code: 'G2S2',
    },
    catostomus_occidentalis_lacusanserinus: {
      common_name: 'Goose Lake sucker',
      scientific_name: 'Catostomus occidentalis lacusanserinus',
      taxon_group: 'Fish',
      status_code: 'G5T2QS1',
    },
    charadrius_alexandrinus_nivosus: {
      common_name: 'western snowy plover',
      scientific_name: 'Charadrius alexandrinus nivosus',
      taxon_group: 'Birds',
      status_code: 'G3T3S2S3',
    },
    chasmistes_brevirostris: {
      common_name: 'shortnose sucker',
      scientific_name: 'Chasmistes brevirostris',
      taxon_group: 'Fish',
      status_code: 'G1S1',
    },
    chlidonias_niger: {
      common_name: 'black tern',
      scientific_name: 'Chlidonias niger',
      taxon_group: 'Birds',
      status_code: 'G4S2',
    },
    claytonia_megarhiza: {
      common_name: 'fell-fields claytonia',
      scientific_name: 'Claytonia megarhiza',
      taxon_group: 'Dicots',
      status_code: 'G5S2',
    },
    collomia_tenella: {
      common_name: 'slender collomia',
      scientific_name: 'Collomia tenella',
      taxon_group: 'Dicots',
      status_code: 'G4?S1',
    },
    cordylanthus_capitatus: {
      common_name: "Yakima bird's-beak",
      scientific_name: 'Cordylanthus capitatus',
      taxon_group: 'Dicots',
      status_code: 'G4S2',
    },
    corynorhinus_townsendii: {
      common_name: "Townsend's big-eared bat",
      scientific_name: 'Corynorhinus townsendii',
      taxon_group: 'Mammals',
      status_code: 'G3G4S2',
    },
    crepis_runcinata: {
      common_name: 'fiddleleaf hawksbeard',
      scientific_name: 'Crepis runcinata',
      taxon_group: 'Dicots',
      status_code: 'G5S3',
    },
    cryptantha_celosioides: {
      common_name: "cocks-comb cat's-eye",
      scientific_name: 'Cryptantha celosioides',
      taxon_group: 'Dicots',
      status_code: 'G5S1',
    },
    dalea_ornata: {
      common_name: 'ornate dalea',
      scientific_name: 'Dalea ornata',
      taxon_group: 'Dicots',
      status_code: 'G4G5S2',
    },
    delphinium_stachydeum: {
      common_name: 'spiked larkspur',
      scientific_name: 'Delphinium stachydeum',
      taxon_group: 'Dicots',
      status_code: 'G5?S3',
    },
    deltistes_luxatus: {
      common_name: 'Lost River sucker',
      scientific_name: 'Deltistes luxatus',
      taxon_group: 'Fish',
      status_code: 'G1S1',
    },
    dimeresia_howellii: {
      common_name: 'doublet',
      scientific_name: 'Dimeresia howellii',
      taxon_group: 'Dicots',
      status_code: 'G4?S3',
    },
    diplacus_cusickioides: {
      common_name: "Cusick's monkeyflower",
      scientific_name: 'Diplacus cusickioides',
      taxon_group: 'Dicots',
      status_code: 'G4G5S2',
    },
    downingia_laeta: {
      common_name: 'Great Basin downingia',
      scientific_name: 'Downingia laeta',
      taxon_group: 'Dicots',
      status_code: 'G5S3',
    },
    drosera_anglica: {
      common_name: 'English sundew',
      scientific_name: 'Drosera anglica',
      taxon_group: 'Dicots',
      status_code: 'G5S2',
    },
    empidonax_traillii: {
      common_name: 'willow flycatcher',
      scientific_name: 'Empidonax traillii',
      taxon_group: 'Birds',
      status_code: 'G5S1S2',
    },
    entosphenus_tridentatus_ssp_1: {
      common_name: 'Goose Lake lamprey',
      scientific_name: 'Entosphenus tridentatus ssp. 1',
      taxon_group: 'Fish',
      status_code: 'G4T1S1',
    },
    erethizon_dorsatum: {
      common_name: 'North American porcupine',
      scientific_name: 'Erethizon dorsatum',
      taxon_group: 'Mammals',
      status_code: 'G5S3',
    },
    eriastrum_tracyi: {
      common_name: "Tracy's eriastrum",
      scientific_name: 'Eriastrum tracyi',
      taxon_group: 'Dicots',
      status_code: 'G3QS3',
    },
    eriogonum_prociduum: {
      common_name: 'prostrate buckwheat',
      scientific_name: 'Eriogonum prociduum',
      taxon_group: 'Dicots',
      status_code: 'G3S3',
    },
    eriogonum_umbellatum_var_glaberrimum: {
      common_name: 'Warner Mountains buckwheat',
      scientific_name: 'Eriogonum umbellatum var. glaberrimum',
      taxon_group: 'Dicots',
      status_code: 'G5T2?S2',
    },
    erythranthe_inflatula: {
      common_name: 'ephemeral monkeyflower',
      scientific_name: 'Erythranthe inflatula',
      taxon_group: 'Dicots',
      status_code: 'G3S2',
    },
    falco_mexicanus: {
      common_name: 'prairie falcon',
      scientific_name: 'Falco mexicanus',
      taxon_group: 'Birds',
      status_code: 'G5S4',
    },
    galium_glabrescens_ssp_modocense: {
      common_name: 'Modoc bedstraw',
      scientific_name: 'Galium glabrescens ssp. modocense',
      taxon_group: 'Dicots',
      status_code: 'G4T3S3',
    },
    galium_serpenticum_ssp_warnerense: {
      common_name: 'Warner Mountains bedstraw',
      scientific_name: 'Galium serpenticum ssp. warnerense',
      taxon_group: 'Dicots',
      status_code: 'G4G5T2T3S2',
    },
    geum_aleppicum: {
      common_name: 'Aleppo avens',
      scientific_name: 'Geum aleppicum',
      taxon_group: 'Dicots',
      status_code: 'G5S2',
    },
    gila_coerulea: {
      common_name: 'blue chub',
      scientific_name: 'Gila coerulea',
      taxon_group: 'Fish',
      status_code: 'G3G4S2S3',
    },
    gonidea_angulata: {
      common_name: 'western ridged mussel',
      scientific_name: 'Gonidea angulata',
      taxon_group: 'Mollusks',
      status_code: 'G3S1S2',
    },
    gratiola_heterosepala: {
      common_name: 'Boggs Lake hedge-hyssop',
      scientific_name: 'Gratiola heterosepala',
      taxon_group: 'Dicots',
      status_code: 'G2S2',
    },
    grus_canadensis_tabida: {
      common_name: 'greater sandhill crane',
      scientific_name: 'Grus canadensis tabida',
      taxon_group: 'Birds',
      status_code: 'G5T4S2',
    },
    gulo_gulo: {
      common_name: 'California wolverine',
      scientific_name: 'Gulo gulo',
      taxon_group: 'Mammals',
      status_code: 'G4S1',
    },
    haliaeetus_leucocephalus: {
      common_name: 'bald eagle',
      scientific_name: 'Haliaeetus leucocephalus',
      taxon_group: 'Birds',
      status_code: 'G5S3',
    },
    heteranthera_dubia: {
      common_name: 'water star-grass',
      scientific_name: 'Heteranthera dubia',
      taxon_group: 'Monocots',
      status_code: 'G5S2',
    },
    hymenoxys_lemmonii: {
      common_name: 'alkali hymenoxys',
      scientific_name: 'Hymenoxys lemmonii',
      taxon_group: 'Dicots',
      status_code: 'G4?S2S3',
    },
    ivesia_baileyi_var_beneolens: {
      common_name: 'Owyhee ivesia',
      scientific_name: 'Ivesia baileyi var. beneolens',
      taxon_group: 'Dicots',
      status_code: 'G5T4S1',
    },
    ivesia_paniculata: {
      common_name: 'Ash Creek ivesia',
      scientific_name: 'Ivesia paniculata',
      taxon_group: 'Dicots',
      status_code: 'G2S2',
    },
    juga_acutifilosa: {
      common_name: 'topaz juga',
      scientific_name: 'Juga acutifilosa',
      taxon_group: 'Mollusks',
      status_code: 'G2S2',
    },
    juncus_luciensis: {
      common_name: 'Santa Lucia dwarf rush',
      scientific_name: 'Juncus luciensis',
      taxon_group: 'Monocots',
      status_code: 'G3S3',
    },
    larus_californicus: {
      common_name: 'California gull',
      scientific_name: 'Larus californicus',
      taxon_group: 'Birds',
      status_code: 'G5S4',
    },
    lathyrus_rigidus: {
      common_name: 'rigid pea',
      scientific_name: 'Lathyrus rigidus',
      taxon_group: 'Dicots',
      status_code: 'G5S1',
    },
    lavinia_symmetricus_mitrulus: {
      common_name: 'Pit roach',
      scientific_name: 'Lavinia symmetricus mitrulus',
      taxon_group: 'Fish',
      status_code: 'G4T2S2',
    },
    lepus_townsendii_townsendii: {
      common_name: 'western white-tailed jackrabbit',
      scientific_name: 'Lepus townsendii townsendii',
      taxon_group: 'Mammals',
      status_code: 'G5T5S3?',
    },
    lithobates_pipiens: {
      common_name: 'northern leopard frog',
      scientific_name: 'Lithobates pipiens',
      taxon_group: 'Amphibians',
      status_code: 'G5S2',
    },
    loeflingia_squarrosa_var_artemisiarum: {
      common_name: 'sagebrush loeflingia',
      scientific_name: 'Loeflingia squarrosa var. artemisiarum',
      taxon_group: 'Dicots',
      status_code: 'G5T3S2',
    },
    lomatium_foeniculaceum_ssp_macdougalii: {
      common_name: "Macdougal's lomatium",
      scientific_name: 'Lomatium foeniculaceum ssp. macdougalii',
      taxon_group: 'Dicots',
      status_code: 'G5T4T5S3',
    },
    lomatium_grayi: {
      common_name: "Gray's lomatium",
      scientific_name: 'Lomatium grayi',
      taxon_group: 'Dicots',
      status_code: 'G5S1S2',
    },
    lomatium_hendersonii: {
      common_name: "Henderson's lomatium",
      scientific_name: 'Lomatium hendersonii',
      taxon_group: 'Dicots',
      status_code: 'G5?S2',
    },
    lomatium_ravenii: {
      common_name: "Raven's lomatium",
      scientific_name: 'Lomatium ravenii',
      taxon_group: 'Dicots',
      status_code: 'G4S2',
    },
    lomatium_roseanum: {
      common_name: 'adobe lomatium',
      scientific_name: 'Lomatium roseanum',
      taxon_group: 'Dicots',
      status_code: 'G2G3S2',
    },
    lupinus_latifolius_var_barbatus: {
      common_name: 'bearded lupine',
      scientific_name: 'Lupinus latifolius var. barbatus',
      taxon_group: 'Dicots',
      status_code: 'G5T2QS2',
    },
    lupinus_pusillus_var_intermontanus: {
      common_name: 'intermontane lupine',
      scientific_name: 'Lupinus pusillus var. intermontanus',
      taxon_group: 'Dicots',
      status_code: 'G5T5?S2',
    },
    lupinus_uncialis: {
      common_name: 'lilliput lupine',
      scientific_name: 'Lupinus uncialis',
      taxon_group: 'Dicots',
      status_code: 'G4S2',
    },
    margaritifera_falcata: {
      common_name: 'western pearlshell',
      scientific_name: 'Margaritifera falcata',
      taxon_group: 'Mollusks',
      status_code: 'G4G5S1S2',
    },
    meesia_uliginosa: {
      common_name: 'broad-nerved hump moss',
      scientific_name: 'Meesia uliginosa',
      taxon_group: 'Bryophytes',
      status_code: 'G5S3',
    },
    mertensia_cusickii: {
      common_name: 'Toiyabe bluebells',
      scientific_name: 'Mertensia cusickii',
      taxon_group: 'Dicots',
      status_code: 'G4?S2',
    },
    mertensia_longiflora: {
      common_name: 'long bluebells',
      scientific_name: 'Mertensia longiflora',
      taxon_group: 'Dicots',
      status_code: 'G4?S2',
    },
    mertensia_oblongifolia_var_amoena: {
      common_name: 'beautiful sagebrush bluebells',
      scientific_name: 'Mertensia oblongifolia var. amoena',
      taxon_group: 'Dicots',
      status_code: 'G5T5S2',
    },
    mertensia_oblongifolia_var_oblongifolia: {
      common_name: 'sagebrush bluebells',
      scientific_name: 'Mertensia oblongifolia var. oblongifolia',
      taxon_group: 'Dicots',
      status_code: 'G5T5S3',
    },
    micromonolepis_pusilla: {
      common_name: 'dwarf monolepis',
      scientific_name: 'Micromonolepis pusilla',
      taxon_group: 'Dicots',
      status_code: 'G5S3?',
    },
    mylopharodon_conocephalus: {
      common_name: 'hardhead',
      scientific_name: 'Mylopharodon conocephalus',
      taxon_group: 'Fish',
      status_code: 'G3S3',
    },
    myotis_evotis: {
      common_name: 'long-eared myotis',
      scientific_name: 'Myotis evotis',
      taxon_group: 'Mammals',
      status_code: 'G5S3',
    },
    myotis_thysanodes: {
      common_name: 'fringed myotis',
      scientific_name: 'Myotis thysanodes',
      taxon_group: 'Mammals',
      status_code: 'G4S3',
    },
    myotis_volans: {
      common_name: 'long-legged myotis',
      scientific_name: 'Myotis volans',
      taxon_group: 'Mammals',
      status_code: 'G5S3',
    },
    nemophila_breviflora: {
      common_name: 'Great Basin nemophila',
      scientific_name: 'Nemophila breviflora',
      taxon_group: 'Dicots',
      status_code: 'G4G5S3',
    },
    ochotona_princeps_schisticeps: {
      common_name: 'gray-headed pika',
      scientific_name: 'Ochotona princeps schisticeps',
      taxon_group: 'Mammals',
      status_code: 'G5T2T4S2S4',
    },
    oncorhynchus_mykiss_aquilarum: {
      common_name: 'Eagle Lake rainbow trout',
      scientific_name: 'Oncorhynchus mykiss aquilarum',
      taxon_group: 'Fish',
      status_code: 'G5T1QS1',
    },
    oncorhynchus_mykiss_ssp_1: {
      common_name: 'Goose Lake redband trout',
      scientific_name: 'Oncorhynchus mykiss ssp. 1',
      taxon_group: 'Fish',
      status_code: 'G5T2QS2',
    },
    orcuttia_tenuis: {
      common_name: 'slender Orcutt grass',
      scientific_name: 'Orcuttia tenuis',
      taxon_group: 'Monocots',
      status_code: 'G2S2',
    },
    orthotrichum_holzingeri: {
      common_name: "Holzinger's orthotrichum moss",
      scientific_name: 'Orthotrichum holzingeri',
      taxon_group: 'Bryophytes',
      status_code: 'G3S2',
    },
    osmorhiza_depauperata: {
      common_name: 'blunt-fruited sweet-cicely',
      scientific_name: 'Osmorhiza depauperata',
      taxon_group: 'Dicots',
      status_code: 'G5S1',
    },
    ovis_canadensis_sierrae: {
      common_name: 'Sierra Nevada bighorn sheep',
      scientific_name: 'Ovis canadensis sierrae',
      taxon_group: 'Mammals',
      status_code: 'G4T2S2',
    },
    pandion_haliaetus: {
      common_name: 'osprey',
      scientific_name: 'Pandion haliaetus',
      taxon_group: 'Birds',
      status_code: 'G5S4',
    },
    pedicularis_centranthera: {
      common_name: 'Great Basin lousewort',
      scientific_name: 'Pedicularis centranthera',
      taxon_group: 'Dicots',
      status_code: 'G4S2',
    },
    pelecanus_erythrorhynchos: {
      common_name: 'American white pelican',
      scientific_name: 'Pelecanus erythrorhynchos',
      taxon_group: 'Birds',
      status_code: 'G4S1S2',
    },
    penstemon_janishiae: {
      common_name: "Janish's beardtongue",
      scientific_name: 'Penstemon janishiae',
      taxon_group: 'Dicots',
      status_code: 'G4S1',
    },
    penstemon_sudans: {
      common_name: 'Susanville beardtongue',
      scientific_name: 'Penstemon sudans',
      taxon_group: 'Dicots',
      status_code: 'G3S3',
    },
    phacelia_inundata: {
      common_name: 'playa phacelia',
      scientific_name: 'Phacelia inundata',
      taxon_group: 'Dicots',
      status_code: 'G2S2',
    },
    phacelia_sericea_var_ciliosa: {
      common_name: 'blue alpine phacelia',
      scientific_name: 'Phacelia sericea var. ciliosa',
      taxon_group: 'Dicots',
      status_code: 'G5T4T5S3',
    },
    phalacrocorax_auritus: {
      common_name: 'double-crested cormorant',
      scientific_name: 'Phalacrocorax auritus',
      taxon_group: 'Birds',
      status_code: 'G5S4',
    },
    phlox_muscoides: {
      common_name: 'squarestem phlox',
      scientific_name: 'Phlox muscoides',
      taxon_group: 'Dicots',
      status_code: 'G4G5S3',
    },
    picoides_arcticus: {
      common_name: 'black-backed woodpecker',
      scientific_name: 'Picoides arcticus',
      taxon_group: 'Birds',
      status_code: 'G5S2',
    },
    pisidium_ultramontanum: {
      common_name: 'montane peaclam',
      scientific_name: 'Pisidium ultramontanum',
      taxon_group: 'Mollusks',
      status_code: 'G1S1',
    },
    plagiobothrys_salsus: {
      common_name: 'desert popcornflower',
      scientific_name: 'Plagiobothrys salsus',
      taxon_group: 'Dicots',
      status_code: 'G2G3S1',
    },
    plegadis_chihi: {
      common_name: 'white-faced ibis',
      scientific_name: 'Plegadis chihi',
      taxon_group: 'Birds',
      status_code: 'G5S3S4',
    },
    pohlia_tundrae: {
      common_name: 'tundra thread moss',
      scientific_name: 'Pohlia tundrae',
      taxon_group: 'Bryophytes',
      status_code: 'G3S3',
    },
    polyctenium_williamsiae: {
      common_name: "Williams' combleaf",
      scientific_name: 'Polyctenium williamsiae',
      taxon_group: 'Dicots',
      status_code: 'G2QS1',
    },
    polygala_subspinosa: {
      common_name: 'spiny milkwort',
      scientific_name: 'Polygala subspinosa',
      taxon_group: 'Dicots',
      status_code: 'G4?S3',
    },
    polygonum_polygaloides_ssp_esotericum: {
      common_name: 'Modoc County knotweed',
      scientific_name: 'Polygonum polygaloides ssp. esotericum',
      taxon_group: 'Dicots',
      status_code: 'G4G5T3S3',
    },
    potamogeton_epihydrus: {
      common_name: "Nuttall's ribbon-leaved pondweed",
      scientific_name: 'Potamogeton epihydrus',
      taxon_group: 'Monocots',
      status_code: 'G5S2S3',
    },
    potamogeton_zosteriformis: {
      common_name: 'eel-grass pondweed',
      scientific_name: 'Potamogeton zosteriformis',
      taxon_group: 'Monocots',
      status_code: 'G5S3',
    },
    potentilla_basaltica: {
      common_name: 'Black Rock potentilla',
      scientific_name: 'Potentilla basaltica',
      taxon_group: 'Dicots',
      status_code: 'G1S1',
    },
    potentilla_newberryi: {
      common_name: "Newberry's cinquefoil",
      scientific_name: 'Potentilla newberryi',
      taxon_group: 'Dicots',
      status_code: 'G3G4S2S3',
    },
    progne_subis: {
      common_name: 'purple martin',
      scientific_name: 'Progne subis',
      taxon_group: 'Birds',
      status_code: 'G5S3',
    },
    pyrgulopsis_cinerana: {
      common_name: 'Ash Valley pyrg',
      scientific_name: 'Pyrgulopsis cinerana',
      taxon_group: 'Mollusks',
      status_code: 'G1G2S1S2',
    },
    pyrgulopsis_eremica: {
      common_name: 'Smoke Creek pyrg',
      scientific_name: 'Pyrgulopsis eremica',
      taxon_group: 'Mollusks',
      status_code: 'G2S2',
    },
    pyrgulopsis_falciglans: {
      common_name: 'Likely pyrg',
      scientific_name: 'Pyrgulopsis falciglans',
      taxon_group: 'Mollusks',
      status_code: 'G1S1',
    },
    pyrgulopsis_lasseni: {
      common_name: 'Willow Creek pyrg',
      scientific_name: 'Pyrgulopsis lasseni',
      taxon_group: 'Mollusks',
      status_code: 'G1G2S1S2',
    },
    rana_pretiosa: {
      common_name: 'Oregon spotted frog',
      scientific_name: 'Rana pretiosa',
      taxon_group: 'Amphibians',
      status_code: 'G2SH',
    },
    ranunculus_macounii: {
      common_name: "Macoun's buttercup",
      scientific_name: 'Ranunculus macounii',
      taxon_group: 'Dicots',
      status_code: 'G5S1',
    },
    ribes_hudsonianum_var_petiolare: {
      common_name: 'western black currant',
      scientific_name: 'Ribes hudsonianum var. petiolare',
      taxon_group: 'Dicots',
      status_code: 'G5T5S2',
    },
    riella_americana: {
      common_name: 'American riella',
      scientific_name: 'Riella americana',
      taxon_group: 'Bryophytes',
      status_code: 'G3?S1',
    },
    riparia_riparia: {
      common_name: 'bank swallow',
      scientific_name: 'Riparia riparia',
      taxon_group: 'Birds',
      status_code: 'G5S2',
    },
    rorippa_columbiae: {
      common_name: 'Columbia yellow cress',
      scientific_name: 'Rorippa columbiae',
      taxon_group: 'Dicots',
      status_code: 'G3S1',
    },
    rumex_venosus: {
      common_name: 'winged dock',
      scientific_name: 'Rumex venosus',
      taxon_group: 'Dicots',
      status_code: 'G5?S3',
    },
    salix_bebbiana: {
      common_name: "Bebb's willow",
      scientific_name: 'Salix bebbiana',
      taxon_group: 'Dicots',
      status_code: 'G5S1',
    },
    saxifraga_cespitosa: {
      common_name: 'tufted saxifrage',
      scientific_name: 'Saxifraga cespitosa',
      taxon_group: 'Dicots',
      status_code: 'G5S1',
    },
    scutellaria_galericulata: {
      common_name: 'marsh skullcap',
      scientific_name: 'Scutellaria galericulata',
      taxon_group: 'Dicots',
      status_code: 'G5S2',
    },
    silene_oregana: {
      common_name: 'Oregon campion',
      scientific_name: 'Silene oregana',
      taxon_group: 'Dicots',
      status_code: 'G4S2',
    },
    siphateles_bicolor_ssp_1: {
      common_name: 'Eagle Lake tui chub',
      scientific_name: 'Siphateles bicolor ssp. 1',
      taxon_group: 'Fish',
      status_code: 'G4T1T2S1S2',
    },
    siphateles_bicolor_thalassina: {
      common_name: 'Goose Lake tui chub',
      scientific_name: 'Siphateles bicolor thalassina',
      taxon_group: 'Fish',
      status_code: 'G4T2T3S2',
    },
    solidago_lepida_var_salebrosa: {
      common_name: 'Rocky Mountains Canada goldenrod',
      scientific_name: 'Solidago lepida var. salebrosa',
      taxon_group: 'Dicots',
      status_code: 'G5T5S1',
    },
    stachys_pilosa: {
      common_name: 'hairy marsh hedge-nettle',
      scientific_name: 'Stachys pilosa',
      taxon_group: 'Dicots',
      status_code: 'G5S3',
    },
    stanleya_viridiflora: {
      common_name: "green-flowered prince's plume",
      scientific_name: 'Stanleya viridiflora',
      taxon_group: 'Dicots',
      status_code: 'G4S2',
    },
    stenotus_lanuginosus_var_lanuginosus: {
      common_name: 'woolly stenotus',
      scientific_name: 'Stenotus lanuginosus var. lanuginosus',
      taxon_group: 'Dicots',
      status_code: 'G5T5S3',
    },
    stipa_exigua: {
      common_name: 'little ricegrass',
      scientific_name: 'Stipa exigua',
      taxon_group: 'Monocots',
      status_code: 'G4G5S2',
    },
    strix_nebulosa: {
      common_name: 'great gray owl',
      scientific_name: 'Strix nebulosa',
      taxon_group: 'Birds',
      status_code: 'G5S1',
    },
    stuckenia_filiformis_ssp_alpina: {
      common_name: 'slender-leaved pondweed',
      scientific_name: 'Stuckenia filiformis ssp. alpina',
      taxon_group: 'Monocots',
      status_code: 'G5T5S3',
    },
    suaeda_occidentalis: {
      common_name: 'western seablite',
      scientific_name: 'Suaeda occidentalis',
      taxon_group: 'Dicots',
      status_code: 'G5S2',
    },
    synthyris_missurica_ssp_missurica: {
      common_name: 'kitten-tails',
      scientific_name: 'Synthyris missurica ssp. missurica',
      taxon_group: 'Dicots',
      status_code: 'G4G5T4T5S3',
    },
    taxidea_taxus: {
      common_name: 'American badger',
      scientific_name: 'Taxidea taxus',
      taxon_group: 'Mammals',
      status_code: 'G5S3',
    },
    thelypodium_howellii_ssp_howellii: {
      common_name: "Howell's thelypodium",
      scientific_name: 'Thelypodium howellii ssp. howellii',
      taxon_group: 'Dicots',
      status_code: 'G1T1S1',
    },
    trifolium_gymnocarpon_ssp_plummerae: {
      common_name: "Plummer's clover",
      scientific_name: 'Trifolium gymnocarpon ssp. plummerae',
      taxon_group: 'Dicots',
      status_code: 'G5T4S2',
    },
    triteleia_grandiflora: {
      common_name: 'large-flowered triteleia',
      scientific_name: 'Triteleia grandiflora',
      taxon_group: 'Monocots',
      status_code: 'G4G5S1',
    },
    tuctoria_greenei: {
      common_name: "Greene's tuctoria",
      scientific_name: 'Tuctoria greenei',
      taxon_group: 'Monocots',
      status_code: 'G1S1',
    },
    utricularia_intermedia: {
      common_name: 'flat-leaved bladderwort',
      scientific_name: 'Utricularia intermedia',
      taxon_group: 'Dicots',
      status_code: 'G5S3',
    },
    valeriana_occidentalis: {
      common_name: 'western valerian',
      scientific_name: 'Valeriana occidentalis',
      taxon_group: 'Dicots',
      status_code: 'G5S1',
    },
    vulpes_vulpes_necator: {
      common_name: 'Sierra Nevada red fox',
      scientific_name: 'Vulpes vulpes necator',
      taxon_group: 'Mammals',
      status_code: 'G5T1T2S1',
    },
    xanthocephalus_xanthocephalus: {
      common_name: 'yellow-headed blackbird',
      scientific_name: 'Xanthocephalus xanthocephalus',
      taxon_group: 'Birds',
      status_code: 'G5S3',
    },
    antilocapra_americana: {
      common_name: 'Pronghorn',
      scientific_name: 'Antilocapra americana',
      taxon_group: 'Mammals',
      status_code: 'G5SNR',
    },
    aythya_americana: {
      common_name: 'Redhead',
      scientific_name: 'Aythya americana',
      taxon_group: 'Birds',
      status_code: 'G5S3S4',
    },
    chaetura_vauxi: {
      common_name: "Vaux's Swift",
      scientific_name: 'Chaetura vauxi',
      taxon_group: 'Birds',
      status_code: 'G5S2S3',
    },
    charadrius_nivosus: {
      common_name: 'Snowy Plover',
      scientific_name: 'Charadrius nivosus',
      taxon_group: 'Birds',
      status_code: 'G3SNR',
    },
    circus_cyaneus: {
      common_name: 'Northern Harrier',
      scientific_name: 'Circus cyaneus',
      taxon_group: 'Birds',
      status_code: 'G5S3',
    },
    contopus_cooperi: {
      common_name: 'Olive-sided Flycatcher',
      scientific_name: 'Contopus cooperi',
      taxon_group: 'Birds',
      status_code: 'G4S4',
    },
    crataegus_castlegarensis: {
      common_name: 'Castlegar Hawthorn',
      scientific_name: 'Crataegus castlegarensis',
      taxon_group: 'Dicots',
      status_code: 'G5S1S3',
    },
    lanius_ludovicianus: {
      common_name: 'Loggerhead Shrike',
      scientific_name: 'Lanius ludovicianus',
      taxon_group: 'Birds',
      status_code: 'G4S4',
    },
    ochotona_princeps: {
      common_name: 'American Pika',
      scientific_name: 'Ochotona princeps',
      taxon_group: 'Mammals',
      status_code: 'G5T4S2S4',
    },
    setophaga_petechia: {
      common_name: 'Yellow Warbler',
      scientific_name: 'Setophaga petechia',
      taxon_group: 'Birds',
      status_code: 'G5S3S4',
    },
  },
  greatvalley: {
    accipiter_cooperii: {
      common_name: 'Accipiter cooperii',
      scientific_name: "Cooper's hawk",
      taxon_group: 'Bird',
      status_code: 'G5S4',
    },
    actinemys_marmorata: {
      common_name: 'Actinemys marmorata',
      scientific_name: 'northern western pond turtle',
      taxon_group: 'Reptile',
      status_code: 'G3G4S3',
    },
    aegialia_concinna: {
      common_name: 'Aegialia concinna',
      scientific_name: 'Ciervo aegilian scarab beetle',
      taxon_group: 'Insect',
      status_code: 'G1S1',
    },
    agelaius_tricolor: {
      common_name: 'Agelaius tricolor',
      scientific_name: 'tricolored blackbird',
      taxon_group: 'Bird',
      status_code: 'G2G3S1S2',
    },
    agrostis_hendersonii: {
      common_name: 'Agrostis hendersonii',
      scientific_name: "Henderson's bent grass",
      taxon_group: 'Plant',
      status_code: 'G2QS2',
    },
    allium_howellii_sanbenitense: {
      common_name: 'Allium howellii sanbenitense',
      scientific_name: 'San Benito onion',
      taxon_group: 'Plant',
      status_code: 'G3G4T3S3',
    },
    allium_jepsonii: {
      common_name: 'Allium jepsonii',
      scientific_name: "Jepson's onion",
      taxon_group: 'Plant',
      status_code: 'G2S2',
    },
    ambystoma_californiense: {
      common_name: 'Ambystoma californiense',
      scientific_name: 'California tiger salamander',
      taxon_group: 'Amphibian',
      status_code: 'G2G3S2S3',
    },
    ammodramus_savannarum: {
      common_name: 'Ammodramus savannarum',
      scientific_name: 'grasshopper sparrow',
      taxon_group: 'Bird',
      status_code: 'G5S3',
    },
    ammospermophilus_nelsoni: {
      common_name: 'Ammospermophilus nelsoni',
      scientific_name: "Nelson's antelope squirrel",
      taxon_group: 'Mammal',
      status_code: 'G2S2S3',
    },
    amsinckia_grandiflora: {
      common_name: 'Amsinckia grandiflora',
      scientific_name: 'large-flowered fiddleneck',
      taxon_group: 'Plant',
      status_code: 'G1S1',
    },
    amsinckia_lunaris: {
      common_name: 'Amsinckia lunaris',
      scientific_name: 'bent-flowered fiddleneck',
      taxon_group: 'Plant',
      status_code: 'G3S3',
    },
    andrena_blennospermatis: {
      common_name: 'Andrena blennospermatis',
      scientific_name: 'Blennosperma vernal pool andrenid bee',
      taxon_group: 'Insect',
      status_code: 'G2S2',
    },
    andrena_macswaini: {
      common_name: 'Andrena macswaini',
      scientific_name: 'An andrenid bee',
      taxon_group: 'Insect',
      status_code: 'G2S2',
    },
    andrena_subapasta: {
      common_name: 'Andrena subapasta',
      scientific_name: 'An andrenid bee',
      taxon_group: 'Insect',
      status_code: 'G1G2S1S2',
    },
    anniella: {
      common_name: 'Anniella',
      scientific_name: 'California legless lizard',
      taxon_group: 'Reptile',
      status_code: 'G3G4S3S4',
    },
    anniella_alexanderae: {
      common_name: 'Anniella alexanderae',
      scientific_name: 'Temblor legless lizard',
      taxon_group: 'Reptile',
      status_code: 'G1S1',
    },
    anniella_grinnelli: {
      common_name: 'Anniella grinnelli',
      scientific_name: 'Bakersfield legless lizard',
      taxon_group: 'Reptile',
      status_code: 'G2G3S2S3',
    },
    anniella_pulchra: {
      common_name: 'Anniella pulchra',
      scientific_name: 'northern California legless lizard',
      taxon_group: 'Reptile',
      status_code: 'G3S3',
    },
    anniella_stebbinsi: {
      common_name: 'Anniella stebbinsi',
      scientific_name: 'southern California legless lizard',
      taxon_group: 'Reptile',
      status_code: 'G3S3',
    },
    anomobryum_julaceum: {
      common_name: 'Anomobryum julaceum',
      scientific_name: 'slender silver moss',
      taxon_group: 'Plant',
      status_code: 'G5S2',
    },
    anser_albifrons_elgasi: {
      common_name: 'Anser albifrons elgasi',
      scientific_name: 'tule greater white-fronted goose',
      taxon_group: 'Bird',
      status_code: 'G5T3S2S3',
    },
    anthicus_antiochensis: {
      common_name: 'Anthicus antiochensis',
      scientific_name: 'Antioch Dunes anthicid beetle',
      taxon_group: 'Insect',
      status_code: 'G1S1',
    },
    anthicus_sacramento: {
      common_name: 'Anthicus sacramento',
      scientific_name: 'Sacramento anthicid beetle',
      taxon_group: 'Insect',
      status_code: 'G1S1',
    },
    antigone_canadensis_tabida: {
      common_name: 'Antigone canadensis tabida',
      scientific_name: 'greater sandhill crane',
      taxon_group: 'Bird',
      status_code: 'G5T4S2',
    },
    antilocapra_americana: {
      common_name: 'Antilocapra americana',
      scientific_name: 'pronghorn',
      taxon_group: 'Mammal',
      status_code: 'G5SNR',
    },
    antrozous_pallidus: {
      common_name: 'Antrozous pallidus',
      scientific_name: 'pallid bat',
      taxon_group: 'Mammal',
      status_code: 'G5S3',
    },
    apodemia_mormo_langei: {
      common_name: 'Apodemia mormo langei',
      scientific_name: "Lange's metalmark butterfly",
      taxon_group: 'Insect',
      status_code: 'G5T1S1',
    },
    aquila_chrysaetos: {
      common_name: 'Aquila chrysaetos',
      scientific_name: 'golden eagle',
      taxon_group: 'Bird',
      status_code: 'G5S3',
    },
    arctostaphylos_auriculata: {
      common_name: 'Arctostaphylos auriculata',
      scientific_name: 'Mt. Diablo manzanita',
      taxon_group: 'Plant',
      status_code: 'G2S2',
    },
    arctostaphylos_manzanita_laevigata: {
      common_name: 'Arctostaphylos manzanita laevigata',
      scientific_name: 'Contra Costa manzanita',
      taxon_group: 'Plant',
      status_code: 'G5T2S2',
    },
    arctostaphylos_myrtifolia: {
      common_name: 'Arctostaphylos myrtifolia',
      scientific_name: 'Ione manzanita',
      taxon_group: 'Plant',
      status_code: 'G1S1',
    },
    arizona_elegans_occidentalis: {
      common_name: 'Arizona elegans occidentalis',
      scientific_name: 'California glossy snake',
      taxon_group: 'Reptile',
      status_code: 'G5T2S2',
    },
    asio_flammeus: {
      common_name: 'Asio flammeus',
      scientific_name: 'short-eared owl',
      taxon_group: 'Bird',
      status_code: 'G5S3',
    },
    asio_otus: {
      common_name: 'Asio otus',
      scientific_name: 'long-eared owl',
      taxon_group: 'Bird',
      status_code: 'G5S3',
    },
    astragalus_hornii_hornii: {
      common_name: 'Astragalus hornii hornii',
      scientific_name: "Horn's milk-vetch",
      taxon_group: 'Plant',
      status_code: 'G4G5T1T2S1',
    },
    astragalus_rattanii_jepsonianus: {
      common_name: 'Astragalus rattanii jepsonianus',
      scientific_name: "Jepson's milk-vetch",
      taxon_group: 'Plant',
      status_code: 'G4T3S3',
    },
    astragalus_tener_ferrisiae: {
      common_name: 'Astragalus tener ferrisiae',
      scientific_name: "Ferris' milk-vetch",
      taxon_group: 'Plant',
      status_code: 'G2T1S1',
    },
    astragalus_tener_tener: {
      common_name: 'Astragalus tener tener',
      scientific_name: 'alkali milk-vetch',
      taxon_group: 'Plant',
      status_code: 'G2T1S1',
    },
    athene_cunicularia: {
      common_name: 'Athene cunicularia',
      scientific_name: 'burrowing owl',
      taxon_group: 'Bird',
      status_code: 'G4S3',
    },
    atriplex_cordulata_cordulata: {
      common_name: 'Atriplex cordulata cordulata',
      scientific_name: 'heartscale',
      taxon_group: 'Plant',
      status_code: 'G3T2S2',
    },
    atriplex_cordulata_erecticaulis: {
      common_name: 'Atriplex cordulata erecticaulis',
      scientific_name: 'Earlimart orache',
      taxon_group: 'Plant',
      status_code: 'G3T1S1',
    },
    atriplex_coronata_vallicola: {
      common_name: 'Atriplex coronata vallicola',
      scientific_name: 'Lost Hills crownscale',
      taxon_group: 'Plant',
      status_code: 'G4T2S2',
    },
    atriplex_depressa: {
      common_name: 'Atriplex depressa',
      scientific_name: 'brittlescale',
      taxon_group: 'Plant',
      status_code: 'G2S2',
    },
    atriplex_minuscula: {
      common_name: 'Atriplex minuscula',
      scientific_name: 'lesser saltscale',
      taxon_group: 'Plant',
      status_code: 'G2S2',
    },
    atriplex_persistens: {
      common_name: 'Atriplex persistens',
      scientific_name: 'vernal pool smallscale',
      taxon_group: 'Plant',
      status_code: 'G2S2',
    },
    atriplex_subtilis: {
      common_name: 'Atriplex subtilis',
      scientific_name: 'subtle orache',
      taxon_group: 'Plant',
      status_code: 'G1S1',
    },
    aythya_americana: {
      common_name: 'Aythya americana',
      scientific_name: 'redhead',
      taxon_group: 'Bird',
      status_code: 'G5S3S4',
    },
    balsamorhiza_macrolepis: {
      common_name: 'Balsamorhiza macrolepis',
      scientific_name: 'big-scale balsamroot',
      taxon_group: 'Plant',
      status_code: 'G2S2',
    },
    banksula_rudolphi: {
      common_name: 'Banksula rudolphi',
      scientific_name: "Rudolph's cave harvestman",
      taxon_group: 'Arachnid',
      status_code: 'G1S1',
    },
    batrachoseps_stebbinsi: {
      common_name: 'Batrachoseps stebbinsi',
      scientific_name: 'Tehachapi slender salamander',
      taxon_group: 'Amphibian',
      status_code: 'G2S2S3',
    },
    blepharizonia_plumosa: {
      common_name: 'Blepharizonia plumosa',
      scientific_name: 'big tarplant',
      taxon_group: 'Plant',
      status_code: 'G1G2S1S2',
    },
    bombus_caliginosus: {
      common_name: 'Bombus caliginosus',
      scientific_name: 'obscure bumble bee',
      taxon_group: 'Insect',
      status_code: 'G4S1S2',
    },
    bombus_crotchii: {
      common_name: 'Bombus crotchii',
      scientific_name: 'Crotch bumble bee',
      taxon_group: 'Insect',
      status_code: 'G3G4S1S2',
    },
    bombus_morrisoni: {
      common_name: 'Bombus morrisoni',
      scientific_name: 'Morrison bumble bee',
      taxon_group: 'Insect',
      status_code: 'G4G5S1S2',
    },
    bombus_occidentalis: {
      common_name: 'Bombus occidentalis',
      scientific_name: 'western bumble bee',
      taxon_group: 'Insect',
      status_code: 'G2G3S1',
    },
    branchinecta_conservatio: {
      common_name: 'Branchinecta conservatio',
      scientific_name: 'Conservancy fairy shrimp',
      taxon_group: 'Crustacean',
      status_code: 'G2S2',
    },
    branchinecta_longiantenna: {
      common_name: 'Branchinecta longiantenna',
      scientific_name: 'longhorn fairy shrimp',
      taxon_group: 'Crustacean',
      status_code: 'G1S1S2',
    },
    branchinecta_lynchi: {
      common_name: 'Branchinecta lynchi',
      scientific_name: 'vernal pool fairy shrimp',
      taxon_group: 'Crustacean',
      status_code: 'G3S3',
    },
    branchinecta_mesovallensis: {
      common_name: 'Branchinecta mesovallensis',
      scientific_name: 'midvalley fairy shrimp',
      taxon_group: 'Crustacean',
      status_code: 'G2S2S3',
    },
    branta_hutchinsii_leucopareia: {
      common_name: 'Branta hutchinsii leucopareia',
      scientific_name: 'cackling (=Aleutian Canada) goose',
      taxon_group: 'Bird',
      status_code: 'G5T3S3',
    },
    brasenia_schreberi: {
      common_name: 'Brasenia schreberi',
      scientific_name: 'watershield',
      taxon_group: 'Plant',
      status_code: 'G5S3',
    },
    brodiaea_insignis: {
      common_name: 'Brodiaea insignis',
      scientific_name: 'Kaweah brodiaea',
      taxon_group: 'Plant',
      status_code: 'G1S1',
    },
    brodiaea_pallida: {
      common_name: 'Brodiaea pallida',
      scientific_name: 'Chinese Camp brodiaea',
      taxon_group: 'Plant',
      status_code: 'G1S1',
    },
    buteo_regalis: {
      common_name: 'Buteo regalis',
      scientific_name: 'ferruginous hawk',
      taxon_group: 'Bird',
      status_code: 'G4S3S4',
    },
    buteo_swainsoni: {
      common_name: 'Buteo swainsoni',
      scientific_name: "Swainson's hawk",
      taxon_group: 'Bird',
      status_code: 'G5S3',
    },
    calicina_breva: {
      common_name: 'Calicina breva',
      scientific_name: 'Stanislaus harvestman',
      taxon_group: 'Arachnid',
      status_code: 'G1S1',
    },
    calicina_macula: {
      common_name: 'Calicina macula',
      scientific_name: 'marbled harvestman',
      taxon_group: 'Arachnid',
      status_code: 'G1S1',
    },
    calicina_piedra: {
      common_name: 'Calicina piedra',
      scientific_name: 'Piedra harvestman',
      taxon_group: 'Arachnid',
      status_code: 'G1S1',
    },
    calochortus_palmeri_palmeri: {
      common_name: 'Calochortus palmeri palmeri',
      scientific_name: "Palmer's mariposa lily",
      taxon_group: 'Plant',
      status_code: 'G3T2S2',
    },
    calochortus_pulchellus: {
      common_name: 'Calochortus pulchellus',
      scientific_name: 'Mt. Diablo fairy-lantern',
      taxon_group: 'Plant',
      status_code: 'G2S2',
    },
    calochortus_striatus: {
      common_name: 'Calochortus striatus',
      scientific_name: 'alkali mariposa lily',
      taxon_group: 'Plant',
      status_code: 'G3S2S3',
    },
    calycadenia_hooveri: {
      common_name: 'Calycadenia hooveri',
      scientific_name: "Hoover's calycadenia",
      taxon_group: 'Plant',
      status_code: 'G2S2',
    },
    campanula_exigua: {
      common_name: 'Campanula exigua',
      scientific_name: 'chaparral harebell',
      taxon_group: 'Plant',
      status_code: 'G2S2',
    },
    campanula_wilkinsiana: {
      common_name: 'Campanula wilkinsiana',
      scientific_name: "Wilkin's harebell",
      taxon_group: 'Plant',
      status_code: 'G2S2',
    },
    campylopodiella_stenocarpa: {
      common_name: 'Campylopodiella stenocarpa',
      scientific_name: 'flagella-like atractylocarpus',
      taxon_group: 'Plant',
      status_code: 'G5S1',
    },
    cardamine_pachystigma_dissectifolia: {
      common_name: 'Cardamine pachystigma dissectifolia',
      scientific_name: 'dissected-leaved toothwort',
      taxon_group: 'Plant',
      status_code: 'G3G5T2QS2',
    },
    carex_comosa: {
      common_name: 'Carex comosa',
      scientific_name: 'bristly sedge',
      taxon_group: 'Plant',
      status_code: 'G5S2',
    },
    carpenteria_californica: {
      common_name: 'Carpenteria californica',
      scientific_name: 'tree-anemone',
      taxon_group: 'Plant',
      status_code: 'G1S1',
    },
    castilleja_campestris_succulenta: {
      common_name: 'Castilleja campestris succulenta',
      scientific_name: "succulent owl's-clover",
      taxon_group: 'Plant',
      status_code: 'G4T2T3S2S3',
    },
    castilleja_rubicundula_rubicundula: {
      common_name: 'Castilleja rubicundula rubicundula',
      scientific_name: 'pink creamsacs',
      taxon_group: 'Plant',
      status_code: 'G5T2S2',
    },
    caulanthus_californicus: {
      common_name: 'Caulanthus californicus',
      scientific_name: 'California jewelflower',
      taxon_group: 'Plant',
      status_code: 'G1S1',
    },
    caulanthus_lemmonii: {
      common_name: 'Caulanthus lemmonii',
      scientific_name: "Lemmon's jewelflower",
      taxon_group: 'Plant',
      status_code: 'G3S3',
    },
    centromadia_parryi_congdonii: {
      common_name: 'Centromadia parryi congdonii',
      scientific_name: "Congdon's tarplant",
      taxon_group: 'Plant',
      status_code: 'G3T1T2S1S2',
    },
    centromadia_parryi_parryi: {
      common_name: 'Centromadia parryi parryi',
      scientific_name: 'pappose tarplant',
      taxon_group: 'Plant',
      status_code: 'G3T2S2',
    },
    ceratochrysis_menkei: {
      common_name: 'Ceratochrysis menkei',
      scientific_name: "Menke's cuckoo wasp",
      taxon_group: 'Insect',
      status_code: 'G1S1',
    },
    charadrius_alexandrinus_nivosus: {
      common_name: 'Charadrius alexandrinus nivosus',
      scientific_name: 'western snowy plover',
      taxon_group: 'Bird',
      status_code: 'G3T3S2S3',
    },
    charadrius_montanus: {
      common_name: 'Charadrius montanus',
      scientific_name: 'mountain plover',
      taxon_group: 'Bird',
      status_code: 'G3S2S3',
    },
    charadrius_nivosus: {
      common_name: 'Charadrius nivosus',
      scientific_name: 'snowy plover (interior population)',
      taxon_group: 'Bird',
      status_code: 'G3SNR',
    },
    chlidonias_niger: {
      common_name: 'Chlidonias niger',
      scientific_name: 'black tern',
      taxon_group: 'Bird',
      status_code: 'G4G5S2',
    },
    chloropyron_molle_hispidum: {
      common_name: 'Chloropyron molle hispidum',
      scientific_name: "hispid bird's-beak",
      taxon_group: 'Plant',
      status_code: 'G2T1S1',
    },
    chloropyron_molle_molle: {
      common_name: 'Chloropyron molle molle',
      scientific_name: "soft bird's-beak",
      taxon_group: 'Plant',
      status_code: 'G2T1S1',
    },
    chloropyron_palmatum: {
      common_name: 'Chloropyron palmatum',
      scientific_name: "palmate-bracted bird's-beak",
      taxon_group: 'Plant',
      status_code: 'G1S1',
    },
    chrysis_tularensis: {
      common_name: 'Chrysis tularensis',
      scientific_name: 'Tulare cuckoo wasp',
      taxon_group: 'Insect',
      status_code: 'G1G2S1S2',
    },
    cicindela_tranquebarica: {
      common_name: 'Cicindela tranquebarica',
      scientific_name: 'San Joaquin tiger beetle',
      taxon_group: 'Insect',
      status_code: 'G5T1S1',
    },
    cicuta_maculata_bolanderi: {
      common_name: 'Cicuta maculata bolanderi',
      scientific_name: "Bolander's water-hemlock",
      taxon_group: 'Plant',
      status_code: 'G5T4T5S2',
    },
    circus_hudsonius: {
      common_name: 'Circus hudsonius',
      scientific_name: 'northern harrier',
      taxon_group: 'Bird',
      status_code: 'G5S3',
    },
    cirsium_crassicaule: {
      common_name: 'Cirsium crassicaule',
      scientific_name: 'slough thistle',
      taxon_group: 'Plant',
      status_code: 'G1S1',
    },
    cirsium_hydrophilum_hydrophilum: {
      common_name: 'Cirsium hydrophilum hydrophilum',
      scientific_name: 'Suisun thistle',
      taxon_group: 'Plant',
      status_code: 'G2T1S1',
    },
    clarkia_gracilis_albicaulis: {
      common_name: 'Clarkia gracilis albicaulis',
      scientific_name: 'white-stemmed clarkia',
      taxon_group: 'Plant',
      status_code: 'G5T3S3',
    },
    clarkia_rostrata: {
      common_name: 'Clarkia rostrata',
      scientific_name: 'beaked clarkia',
      taxon_group: 'Plant',
      status_code: 'G2G3S2S3',
    },
    clarkia_springvillensis: {
      common_name: 'Clarkia springvillensis',
      scientific_name: 'Springville clarkia',
      taxon_group: 'Plant',
      status_code: 'G2S2',
    },
    clarkia_tembloriensis_calientensis: {
      common_name: 'Clarkia tembloriensis calientensis',
      scientific_name: "Vasek's clarkia",
      taxon_group: 'Plant',
      status_code: 'G3T1S1',
    },
    coccyzus_americanus: {
      common_name: 'Coccyzus americanus',
      scientific_name: 'western yellow-billed cuckoo',
      taxon_group: 'Bird',
      status_code: 'G5T2T3S1',
    },
    coccyzus_americanus_occidentalis: {
      common_name: 'Coccyzus americanus occidentalis',
      scientific_name: 'western yellow-billed cuckoo',
      taxon_group: 'Bird',
      status_code: 'G5T2T3S1',
    },
    coelus_gracilis: {
      common_name: 'Coelus gracilis',
      scientific_name: 'San Joaquin dune beetle',
      taxon_group: 'Insect',
      status_code: 'G1S1',
    },
    corynorhinus_townsendii: {
      common_name: 'Corynorhinus townsendii',
      scientific_name: "Townsend's big-eared bat",
      taxon_group: 'Mammal',
      status_code: 'G3G4S2',
    },
    coturnicops_noveboracensis: {
      common_name: 'Coturnicops noveboracensis',
      scientific_name: 'yellow rail',
      taxon_group: 'Bird',
      status_code: 'G4S1S2',
    },
    crocanthemum_suffrutescens: {
      common_name: 'Crocanthemum suffrutescens',
      scientific_name: 'Bisbee Peak rush-rose',
      taxon_group: 'Plant',
      status_code: 'G2QS2',
    },
    cryptantha_crinita: {
      common_name: 'Cryptantha crinita',
      scientific_name: 'silky cryptantha',
      taxon_group: 'Plant',
      status_code: 'G2S2',
    },
    cryptantha_mariposae: {
      common_name: 'Cryptantha mariposae',
      scientific_name: 'Mariposa cryptantha',
      taxon_group: 'Plant',
      status_code: 'G2G3S2S3',
    },
    cuscuta_jepsonii: {
      common_name: 'Cuscuta jepsonii',
      scientific_name: "Jepson's dodder",
      taxon_group: 'Plant',
      status_code: 'G1S1',
    },
    cuscuta_obtusiflora_glandulosa: {
      common_name: 'Cuscuta obtusiflora glandulosa',
      scientific_name: 'Peruvian dodder',
      taxon_group: 'Plant',
      status_code: 'G5T4SH',
    },
    danaus_plexippus: {
      common_name: 'Danaus plexippus',
      scientific_name: 'monarch - California overwintering population',
      taxon_group: 'Insect',
      status_code: 'G4T2T3S2S3',
    },
    deinandra_arida: {
      common_name: 'Deinandra arida',
      scientific_name: 'Red Rock tarplant',
      taxon_group: 'Plant',
      status_code: 'G1S1',
    },
    deinandra_halliana: {
      common_name: 'Deinandra halliana',
      scientific_name: "Hall's tarplant",
      taxon_group: 'Plant',
      status_code: 'G3S3',
    },
    delphinium_californicum_interius: {
      common_name: 'Delphinium californicum interius',
      scientific_name: 'Hospital Canyon larkspur',
      taxon_group: 'Plant',
      status_code: 'G3T3S3',
    },
    delphinium_purpusii: {
      common_name: 'Delphinium purpusii',
      scientific_name: 'rose-flowered larkspur',
      taxon_group: 'Plant',
      status_code: 'G3S3',
    },
    delphinium_recurvatum: {
      common_name: 'Delphinium recurvatum',
      scientific_name: 'recurved larkspur',
      taxon_group: 'Plant',
      status_code: 'G2S2',
    },
    dendrocygna_bicolor: {
      common_name: 'Dendrocygna bicolor',
      scientific_name: 'fulvous whistling-duck',
      taxon_group: 'Bird',
      status_code: 'G5S1',
    },
    desmocerus_californicus_dimorphus: {
      common_name: 'Desmocerus californicus dimorphus',
      scientific_name: 'valley elderberry longhorn beetle',
      taxon_group: 'Insect',
      status_code: 'G3T2S2',
    },
    diadophis_punctatus_modestus: {
      common_name: 'Diadophis punctatus modestus',
      scientific_name: 'San Bernardino ringneck snake',
      taxon_group: 'Reptile',
      status_code: 'G5T2T3S2',
    },
    diplacus_pictus: {
      common_name: 'Diplacus pictus',
      scientific_name: 'calico monkeyflower',
      taxon_group: 'Plant',
      status_code: 'G2S2',
    },
    dipodomys_agilis: {
      common_name: 'Dipodomys agilis',
      scientific_name: 'agile (=Pacific) kangaroo rat',
      taxon_group: 'Mammal',
      status_code: 'G3G4SNR',
    },
    dipodomys_californicus_eximius: {
      common_name: 'Dipodomys californicus eximius',
      scientific_name: 'Marysville California kangaroo rat',
      taxon_group: 'Mammal',
      status_code: 'G4T1S1',
    },
    dipodomys_heermanni: {
      common_name: 'Dipodomys heermanni',
      scientific_name: "Heermann's kangaroo rat",
      taxon_group: 'Mammal',
      status_code: 'G4TNRSNR',
    },
    dipodomys_heermanni_dixoni: {
      common_name: 'Dipodomys heermanni dixoni',
      scientific_name: 'Merced kangaroo rat',
      taxon_group: 'Mammal',
      status_code: 'G3G4T2T3S2S3',
    },
    dipodomys_ingens: {
      common_name: 'Dipodomys ingens',
      scientific_name: 'giant kangaroo rat',
      taxon_group: 'Mammal',
      status_code: 'G1G2S1S2',
    },
    dipodomys_nitratoides_brevinasus: {
      common_name: 'Dipodomys nitratoides brevinasus',
      scientific_name: 'short-nosed kangaroo rat',
      taxon_group: 'Mammal',
      status_code: 'G3T1T2S1S2',
    },
    dipodomys_nitratoides_exilis: {
      common_name: 'Dipodomys nitratoides exilis',
      scientific_name: 'Fresno kangaroo rat',
      taxon_group: 'Mammal',
      status_code: 'G3THSH',
    },
    dipodomys_nitratoides_nitratoides: {
      common_name: 'Dipodomys nitratoides nitratoides',
      scientific_name: 'Tipton kangaroo rat',
      taxon_group: 'Mammal',
      status_code: 'G3T1T2S1S2',
    },
    downingia_pusilla: {
      common_name: 'Downingia pusilla',
      scientific_name: 'dwarf downingia',
      taxon_group: 'Plant',
      status_code: 'GUS2',
    },
    dumontia_oregonensis: {
      common_name: 'Dumontia oregonensis',
      scientific_name: 'hairy water flea',
      taxon_group: 'Crustacean',
      status_code: 'G1G3S1',
    },
    efferia_antiochi: {
      common_name: 'Efferia antiochi',
      scientific_name: 'Antioch efferian robberfly',
      taxon_group: 'Insect',
      status_code: 'G1G2S1S2',
    },
    elanus_leucurus: {
      common_name: 'Elanus leucurus',
      scientific_name: 'white-tailed kite',
      taxon_group: 'Bird',
      status_code: 'G5S3S4',
    },
    elaphrus_viridis: {
      common_name: 'Elaphrus viridis',
      scientific_name: 'Delta green ground beetle',
      taxon_group: 'Insect',
      status_code: 'G1S1',
    },
    empidonax_traillii: {
      common_name: 'Empidonax traillii',
      scientific_name: 'willow flycatcher',
      taxon_group: 'Bird',
      status_code: 'G5S1S2',
    },
    ensatina_eschscholtzii_croceater: {
      common_name: 'Ensatina eschscholtzii croceater',
      scientific_name: 'yellow-blotched salamander',
      taxon_group: 'Amphibian',
      status_code: 'G5T3S3',
    },
    eremalche_parryi_kernensis: {
      common_name: 'Eremalche parryi kernensis',
      scientific_name: 'Kern mallow',
      taxon_group: 'Plant',
      status_code: 'G3G4T3S3',
    },
    eremophila_alpestris_actia: {
      common_name: 'Eremophila alpestris actia',
      scientific_name: 'California horned lark',
      taxon_group: 'Bird',
      status_code: 'G5T4QS4',
    },
    erethizon_dorsatum: {
      common_name: 'Erethizon dorsatum',
      scientific_name: 'porcupine',
      taxon_group: 'Mammal',
      status_code: 'G5S3',
    },
    eriastrum_tracyi: {
      common_name: 'Eriastrum tracyi',
      scientific_name: "Tracy's eriastrum",
      taxon_group: 'Plant',
      status_code: 'G3QS3',
    },
    eriogonum_apricum_apricum: {
      common_name: 'Eriogonum apricum apricum',
      scientific_name: 'Ione buckwheat',
      taxon_group: 'Plant',
      status_code: 'G2T1S1',
    },
    eriogonum_apricum_prostratum: {
      common_name: 'Eriogonum apricum prostratum',
      scientific_name: 'Irish Hill buckwheat',
      taxon_group: 'Plant',
      status_code: 'G2T1S1',
    },
    eriogonum_luteolum_caninum: {
      common_name: 'Eriogonum luteolum caninum',
      scientific_name: 'Tiburon buckwheat',
      taxon_group: 'Plant',
      status_code: 'G5T2S2',
    },
    eriogonum_nudum_psychicola: {
      common_name: 'Eriogonum nudum psychicola',
      scientific_name: 'Antioch Dunes buckwheat',
      taxon_group: 'Plant',
      status_code: 'G5T1S1',
    },
    eriogonum_temblorense: {
      common_name: 'Eriogonum temblorense',
      scientific_name: 'Temblor buckwheat',
      taxon_group: 'Plant',
      status_code: 'G2S2',
    },
    eriogonum_truncatum: {
      common_name: 'Eriogonum truncatum',
      scientific_name: 'Mt. Diablo buckwheat',
      taxon_group: 'Plant',
      status_code: 'G1S1',
    },
    eriophyllum_lanatum_hallii: {
      common_name: 'Eriophyllum lanatum hallii',
      scientific_name: 'Fort Tejon woolly sunflower',
      taxon_group: 'Plant',
      status_code: 'G5T1S1',
    },
    eryngium_jepsonii: {
      common_name: 'Eryngium jepsonii',
      scientific_name: "Jepson's coyote thistle",
      taxon_group: 'Plant',
      status_code: 'G2S2',
    },
    eryngium_pinnatisectum: {
      common_name: 'Eryngium pinnatisectum',
      scientific_name: 'Tuolumne button-celery',
      taxon_group: 'Plant',
      status_code: 'G2S2',
    },
    eryngium_racemosum: {
      common_name: 'Eryngium racemosum',
      scientific_name: 'Delta button-celery',
      taxon_group: 'Plant',
      status_code: 'G1S1',
    },
    eryngium_spinosepalum: {
      common_name: 'Eryngium spinosepalum',
      scientific_name: 'spiny-sepaled button-celery',
      taxon_group: 'Plant',
      status_code: 'G2S2',
    },
    erysimum_capitatum_angustatum: {
      common_name: 'Erysimum capitatum angustatum',
      scientific_name: 'Contra Costa wallflower',
      taxon_group: 'Plant',
      status_code: 'G5T1S1',
    },
    erythranthe_marmorata: {
      common_name: 'Erythranthe marmorata',
      scientific_name: 'Stanislaus monkeyflower',
      taxon_group: 'Plant',
      status_code: 'GXQSX',
    },
    eschscholzia_lemmonii_kernensis: {
      common_name: 'Eschscholzia lemmonii kernensis',
      scientific_name: 'Tejon poppy',
      taxon_group: 'Plant',
      status_code: 'G5T2S2',
    },
    eschscholzia_rhombipetala: {
      common_name: 'Eschscholzia rhombipetala',
      scientific_name: 'diamond-petaled California poppy',
      taxon_group: 'Plant',
      status_code: 'G1S1',
    },
    eucerceris_ruficeps: {
      common_name: 'Eucerceris ruficeps',
      scientific_name: 'redheaded sphecid wasp',
      taxon_group: 'Insect',
      status_code: 'G1G3S1S2',
    },
    euderma_maculatum: {
      common_name: 'Euderma maculatum',
      scientific_name: 'spotted bat',
      taxon_group: 'Mammal',
      status_code: 'G4S3',
    },
    eumops_perotis_californicus: {
      common_name: 'Eumops perotis californicus',
      scientific_name: 'western mastiff bat',
      taxon_group: 'Mammal',
      status_code: 'G5T4S3S4',
    },
    euphorbia_hooveri: {
      common_name: 'Euphorbia hooveri',
      scientific_name: "Hoover's spurge",
      taxon_group: 'Plant',
      status_code: 'G1S1',
    },
    euphorbia_ocellata_rattanii: {
      common_name: 'Euphorbia ocellata rattanii',
      scientific_name: 'Stony Creek spurge',
      taxon_group: 'Plant',
      status_code: 'G4T2S2',
    },
    extriplex_joaquinana: {
      common_name: 'Extriplex joaquinana',
      scientific_name: 'San Joaquin spearscale',
      taxon_group: 'Plant',
      status_code: 'G2S2',
    },
    falco_columbarius: {
      common_name: 'Falco columbarius',
      scientific_name: 'merlin',
      taxon_group: 'Bird',
      status_code: 'G5S3S4',
    },
    falco_mexicanus: {
      common_name: 'Falco mexicanus',
      scientific_name: 'prairie falcon',
      taxon_group: 'Bird',
      status_code: 'G5S4',
    },
    falco_peregrinus_anatum: {
      common_name: 'Falco peregrinus anatum',
      scientific_name: 'American peregrine falcon',
      taxon_group: 'Bird',
      status_code: 'G4T4S3S4',
    },
    fremontodendron_decumbens: {
      common_name: 'Fremontodendron decumbens',
      scientific_name: 'Pine Hill flannelbush',
      taxon_group: 'Plant',
      status_code: 'G1S1',
    },
    fritillaria_liliacea: {
      common_name: 'Fritillaria liliacea',
      scientific_name: 'fragrant fritillary',
      taxon_group: 'Plant',
      status_code: 'G2S2',
    },
    fritillaria_pluriflora: {
      common_name: 'Fritillaria pluriflora',
      scientific_name: 'adobe-lily',
      taxon_group: 'Plant',
      status_code: 'G2G3S2S3',
    },
    fritillaria_striata: {
      common_name: 'Fritillaria striata',
      scientific_name: 'striped adobe-lily',
      taxon_group: 'Plant',
      status_code: 'G1S1',
    },
    gambelia_sila: {
      common_name: 'Gambelia sila',
      scientific_name: 'blunt-nosed leopard lizard',
      taxon_group: 'Reptile',
      status_code: 'G1S1',
    },
    geothlypis_trichas_sinuosa: {
      common_name: 'Geothlypis trichas sinuosa',
      scientific_name: 'saltmarsh common yellowthroat',
      taxon_group: 'Bird',
      status_code: 'G5T3S3',
    },
    gratiola_heterosepala: {
      common_name: 'Gratiola heterosepala',
      scientific_name: 'Boggs Lake hedge-hyssop',
      taxon_group: 'Plant',
      status_code: 'G2S2',
    },
    gymnogyps_californianus: {
      common_name: 'Gymnogyps californianus',
      scientific_name: 'California condor',
      taxon_group: 'Bird',
      status_code: 'G1S1',
    },
    haliaeetus_leucocephalus: {
      common_name: 'Haliaeetus leucocephalus',
      scientific_name: 'bald eagle',
      taxon_group: 'Bird',
      status_code: 'G5S3',
    },
    helianthella_castanea: {
      common_name: 'Helianthella castanea',
      scientific_name: 'Diablo helianthella',
      taxon_group: 'Plant',
      status_code: 'G2S2',
    },
    helianthus_winteri: {
      common_name: 'Helianthus winteri',
      scientific_name: "Winter's sunflower",
      taxon_group: 'Plant',
      status_code: 'G2S2',
    },
    helminthoglypta_callistoderma: {
      common_name: 'Helminthoglypta callistoderma',
      scientific_name: 'Kern shoulderband',
      taxon_group: 'Mollusk',
      status_code: 'G1S1',
    },
    helminthoglypta_nickliniana_bridgesi: {
      common_name: 'Helminthoglypta nickliniana bridgesi',
      scientific_name: "Bridges' coast range shoulderband",
      taxon_group: 'Mollusk',
      status_code: 'G3T1S1S2',
    },
    hesperolinon_breweri: {
      common_name: 'Hesperolinon breweri',
      scientific_name: "Brewer's western flax",
      taxon_group: 'Plant',
      status_code: 'G2S2',
    },
    heteranthera_dubia: {
      common_name: 'Heteranthera dubia',
      scientific_name: 'water star-grass',
      taxon_group: 'Plant',
      status_code: 'G5S2',
    },
    heterotheca_shevockii: {
      common_name: 'Heterotheca shevockii',
      scientific_name: "Shevock's golden-aster",
      taxon_group: 'Plant',
      status_code: 'G2S2',
    },
    hibiscus_lasiocarpos_occidentalis: {
      common_name: 'Hibiscus lasiocarpos occidentalis',
      scientific_name: 'woolly rose-mallow',
      taxon_group: 'Plant',
      status_code: 'G5T3S3',
    },
    horkelia_parryi: {
      common_name: 'Horkelia parryi',
      scientific_name: "Parry's horkelia",
      taxon_group: 'Plant',
      status_code: 'G2S2',
    },
    hydrochara_rickseckeri: {
      common_name: 'Hydrochara rickseckeri',
      scientific_name: "Ricksecker's water scavenger beetle",
      taxon_group: 'Insect',
      status_code: 'G2S2',
    },
    hygrotus_curvipes: {
      common_name: 'Hygrotus curvipes',
      scientific_name: 'curved-foot hygrotus diving beetle',
      taxon_group: 'Insect',
      status_code: 'G1S1',
    },
    icteria_virens: {
      common_name: 'Icteria virens',
      scientific_name: 'yellow-breasted chat',
      taxon_group: 'Bird',
      status_code: 'G5S3',
    },
    idiostatus_middlekauffi: {
      common_name: 'Idiostatus middlekauffi',
      scientific_name: "Middlekauff's shieldback katydid",
      taxon_group: 'Insect',
      status_code: 'G1G2S1',
    },
    imperata_brevifolia: {
      common_name: 'Imperata brevifolia',
      scientific_name: 'California satintail',
      taxon_group: 'Plant',
      status_code: 'G4S3',
    },
    isocoma_arguta: {
      common_name: 'Isocoma arguta',
      scientific_name: 'Carquinez goldenbush',
      taxon_group: 'Plant',
      status_code: 'G1S1',
    },
    ixobrychus_exilis: {
      common_name: 'Ixobrychus exilis',
      scientific_name: 'least bittern',
      taxon_group: 'Bird',
      status_code: 'G4G5S2',
    },
    juglans_hindsii: {
      common_name: 'Juglans hindsii',
      scientific_name: 'Northern California black walnut',
      taxon_group: 'Plant',
      status_code: 'G1S1',
    },
    juncus_leiospermus_ahartii: {
      common_name: 'Juncus leiospermus ahartii',
      scientific_name: "Ahart's dwarf rush",
      taxon_group: 'Plant',
      status_code: 'G2T1S1',
    },
    juncus_leiospermus_leiospermus: {
      common_name: 'Juncus leiospermus leiospermus',
      scientific_name: 'Red Bluff dwarf rush',
      taxon_group: 'Plant',
      status_code: 'G2T2S2',
    },
    lagophylla_dichotoma: {
      common_name: 'Lagophylla dichotoma',
      scientific_name: 'forked hare-leaf',
      taxon_group: 'Plant',
      status_code: 'G2S2',
    },
    lanius_ludovicianus: {
      common_name: 'Lanius ludovicianus',
      scientific_name: 'loggerhead shrike',
      taxon_group: 'Bird',
      status_code: 'G4S4',
    },
    lasiurus_blossevillii: {
      common_name: 'Lasiurus blossevillii',
      scientific_name: 'western red bat',
      taxon_group: 'Mammal',
      status_code: 'G5S3',
    },
    lasthenia_conjugens: {
      common_name: 'Lasthenia conjugens',
      scientific_name: 'Contra Costa goldfields',
      taxon_group: 'Plant',
      status_code: 'G1S1',
    },
    lasthenia_glabrata_coulteri: {
      common_name: 'Lasthenia glabrata coulteri',
      scientific_name: "Coulter's goldfields",
      taxon_group: 'Plant',
      status_code: 'G4T2S2',
    },
    laterallus_jamaicensis_coturniculus: {
      common_name: 'Laterallus jamaicensis coturniculus',
      scientific_name: 'California black rail',
      taxon_group: 'Bird',
      status_code: 'G3G4T1S1',
    },
    lathyrus_jepsonii_jepsonii: {
      common_name: 'Lathyrus jepsonii jepsonii',
      scientific_name: 'Delta tule pea',
      taxon_group: 'Plant',
      status_code: 'G5T2S2',
    },
    layia_heterotricha: {
      common_name: 'Layia heterotricha',
      scientific_name: 'pale-yellow layia',
      taxon_group: 'Plant',
      status_code: 'G2S2',
    },
    layia_leucopappa: {
      common_name: 'Layia leucopappa',
      scientific_name: 'Comanche Point layia',
      taxon_group: 'Plant',
      status_code: 'G1S1',
    },
    layia_munzii: {
      common_name: 'Layia munzii',
      scientific_name: "Munz's tidy-tips",
      taxon_group: 'Plant',
      status_code: 'G2S2',
    },
    layia_septentrionalis: {
      common_name: 'Layia septentrionalis',
      scientific_name: 'Colusa layia',
      taxon_group: 'Plant',
      status_code: 'G2S2',
    },
    legenere_limosa: {
      common_name: 'Legenere limosa',
      scientific_name: 'legenere',
      taxon_group: 'Plant',
      status_code: 'G2S2',
    },
    lepidium_jaredii_album: {
      common_name: 'Lepidium jaredii album',
      scientific_name: 'Panoche pepper-grass',
      taxon_group: 'Plant',
      status_code: 'G2G3T2T3S2S3',
    },
    lepidium_jaredii_jaredii: {
      common_name: 'Lepidium jaredii jaredii',
      scientific_name: "Jared's pepper-grass",
      taxon_group: 'Plant',
      status_code: 'G2G3T1T2S1S2',
    },
    lepidium_latipes_heckardii: {
      common_name: 'Lepidium latipes heckardii',
      scientific_name: "Heckard's pepper-grass",
      taxon_group: 'Plant',
      status_code: 'G4T1S1',
    },
    lepidurus_packardi: {
      common_name: 'Lepidurus packardi',
      scientific_name: 'vernal pool tadpole shrimp',
      taxon_group: 'Crustacean',
      status_code: 'G4S3S4',
    },
    leptosiphon_jepsonii: {
      common_name: 'Leptosiphon jepsonii',
      scientific_name: "Jepson's leptosiphon",
      taxon_group: 'Plant',
      status_code: 'G2G3S2S3',
    },
    leptosiphon_serrulatus: {
      common_name: 'Leptosiphon serrulatus',
      scientific_name: 'Madera leptosiphon',
      taxon_group: 'Plant',
      status_code: 'G3S3',
    },
    lilaeopsis_masonii: {
      common_name: 'Lilaeopsis masonii',
      scientific_name: "Mason's lilaeopsis",
      taxon_group: 'Plant',
      status_code: 'G2S2',
    },
    limnanthes_floccosa_californica: {
      common_name: 'Limnanthes floccosa californica',
      scientific_name: 'Butte County meadowfoam',
      taxon_group: 'Plant',
      status_code: 'G4T1S1',
    },
    limosella_australis: {
      common_name: 'Limosella australis',
      scientific_name: 'Delta mudwort',
      taxon_group: 'Plant',
      status_code: 'G4G5S2',
    },
    linderiella_occidentalis: {
      common_name: 'Linderiella occidentalis',
      scientific_name: 'California linderiella',
      taxon_group: 'Crustacean',
      status_code: 'G2G3S2S3',
    },
    lithobates_pipiens: {
      common_name: 'Lithobates pipiens',
      scientific_name: 'northern leopard frog',
      taxon_group: 'Amphibian',
      status_code: 'G5S2',
    },
    lytta_hoppingi: {
      common_name: 'Lytta hoppingi',
      scientific_name: "Hopping's blister beetle",
      taxon_group: 'Insect',
      status_code: 'G1G2S1S2',
    },
    lytta_moesta: {
      common_name: 'Lytta moesta',
      scientific_name: 'moestan blister beetle',
      taxon_group: 'Insect',
      status_code: 'G2S2',
    },
    lytta_molesta: {
      common_name: 'Lytta molesta',
      scientific_name: 'molestan blister beetle',
      taxon_group: 'Insect',
      status_code: 'G2S2',
    },
    lytta_morrisoni: {
      common_name: 'Lytta morrisoni',
      scientific_name: "Morrison's blister beetle",
      taxon_group: 'Insect',
      status_code: 'G1G2S1S2',
    },
    madia_radiata: {
      common_name: 'Madia radiata',
      scientific_name: 'showy golden madia',
      taxon_group: 'Plant',
      status_code: 'G3S3',
    },
    malacothamnus_aboriginum: {
      common_name: 'Malacothamnus aboriginum',
      scientific_name: 'Indian Valley bush-mallow',
      taxon_group: 'Plant',
      status_code: 'G3S3',
    },
    malacothamnus_hallii: {
      common_name: 'Malacothamnus hallii',
      scientific_name: "Hall's bush-mallow",
      taxon_group: 'Plant',
      status_code: 'G2S2',
    },
    masticophis_flagellum_ruddocki: {
      common_name: 'Masticophis flagellum ruddocki',
      scientific_name: 'San Joaquin coachwhip',
      taxon_group: 'Reptile',
      status_code: 'G5T2T3S2',
    },
    masticophis_lateralis_euryxanthus: {
      common_name: 'Masticophis lateralis euryxanthus',
      scientific_name: 'Alameda whipsnake',
      taxon_group: 'Reptile',
      status_code: 'G4T2S2',
    },
    melospiza_melodia_maillardi: {
      common_name: 'Melospiza melodia maillardi',
      scientific_name: 'song sparrow ("Modesto" population)',
      taxon_group: 'Bird',
      status_code: 'G5S3',
    },
    melospiza_melodia_maxillaris: {
      common_name: 'Melospiza melodia maxillaris',
      scientific_name: 'Suisun song sparrow',
      taxon_group: 'Bird',
      status_code: 'G5T3S3',
    },
    metapogon_hurdi: {
      common_name: 'Metapogon hurdi',
      scientific_name: "Hurd's metapogon robberfly",
      taxon_group: 'Insect',
      status_code: 'G1G2S1S2',
    },
    microseris_paludosa: {
      common_name: 'Microseris paludosa',
      scientific_name: 'marsh microseris',
      taxon_group: 'Plant',
      status_code: 'G2S2',
    },
    monadenia_mormonum_buttoni: {
      common_name: 'Monadenia mormonum buttoni',
      scientific_name: "Button's Sierra sideband",
      taxon_group: 'Mollusk',
      status_code: 'G2T1S1S2',
    },
    monardella_venosa: {
      common_name: 'Monardella venosa',
      scientific_name: 'veiny monardella',
      taxon_group: 'Plant',
      status_code: 'G1S1',
    },
    monolopia_congdonii: {
      common_name: 'Monolopia congdonii',
      scientific_name: 'San Joaquin woollythreads',
      taxon_group: 'Plant',
      status_code: 'G2S2',
    },
    myotis_volans: {
      common_name: 'Myotis volans',
      scientific_name: 'long-legged myotis',
      taxon_group: 'Mammal',
      status_code: 'G4G5S3',
    },
    nama_stenocarpa: {
      common_name: 'Nama stenocarpa',
      scientific_name: 'mud nama',
      taxon_group: 'Plant',
      status_code: 'G4G5S1S2',
    },
    navarretia_gowenii: {
      common_name: 'Navarretia gowenii',
      scientific_name: 'Lime Ridge navarretia',
      taxon_group: 'Plant',
      status_code: 'G1S1',
    },
    navarretia_leucocephala_bakeri: {
      common_name: 'Navarretia leucocephala bakeri',
      scientific_name: "Baker's navarretia",
      taxon_group: 'Plant',
      status_code: 'G4T2S2',
    },
    navarretia_myersii_myersii: {
      common_name: 'Navarretia myersii myersii',
      scientific_name: 'pincushion navarretia',
      taxon_group: 'Plant',
      status_code: 'G2T2S2',
    },
    navarretia_nigelliformis_radians: {
      common_name: 'Navarretia nigelliformis radians',
      scientific_name: 'shining navarretia',
      taxon_group: 'Plant',
      status_code: 'G4T2S2',
    },
    navarretia_paradoxiclara: {
      common_name: 'Navarretia paradoxiclara',
      scientific_name: "Patterson's navarretia",
      taxon_group: 'Plant',
      status_code: 'G2S2',
    },
    navarretia_prostrata: {
      common_name: 'Navarretia prostrata',
      scientific_name: 'prostrate vernal pool navarretia',
      taxon_group: 'Plant',
      status_code: 'G2S2',
    },
    navarretia_setiloba: {
      common_name: 'Navarretia setiloba',
      scientific_name: 'Piute Mountains navarretia',
      taxon_group: 'Plant',
      status_code: 'G2S2',
    },
    neostapfia_colusana: {
      common_name: 'Neostapfia colusana',
      scientific_name: 'Colusa grass',
      taxon_group: 'Plant',
      status_code: 'G1S1',
    },
    neotoma_fuscipes_riparia: {
      common_name: 'Neotoma fuscipes riparia',
      scientific_name: 'riparian (=San Joaquin Valley) woodrat',
      taxon_group: 'Mammal',
      status_code: 'G5T1QS1',
    },
    nyctinomops_macrotis: {
      common_name: 'Nyctinomops macrotis',
      scientific_name: 'big free-tailed bat',
      taxon_group: 'Mammal',
      status_code: 'G5S3',
    },
    oenothera_deltoides_howellii: {
      common_name: 'Oenothera deltoides howellii',
      scientific_name: 'Antioch Dunes evening-primrose',
      taxon_group: 'Plant',
      status_code: 'G5T1S1',
    },
    onychomys_torridus_tularensis: {
      common_name: 'Onychomys torridus tularensis',
      scientific_name: 'Tulare grasshopper mouse',
      taxon_group: 'Mammal',
      status_code: 'G5T1T2S1S2',
    },
    opuntia_basilaris_treleasei: {
      common_name: 'Opuntia basilaris treleasei',
      scientific_name: 'Bakersfield cactus',
      taxon_group: 'Plant',
      status_code: 'G5T1S1',
    },
    orcuttia_inaequalis: {
      common_name: 'Orcuttia inaequalis',
      scientific_name: 'San Joaquin Valley Orcutt grass',
      taxon_group: 'Plant',
      status_code: 'G1S1',
    },
    orcuttia_pilosa: {
      common_name: 'Orcuttia pilosa',
      scientific_name: 'hairy Orcutt grass',
      taxon_group: 'Plant',
      status_code: 'G1S1',
    },
    orcuttia_tenuis: {
      common_name: 'Orcuttia tenuis',
      scientific_name: 'slender Orcutt grass',
      taxon_group: 'Plant',
      status_code: 'G2S2',
    },
    orcuttia_viscida: {
      common_name: 'Orcuttia viscida',
      scientific_name: 'Sacramento Orcutt grass',
      taxon_group: 'Plant',
      status_code: 'G1S1',
    },
    pandion_haliaetus: {
      common_name: 'Pandion haliaetus',
      scientific_name: 'osprey',
      taxon_group: 'Bird',
      status_code: 'G5S4',
    },
    paronychia_ahartii: {
      common_name: 'Paronychia ahartii',
      scientific_name: "Ahart's paronychia",
      taxon_group: 'Plant',
      status_code: 'G3S3',
    },
    pelecanus_erythrorhynchos: {
      common_name: 'Pelecanus erythrorhynchos',
      scientific_name: 'American white pelican',
      taxon_group: 'Bird',
      status_code: 'G4S1S2',
    },
    perdita_scitula_antiochensis: {
      common_name: 'Perdita scitula antiochensis',
      scientific_name: 'Antioch andrenid bee',
      taxon_group: 'Insect',
      status_code: 'G1T1S1',
    },
    perognathus_inornatus: {
      common_name: 'Perognathus inornatus',
      scientific_name: 'San Joaquin Pocket Mouse',
      taxon_group: 'Mammal',
      status_code: 'G2G3S2S3',
    },
    phalacrocorax_auritus: {
      common_name: 'Phalacrocorax auritus',
      scientific_name: 'double-crested cormorant',
      taxon_group: 'Bird',
      status_code: 'G5S4',
    },
    phrynosoma_blainvillii: {
      common_name: 'Phrynosoma blainvillii',
      scientific_name: 'coast horned lizard',
      taxon_group: 'Reptile',
      status_code: 'G3G4S3S4',
    },
    plagiobothrys_hystriculus: {
      common_name: 'Plagiobothrys hystriculus',
      scientific_name: 'bearded popcornflower',
      taxon_group: 'Plant',
      status_code: 'G2S2',
    },
    plebulina_emigdionis: {
      common_name: 'Plebulina emigdionis',
      scientific_name: 'San Emigdio blue butterfly',
      taxon_group: 'Insect',
      status_code: 'G1G2S1S2',
    },
    plegadis_chihi: {
      common_name: 'Plegadis chihi',
      scientific_name: 'white-faced ibis',
      taxon_group: 'Bird',
      status_code: 'G5S3S4',
    },
    pooecetes_gramineus_affinis: {
      common_name: 'Pooecetes gramineus affinis',
      scientific_name: 'Oregon vesper sparrow',
      taxon_group: 'Bird',
      status_code: 'G5T3S2',
    },
    potamogeton_zosteriformis: {
      common_name: 'Potamogeton zosteriformis',
      scientific_name: 'eel-grass pondweed',
      taxon_group: 'Plant',
      status_code: 'G5S3',
    },
    progne_subis: {
      common_name: 'Progne subis',
      scientific_name: 'purple martin',
      taxon_group: 'Bird',
      status_code: 'G5S3',
    },
    protodufourea_zavortinki: {
      common_name: 'Protodufourea zavortinki',
      scientific_name: "Zavortink's protodufourea bee",
      taxon_group: 'Insect',
      status_code: 'G1S1',
    },
    pseudobahia_bahiifolia: {
      common_name: 'Pseudobahia bahiifolia',
      scientific_name: "Hartweg's golden sunburst",
      taxon_group: 'Plant',
      status_code: 'G2S2',
    },
    pseudobahia_peirsonii: {
      common_name: 'Pseudobahia peirsonii',
      scientific_name: 'San Joaquin adobe sunburst',
      taxon_group: 'Plant',
      status_code: 'G1S1',
    },
    puccinellia_simplex: {
      common_name: 'Puccinellia simplex',
      scientific_name: 'California alkali grass',
      taxon_group: 'Plant',
      status_code: 'G3S2',
    },
    pyrgulopsis_greggi: {
      common_name: 'Pyrgulopsis greggi',
      scientific_name: 'Kern River pyrg',
      taxon_group: 'Mollusk',
      status_code: 'G1S1',
    },
    rallus_obsoletus_obsoletus: {
      common_name: 'Rallus obsoletus obsoletus',
      scientific_name: "California Ridgway's rail",
      taxon_group: 'Bird',
      status_code: 'G5T1S1',
    },
    rana_boylii: {
      common_name: 'Rana boylii',
      scientific_name: 'foothill yellow-legged frog',
      taxon_group: 'Amphibian',
      status_code: 'G3S3',
    },
    rana_draytonii: {
      common_name: 'Rana draytonii',
      scientific_name: 'California red-legged frog',
      taxon_group: 'Amphibian',
      status_code: 'G2G3S2S3',
    },
    reithrodontomys_raviventris: {
      common_name: 'Reithrodontomys raviventris',
      scientific_name: 'salt-marsh harvest mouse',
      taxon_group: 'Mammal',
      status_code: 'G1G2S1S2',
    },
    rhynchospora_californica: {
      common_name: 'Rhynchospora californica',
      scientific_name: 'California beaked-rush',
      taxon_group: 'Plant',
      status_code: 'G1S1',
    },
    ribes_menziesii_ixoderme: {
      common_name: 'Ribes menziesii ixoderme',
      scientific_name: 'Aromatic canyon gooseberry',
      taxon_group: 'Plant',
      status_code: 'G4T2S2',
    },
    riparia_riparia: {
      common_name: 'Riparia riparia',
      scientific_name: 'bank swallow',
      taxon_group: 'Bird',
      status_code: 'G5S2',
    },
    sagittaria_sanfordii: {
      common_name: 'Sagittaria sanfordii',
      scientific_name: "Sanford's arrowhead",
      taxon_group: 'Plant',
      status_code: 'G3S3',
    },
    scutellaria_galericulata: {
      common_name: 'Scutellaria galericulata',
      scientific_name: 'marsh skullcap',
      taxon_group: 'Plant',
      status_code: 'G5S2',
    },
    scutellaria_lateriflora: {
      common_name: 'Scutellaria lateriflora',
      scientific_name: 'side-flowering skullcap',
      taxon_group: 'Plant',
      status_code: 'G5S2',
    },
    senecio_aphanactis: {
      common_name: 'Senecio aphanactis',
      scientific_name: 'chaparral ragwort',
      taxon_group: 'Plant',
      status_code: 'G3S2',
    },
    setophaga_petechia: {
      common_name: 'Setophaga petechia',
      scientific_name: 'yellow warbler',
      taxon_group: 'Bird',
      status_code: 'G5S3S4',
    },
    sidalcea_keckii: {
      common_name: 'Sidalcea keckii',
      scientific_name: "Keck's checkerbloom",
      taxon_group: 'Plant',
      status_code: 'G2S2',
    },
    sidalcea_robusta: {
      common_name: 'Sidalcea robusta',
      scientific_name: 'Butte County checkerbloom',
      taxon_group: 'Plant',
      status_code: 'G2S2',
    },
    sorex_ornatus_relictus: {
      common_name: 'Sorex ornatus relictus',
      scientific_name: 'Buena Vista Lake ornate shrew',
      taxon_group: 'Mammal',
      status_code: 'G5T1S1',
    },
    sorex_ornatus_sinuosus: {
      common_name: 'Sorex ornatus sinuosus',
      scientific_name: 'Suisun shrew',
      taxon_group: 'Mammal',
      status_code: 'G5T1T2QS1S2',
    },
    spea_hammondii: {
      common_name: 'Spea hammondii',
      scientific_name: 'western spadefoot',
      taxon_group: 'Amphibian',
      status_code: 'G3S3',
    },
    spergularia_macrotheca_longistyla: {
      common_name: 'Spergularia macrotheca longistyla',
      scientific_name: 'long-styled sand-spurrey',
      taxon_group: 'Plant',
      status_code: 'G5T2S2',
    },
    speyeria_callippe_callippe: {
      common_name: 'Speyeria callippe callippe',
      scientific_name: 'callippe silverspot butterfly',
      taxon_group: 'Insect',
      status_code: 'G5T1S1',
    },
    sphecodogastra_antiochensis: {
      common_name: 'Sphecodogastra antiochensis',
      scientific_name: 'Antioch Dunes halcitid bee',
      taxon_group: 'Insect',
      status_code: 'G1S1',
    },
    sphenopholis_obtusata: {
      common_name: 'Sphenopholis obtusata',
      scientific_name: 'prairie wedge grass',
      taxon_group: 'Plant',
      status_code: 'G5S2',
    },
    sternula_antillarum_browni: {
      common_name: 'Sternula antillarum browni',
      scientific_name: 'California least tern',
      taxon_group: 'Bird',
      status_code: 'G4T2T3QS2',
    },
    streptanthus_insignis_lyonii: {
      common_name: 'Streptanthus insignis lyonii',
      scientific_name: 'Arburua Ranch jewelflower',
      taxon_group: 'Plant',
      status_code: 'G3G4T2S2',
    },
    stuckenia_filiformis_alpina: {
      common_name: 'Stuckenia filiformis alpina',
      scientific_name: 'slender-leaved pondweed',
      taxon_group: 'Plant',
      status_code: 'G5T5S2S3',
    },
    stylocline_citroleum: {
      common_name: 'Stylocline citroleum',
      scientific_name: 'oil neststraw',
      taxon_group: 'Plant',
      status_code: 'G3S3',
    },
    stylocline_masonii: {
      common_name: 'Stylocline masonii',
      scientific_name: "Mason's neststraw",
      taxon_group: 'Plant',
      status_code: 'G1S1',
    },
    sylvilagus_bachmani_riparius: {
      common_name: 'Sylvilagus bachmani riparius',
      scientific_name: 'riparian brush rabbit',
      taxon_group: 'Mammal',
      status_code: 'G5T1S1',
    },
    symphyotrichum_lentum: {
      common_name: 'Symphyotrichum lentum',
      scientific_name: 'Suisun Marsh aster',
      taxon_group: 'Plant',
      status_code: 'G2S2',
    },
    talanites_moodyae: {
      common_name: 'Talanites moodyae',
      scientific_name: "Moody's gnaphosid spider",
      taxon_group: 'Arachnid',
      status_code: 'G1G2S1S2',
    },
    taxidea_taxus: {
      common_name: 'Taxidea taxus',
      scientific_name: 'American badger',
      taxon_group: 'Mammal',
      status_code: 'G5S3',
    },
    thamnophis_gigas: {
      common_name: 'Thamnophis gigas',
      scientific_name: 'giant gartersnake',
      taxon_group: 'Reptile',
      status_code: 'G2S2',
    },
    thamnophis_hammondii: {
      common_name: 'Thamnophis hammondii',
      scientific_name: 'two-striped gartersnake',
      taxon_group: 'Reptile',
      status_code: 'G4S3S4',
    },
    tortula_californica: {
      common_name: 'Tortula californica',
      scientific_name: 'California screw-moss',
      taxon_group: 'Plant',
      status_code: 'G2G3S2S3',
    },
    toxostoma_lecontei: {
      common_name: 'Toxostoma lecontei',
      scientific_name: "Le Conte's thrasher",
      taxon_group: 'Bird',
      status_code: 'G4S3',
    },
    trichocoronis_wrightii_wrightii: {
      common_name: 'Trichocoronis wrightii wrightii',
      scientific_name: "Wright's trichocoronis",
      taxon_group: 'Plant',
      status_code: 'G4T3S1',
    },
    trifolium_amoenum: {
      common_name: 'Trifolium amoenum',
      scientific_name: 'two-fork clover',
      taxon_group: 'Plant',
      status_code: 'G1S1',
    },
    trifolium_hydrophilum: {
      common_name: 'Trifolium hydrophilum',
      scientific_name: 'saline clover',
      taxon_group: 'Plant',
      status_code: 'G2S2',
    },
    trifolium_jokerstii: {
      common_name: 'Trifolium jokerstii',
      scientific_name: 'Butte County golden clover',
      taxon_group: 'Plant',
      status_code: 'G2S2',
    },
    trigonoscuta: {
      common_name: 'Trigonoscuta',
      scientific_name: "Doyen's trigonoscuta dune weevil",
      taxon_group: 'Insect',
      status_code: 'G1QS1',
    },
    tropidocarpum_californicum: {
      common_name: 'Tropidocarpum californicum',
      scientific_name: 'Kings gold',
      taxon_group: 'Plant',
      status_code: 'G1S1',
    },
    tropidocarpum_capparideum: {
      common_name: 'Tropidocarpum capparideum',
      scientific_name: 'caper-fruited tropidocarpum',
      taxon_group: 'Plant',
      status_code: 'G1S1',
    },
    tuctoria_greenei: {
      common_name: 'Tuctoria greenei',
      scientific_name: "Greene's tuctoria",
      taxon_group: 'Plant',
      status_code: 'G1S1',
    },
    tuctoria_mucronata: {
      common_name: 'Tuctoria mucronata',
      scientific_name: "Crampton's tuctoria or Solano grass",
      taxon_group: 'Plant',
      status_code: 'G1S1',
    },
    vireo_bellii_pusillus: {
      common_name: 'Vireo bellii pusillus',
      scientific_name: "least Bell's vireo",
      taxon_group: 'Bird',
      status_code: 'G5T2S2',
    },
    vulpes_macrotis_mutica: {
      common_name: 'Vulpes macrotis mutica',
      scientific_name: 'San Joaquin kit fox',
      taxon_group: 'Mammal',
      status_code: 'G4T2S2',
    },
    vulpes_vulpes_patwin: {
      common_name: 'Vulpes vulpes patwin',
      scientific_name: 'Sacramento Valley red fox',
      taxon_group: 'Mammal',
      status_code: 'G5TNRS2',
    },
    wolffia_brasiliensis: {
      common_name: 'Wolffia brasiliensis',
      scientific_name: 'Brazilian watermeal',
      taxon_group: 'Plant',
      status_code: 'G5S2',
    },
    xanthocephalus_xanthocephalus: {
      common_name: 'Xanthocephalus xanthocephalus',
      scientific_name: 'yellow-headed blackbird',
      taxon_group: 'Bird',
      status_code: 'G5S3',
    },
    xantusia_vigilis_sierrae: {
      common_name: 'Xantusia vigilis sierrae',
      scientific_name: 'Sierra night lizard',
      taxon_group: 'Reptile',
      status_code: 'G5T1S1',
    },
  },
}

const FULL_SPECIES_LIST = {
  mojave: [
    'berberis_harrisoniana',
    'eriastrum_tracyi',
    'batrachoseps_campi',
    'schoenus_nigricans',
    'allium_atrorubens_var_atrorubens',
    'setophaga_petechia_sonorana',
    'scutellaria_bolanderi_ssp_austromontana',
    'abutilon_parvulum',
    'rallus_obsoletus_yumanensis',
    'eriodictyon_angustifolium',
    'perognathus_alticolus_inexpectatus',
    'oenothera_cavernae',
    'plagiobothrys_parishii',
    'astragalus_platytropis',
    'penstemon_calcareus',
    'piranga_flava',
    'eriogonum_contiguum',
    'cladium_californicum',
    'stipa_arida',
    'astragalus_bernardinus',
    'heloderma_suspectum_cinctum',
    'hydromantes_platycephalus',
    'phacelia_barnebyana',
    'sidalcea_covillei',
    'phacelia_parishii',
    'enceliopsis_nudicaulis_var_corrugata',
    'physocarpus_alternans',
    'icteria_virens',
    'nemacladus_secundiflorus_var_robbinsii',
    'eriogonum_hoffmannii_var_hoffmannii',
    'microtus_californicus_scirpensis',
    'cryptantha_clokeyi',
    'lithospermum_incisum',
    'ivesia_jaegeri',
    'lupinus_holmgrenianus',
    'paranomada_californica',
    'atriplex_argentea_var_longitrichoma',
    'erigeron_calvus',
    'crotalus_ruber',
    'jaffueliobryum_raui',
    'boechera_parishii',
    'polioptila_californica_californica',
    'myotis_evotis',
    'navarretia_peninsularis',
    'cuniculotinus_gramineus',
    'bombus_occidentalis',
    'oenothera_longissima',
    'ovis_canadensis_sierrae',
    'ensatina_eschscholtzii_croceator',
    'phacelia_perityloides_var_jaegeri',
    'mentzelia_tricuspis',
    'navarretia_fossalis',
    'puccinellia_simplex',
    'colubrina_californica',
    'polyphylla_erratica',
    'boechera_dispar',
    'androstephium_breviflorum',
    'castela_emoryi',
    'mentzelia_tridentata',
    'eriogonum_umbellatum_var_juniporinum',
    'pyrgulopsis_wongi',
    'piranga_rubra',
    'menodora_spinescens_var_mohavensis',
    'echinocereus_engelmannii_var_howei',
    'cymopterus_ripleyi_var_saniculoides',
    'eriogonum_hoffmannii_var_robustius',
    'rhopalolemma_robertsi',
    'pandion_haliaetus',
    'erythranthe_rhodopetra',
    'acleisanthes_nevadensis',
    'allium_nevadense',
    'monardella_eremicola',
    'myiarchus_tyrannulus',
    'lasthenia_glabrata_ssp_coulteri',
    'loeflingia_squarrosa_var_artemisiarum',
    'assiminea_infima',
    'physaria_chambersii',
    'hyalella_muerta',
    'toxostoma_lecontei',
    'lupinus_magnificus_var_magnificus',
    'tortella_alpicola',
    'oreonana_vestita',
    'heuchera_parishii',
    'muhlenbergia_pauciflora',
    'gulo_gulo',
    'batrachoseps_stebbinsi',
    'dipodomys_merriami_parvus',
    'woodsia_plummerae',
    'eremarionta_rowelli_bakerensis',
    'monardella_boydii',
    'penstemon_albomarginatus',
    'arctomecon_merriamii',
    'cyprinodon_radiosus',
    'melanerpes_uropygialis',
    'pelocoris_shoshone',
    'nemacaulis_denudata_var_gracilis',
    'astragalus_sabulonum',
    'opuntia_wigginsii',
    'chorizanthe_parryi_var_parryi',
    'populus_angustifolia',
    'mentzelia_torreyi',
    'elgaria_panamintina',
    'rana_muscosa',
    'tripterocalyx_micranthus',
    'nama_dichotoma_var_dichotoma',
    'berberis_fremontii',
    'delphinium_recurvatum',
    'opuntia_basilaris_var_treleasei',
    'astragalus_hornii_var_hornii',
    'melozone_crissalis_eremophilus',
    'siphateles_bicolor_mohavensis',
    'linum_puberulum',
    'thysanocarpus_rigidus',
    'astrolepis_cochisensis_ssp_cochisensis',
    'plebulina_emigdionis',
    'sphenopholis_obtusata',
    'microtus_californicus_stephensi',
    'astragalus_allochrous_var_playanus',
    'eremothera_boothii_ssp_intermedia',
    'glossopetalon_pungens',
    'mentzelia_polita',
    'eumops_perotis_californicus',
    'diadophis_punctatus_regalis',
    'astragalus_cimae_var_cimae',
    'falco_columbarius',
    'ensatina_klauberi',
    'astragalus_insularis_var_harwoodii',
    'fimbristylis_thermalis',
    'chorizanthe_xanti_var_leucotheca',
    'onychomys_torridus_tularensis',
    'pyrocephalus_rubinus',
    'peteria_thompsoniae',
    'catostomus_latipinnis',
    'mentzelia_puberula',
    'cyprinodon_nevadensis_amargosae',
    'penstemon_fruticiformis_var_amargosae',
    'eschscholzia_minutiflora_ssp_twisselmannii',
    'agelaius_tricolor',
    'oreothlypis_luciae',
    'chloropyron_tecopense',
    'myriopteris_wootonii',
    'digitaria_californica_var_californica',
    'vireo_vicinior',
    'sphaeralcea_rusbyi_var_eremicola',
    'chaetodipus_fallax_pallidus',
    'buteo_swainsoni',
    'polygala_heterorhyncha',
    'phrynosoma_blainvillii',
    'munroa_squarrosa',
    'astragalus_lentiginosus_var_antonius',
    'ammodramus_savannarum',
    'muhlenbergia_alopecuroides',
    'athene_cunicularia',
    'psorothamnus_fremontii_var_attenuatus',
    'enneapogon_desvauxii',
    'elymus_salina',
    'aimophila_ruficeps_canescens',
    'rhinichthys_osculus_ssp_3',
    'myotis_thysanodes',
    'imperata_brevifolia',
    'opuntia_basilaris_var_brachyclada',
    'lasiurus_xanthinus',
    'erigeron_oxyphyllus',
    'phacelia_pulchella_var_gooddingii',
    'nyctinomops_macrotis',
    'cordylanthus_parviflorus',
    'teucrium_glandulosum',
    'microtus_californicus_mohavensis',
    'enceliopsis_covillei',
    'ixobrychus_exilis',
    'vireo_bellii_arizonae',
    'eriophyllum_mohavense',
    'astragalus_lentiginosus_var_sesquimetralis',
    'dudleya_abramsii_ssp_affinis',
    'ipnobius_robustus',
    'bouteloua_trifida',
    'phacelia_inyoensis',
    'muhlenbergia_arsenei',
    'astragalus_funereus',
    'gymnogyps_californianus',
    'pediomelum_castoreum',
    'perognathus_longimembris_bangsi',
    'pholistoma_auritum_var_arizonicum',
    'sisyrinchium_funereum',
    'juncus_interior',
    'taxidea_taxus',
    'polygala_intermontana',
    'dipodomys_panamintinus_argusensis',
    'scleropogon_brevifolius',
    'astragalus_tricarinatus',
    'texella_shoshone',
    'juncus_nodosus',
    'grimmia_vaginulata',
    'crepis_runcinata',
    'circus_cyaneus',
    'eremogone_congesta_var_charlestonensis',
    'poa_lettermanii',
    'salvia_greatae',
    'penstemon_utahensis',
    'dudleya_saxosa_ssp_saxosa',
    'layia_heterotricha',
    'euderma_maculatum',
    'chrysothamnus_greenei',
    'eriogonum_bifurcatum',
    'asio_flammeus',
    'erigeron_parishii',
    'ammopelmatus_kelsoensis',
    'diplacus_parryi',
    'oryctes_nevadensis',
    'charadrius_montanus',
    'petrophytum_caespitosum_ssp_acuminatum',
    'neotamias_panamintinus_acrus',
    'ammospermophilus_nelsoni',
    'hymenoxys_odorata',
    'boechera_shockleyi',
    'deinandra_arida',
    'vireo_bellii_pusillus',
    'microtus_californicus_vallicola',
    'jaffueliobryum_wrightii',
    'petalonyx_thurberi_ssp_gilmanii',
    'acmispon_argyraeus_var_notitius',
    'sanvitalia_abertii',
    'cyprinodon_nevadensis_nevadensis',
    'eriogonum_ovalifolium_var_vineum',
    'haliaeetus_leucocephalus',
    'monardella_robisonii',
    'blepharidachne_kingii',
    'corynorhinus_townsendii',
    'calochortus_excavatus',
    'rana_draytonii',
    'hackelia_sharsmithii',
    'lupinus_magnificus_var_hesperius',
    'carnegiea_gigantea',
    'micrathene_whitneyi',
    'falco_mexicanus',
    'grindelia_fraxinipratensis',
    'opuntia_xcurvispina',
    'anniella_campi',
    'linanthus_concinnus',
    'ambrysus_funebris',
    'sigmodon_arizonae_plenus',
    'ayenia_compacta',
    'gopherus_agassizii',
    'oreothlypis_virginiae',
    'myotis_volans',
    'artemisiospiza_belli_belli',
    'ivesia_arizonica_var_arizonica',
    'ranunculus_hydrocharoides',
    'galium_wrightii',
    'sclerocactus_johnsonii',
    'colaptes_chrysoides',
    'nyctinomops_femorosaccus',
    'coryphantha_alversonii',
    'bombus_crotchii',
    'empidonax_traillii_extimus',
    'anaxyrus_californicus',
    'castilleja_lasiorhyncha',
    'agabus_rumppi',
    'coryphantha_chlorantha',
    'coryphantha_vivipara_var_rosea',
    'antrozous_pallidus',
    'nitrophila_mohavensis',
    'xerospermophilus_mohavensis',
    'thelypodium_integrifolium_ssp_complanatum',
    'phacelia_mustelina',
    'aquila_chrysaetos',
    'linanthus_maculatus_ssp_maculatus',
    'toxostoma_crissale',
    'pellaea_truncata',
    'erigeron_uncialis_var_uncialis',
    'anniella_pulchra',
    'miloderes_nelsoni',
    'monarda_pectinata',
    'penstemon_pseudospectabilis_ssp_pseudospectabilis',
    'phacelia_coerulea',
    'oliarces_clara',
    'polygala_acanthoclada',
    'sarcobatus_baileyi',
    'uma_scoparia',
    'galium_hilendiae_ssp_kingstonense',
    'eremothera_boothii_ssp_boothii',
    'coccyzus_americanus_occidentalis',
    'matelea_parvifolia',
    'prunus_eremophila',
    'maurandella_antirrhiniflora',
    'astragalus_tidestromii',
    'atriplex_parishii',
    'buteo_regalis',
    'lilium_parryi',
    'menodora_scabra_var_scabra',
    'navarretia_setiloba',
    'calochortus_striatus',
    'aspidoscelis_tigris_stejnegeri',
    'delphinium_scaposum',
    'physalis_lobata',
    'thamnophis_hammondii',
    'myotis_velifer',
    'cardinalis_cardinalis',
    'charadrius_alexandrinus_nivosus',
    'euphorbia_parryi',
    'cymopterus_gilmanii',
    'euphorbia_abramsiana',
    'penstemon_thompsoniae',
    'ageratina_herbacea',
    'dodecahema_leptoceras',
    'diplacus_mohavensis',
    'hedeoma_drummondii',
    'cymopterus_deserticola',
    'mirabilis_coccinea',
    'plagiobothrys_salsus',
    'panicum_hirticaule_ssp_hirticaule',
    'texella_kokoweef',
    'arizona_elegans_occidentalis',
    'siphateles_bicolor_snyderi',
    'mammillaria_grahamii_var_grahamii',
    'astragalus_jaegerianus',
    'acmispon_argyraeus_var_multicaulis',
    'perognathus_parvus_xanthonotus',
    'ivesia_patellifera',
    'deinandra_mohavensis',
    'aliciella_triodon',
    'euphorbia_jaegeri',
    'catostomus_fumeiventris',
    'astragalus_preussii_var_preussii',
    'california_macrophylla',
    'ditaxis_claryana',
    'orobanche_valida_ssp_valida',
    'puccinellia_parishii',
    'eremophila_alpestris_actia',
    'calochortus_palmeri_var_palmeri',
    'onychomys_torridus_ramona',
    'erioneuron_pilosum',
    'frasera_albomarginata_var_albomarginata',
    'hyalella_sandra',
    'linanthus_bernardinus',
    'penstemon_stephensii',
    'agave_utahensis_var_eborispina',
    'rhinichthys_osculus_ssp_1',
    'boechera_lincolnensis',
    'aliciella_ripleyi',
    'astragalus_nyensis',
    'penstemon_bicolor_ssp_roseus',
    'gilmania_luteola',
    'johanneshowellia_puberula',
    'accipiter_cooperii',
    'muhlenbergia_fragilis',
    'frasera_albomarginata_var_induta',
    'toxostoma_bendirei',
    'trifolium_dedeckerae',
    'galium_proliferum',
    'eriastrum_harwoodii',
    'cirsium_arizonicum_var_tenuisectum',
    'chaetadelpha_wheeleri',
    'astragalus_albens',
    'cymopterus_multinervatus',
    'astragalus_preussii_var_laxiflorus',
    'grusonia_parishii',
    'euphorbia_exstipulata_var_exstipulata',
    'junco_hyemalis_caniceps',
    'euphorbia_platysperma',
    'muhlenbergia_appressa',
    'hubbardia_shoshonensis',
    'helminthoglypta_greggi',
    'cryptochia_denningi',
    'symphyotrichum_defoliatum',
    'bahia_neomexicana',
    'lanius_ludovicianus',
    'wislizenia_refracta_ssp_refracta',
    'polioptila_melanura',
    'asclepias_nyctaginifolia',
    'mentzelia_pterosperma',
    'stipa_divaricata',
    'emys_marmorata',
    'ephedra_torreyana',
    'acanthoscyphus_parishii_var_goodmaniana',
    'eriogonum_thornei',
    'eriogonum_gilmanii',
    'phacelia_nashiana',
    'saltugilia_latimeri',
    'galium_hilendiae_ssp_carneum',
    'perognathus_inornatus',
    'macrotus_californicus',
    'eriogonum_intrafractum',
    'microcylloepus_formicoideus',
    'asio_otus',
    'belostoma_saratogae',
    'bombus_morrisoni',
    'atriplex_argentea_var_hillmanii',
    'setophaga_petechia',
    'robinia_neomexicana',
    'ovis_canadensis_nelsoni',
    'eriastrum_rosamondense',
    'erigeron_utahensis',
    'hymenopappus_filifolius_var_eriopodus',
    'phacelia_anelsonii',
    'senna_covesii',
  ],
  modoc: [
    'astragalus_anxius',
    'chasmistes_brevirostris',
    'deltistes_luxatus',
    'pacifastacus_fortis',
    'pisidium_ultramontanum',
    'potentilla_basaltica',
    'pyrgulopsis_archimedis',
    'pyrgulopsis_cinerana',
    'pyrgulopsis_falciglans',
    'pyrgulopsis_lasseni',
    'tuctoria_greenei',
    'agelaius_tricolor',
    'bombus_occidentalis',
    'plagiobothrys_salsus',
    'astragalus_lemmonii',
    'catostomus_microps',
    'cottus_asperrimus',
    'gratiola_heterosepala',
    'ivesia_paniculata',
    'juga_acutifilosa',
    'lanx_patelloides',
    'lomatium_roseanum',
    'northern_interior_cypress_forest',
    'orcuttia_tenuis',
    'phacelia_inundata',
    'pyrgulopsis_eremica',
    'allium_punctum',
    'bombus_crotchii',
    'bombus_morrisoni',
    'buxbaumia_viridis',
    'carex_atherodes',
    'catostomus_occidentalis_lacusanserinus',
    'collomia_tenella',
    'coturnicops_noveboracensis',
    'crataegus_castlegarensis',
    'cryptantha_celosioides',
    'empidonax_traillii',
    'entosphenus_tridentatus_ssp_1',
    'gonidea_angulata',
    'gulo_gulo',
    'ivesia_baileyi_var_beneolens',
    'lathyrus_rigidus',
    'lomatium_grayi',
    'lysimachia_thyrsiflora',
    'margaritifera_falcata',
    'oncorhynchus_mykiss_aquilarum',
    'osmorhiza_depauperata',
    'pelecanus_erythrorhynchos',
    'penstemon_janishiae',
    'polyctenium_williamsiae',
    'pyrgulopsis_gibba',
    'ranunculus_macounii',
    'riella_americana',
    'rorippa_columbiae',
    'salix_bebbiana',
    'saxifraga_cespitosa',
    'siphateles_bicolor_ssp_1',
    'solidago_lepida_var_salebrosa',
    'strix_nebulosa',
    'thelypodium_howellii_ssp_howellii',
    'triteleia_grandiflora',
    'valeriana_occidentalis',
    'vulpes_vulpes_necator',
    'astragalus_agrestis',
    'astragalus_pulsiferae_var_pulsiferae',
    'atriplex_gardneri_var_falcata',
    'balsamorhiza_serrata',
    'betula_glandulosa',
    'botrychium_ascendens',
    'botrychium_lunaria',
    'botrychium_montanum',
    'botrychium_pinnatum',
    'carex_comosa',
    'carex_sheldonii',
    'carex_vallicola',
    'chaetura_vauxi',
    'charadrius_alexandrinus_nivosus',
    'chlidonias_niger',
    'claytonia_megarhiza',
    'cordylanthus_capitatus',
    'corynorhinus_townsendii',
    'dalea_ornata',
    'diplacus_cusickioides',
    'drosera_anglica',
    'eriogonum_umbellatum_var_glaberrimum',
    'erythranthe_inflatula',
    'galium_serpenticum_ssp_warnerense',
    'geum_aleppicum',
    'gila_coerulea',
    'grus_canadensis_tabida',
    'heteranthera_dubia',
    'hymenoxys_lemmonii',
    'lavinia_symmetricus_mitrulus',
    'lithobates_pipiens',
    'loeflingia_squarrosa_var_artemisiarum',
    'lomatium_hendersonii',
    'lomatium_ravenii',
    'lupinus_latifolius_var_barbatus',
    'lupinus_pusillus_var_intermontanus',
    'lupinus_uncialis',
    'mertensia_cusickii',
    'mertensia_longiflora',
    'mertensia_oblongifolia_var_amoena',
    'ochotona_princeps',
    'ochotona_princeps_schisticeps',
    'oncorhynchus_mykiss_ssp_1',
    'orthotrichum_holzingeri',
    'ovis_canadensis_sierrae',
    'pedicularis_centranthera',
    'picoides_arcticus',
    'potamogeton_epihydrus',
    'potentilla_newberryi',
    'ribes_hudsonianum_var_petiolare',
    'riparia_riparia',
    'scutellaria_galericulata',
    'silene_oregana',
    'siphateles_bicolor_thalassina',
    'stanleya_viridiflora',
    'stellaria_longifolia',
    'stipa_exigua',
    'suaeda_occidentalis',
    'trifolium_gymnocarpon_ssp_plummerae',
    'eriastrum_tracyi',
    'eriogonum_prociduum',
    'juncus_luciensis',
    'mylopharodon_conocephalus',
    'penstemon_sudans',
    'pohlia_tundrae',
    'accipiter_gentilis',
    'alisma_gramineum',
    'ambystoma_macrodactylum_sigillatum',
    'antrozous_pallidus',
    'aquila_chrysaetos',
    'arnica_fulgens',
    'asio_flammeus',
    'asio_otus',
    'athene_cunicularia',
    'aythya_americana',
    'botrychium_crenulatum',
    'botrychium_minganense',
    'brachylagus_idahoensis',
    'buteo_regalis',
    'calochortus_longebarbatus_var_longebarbatus',
    'carex_limosa',
    'carex_petasata',
    'circus_cyaneus',
    'crepis_runcinata',
    'delphinium_stachydeum',
    'dimeresia_howellii',
    'downingia_laeta',
    'erethizon_dorsatum',
    'galium_glabrescens_ssp_modocense',
    'haliaeetus_leucocephalus',
    'lepus_townsendii_townsendii',
    'lomatium_foeniculaceum_ssp_macdougalii',
    'meesia_uliginosa',
    'mertensia_oblongifolia_var_oblongifolia',
    'micromonolepis_pusilla',
    'myotis_evotis',
    'myotis_thysanodes',
    'myotis_volans',
    'nemophila_breviflora',
    'phacelia_sericea_var_ciliosa',
    'phlox_muscoides',
    'plegadis_chihi',
    'polygala_subspinosa',
    'polygonum_polygaloides_ssp_esotericum',
    'potamogeton_zosteriformis',
    'progne_subis',
    'rumex_venosus',
    'setophaga_petechia',
    'stachys_pilosa',
    'stenotus_lanuginosus_var_lanuginosus',
    'stuckenia_filiformis_ssp_alpina',
    'synthyris_missurica_ssp_missurica',
    'taxidea_taxus',
    'utricularia_intermedia',
    'xanthocephalus_xanthocephalus',
    'antilocapra_americana',
    'charadrius_nivosus',
    'contopus_cooperi',
    'falco_mexicanus',
    'lanius_ludovicianus',
    'larus_californicus',
    'pandion_haliaetus',
    'phalacrocorax_auritus',
    'rana_pretios',
  ],
  greatvalley: [
    'accipiter_cooperii',
    'actinemys_marmorata',
    'aegialia_concinna',
    'agelaius_tricolor',
    'agrostis_hendersonii',
    'allium_howellii_sanbenitense',
    'allium_jepsonii',
    'ambystoma_californiense',
    'ammodramus_savannarum',
    'ammospermophilus_nelsoni',
    'amsinckia_grandiflora',
    'amsinckia_lunaris',
    'andrena_blennospermatis',
    'andrena_macswaini',
    'andrena_subapasta',
    'anniella',
    'anniella_alexanderae',
    'anniella_grinnelli',
    'anniella_pulchra',
    'anniella_stebbinsi',
    'anomobryum_julaceum',
    'anser_albifrons_elgasi',
    'anthicus_antiochensis',
    'anthicus_sacramento',
    'antigone_canadensis_tabida',
    'antilocapra_americana',
    'antrozous_pallidus',
    'apodemia_mormo_langei',
    'aquila_chrysaetos',
    'arctostaphylos_auriculata',
    'arctostaphylos_manzanita_laevigata',
    'arctostaphylos_myrtifolia',
    'arizona_elegans_occidentalis',
    'asio_flammeus',
    'asio_otus',
    'astragalus_hornii_hornii',
    'astragalus_rattanii_jepsonianus',
    'astragalus_tener_ferrisiae',
    'astragalus_tener_tener',
    'athene_cunicularia',
    'atriplex_cordulata_cordulata',
    'atriplex_cordulata_erecticaulis',
    'atriplex_coronata_vallicola',
    'atriplex_depressa',
    'atriplex_minuscula',
    'atriplex_persistens',
    'atriplex_subtilis',
    'aythya_americana',
    'balsamorhiza_macrolepis',
    'banksula_rudolphi',
    'batrachoseps_stebbinsi',
    'blepharizonia_plumosa',
    'bombus_caliginosus',
    'bombus_crotchii',
    'bombus_morrisoni',
    'bombus_occidentalis',
    'branchinecta_conservatio',
    'branchinecta_longiantenna',
    'branchinecta_lynchi',
    'branchinecta_mesovallensis',
    'branta_hutchinsii_leucopareia',
    'brasenia_schreberi',
    'brodiaea_insignis',
    'brodiaea_pallida',
    'buteo_regalis',
    'buteo_swainsoni',
    'calicina_breva',
    'calicina_macula',
    'calicina_piedra',
    'calochortus_palmeri_palmeri',
    'calochortus_pulchellus',
    'calochortus_striatus',
    'calycadenia_hooveri',
    'campanula_exigua',
    'campanula_wilkinsiana',
    'campylopodiella_stenocarpa',
    'cardamine_pachystigma_dissectifolia',
    'carex_comosa',
    'carpenteria_californica',
    'castilleja_campestris_succulenta',
    'castilleja_rubicundula_rubicundula',
    'caulanthus_californicus',
    'caulanthus_lemmonii',
    'centromadia_parryi_congdonii',
    'centromadia_parryi_parryi',
    'ceratochrysis_menkei',
    'charadrius_alexandrinus_nivosus',
    'charadrius_montanus',
    'charadrius_nivosus',
    'chlidonias_niger',
    'chloropyron_molle_hispidum',
    'chloropyron_molle_molle',
    'chloropyron_palmatum',
    'chrysis_tularensis',
    'cicindela_tranquebarica',
    'cicuta_maculata_bolanderi',
    'circus_hudsonius',
    'cirsium_crassicaule',
    'cirsium_hydrophilum_hydrophilum',
    'clarkia_gracilis_albicaulis',
    'clarkia_rostrata',
    'clarkia_springvillensis',
    'clarkia_tembloriensis_calientensis',
    'coccyzus_americanus',
    'coccyzus_americanus_occidentalis',
    'coelus_gracilis',
    'corynorhinus_townsendii',
    'coturnicops_noveboracensis',
    'crocanthemum_suffrutescens',
    'cryptantha_crinita',
    'cryptantha_mariposae',
    'cuscuta_jepsonii',
    'cuscuta_obtusiflora_glandulosa',
    'danaus_plexippus',
    'deinandra_arida',
    'deinandra_halliana',
    'delphinium_californicum_interius',
    'delphinium_purpusii',
    'delphinium_recurvatum',
    'dendrocygna_bicolor',
    'desmocerus_californicus_dimorphus',
    'diadophis_punctatus_modestus',
    'diplacus_pictus',
    'dipodomys_agilis',
    'dipodomys_californicus_eximius',
    'dipodomys_heermanni',
    'dipodomys_heermanni_dixoni',
    'dipodomys_ingens',
    'dipodomys_nitratoides_brevinasus',
    'dipodomys_nitratoides_exilis',
    'dipodomys_nitratoides_nitratoides',
    'downingia_pusilla',
    'dumontia_oregonensis',
    'efferia_antiochi',
    'elanus_leucurus',
    'elaphrus_viridis',
    'empidonax_traillii',
    'ensatina_eschscholtzii_croceater',
    'eremalche_parryi_kernensis',
    'eremophila_alpestris_actia',
    'erethizon_dorsatum',
    'eriastrum_tracyi',
    'eriogonum_apricum_apricum',
    'eriogonum_apricum_prostratum',
    'eriogonum_luteolum_caninum',
    'eriogonum_nudum_psychicola',
    'eriogonum_temblorense',
    'eriogonum_truncatum',
    'eriophyllum_lanatum_hallii',
    'eryngium_jepsonii',
    'eryngium_pinnatisectum',
    'eryngium_racemosum',
    'eryngium_spinosepalum',
    'erysimum_capitatum_angustatum',
    'erythranthe_marmorata',
    'eschscholzia_lemmonii_kernensis',
    'eschscholzia_rhombipetala',
    'eucerceris_ruficeps',
    'euderma_maculatum',
    'eumops_perotis_californicus',
    'euphorbia_hooveri',
    'euphorbia_ocellata_rattanii',
    'extriplex_joaquinana',
    'falco_columbarius',
    'falco_mexicanus',
    'falco_peregrinus_anatum',
    'fremontodendron_decumbens',
    'fritillaria_liliacea',
    'fritillaria_pluriflora',
    'fritillaria_striata',
    'gambelia_sila',
    'geothlypis_trichas_sinuosa',
    'gratiola_heterosepala',
    'gymnogyps_californianus',
    'haliaeetus_leucocephalus',
    'helianthella_castanea',
    'helianthus_winteri',
    'helminthoglypta_callistoderma',
    'helminthoglypta_nickliniana_bridgesi',
    'hesperolinon_breweri',
    'heteranthera_dubia',
    'heterotheca_shevockii',
    'hibiscus_lasiocarpos_occidentalis',
    'horkelia_parryi',
    'hydrochara_rickseckeri',
    'hygrotus_curvipes',
    'icteria_virens',
    'idiostatus_middlekauffi',
    'imperata_brevifolia',
    'isocoma_arguta',
    'ixobrychus_exilis',
    'juglans_hindsii',
    'juncus_leiospermus_ahartii',
    'juncus_leiospermus_leiospermus',
    'lagophylla_dichotoma',
    'lanius_ludovicianus',
    'lasiurus_blossevillii',
    'lasthenia_conjugens',
    'lasthenia_glabrata_coulteri',
    'laterallus_jamaicensis_coturniculus',
    'lathyrus_jepsonii_jepsonii',
    'layia_heterotricha',
    'layia_leucopappa',
    'layia_munzii',
    'layia_septentrionalis',
    'legenere_limosa',
    'lepidium_jaredii_album',
    'lepidium_jaredii_jaredii',
    'lepidium_latipes_heckardii',
    'lepidurus_packardi',
    'leptosiphon_jepsonii',
    'leptosiphon_serrulatus',
    'lilaeopsis_masonii',
    'limnanthes_floccosa_californica',
    'limosella_australis',
    'linderiella_occidentalis',
    'lithobates_pipiens',
    'lytta_hoppingi',
    'lytta_moesta',
    'lytta_molesta',
    'lytta_morrisoni',
    'madia_radiata',
    'malacothamnus_aboriginum',
    'malacothamnus_hallii',
    'masticophis_flagellum_ruddocki',
    'masticophis_lateralis_euryxanthus',
    'melospiza_melodia_maillardi',
    'melospiza_melodia_maxillaris',
    'metapogon_hurdi',
    'microseris_paludosa',
    'monadenia_mormonum_buttoni',
    'monardella_venosa',
    'monolopia_congdonii',
    'myotis_volans',
    'nama_stenocarpa',
    'navarretia_gowenii',
    'navarretia_leucocephala_bakeri',
    'navarretia_myersii_myersii',
    'navarretia_nigelliformis_radians',
    'navarretia_paradoxiclara',
    'navarretia_prostrata',
    'navarretia_setiloba',
    'neostapfia_colusana',
    'neotoma_fuscipes_riparia',
    'nyctinomops_macrotis',
    'oenothera_deltoides_howellii',
    'onychomys_torridus_tularensis',
    'opuntia_basilaris_treleasei',
    'orcuttia_inaequalis',
    'orcuttia_pilosa',
    'orcuttia_tenuis',
    'orcuttia_viscida',
    'pandion_haliaetus',
    'paronychia_ahartii',
    'pelecanus_erythrorhynchos',
    'perdita_scitula_antiochensis',
    'perognathus_inornatus',
    'phalacrocorax_auritus',
    'phrynosoma_blainvillii',
    'plagiobothrys_hystriculus',
    'plebulina_emigdionis',
    'plegadis_chihi',
    'pooecetes_gramineus_affinis',
    'potamogeton_zosteriformis',
    'progne_subis',
    'protodufourea_zavortinki',
    'pseudobahia_bahiifolia',
    'pseudobahia_peirsonii',
    'puccinellia_simplex',
    'pyrgulopsis_greggi',
    'rallus_obsoletus_obsoletus',
    'rana_boylii',
    'rana_draytonii',
    'reithrodontomys_raviventris',
    'rhynchospora_californica',
    'ribes_menziesii_ixoderme',
    'riparia_riparia',
    'sagittaria_sanfordii',
    'scutellaria_galericulata',
    'scutellaria_lateriflora',
    'senecio_aphanactis',
    'setophaga_petechia',
    'sidalcea_keckii',
    'sidalcea_robusta',
    'sorex_ornatus_relictus',
    'sorex_ornatus_sinuosus',
    'spea_hammondii',
    'spergularia_macrotheca_longistyla',
    'speyeria_callippe_callippe',
    'sphecodogastra_antiochensis',
    'sphenopholis_obtusata',
    'sternula_antillarum_browni',
    'streptanthus_insignis_lyonii',
    'stuckenia_filiformis_alpina',
    'stylocline_citroleum',
    'stylocline_masonii',
    'sylvilagus_bachmani_riparius',
    'symphyotrichum_lentum',
    'talanites_moodyae',
    'taxidea_taxus',
    'thamnophis_gigas',
    'thamnophis_hammondii',
    'tortula_californica',
    'toxostoma_lecontei',
    'trichocoronis_wrightii_wrightii',
    'trifolium_amoenum',
    'trifolium_hydrophilum',
    'trifolium_jokerstii',
    'trigonoscuta',
    'tropidocarpum_californicum',
    'tropidocarpum_capparideum',
    'tuctoria_greenei',
    'tuctoria_mucronata',
    'vireo_bellii_pusillus',
    'vulpes_macrotis_mutica',
    'vulpes_vulpes_patwin',
    'wolffia_brasiliensis',
    'xanthocephalus_xanthocephalus',
    'xantusia_vigilis_sierrae',
  ],
}

export const SPECIES_BY_TARGET = {
  mojave: {
    all: FULL_SPECIES_LIST.mojave,
    legal_te: [
      'ammospermophilus_nelsoni',
      'anaxyrus_californicus',
      'batrachoseps_stebbinsi',
      'buteo_swainsoni',
      'charadrius_alexandrinus_nivosus',
      'coccyzus_americanus_occidentalis',
      'colaptes_chrysoides',
      'cyprinodon_radiosus',
      'dipodomys_merriami_parvus',
      'empidonax_traillii_extimus',
      'gopherus_agassizii',
      'gulo_gulo',
      'gymnogyps_californianus',
      'haliaeetus_leucocephalus',
      'melanerpes_uropygialis',
      'melozone_crissalis_eremophilus',
      'micrathene_whitneyi',
      'microtus_californicus_scirpensis',
      'ovis_canadensis_sierrae',
      'polioptila_californica_californica',
      'rallus_obsoletus_yumanensis',
      'rana_draytonii',
      'rana_muscosa',
      'siphateles_bicolor_mohavensis',
      'siphateles_bicolor_snyderi',
      'vireo_bellii_arizonae',
      'vireo_bellii_pusillus',
      'xerospermophilus_mohavensis',
      'astragalus_insularis_var_harwoodii',
      'astragalus_tricarinatus',
      'berberis_fremontii',
      'blepharidachne_kingii',
      'calochortus_striatus',
      'chaetadelpha_wheeleri',
      'deinandra_arida',
      'delphinium_scaposum',
      'ditaxis_claryana',
      'enceliopsis_nudicaulis_var_corrugata',
      'eremothera_boothii_ssp_boothii',
      'frasera_albomarginata_var_albomarginata',
      'monardella_robisonii',
      'muhlenbergia_fragilis',
      'opuntia_basilaris_var_treleasei',
      'physaria_chambersii',
      'prunus_eremophila',
      'schoenus_nigricans',
      'scutellaria_bolanderi_ssp_austromontana',
    ],
    legal_top5: FULL_SPECIES_LIST.mojave,
  },
  modoc: {
    all: FULL_SPECIES_LIST.modoc,
    legal_te: [
      'chasmistes_brevirostris',
      'deltistes_luxatus',
      'pacifastacus_fortis',
      'tuctoria_greenei',
      'empidonax_traillii',
      'gulo_gulo',
      'strix_nebulosa',
      'vulpes_vulpes_necator',
      'catostomus_microps',
      'cottus_asperrimus',
      'gratiola_heterosepala',
      'orcuttia_tenuis',
      'charadrius_alexandrinus_nivosus',
      'grus_canadensis_tabida',
      'ovis_canadensis_sierrae',
      'riparia_riparia',
      'haliaeetus_leucocephalus',
      'rana_pretiosa',
    ],
    legal_top5: FULL_SPECIES_LIST.modoc,
  },
  greatvalley: {
    all: FULL_SPECIES_LIST.greatvalley,
    legal_te: [
      'agelaius_tricolor',
      'ambystoma_californiense',
      'ammospermophilus_nelsoni',
      'amsinckia_grandiflora',
      'antigone_canadensis_tabida',
      'apodemia_mormo_langei',
      'arctostaphylos_myrtifolia',
      'batrachoseps_stebbinsi',
      'branchinecta_conservatio',
      'branchinecta_longiantenna',
      'branchinecta_lynchi',
      'brodiaea_insignis',
      'brodiaea_pallida',
      'buteo_swainsoni',
      'carpenteria_californica',
      'castilleja_campestris_succulenta',
      'caulanthus_californicus',
      'charadrius_alexandrinus_nivosus',
      'chloropyron_molle_molle',
      'chloropyron_palmatum',
      'cirsium_hydrophilum_hydrophilum',
      'clarkia_springvillensis',
      'coccyzus_americanus',
      'coccyzus_americanus_occidentalis',
      'desmocerus_californicus_dimorphus',
      'dipodomys_ingens',
      'dipodomys_nitratoides_exilis',
      'dipodomys_nitratoides_nitratoides',
      'elaphrus_viridis',
      'empidonax_traillii',
      'eremalche_parryi_kernensis',
      'eriogonum_apricum_apricum',
      'eriogonum_apricum_prostratum',
      'eryngium_racemosum',
      'erysimum_capitatum_angustatum',
      'euphorbia_hooveri',
      'fremontodendron_decumbens',
      'fritillaria_striata',
      'gambelia_sila',
      'gratiola_heterosepala',
      'gymnogyps_californianus',
      'haliaeetus_leucocephalus',
      'lasthenia_conjugens',
      'laterallus_jamaicensis_coturniculus',
      'lepidurus_packardi',
      'limnanthes_floccosa_californica',
      'masticophis_lateralis_euryxanthus',
      'monolopia_congdonii',
      'neostapfia_colusana',
      'neotoma_fuscipes_riparia',
      'oenothera_deltoides_howellii',
      'opuntia_basilaris_treleasei',
      'orcuttia_inaequalis',
      'orcuttia_pilosa',
      'orcuttia_tenuis',
      'orcuttia_viscida',
      'pseudobahia_bahiifolia',
      'pseudobahia_peirsonii',
      'rallus_obsoletus_obsoletus',
      'rana_draytonii',
      'reithrodontomys_raviventris',
      'riparia_riparia',
      'sidalcea_keckii',
      'sorex_ornatus_relictus',
      'speyeria_callippe_callippe',
      'sternula_antillarum_browni',
      'sylvilagus_bachmani_riparius',
      'thamnophis_gigas',
      'trifolium_amoenum',
      'tuctoria_greenei',
      'tuctoria_mucronata',
      'vireo_bellii_pusillus',
      'vulpes_macrotis_mutica',
    ],
  },
}

export const ECOSYSTEMS = {
  mojave: {
    Abies_concolor_D_F_a_W_Alliance: 'Abies concolor Dry Forest and Woodland Alliance',
    Achnatherum_hymenoides_Alliance: 'Achnatherum hymenoides Alliance',
    Achnatherum_speciosum_Alliance: 'Achnatherum speciosum Alliance',
    Amelanchier_utahensis_C_m_C_i_S_Alliance:
      'Amelanchier utahensis Cercocarpus montanus Cercocarpus intricatus Shrubland Alliance',
    Amphipappus_fremontii_S_f_S_Alliance: 'Amphipappus fremontii Salvia funerea Scrub Alliance',
    Anemopsis_californica_H_n_S_s_Alliance: 'Anemopsis californica Helianthus nuttallii Solidago spectabilis Alliance',
    Atriplex_parryi_W_S_Alliance: 'Atriplex parryi Wet Shrubland Alliance',
    Calamagrostis_scopulorum_A_g_H_G_Alliance: 'Calamagrostis scopulorum Andropogon glomeratus Hanging Garden Alliance',
    Carnegiea_gigantea_P_m_P_v_Alliance: 'Carnegiea gigantea Parkinsonia microphylla Prosopis velutina Alliance',
    Castela_emoryi_Alliance: 'Castela emoryi Alliance',
    Cladium_californicum_A_S_Alliance: 'Cladium californicum Alkaline Seep Alliance',
    Cylindropuntia_acanthocarpa_P_r_S_Alliance: 'Cylindropuntia acanthocarpa Pleuraphis rigida Scrub Alliance',
    Dicoria_canescens_A_v_P_u_D_Alliance: 'Dicoria canescens Abronia villosa Panicum urvilleanum Dune Alliance',
    Ephedra_funerea_Alliance: 'Ephedra funerea Alliance',
    Fallugia_paradoxa_D_W_S_Alliance: 'Fallugia paradoxa Desert Wash Scrub Alliance',
    Fremontodendron_californicum_Alliance: 'Fremontodendron californicum Alliance',
    Krascheninnikovia_lanata_S_a_D_s_Alliance: 'Krascheninnikovia lanata Steppe and Dwarf shrubland Alliance',
    Mortonia_utahensis_S_Alliance: 'Mortonia utahensis Scrub Alliance',
    Nolina_bigelovii_parryi_Alliance: 'Nolina bigelovii parryi Alliance',
    Pinus_edulis_Alliance: 'Pinus edulis Alliance',
    Pleuraphis_jamesii_Alliance: 'Pleuraphis jamesii Alliance',
    Pleuraphis_rigida_Alliance: 'Pleuraphis rigida Alliance',
    Prosopis_glandulosa_P_v_P_p_W_S_Alliance:
      'Prosopis glandulosa Prosopis velutina Prosopis pubescens Wet Scrub Alliance',
    Prunus_fasciculata_S_m_N_M_D_W_S_Alliance:
      'Prunus fasciculata Salazaria mexicana Northern Mojave Desert Wash Scrub Alliance',
    Purshia_stansburiana_S_Alliance: 'Purshia stansburiana Scrub Alliance',
    Quercus_turbinella_Alliance: 'Quercus turbinella Alliance',
    Rhus_trilobata_C_r_F_p_S_Alliance: 'Rhus trilobata Crataegus rivularis Forestiera pubescens Shrubland Alliance',
    Sporobolus_airoides_Alliance: 'Sporobolus airoides Alliance',
    Sporobolus_airoides_M_a_S_g_A_W_M_Alliance:
      'Sporobolus airoides Muhlenbergia asperifolia Spartina gracilis Alkaline Wet Meadow Alliance',
    Tetracoccus_hallii_Alliance: 'Tetracoccus hallii Alliance',
    Vitis_arizonica_V_g_W_S_Alliance: 'Vitis arizonica Vitis girdiana Wet Shrubland Alliance',
  },
  modoc: {
    Danthonia_unispicata_P_s_W_M_Association: 'Danthonia unispicata – Poa secunda Wet Meadow Association',
    Elymus_cinereus_B_W_M_Association: 'Elymus cinereus Bottomland Wet Meadow Association',
    Elymus_smithii_C_R_M_G_Association: 'Elymus smithii Central Rocky Mountain Grassland Association',
    Eriogonum_sphaerocephalum_P_s_D_G_Association:
      'Eriogonum sphaerocephalum / Poa secunda Dwarf-shrub Grassland Association',
    Festuca_idahoensis_Provisional_Association: 'Festuca idahoensis Provisional Association',
    Muhlenbergia_richardsonis_D_b_P_Association:
      'Muhlenbergia richardsonis – Downingia bacigalupii Provisional Association',
  },
  greatvalley: {
    Acer_negundo: 'Acer negundo',
    Achnatherum_hymenoides: 'Achnatherum hymenoides',
    Aesculus_californica: 'Aesculus californica',
    Allenrolfea_occidentalis: 'Allenrolfea occidentalis',
    Carex_barbarae: 'Carex barbarae',
    Centromadia_pungens: 'Centromadia pungens',
    Cephalanthus_occidentalis: 'Cephalanthus occidentalis',
    Cressa_truxillensis_Distichlis_spicata: 'Cressa truxillensis Distichlis spicata',
    Frankenia_salina: 'Frankenia salina',
    Fraxinus_latifolia: 'Fraxinus latifolia',
    Heterotheca_oregona_sessiliflora: 'Heterotheca oregona sessiliflora',
    Lasthenia_fremontii_Distichlis_spicata: 'Lasthenia fremontii Distichlis spicata',
    Lasthenia_fremontii_Downingia_bicornuta: 'Lasthenia fremontii Downingia bicornuta',
    Layia_fremontii_Achyrachaena_mollis: 'Layia fremontii Achyrachaena mollis',
    Leymus_cinereus_Leymus_triticoides: 'Leymus cinereus Leymus triticoides',
    Lupinus_albifrons: 'Lupinus albifrons',
    Platanus_racemosa: 'Platanus racemosa',
    Populus_fremontii_Fraxinus_velutina_Salix_gooddingii: 'Populus fremontii Fraxinus velutina Salix gooddingii',
    Prosopis_glandulosa_Prosopis_velutina_Prosopis_pubescens:
      'Prosopis glandulosa Prosopis velutina Prosopis pubescens',
    Quercus_lobata: 'Quercus lobata',
    Rosa_californica: 'Rosa californica',
    Salix_gooddingii: 'Salix gooddingii',
    Salix_laevigata: 'Salix laevigata',
    Sambucus_nigra: 'Sambucus nigra',
    Schoenoplectus_acutus_californicus: 'Schoenoplectus acutus californicus',
    Sesuvium_verrucosum: 'Sesuvium verrucosum',
    Suaeda_moquinii: 'Suaeda moquinii',
    Acer_negundo_Salix_gooddingii: 'Acer negundo Salix gooddingii',
    Arctostaphylos_manzanita: 'Arctostaphylos manzanita',
    Cornus_sericea_Salix_exigua: 'Cornus sericea Salix exigua',
    Encelia_actonii: 'Encelia actonii',
    Hesperoyucca_whipplei: 'Hesperoyucca whipplei',
    Heterotheca_oregona: 'Heterotheca oregona',
    Hydrocotyle_ranunculoides: 'Hydrocotyle ranunculoides',
    Quercus_lobata_Acer_negundo: 'Quercus lobata Acer negundo',
    Salix_gooddingii_Rubus_armeniacus: 'Salix gooddingii Rubus armeniacus',
    Salix_gooddingii_Quercus_lobata_wetland_herb: 'Salix gooddingii Quercus lobata wetland herb',
    Sarcocornia_pacifica_Cotula_coronopifolia: 'Sarcocornia pacifica Cotula coronopifolia',
    Sarcocornia_pacifica_Distichlis_spicata: 'Sarcocornia pacifica Distichlis spicata',
    Schoenoplectus_acutus: 'Schoenoplectus acutus',
    Schoenoplectus_acutus_Typha_angustifolia: 'Schoenoplectus acutus Typha angustifolia',
    Schoenoplectus_acutus_Typha_latifolia: 'Schoenoplectus acutus Typha latifolia',
    Schoenoplectus_acutus_Typha_latifolia_Phragmites_australis:
      'Schoenoplectus acutus Typha latifolia Phragmites australis',
    Schoenoplectus_californicus_Schoenoplectus_acutus: 'Schoenoplectus californicus Schoenoplectus acutus',
    Typha_angustifolia_Distichlis_spicata: 'Typha angustifolia Distichlis spicata',
    Typha_latifolia: 'Typha latifolia',
    Vitis_californica: 'Vitis californica',
    Aesculus_californica_Toxicodendron_diversilobum_moss: 'Aesculus californica Toxicodendron diversilobum moss',
    Allenrolfea_occidentalis_Distichlis_spicata: 'Allenrolfea occidentalis Distichlis spicata',
    Allenrolfea_occidentalis_Suaeda_moquinii: 'Allenrolfea occidentalis Suaeda moquinii',
    Anemopsis_californica: 'Anemopsis californica',
    Arthrocnemum_subterminale: 'Arthrocnemum subterminale',
    Atriplex_spinifera_herbaceous: 'Atriplex spinifera herbaceous',
    Centromadia_pungens_Lepidium_dictyotum: 'Centromadia pungens Lepidium dictyotum',
    Coreopsis_calliopsidea_Mentzelia_pectinata: 'Coreopsis calliopsidea Mentzelia pectinata',
    Cornus_sericea_Salix_lasiolepis: 'Cornus sericea Salix lasiolepis',
    Diplacus_aurantiacus: 'Diplacus aurantiacus',
    Downingia_bicornuta_cuspidata: 'Downingia bicornuta cuspidata',
    Downingia_insignis_Psilocarphus_brevissimus: 'Downingia insignis Psilocarphus brevissimus',
    Downingia_pulchella_Cressa_truxillensis: 'Downingia pulchella Cressa truxillensis',
    Eastwoodia_elegans: 'Eastwoodia elegans',
    Elymus_glaucus: 'Elymus glaucus',
    Ephedra_californica_annual_perennial_herb: 'Ephedra californica annual perennial herb',
    Ephedra_californica_Gutierrezia_californica_Eriastrum_pluriflo:
      'Ephedra californica Gutierrezia californica Eriastrum pluriflorum',
    Ephedra_viridis: 'Ephedra viridis',
    Equisetum_hyemale: 'Equisetum hyemale',
    Eriogonum_wrightii_Corethrogyne_filaginifolia: 'Eriogonum wrightii Corethrogyne filaginifolia',
    Eryngium_vaseyi_castrense: 'Eryngium vaseyi castrense',
    Frankenia_salina_Distichlis_spicata: 'Frankenia salina Distichlis spicata',
    Frankenia_salina_Psilocarphus_brevissimus: 'Frankenia salina Psilocarphus brevissimus',
    Fraxinus_latifolia_Alnus_rhombifolia: 'Fraxinus latifolia Alnus rhombifolia',
    Grindelia_camporum: 'Grindelia camporum',
    Gutierrezia_californica_Poa_secunda: 'Gutierrezia californica Poa secunda',
    Hemizonia_congesta: 'Hemizonia congesta',
    Holocarpha_virgata: 'Holocarpha virgata',
    Hordeum_brachyantherum: 'Hordeum brachyantherum',
    Hordeum_depressum_murinum_ssp_leporinum: 'Hordeum depressum murinum ssp leporinum',
    Isocoma_acradenia_Suaeda_moquinii: 'Isocoma acradenia Suaeda moquinii',
    Isocoma_acradenia_Alkaline_Wet: 'Isocoma acradenia Alkaline Wet',
    Juncus_arcticus_var_balticus: 'Juncus arcticus var balticus',
    Juncus_arcticus_var_mexicanus: 'Juncus arcticus var mexicanus',
    Juncus_xiphioides: 'Juncus xiphioides',
    Lasthenia_fremontii: 'Lasthenia fremontii',
    Lasthenia_fremontii_Downingia_ornatissima: 'Lasthenia fremontii Downingia ornatissima',
    Lasthenia_glaberrima_Downingia_insignis: 'Lasthenia glaberrima Downingia insignis',
    Lasthenia_glaberrima_Lupinus_bicolor: 'Lasthenia glaberrima Lupinus bicolor',
    Lepidospartum_squamatum_Baccharis_salicifolia: 'Lepidospartum squamatum Baccharis salicifolia',
    Lepidospartum_squamatum_ephemeral_annuals: 'Lepidospartum squamatum ephemeral annuals',
    Leymus_cinereus: 'Leymus cinereus',
    Leymus_triticoides: 'Leymus triticoides',
    Leymus_triticoides_Bromus_spp_Avena_spp: 'Leymus triticoides Bromus spp Avena spp',
    Limnanthes_douglasii_ssp_rosea_Pleuropogon_californicus: 'Limnanthes douglasii ssp rosea Pleuropogon californicus',
    Mimulus_guttatus: 'Mimulus guttatus',
    Mimulus_guttatus_Vulpia_microstachys: 'Mimulus guttatus Vulpia microstachys',
    Montia_fontana_Sidalcea_calycosa: 'Montia fontana Sidalcea calycosa',
    Muhlenbergia_rigens: 'Muhlenbergia rigens',
    Plagiobothrys_austiniae_Achyrachaena_mollis: 'Plagiobothrys austiniae Achyrachaena mollis',
    Platanus_racemosa_annual_grass: 'Platanus racemosa annual grass',
    Platanus_racemosa_Populus_fremontii_Salix_lasiolepis: 'Platanus racemosa Populus fremontii Salix lasiolepis',
    Platanus_racemosa_Quercus_lobata: 'Platanus racemosa Quercus lobata',
    Platanus_racemosa_Salix_laevigata_Salix_lasiolepis_Baccharis_:
      'Platanus racemosa Salix laevigata Salix lasiolepis Baccharis salicifolia',
    Pluchea_sericea_Seasonally_Flooded: 'Pluchea sericea Seasonally Flooded',
    Populus_fremontii_Acer_negundo: 'Populus fremontii Acer negundo',
    Populus_fremontii_Baccharis_salicifolia: 'Populus fremontii Baccharis salicifolia',
    Populus_fremontii_Salix_exigua: 'Populus fremontii Salix exigua',
    Populus_fremontii_Salix_gooddingii: 'Populus fremontii Salix gooddingii',
    Populus_fremontii_Salix_laevigata: 'Populus fremontii Salix laevigata',
    Populus_fremontii_Salix_lasiolepis: 'Populus fremontii Salix lasiolepis',
    Populus_fremontii_Vitis_californica: 'Populus fremontii Vitis californica',
    Populus_fremontii_Great_Valley: 'Populus fremontii Great Valley',
    Quercus_lobata_Alnus_rhombifolia: 'Quercus lobata Alnus rhombifolia',
    Quercus_lobata_Carex_barbarae: 'Quercus lobata Carex barbarae',
    Quercus_lobata_Fraxinus_latifolia_Vitis_californica: 'Quercus lobata Fraxinus latifolia Vitis californica',
    Quercus_lobata_herbaceous_semi_riparian: 'Quercus lobata herbaceous semi riparian',
    Quercus_lobata_Quercus_agrifolia_grass: 'Quercus lobata Quercus agrifolia grass',
    Quercus_lobata_Quercus_wislizeni: 'Quercus lobata Quercus wislizeni',
    Quercus_lobata_Rubus_armeniacus: 'Quercus lobata Rubus armeniacus',
    Quercus_lobata_Rubus_ursinus_Rosa_californica: 'Quercus lobata Rubus ursinus Rosa californica',
    Quercus_lobata_Salix_lasiolepis: 'Quercus lobata Salix lasiolepis',
    Ribes_quercetorum: 'Ribes quercetorum',
    Rubus_ursinus: 'Rubus ursinus',
    Salix_gooddingii_Fraxinus_latifolia: 'Salix gooddingii Fraxinus latifolia',
    Salix_gooddingii_Salix_exigua: 'Salix gooddingii Salix exigua',
    Salix_laevigata_Salix_lasiolepis: 'Salix laevigata Salix lasiolepis',
    Salix_lucida_ssp_lasiandra: 'Salix lucida ssp lasiandra',
    Salvia_carduacea: 'Salvia carduacea',
    Sarcocornia_pacifica_Frankenia_salina: 'Sarcocornia pacifica Frankenia salina',
    Schoenoplectus_acutus_Phragmites_australis: 'Schoenoplectus acutus Phragmites australis',
    Schoenoplectus_americanus: 'Schoenoplectus americanus',
    Schoenoplectus_californicus: 'Schoenoplectus californicus',
    Sesuvium_verrucosum_Distichlis_spicata: 'Sesuvium verrucosum Distichlis spicata',
    Sporobolus_airoides: 'Sporobolus airoides',
    Sporobolus_airoides_Allenrolfea_occidentalis: 'Sporobolus airoides Allenrolfea occidentalis',
    Stuckenia_pectinata: 'Stuckenia pectinata',
    Suaeda_moquinii_Lepidium_dictyotum: 'Suaeda moquinii Lepidium dictyotum',
    Trifolium_gracilentum_Hesperevax_caulescens: 'Trifolium gracilentum Hesperevax caulescens',
    Trifolium_variegatum: 'Trifolium variegatum',
    Trifolium_variegatum_Juncus_bufonius: 'Trifolium variegatum Juncus bufonius',
    Trifolium_variegatum_Vulpia_bromoides_Hypochaeris_glabra_Leon:
      'Trifolium variegatum Vulpia bromoides Hypochaeris glabra Leontodon taraxacoides',
    Zigadenus_fremontii_Lolium_perenne: 'Zigadenus fremontii Lolium perenne',
    Anemopsis_californica_Helianthus_nuttallii_Solidago_spectabili:
      'Anemopsis californica Helianthus nuttallii Solidago spectabilis',
    Bolboschoenus_maritimus: 'Bolboschoenus maritimus',
    Krascheninnikovia_lanata: 'Krascheninnikovia lanata',
    Lepidospartum_squamatum: 'Lepidospartum squamatum',
    Pluchea_sericea: 'Pluchea sericea',
    Populus_fremontii: 'Populus fremontii',
    Salix_lucida: 'Salix lucida',
    Sarcocornia_pacifica_Salicornia_depressa: 'Sarcocornia pacifica Salicornia depressa',
    Selaginella_bigelovii: 'Selaginella bigelovii',
    Sporobolus_airoides_Muhlenbergia_asperifolia_Spartina_gracilis:
      'Sporobolus airoides Muhlenbergia asperifolia Spartina gracilis',
    Umbellularia_californica: 'Umbellularia californica',
  },
}

export const ECOSYSTEMS_BY_TARGET = {
  mojave: {
    all: Object.keys(ECOSYSTEMS.mojave),
    legal_te: Object.keys(ECOSYSTEMS.mojave),
    legal_top5: Object.keys(ECOSYSTEMS.mojave),
  },
  modoc: {
    all: Object.keys(ECOSYSTEMS.modoc),
    legal_te: Object.keys(ECOSYSTEMS.modoc),
    legal_top5: Object.keys(ECOSYSTEMS.modoc),
  },
  greatvalley: {
    all: Object.keys(ECOSYSTEMS.greatvalley),
    legal_te: [
      'Acer_negundo_Salix_gooddingii',
      'Allenrolfea_occidentalis',
      'Allenrolfea_occidentalis_Distichlis_spicata',
      'Allenrolfea_occidentalis_Suaeda_moquinii',
      'Anemopsis_californica',
      'Anemopsis_californica_Helianthus_nuttallii_Solidago_spectabili',
      'Arthrocnemum_subterminale',
      'Bolboschoenus_maritimus',
      'Carex_barbarae',
      'Cephalanthus_occidentalis',
      'Cornus_sericea_Salix_exigua',
      'Cornus_sericea_Salix_lasiolepis',
      'Cressa_truxillensis_Distichlis_spicata',
      'Downingia_bicornuta_cuspidata',
      'Downingia_insignis_Psilocarphus_brevissimus',
      'Downingia_pulchella_Cressa_truxillensis',
      'Frankenia_salina',
      'Frankenia_salina_Distichlis_spicata',
      'Frankenia_salina_Psilocarphus_brevissimus',
      'Fraxinus_latifolia',
      'Fraxinus_latifolia_Alnus_rhombifolia',
      'Grindelia_camporum',
      'Hordeum_brachyantherum',
      'Hordeum_depressum_murinum_ssp_leporinum',
      'Hydrocotyle_ranunculoides',
      'Juncus_arcticus_var_balticus',
      'Juncus_arcticus_var_mexicanus',
      'Leymus_triticoides',
      'Leymus_triticoides_Bromus_spp_Avena_spp',
      'Limnanthes_douglasii_ssp_rosea_Pleuropogon_californicus',
      'Mimulus_guttatus',
      'Mimulus_guttatus_Vulpia_microstachys',
      'Montia_fontana_Sidalcea_calycosa',
      'Plagiobothrys_austiniae_Achyrachaena_mollis',
      'Platanus_racemosa',
      'Platanus_racemosa_annual_grass',
      'Platanus_racemosa_Populus_fremontii_Salix_lasiolepis',
      'Platanus_racemosa_Quercus_lobata',
      'Platanus_racemosa_Salix_laevigata_Salix_lasiolepis_Baccharis_',
      'Pluchea_sericea',
      'Pluchea_sericea_Seasonally_Flooded',
      'Populus_fremontii',
      'Populus_fremontii_Acer_negundo',
      'Populus_fremontii_Baccharis_salicifolia',
      'Populus_fremontii_Fraxinus_velutina_Salix_gooddingii',
      'Populus_fremontii_Salix_exigua',
      'Populus_fremontii_Salix_gooddingii',
      'Populus_fremontii_Salix_laevigata',
      'Populus_fremontii_Salix_lasiolepis',
      'Populus_fremontii_Vitis_californica',
      'Populus_fremontii_Great_Valley',
      'Quercus_lobata_Alnus_rhombifolia',
      'Salix_gooddingii',
      'Salix_gooddingii_Fraxinus_latifolia',
      'Salix_gooddingii_Quercus_lobata_wetland_herb',
      'Salix_gooddingii_Rubus_armeniacus',
      'Salix_gooddingii_Salix_exigua',
      'Salix_laevigata',
      'Salix_laevigata_Salix_lasiolepis',
      'Salix_lucida',
      'Salix_lucida_ssp_lasiandra',
      'Sambucus_nigra',
      'Sarcocornia_pacifica_Cotula_coronopifolia',
      'Sarcocornia_pacifica_Distichlis_spicata',
      'Sarcocornia_pacifica_Frankenia_salina',
      'Sarcocornia_pacifica_Salicornia_depressa',
      'Schoenoplectus_acutus',
      'Schoenoplectus_acutus_Phragmites_australis',
      'Schoenoplectus_acutus_californicus',
      'Schoenoplectus_acutus_Typha_angustifolia',
      'Schoenoplectus_acutus_Typha_latifolia',
      'Schoenoplectus_acutus_Typha_latifolia_Phragmites_australis',
      'Schoenoplectus_americanus',
      'Schoenoplectus_californicus',
      'Schoenoplectus_californicus_Schoenoplectus_acutus',
      'Sesuvium_verrucosum',
      'Sesuvium_verrucosum_Distichlis_spicata',
      'Sporobolus_airoides',
      'Sporobolus_airoides_Allenrolfea_occidentalis',
      'Sporobolus_airoides_Muhlenbergia_asperifolia_Spartina_gracilis',
      'Stuckenia_pectinata',
      'Suaeda_moquinii_Lepidium_dictyotum',
      'Trifolium_gracilentum_Hesperevax_caulescens',
      'Trifolium_variegatum_Juncus_bufonius',
      'Typha_angustifolia_Distichlis_spicata',
      'Typha_latifolia',
    ],
  },
}

export const SPECIAL_HABITATS = {
  mojave: {
    Canyons: 'Canyons',
    MicrophyllWoodlands: 'Microphyll Woodlands',
    Mines: 'Mines',
    MudHills: 'Mud Hills',
    Sand_DuneSystems: 'Sand Dune',
    Seeps_Springs: 'Seeps/Springs',
    Wetlands_LakeRes: 'Wetlands/Lakes',
    Wetlands_Playa: 'Wetlands/Playa',
    Wetlands_Pond: 'Wetlands/Pond',
  },
  modoc: {
    Wetlands_Lake_Reservoir: 'Wetlands/Lakes',
    Wetlands_Pond: 'Wetlands/Pond',
    Aspen: 'Aspen',
    Ann_Per_Grassland: 'Ann Per Grassland',
    Northern_Interior_Cypress_Forest: 'Northern Interior Cypress Forest',
    Northern_Basalt_Flow_Vernal_Pool: 'Northern Basalt Flow Vernal Pool',
  },
  greatvalley: {
    Arid_West_freshwater_emergent_marsh: 'Arid West freshwater emergent marsh',
    Californian_mixed_annual_perennial_freshwater_vernal_pool_swale_:
      'Californian mixed annual perennial freshwater vernal pool swale bottomland',
    Californian_warm_temperate_marsh_seep: 'Californian warm temperate marsh seep',
    Naturalized_warm_temperate_riparian_and_wetland: 'Naturalized warm temperate riparian and wetland',
    Southwestern_North_American_riparian_evergreen_and_deciduous_woo:
      'Southwestern North American riparian evergreen and deciduous woodland',
    Southwestern_North_American_riparian_wash_scrub: 'Southwestern North American riparian wash scrub',
    Southwestern_North_American_salt_basin_and_high_marsh: 'Southwestern North American salt basin and high marsh',
    Western_North_American_disturbed_alkaline_marsh_and_meadow:
      'Western North American disturbed alkaline marsh and meadow',
    Alkali_Meadow: 'Alkali Meadow',
    Alkali_Seep: 'Alkali Seep',
    Cismontane_Alkali_Marsh: 'Cismontane Alkali Marsh',
    Coastal_and_Valley_Freshwater_Marsh: 'Coastal and Valley Freshwater Marsh',
    Coastal_Brackish_Marsh: 'Coastal Brackish Marsh',
    Elderberry_Savanna: 'Elderberry Savanna',
    Great_Valley_Cottonwood_Riparian_Forest: 'Great Valley Cottonwood Riparian Forest',
    Great_Valley_Mesquite_Scrub: 'Great Valley Mesquite Scrub',
    Great_Valley_Mixed_Riparian_Forest: 'Great Valley Mixed Riparian Forest',
    Great_Valley_Valley_Oak_Riparian_Forest: 'Great Valley Valley Oak Riparian Forest',
    Ione_Chaparral: 'Ione Chaparral',
    Monvero_Residual_Dunes: 'Monvero Residual Dunes',
    Northern_Basalt_Flow_Vernal_Pool: 'Northern Basalt Flow Vernal Pool',
    Northern_Claypan_Vernal_Pool: 'Northern Claypan Vernal Pool',
    Northern_Volcanic_Mud_Flow_Vernal_Pool: 'Northern Volcanic Mud Flow Vernal Pool',
    Stabilized_Interior_Dunes: 'Stabilized Interior Dunes',
    Sycamore_Alluvial_Woodland: 'Sycamore Alluvial Woodland',
    Valley_Oak_Woodland: 'Valley Oak Woodland',
    Valley_Sacaton_Grassland: 'Valley Sacaton Grassland',
    Valley_Saltbush_Scrub: 'Valley Saltbush Scrub',
    Valley_Sink_Scrub: 'Valley Sink Scrub',
  },
}

export const SPECIAL_HABITATS_BY_TARGET = {
  mojave: {
    all: Object.keys(SPECIAL_HABITATS.mojave),
    legal_te: Object.keys(SPECIAL_HABITATS.mojave),
    legal_top5: Object.keys(SPECIAL_HABITATS.mojave),
  },
  modoc: {
    all: Object.keys(SPECIAL_HABITATS.modoc),
    legal_te: Object.keys(SPECIAL_HABITATS.modoc),
    legal_top5: Object.keys(SPECIAL_HABITATS.modoc),
  },
  greatvalley: {
    all: Object.keys(SPECIAL_HABITATS.greatvalley),
    legal_te: [
      'Alkali_Meadow',
      'Alkali_Seep',
      'Arid_West_freshwater_emergent_marsh',
      'Arid_West_freshwater_emergent_marsh_group',
      'Californian_mixed_annual_perennial_freshwater_vernal_pool_swale_bottomland',
      'Californian_mixed_annual_perennial_freshwater_vernal_pool_swale_bottomland_group',
      'Californian_mixed_annual_perennial_freshwater_vernal_pool_swale_plain_bottomland_group',
      'Californian_warm_temperate_marsh_seep',
      'Californian_warm_temperate_marsh_seep_group',
      'Cismontane_Alkali_Marsh',
      'Coastal_and_Valley_Freshwater_Marsh',
      'Coastal_Brackish_Marsh',
      'Great_Valley_Cottonwood_Riparian_Forest',
      'Great_Valley_Mixed_Riparian_Forest',
      'Great_Valley_Valley_Oak_Riparian_Forest',
      'Naturalized_warm_temperate_riparian_and_wetland_group',
      'Northern_Basalt_Flow_Vernal_Pool',
      'Northern_Claypan_Vernal_Pool',
      'Northern_Volcanic_Mud_Flow_Vernal_Pool',
      'Southwestern_North_American_riparian_wash_scrub',
      'Southwestern_North_American_riparian_wash_scrub_group',
      'Southwestern_North_American_salt_basin_and_high_marsh_group',
      'Western_North_American_disturbed_alkaline_marsh_and_meadow',
      'Western_North_American_disturbed_alkaline_marsh_and_meadow_group',
    ],
  },
}

export const AQUATIC_ATTRIBUTES = [
  {
    category: 'Species Richness',
    attributes: [
      {
        label: 'Fish species richness',
        variable: 'species_fish',
      },
      {
        label: 'Crustacean species richness',
        variable: 'species_crust',
      },
      {
        label: 'Herp species richness',
        variable: 'species_herps',
      },
      {
        label: 'Invert species richness',
        variable: 'species_inverts',
      },
      {
        label: 'Mollusk species richness',
        variable: 'species_mollusks',
      },
      {
        label: 'Plant species richness',
        variable: 'species_plants',
      },
      {
        label: 'Bird species richness',
        variable: 'species_birds',
      },
      {
        label: 'Mammal species richness',
        variable: 'species_mammals',
      },
    ],
  },
  {
    category: 'Species Endemism',
    attributes: [
      {
        label: 'Endemic fish',
        variable: 'species_endemic_fish',
      },
      {
        label: 'Endemic crustaceans',
        variable: 'species_endemic_crust',
      },
      {
        label: 'Endemic herps',
        variable: 'species_endemic_herps',
      },
      {
        label: 'Endemic inverts',
        variable: 'species_endemic_inverts',
      },
      {
        label: 'Endemic mollusks',
        variable: 'species_endemic_mollusks',
      },
      {
        label: 'Endemic plants',
        variable: 'species_endemic_plants',
      },
      {
        label: 'Endemic birds',
        variable: 'species_endemic_birds',
      },
      {
        label: 'Endemic mammals',
        variable: 'species_endemic_mammals',
      },
    ],
  },
  {
    category: 'Species At-Risk',
    attributes: [
      {
        label: 'Vulnerable species',
        variable: 'species_vulnerable',
      },
      {
        label: 'Listed species',
        variable: 'species_listed',
      },
      {
        label: 'Endemic and vulnerable',
        variable: 'species_endemic_vulnerable',
      },
      {
        label: 'Endemic and listed',
        variable: 'species_endemic_listed',
      },
    ],
  },
]
