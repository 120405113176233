import React from 'react'
import PropTypes from 'prop-types'
import { Header, Menu, Image } from 'semantic-ui-react'

import scg from '../images/logo_sgc.png'

const BaseHeader = ({ childrenLeft, childrenRight }) => (
  <Menu id="Header" borderless>
    <Image
      src={scg}
      size="small"
      style={{
        zIndex: 1001,
        padding: '5px 10px',
        top: '5px',
        borderTopRightRadius: '15px',
        borderBottomRightRadius: '15px',
        background: 'white',
        height: 'calc(100% + 15px)',
      }}
    />
    <Menu.Item>
      <Header as="a" href="/">
        RePlan: Regional Conservation and Development Planning Tool
      </Header>
    </Menu.Item>
    {childrenLeft}
    <Menu.Menu position="right">{childrenRight}</Menu.Menu>
  </Menu>
)

BaseHeader.propTypes = {
  childrenLeft: PropTypes.node,
  childrenRight: PropTypes.node,
}

BaseHeader.defaultProps = {
  childrenLeft: null,
  childrenRight: null,
}

export default BaseHeader
