import React from 'react'
import axios from 'axios'
import { Message, Segment, Table } from 'semantic-ui-react'

class Workspace extends React.Component {
  state = {
    sharedLinks: [],
    hasError: false,
    isLoading: true,
  }

  componentDidMount() {
    axios
      .get(Urls['accounts:shared-links-list']())
      .then(response => {
        this.setState({ isLoading: false, sharedLinks: response.data })
      })
      .catch(() => {
        this.setState({ isLoading: false, hasError: true })
      })
  }

  render() {
    return (
      <Segment basic loading={this.state.isLoading}>
        {this.state.hasError ? <Message error>Error!</Message> : null}
        {this.state.sharedLinks.length ? (
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Hash</Table.HeaderCell>
                <Table.HeaderCell>Date Created</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.state.sharedLinks.map(link => (
                <Table.Row key={link.id}>
                  <Table.Cell>{link.hash}</Table.Cell>
                  <Table.Cell>{link.created}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        ) : (
          'You have no shared links'
        )}
      </Segment>
    )
  }
}

export default Workspace
