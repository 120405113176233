import { fromJS } from 'immutable'

import { ACTIONS, STEPS } from '../actions/page'

const INITIAL_STATE = fromJS({
  isLoading: false,
  hideSpinner: true,
  loadingMessage: null,
  skipHome: false,
  activeStep: STEPS.REGION,
  skippedScreen: false,
  isQuerying: false,
  isUploading: false,
  modal: {
    active: false,
    text: '',
  },
  modalDisplayed: false,
})

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.UPDATE_LOADING_STATUS:
      return state
        .set('isLoading', action.isLoading)
        .set('hideSpinner', action.hideSpinner)
        .set('loadingMessage', action.loadingMessage)
    case ACTIONS.ADVANCE_ONE_STEP:
      return state.set('activeStep', state.get('activeStep') + 1)
    case ACTIONS.BACK_ONE_STEP:
      return state.set('activeStep', state.get('activeStep') - 1)
    case ACTIONS.UPDATE_ACTIVE_STEP:
      return state.set('activeStep', action.activeStep)
    case ACTIONS.UPDATE_SCREEN_STEP_STATUS:
      return state.set('skippedScreen', action.skippedScreen)
    case ACTIONS.UPDATE_QUERY_STATUS:
      return state.set('isQuerying', action.isQuerying)
    case ACTIONS.UPDATE_MODAL:
      return state.set('modalDisplayed', true).set('modal', action.modal)
    default:
      return state
  }
}
