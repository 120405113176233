/* eslint-disable dot-notation,quote-props */
import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox, Divider, Form, Icon, List } from 'semantic-ui-react'

import VerticalBarChart from '../../components/VerticalBarchart'
import { precision } from '../../utils/formatters'

const MAPPING_DATA = {
  'Private Land': {
    color: '#ebeb4a',
    label: 'Private Land',
    value: 0,
  },
  'USFWS': {
    color: '#156ead',
    label: 'USFWS',
    value: 0,
  },
  'USFS': {
    color: '#265c00',
    label: 'USFS',
    value: 0,
  },
  'NPS': {
    color: '#c56c39',
    label: 'NPS',
    value: 0,
  },
  'BLM': {
    color: '#ffaa00',
    label: 'BLM',
    value: 0,
  },
  'State': {
    color: '#9fd6c1',
    label: 'State',
    value: 0,
  },
  'Other Public Lands': {
    color: '#37a600',
    label: 'Other',
    value: 0,
  },
  'Military': {
    color: '#b0b0b0',
    label: 'Military',
    value: 0,
  },
  'Tribal': {
    color: '#c300ff',
    label: 'Tribal Land',
    value: 0,
  },
}

const META = {
  unit: 'acres',
  convertToPercent: true,
  tooltipContent: d => d.tooltip,
}

const renderer = ({ id, data }, { shapeMeta }) => {
  let chartData = data
  if (chartData) {
    // eslint-disable-next-line no-return-assign,no-param-reassign
    const totalAreasWithData = Object.values(chartData).reduce((sum, area) => (sum += area))
    chartData['Private Land'] = shapeMeta[id].area - totalAreasWithData
  } else {
    chartData = { 'Private Land': shapeMeta[id].area }
  }

  const mappedData = []
  Object.entries(MAPPING_DATA).forEach(([key, attrs]) => {
    mappedData.push({ ...attrs, value: chartData[key] || 0 })
  })

  return <VerticalBarChart meta={META} data={mappedData} />
}

const table = (dataset, data, shapeMeta) => {
  const rows = [['', 'Area (acres)', 'Percent (%)']]

  if (data) {
    // eslint-disable-next-line no-return-assign,no-param-reassign
    const totalAreasWithData = Object.values(data).reduce((sum, area) => (sum += area))
    data['Private Land'] = shapeMeta.area - totalAreasWithData
  } else {
    data = { 'Private Land': shapeMeta.area }
  }

  Object.entries(MAPPING_DATA).forEach(([key, attrs]) => {
    rows.push([attrs.label, precision(data[key] || 0, 2), precision((100 * (data[key] || 0)) / shapeMeta.area, 2)])
  })
  return rows
}

class Filter extends React.Component {
  constructor(props) {
    super(props)

    this.defaultValues = {
      'USFS': false,
      'BLM': false,
      'Other Public Lands': false,
      'USFWS': false,
      'Tribal': false,
      'NPS': false,
      'Military': false,
      'State': false,
      'Private Land': false,
    }
    const initValues = (props.initValues[0] && props.initValues[0][2]) || []
    this.state = {
      'USFS': initValues.indexOf('USFS') !== -1,
      'BLM': initValues.indexOf('BLM') !== -1,
      'Other Public Lands': initValues.indexOf('Other Public Lands') !== -1,
      'USFWS': initValues.indexOf('USFWS') !== -1,
      'Tribal': initValues.indexOf('Tribal') !== -1,
      'NPS': initValues.indexOf('NPS') !== -1,
      'Military': initValues.indexOf('Military') !== -1,
      'State': initValues.indexOf('State') !== -1,
      'Private Land': initValues.indexOf('Private Land') !== -1,
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.useDefaultValues && !this.props.useDefaultValues) {
      this.setState({ ...this.defaultValues }, () =>
        this.props.onChange({
          pad_owner_type: null,
        }),
      )
    }
  }

  handleOwnerTypeChange = (e, props) => {
    let updatedOwnerTypes
    if (props.label === 'Public Land') {
      updatedOwnerTypes = {
        'Military': props.checked,
        'USFS': props.checked,
        'BLM': props.checked,
        'Other Public Lands': props.checked,
        'USFWS': props.checked,
        'NPS': props.checked,
        'State': props.checked,
      }
    } else {
      updatedOwnerTypes = {
        [props.label]: props.checked,
      }
    }

    this.setState(
      state => ({ ...state, ...updatedOwnerTypes }),
      () => this.handleValueChange(),
    )
  }

  handleValueChange = () => {
    const conditions = {
      pad_owner_type: null,
    }

    const ownType = []
    Object.entries(this.state).forEach(([label, value]) => {
      if (label !== 'Public Land' && value) {
        ownType.push(label)
      }
    })
    if (ownType.length) {
      conditions.pad_owner_type = [['pad_owner_type.owner_display', '!=', ownType]]
    }

    this.props.onChange(conditions)
  }

  render() {
    let isPublicLandChecked
    if (this.props.isStatic) {
      isPublicLandChecked =
        this.props.initValues[0][2].indexOf('USFS') !== -1 &&
        this.props.initValues[0][2].indexOf('BLM') !== -1 &&
        this.props.initValues[0][2].indexOf('Other Public Lands') !== -1 &&
        this.props.initValues[0][2].indexOf('USFWS') !== -1 &&
        this.props.initValues[0][2].indexOf('NPS') !== -1 &&
        this.props.initValues[0][2].indexOf('State') !== -1
    } else {
      isPublicLandChecked =
        this.state['USFS'] &&
        this.state['BLM'] &&
        this.state['Other Public Lands'] &&
        this.state['USFWS'] &&
        this.state['NPS'] &&
        this.state['State']
    }
    return (
      <Form.Field>
        <small className="fieldHint">
          Avoid areas with the selected <b>owner type</b>
        </small>
        <List>
          <List.Item>
            <Checkbox
              checked={
                this.props.isStatic ? this.props.initValues[0][2].indexOf('Tribal') !== -1 : this.state['Tribal']
              }
              label="Tribal"
              value="Tribal"
              onChange={this.handleOwnerTypeChange}
            />
          </List.Item>
          <List.Item>
            <Checkbox
              checked={
                this.props.isStatic
                  ? this.props.initValues[0][2].indexOf('Private Land') !== -1
                  : this.state['Private Land']
              }
              label="Private Land"
              value="Private Land"
              onChange={this.handleOwnerTypeChange}
            />
          </List.Item>
          <List.Item>
            <Checkbox
              checked={isPublicLandChecked}
              label="Public Land"
              value="Public Land"
              onChange={this.handleOwnerTypeChange}
            />
            <List.List>
              <List.Item>
                <Checkbox
                  checked={
                    this.props.isStatic
                      ? this.props.initValues[0][2].indexOf('Military') !== -1
                      : this.state['Military']
                  }
                  label="Military"
                  value="Military"
                  onChange={this.handleOwnerTypeChange}
                />
              </List.Item>
              <List.Item>
                <Checkbox
                  checked={
                    this.props.isStatic ? this.props.initValues[0][2].indexOf('USFWS') !== -1 : this.state['USFWS']
                  }
                  label="USFWS"
                  value="USFWS"
                  onChange={this.handleOwnerTypeChange}
                />
              </List.Item>
              <List.Item>
                <Checkbox
                  checked={
                    this.props.isStatic ? this.props.initValues[0][2].indexOf('USFS') !== -1 : this.state['USFS']
                  }
                  label="USFS"
                  value="USFS"
                  onChange={this.handleOwnerTypeChange}
                />
              </List.Item>
              <List.Item>
                <Checkbox
                  checked={this.props.isStatic ? this.props.initValues[0][2].indexOf('NPS') !== -1 : this.state['NPS']}
                  label="NPS"
                  value="NPS"
                  onChange={this.handleOwnerTypeChange}
                />
              </List.Item>
              <List.Item>
                <Checkbox
                  checked={this.props.isStatic ? this.props.initValues[0][2].indexOf('BLM') !== -1 : this.state['BLM']}
                  label="BLM"
                  value="BLM"
                  onChange={this.handleOwnerTypeChange}
                />
              </List.Item>
              <List.Item>
                <Checkbox
                  checked={
                    this.props.isStatic ? this.props.initValues[0][2].indexOf('State') !== -1 : this.state['State']
                  }
                  label="State"
                  value="State"
                  onChange={this.handleOwnerTypeChange}
                />
              </List.Item>
              <List.Item>
                <Checkbox
                  checked={
                    this.props.isStatic
                      ? this.props.initValues[0][2].indexOf('Other Public Lands') !== -1
                      : this.state['Other Public Lands']
                  }
                  label="Other Public Lands"
                  value="Other Public Lands"
                  onChange={this.handleOwnerTypeChange}
                />
              </List.Item>
            </List.List>
          </List.Item>
        </List>
      </Form.Field>
    )
  }
}

Filter.defaultValue = null
Filter.ignoreReset = true

Filter.propTypes = {
  dataset: PropTypes.shape({}).isRequired,
  useDefaultValues: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  initValues: PropTypes.arrayOf(PropTypes.array).isRequired,
  isStatic: PropTypes.bool.isRequired,
}

export default {
  renderer,
  table,
  filter: Filter,
}
