import React from 'react'
import { Header, Table } from 'semantic-ui-react'
import { toTitleCase } from '../../utils/formatters'

const renderer = ({ data, meta }) => {
  if (!data) {
    return 'No species found.'
  }
  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Species</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {data.map(({ cname, sname }) => (
          <Table.Row key={`${cname}_${sname}`}>
            <Table.Cell>
              <Header size="small">
                {toTitleCase(cname)}
                <Header.Subheader>{sname}</Header.Subheader>
              </Header>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  )
}

const table = (dataset, data) => {
  const rows = [['Species', 'Scientific name']]
  if (data) {
    Object.values(data).forEach(d => rows.push([d.cname, d.sname]))
  }
  return rows
}

export default { renderer, table }
