/* eslint-disable import/first,import/no-unresolved */
import './config'

import axios from 'axios'
import ReactGA from 'react-ga'
import * as Sentry from '@sentry/browser'
import { compose } from 'redux'

// Axios
axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN'
axios.defaults.xsrfCookieName = 'XCSRF-TOKEN'

// Redux store
// eslint-disable-next-line
export const composeEnhancer = (window.CONFIG.DEBUG && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose

// Production stuff
if (!CONFIG.DEBUG) {
  // Sentry
  if (window.CONFIG.SENTRY_DSN) {
    Sentry.init({ dsn: window.CONFIG.SENTRY_DSN, release: window.CONFIG.SENTRY_RELEASE })
  }

  // Google Analytics
  if (CONFIG.GA_TOKEN) {
    ReactGA.initialize(CONFIG.GA_TOKEN)
  }
}
