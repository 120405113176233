import React from 'react'
import { fromJS } from 'immutable'
import PropTypes from 'prop-types'
import Slider from 'rc-slider'
import { Form, Header, List, Table } from 'semantic-ui-react'

import {
  SPECIES_BY_TARGET,
  SPECIES_INFO,
  SPECIAL_HABITATS,
  SPECIAL_HABITATS_BY_TARGET,
  ECOSYSTEMS,
  ECOSYSTEMS_BY_TARGET,
  AQUATIC_ATTRIBUTES,
} from '../../species'
import RangeHandle from '../../components/RangeHandle'
import { percent, precision } from '../../utils/formatters'
import { REGION_LAYER_NAMES } from '../../utils/rca'

const MAPPING_DATA = fromJS({
  '-0.75': {
    color: '#666',
    label: 'Very Low',
    value: 0,
    valueRange: [-1, -0.75],
  },
  '-0.5': {
    color: '#666',
    label: 'Low',
    value: 0,
    valueRange: [-0.75, -0.5],
  },
  '0': {
    color: '#666',
    label: 'Moderately Low',
    value: 0,
    valueRange: [-0.5, 0],
  },
  '0.5': {
    color: '#666',
    label: 'Moderately High',
    value: 0,
    valueRange: [0, 0.5],
  },
  '0.75': {
    color: '#666',
    label: 'High',
    value: 0,
    valueRange: [0.5, 0.75],
  },
  '1': {
    color: '#666',
    label: 'Very High',
    value: 0,
    valueRange: [0.75, 1],
  },
})

const renderTerrestrial = (sectionProps, data) => {
  const target = sectionProps.target === 'custom' ? 'all' : sectionProps.target
  const { ecoregion, species, ecosystems, specialHabitats } = sectionProps
  const speciesInfo = SPECIES_INFO[ecoregion]

  let targetSpecies
  let targetEcosystems
  let targetHabitats

  if (sectionProps.target === 'custom') {
    targetSpecies = species.map(item => item.toLowerCase())
    targetEcosystems = Object.keys(ECOSYSTEMS[ecoregion]).filter(item => ecosystems.indexOf(item) >= 0)
    targetHabitats = Object.keys(SPECIAL_HABITATS[ecoregion]).filter(item => specialHabitats.indexOf(item) >= 0)
  } else {
    targetSpecies = SPECIES_BY_TARGET[ecoregion][target]
    targetEcosystems = ECOSYSTEMS_BY_TARGET[ecoregion][target]
    targetHabitats = SPECIAL_HABITATS_BY_TARGET[ecoregion][target]
  }

  const filteredSpecies = data.species.filter(item => targetSpecies.indexOf(item) >= 0)
  filteredSpecies.sort((sp1, sp2) =>
    speciesInfo[sp1].common_name.toLowerCase() > speciesInfo[sp2].common_name.toLowerCase() ? 1 : -1,
  )

  const ecosystemsMap = new Map(targetEcosystems.map(item => [item.toLowerCase(), ECOSYSTEMS[ecoregion][item]]))
  const filteredEcosystems = data.species.filter(item => ecosystemsMap.has(item)).map(item => ecosystemsMap.get(item))
  filteredEcosystems.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))

  const habitats = new Map(targetHabitats.map(item => [item.toLowerCase(), SPECIAL_HABITATS[ecoregion][item]]))
  const filteredHabitats = data.species.filter(item => habitats.has(item.toLowerCase()))
  filteredHabitats.sort((h1, h2) => (habitats.get(h1).toLowerCase() > habitats.get(h2).toLowerCase() ? 1 : -1))

  return (
    <>
      <p>Total species: {filteredSpecies.length}</p>
      <List bulleted>
        {filteredSpecies.map(sp => (
          <List.Item key={sp}>
            {`${speciesInfo[sp].common_name} (${speciesInfo[sp].scientific_name}), ${speciesInfo[sp].status_code}`}
          </List.Item>
        ))}
      </List>
      <p>Ecosystems: {filteredEcosystems.length}</p>
      <List bulleted>
        {filteredEcosystems.map(ecosystem => (
          <List.Item key={ecosystem}>
            {ECOSYSTEMS[ecoregion][ecosystem]} {ecosystem}
          </List.Item>
        ))}
      </List>
      <p>Special habitats: {filteredHabitats.length}</p>
      <List bulleted>
        {filteredHabitats.map(habitat => (
          <List.Item key={habitat}>{habitats.get(habitat)}</List.Item>
        ))}
        ))}
      </List>
    </>
  )
}

const renderAquatic = data => {
  return (
    <>
      {AQUATIC_ATTRIBUTES.map(({ category, attributes }) => {
        return (
          <>
            <Header as="h4">{category}</Header>
            <Table celled>
              <Table.Body>
                {attributes.map(({ label, variable }) => {
                  return (
                    <Table.Row>
                      <Table.Cell>{label}</Table.Cell>
                      <Table.Cell textAlign="right">{data.attributes[variable]}</Table.Cell>
                    </Table.Row>
                  )
                })}
              </Table.Body>
            </Table>
          </>
        )
      })}
    </>
  )
}

const renderer = ({ id, data }, sectionProps) => {
  const intersectionPercent = sectionProps.selectedSites[id].RCAs[sectionProps.ecoregion] || 0
  const ecoregionLabel = REGION_LAYER_NAMES[sectionProps.ecoregion].label
  if (!intersectionPercent) {
    return <div>Does not intersect {ecoregionLabel}</div>
  }

  if (!data.stats[0].categories) {
    return <div>No data found.</div>
  }

  return (
    <div>
      <Header.Subheader>
        {percent(intersectionPercent * 100)} intersects {ecoregionLabel}
      </Header.Subheader>
      {data.type === 'terrestrial' && renderTerrestrial(sectionProps, data)}
      {data.type === 'aquatic' && renderAquatic(data)}
    </div>
  )
}

const table = (dataset, data) => {
  const rows = [['', 'Count']]

  if (!data.stats[0].categories) {
    data.stats[0].categories = {}
  }

  MAPPING_DATA.entrySeq().forEach(([k, v]) => {
    rows.push([v.get('label'), precision(data.stats[0].categories[k] || 0, 2)])
  })
  return rows
}

class Filter extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      minValue: -1,
      maxValue: 1,
    }

    this.handleValueChange = this.handleValueChange.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.useDefaultValues && !this.props.useDefaultValues) {
      this.setState({ minValue: -1, maxValue: 1 }, () => this.props.onChange({ [this.props.dataset.dataset_id]: null }))
    }
  }

  handleValueChange([minValue, maxValue]) {
    this.setState({ minValue, maxValue }, () => {
      this.props.onChange({
        [this.props.dataset.dataset_id]:
          this.state.minValue > -1 || this.state.maxValue < 1
            ? [
                [`${this.props.dataset.dataset_id}.conservation_value`, '>=', this.state.minValue],
                [`${this.props.dataset.dataset_id}.conservation_value`, '<=', this.state.maxValue],
              ]
            : null,
      })
    })
  }

  render() {
    return (
      <Form.Field className="rangeRCA">
        <Slider.Range
          min={-1}
          max={1}
          step={0.01}
          handle={RangeHandle}
          onChange={this.handleValueChange}
          value={[this.state.minValue, this.state.maxValue]}
          marks={{
            '-1': '',
            '-0.9': '',
            '-0.875': 'Very Low',
            '-0.625': 'Low',
            '-0.25': 'Moderately Low',
            '0': '',
            '0.25': 'Moderately High',
            '0.625': 'High',
            '0.875': 'Very High',
            '0.9': '',
            '1': '',
          }}
        />
      </Form.Field>
    )
  }
}

Filter.defaultValue = null

Filter.propTypes = {
  dataset: PropTypes.shape({
    dataset_id: PropTypes.string,
  }).isRequired,
  useDefaultValues: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default { renderer, table, filter: Filter }
