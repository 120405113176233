import React from 'react'
import { Modal, Icon, Button } from 'semantic-ui-react'

class UnsupportedBrowser extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: true,
    }
  }

  render() {
    return (
      <Modal size="tiny" open={this.state.open}>
        <Modal.Content>
          <h4>Unfortunately, it appears you are using an unsupported browser.</h4>
          <p>
            Please use a supported browser such as Google Chrome, Firefox, or Safari. You might encounter errors if you
            continue.
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => this.setState({ open: false })}>
            <Icon name="exclamation triangle" />
            Continue Continue
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}

export default UnsupportedBrowser
