import React from 'react'
import Slider from 'rc-slider'
import { Table, Form } from 'semantic-ui-react'

import PropTypes from 'prop-types'
import { percent, precision } from '../../utils/formatters'
import RangeHandle from '../../components/RangeHandle'

const SAGBI_RATING = ['Excellent', 'Good', 'Moderately Good', 'Moderately Poor', 'Poor', 'Very Poor']

const renderer = ({ id, data }, { shapeMeta }) => {
  if (!data) {
    return 'No data found.'
  }

  return (
    <Table textAlign="center">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>SAGBI_RATING</Table.HeaderCell>
          <Table.HeaderCell>Area (acres)</Table.HeaderCell>
          <Table.HeaderCell>%</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {SAGBI_RATING.map(label => (
          <Table.Row key={`${id}_${label}`}>
            <Table.Cell>{label}</Table.Cell>
            <Table.Cell>{precision(data[label] || 0, 1)}</Table.Cell>
            <Table.Cell>{percent((100 * (data[label] || 0)) / shapeMeta[id].area)}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  )
}

const table = (dataset, data) => {
  const rows = [['SAGBI Rating', 'Area (acres)']]
  if (data) {
    SAGBI_RATING.forEach(label => rows.push([label, precision(data[label] || 0, 1)]))
  }
  return rows
}

class Filter extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      minValue: (props.initValues[0] && props.initValues[0][2]) || 0,
      maxValue: (props.initValues[1] && props.initValues[1][2]) || 100,
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.useDefaultValues && !this.props.useDefaultValues) {
      this.setState({ minValue: 0, maxValue: 100 }, () =>
        this.props.onChange({ [this.props.dataset.dataset_id]: null }),
      )
    }
  }

  handleValueChange = ([minValue, maxValue]) => {
    this.setState({ minValue, maxValue }, () => {
      this.props.onChange({
        [this.props.dataset.dataset_id]:
          this.state.minValue > 0 || this.state.maxValue < 100
            ? [
                [`${this.props.dataset.dataset_id}.sagbi`, '>=', this.state.minValue],
                [`${this.props.dataset.dataset_id}.sagbi`, '<=', this.state.maxValue],
              ]
            : null,
      })
    })
  }

  render() {
    let value
    if (this.props.isStatic) {
      value = [this.props.initValues[0][2], this.props.initValues[1][2]]
    } else {
      value = [this.state.minValue, this.state.maxValue]
    }
    return (
      <Form.Field className="rangeSAGBI">
        <Slider.Range
          min={0}
          max={100}
          step={1}
          handle={RangeHandle}
          onChange={this.handleValueChange}
          value={value}
          marks={{
            6: 'Very Poor',
            20: 'Poor',
            37.5: 'Moderately Poor',
            59: 'Moderately Good',
            76: 'Good',
            92: 'Excellent',
          }}
        />
      </Form.Field>
    )
  }
}

Filter.defaultValue = null

Filter.propTypes = {
  dataset: PropTypes.shape({
    dataset_id: PropTypes.string,
  }).isRequired,
  useDefaultValues: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  initValues: PropTypes.arrayOf(PropTypes.array).isRequired,
  isStatic: PropTypes.bool.isRequired,
}

export default { renderer, table, filter: Filter }
