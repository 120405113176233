import React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Label, Icon, Button } from 'semantic-ui-react'

class Share extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      copied: false,
    }
  }

  render() {
    return (
      <div>
        <div>
          Your current configuration can be loaded later by visiting the link below. Copy the link yourself or just
          click the button to copy it to the clipboard. Then share the link with a friend or bookmark it in your browser
          to save it for future use.
        </div>
        <div>&nbsp;</div>
        <Label icon="linkify" size="large" content={this.props.link} />
        <CopyToClipboard text={this.props.link} onCopy={() => this.setState({ copied: true })}>
          <Button size="small">
            <Icon name="copy" />
            Copy to Clipboard
          </Button>
        </CopyToClipboard>
        {this.state.copied ? <span style={{ color: 'red' }}> Copied</span> : null}
      </div>
    )
  }
}

export default Share
