/* eslint-disable dot-notation,quote-props */
import React from 'react'
import { fromJS } from 'immutable'
import PropTypes from 'prop-types'
import { Checkbox, Form, Icon, List, Divider } from 'semantic-ui-react'

import VerticalBarChart from '../../components/VerticalBarchart'
import { precision } from '../../utils/formatters'

const MAPPING_DATA = {
  1: {
    color: '#26643d',
    label: 'GAP 1',
    value: 0,
    tooltip: `
            An area having permanent protection from conversion of natural land cover and a mandated management plan I 
            operation to maintain a natural state within which disturbance events are allowed to proceed without 
            interference.`,
  },
  2: {
    color: '#879647',
    label: 'GAP 2',
    value: 0,
    tooltip: `
            An area having permanent protection from conversion of natural land cover and a mandated management plan in 
            operation to maintain a primarily natural state, but which may receive uses or management practices that 
            degrade the quality of existing natural communities.`,
  },
  3: {
    color: '#bbbbbb',
    label: 'GAP 3',
    value: 0,
    tooltip: `
            An area having permanent protection from conversion of natural land cover for the majority of area. 
            Subject to extractive uses.`,
  },
  4: {
    color: '#2f353c',
    label: 'GAP 4',
    value: 0,
    tooltip: 'No known public/private institutional mandates/legally recognized easements.',
  },
  Unassigned: {
    color: '#ffffff',
    label: 'Unassigned',
    value: 0,
    tooltip: 'Status is unknown.',
  },
}

const META = {
  unit: 'acres',
  convertToPercent: true,
  tooltipContent: d => d.tooltip,
}

const renderer = ({ id, data }, { shapeMeta }) => {
  let chartData = data
  if (chartData) {
    // eslint-disable-next-line no-return-assign,no-param-reassign
    const totalAreasWithData = Object.values(chartData).reduce((sum, area) => (sum += area))
    chartData[4] = shapeMeta[id].area - totalAreasWithData
  } else {
    chartData = { 4: shapeMeta[id].area }
  }

  const mappedData = []
  Object.entries(MAPPING_DATA).forEach(([key, attrs]) => {
    mappedData.push({ ...attrs, value: chartData[key] || 0 })
  })

  return <VerticalBarChart meta={META} data={mappedData} />
}

const table = (dataset, data, shapeMeta) => {
  const rows = [['', 'Area (acres)', 'Percent (%)']]

  if (data) {
    // eslint-disable-next-line no-return-assign,no-param-reassign
    const totalAreasWithData = Object.values(data).reduce((sum, area) => (sum += area))
    data[4] = shapeMeta.area - totalAreasWithData
  } else {
    data = { 4: shapeMeta.area }
  }

  Object.entries(MAPPING_DATA).forEach(([key, attrs]) => {
    rows.push([attrs.label, precision(data[key] || 0, 2), precision((100 * (data[key] || 0)) / shapeMeta.area, 2)])
  })
  return rows
}

class Filter extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      avoid_protected: props.initValues.length > 0,
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.useDefaultValues && !this.props.useDefaultValues) {
      this.setState({ avoid_protected: false }, () => this.props.onChange({ pad_gap_status: null }))
    }
  }

  handleValueChange = () => {
    this.props.onChange({
      [this.props.dataset.dataset_id]: this.state.avoid_protected
        ? [['pad_gap_status.gap_sts', '!=', ['1', '2']]]
        : null,
    })
  }

  render() {
    return (
      <Form.Field>
        <Checkbox
          label="Avoid protected areas"
          checked={this.state.avoid_protected}
          onChange={(e, props) => this.setState({ avoid_protected: props.checked }, () => this.handleValueChange())}
        />
      </Form.Field>
    )
  }
}

Filter.defaultValue = null
Filter.ignoreReset = true

Filter.propTypes = {
  dataset: PropTypes.shape({
    dataset_id: PropTypes.string,
  }).isRequired,
  useDefaultValues: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  initValues: PropTypes.arrayOf(PropTypes.array).isRequired,
  isStatic: PropTypes.bool.isRequired,
}

export default {
  renderer,
  table,
  filter: Filter,
}
