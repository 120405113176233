import React from 'react'
import { connect } from 'react-redux'
import { Modal, Header } from 'semantic-ui-react'
import { updateModal } from '../actions/page'

class CaveatModal extends React.Component {
  render() {
    return (
      <Modal
        closeIcon
        open={this.props.active}
        onClose={() => this.props.updateModal({ active: false, text: '' })}
        size="tiny"
      >
        <Modal.Content>
          <Header>Disclaimer:</Header>
          <Modal.Description>{this.props.text}</Modal.Description>
        </Modal.Content>
      </Modal>
    )
  }
}

export default connect(null, { updateModal })(CaveatModal)
