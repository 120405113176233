import React from 'react'
import { Header, Menu, Message, Segment } from 'semantic-ui-react'

import { GEOMETRY_SOURCES } from '../../actions/map'
import Draw from './Draw'
import Upload from './Upload'

class Regions extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      view: GEOMETRY_SOURCES.draw,
    }
  }

  handleViewChange = view => {
    this.setState({ view })
  }

  render() {
    let View
    switch (this.state.view) {
      case GEOMETRY_SOURCES.upload:
        View = <Upload />
        break
      case GEOMETRY_SOURCES.draw:
      default:
        View = <Draw />
    }
    return (
      <Segment basic>
        <Header as="h2" textAlign="center">
          Add Areas
        </Header>

        <Message positive size="small">
          From this page, you can either draw potential sites for analysis or upload a shapefile containing the shapes
          of potential interest. Click on <i>Draw</i> or <i>Upload</i> below and follow the instructions.
        </Message>

        <Menu pointing secondary size="massive" widths={3}>
          <Menu.Item
            active={this.state.view === GEOMETRY_SOURCES.draw}
            onClick={() => this.handleViewChange(GEOMETRY_SOURCES.draw)}
          >
            Draw
          </Menu.Item>
          <Menu.Item
            active={this.state.view === GEOMETRY_SOURCES.upload}
            onClick={() => this.handleViewChange(GEOMETRY_SOURCES.upload)}
          >
            Upload
          </Menu.Item>
        </Menu>

        {View}
      </Segment>
    )
  }
}

export default Regions
