import React from 'react'
import { fromJS } from 'immutable'

import VerticalBarChart from '../../components/VerticalBarchart'
import { precision } from '../../utils/formatters'

const MAPPING_DATA = fromJS({
  'NONE': {
    color: '#fafcff',
    label: 'None',
    value: 0,
    valueRange: [-1, -0.75],
  },
  'VERY LOW': {
    color: '#bed2ff',
    label: 'Very Low',
    value: 0,
    valueRange: [-0.75, -0.5],
  },
  'LOW': {
    color: '#7a8df5',
    label: 'Low',
    value: 0,
    valueRange: [-0.5, 0],
  },
  'MEDIUM': {
    color: '#6577cf',
    label: 'Medium',
    value: 0,
    valueRange: [0, 0.5],
  },
  'HIGH': {
    color: '#454f88',
    label: 'High',
    value: 0,
    valueRange: [0.5, 0.75],
  },
})

const META = {
  unit: 'acres',
  convertToPercent: true,
  showTooltip: false,
}

const renderer = ({ data, meta }) => {
  if (!data) {
    return 'No data found.'
  }

  return (
    <VerticalBarChart
      meta={{ ...META, ...meta }}
      data={
        MAPPING_DATA.map(
          (v, k) => v.set('value', data[k] || 0),
        ).valueSeq().toJS()
      }
    />
  )
}

const table = (dataset, data = {}, shapeMeta) => {
  const rows = [['', 'Area (area)', 'Percent (%)']]

  MAPPING_DATA.entrySeq().forEach(([k, v]) => {
    rows.push([v.get('label'), precision(data[k] || 0, 2), precision((100 * (data[k] || 0)) / shapeMeta.area, 2)])
  })
  return rows
}

export default { renderer, table }
