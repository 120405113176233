import PropTypes from 'prop-types'
import React from 'react'

import { Checkbox, Form } from 'semantic-ui-react'
import Intersects from './Intersects'

class Filter extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      avoid_disadvantaged_commmunities: props.initValues.length > 0,
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.useDefaultValues && !this.props.useDefaultValues) {
      this.setState(
        { avoid_disadvantaged_commmunities: false },
        this.props.onChange({ [this.props.dataset.dataset_id]: null }),
      )
    }
  }

  handleValueChange = () => {
    const conditions = []
    if (this.state.avoid_disadvantaged_commmunities) {
      conditions.push([`${this.props.dataset.dataset_id}.group`, '==', null])
    }
    this.props.onChange({ [this.props.dataset.dataset_id]: conditions.length ? conditions : null })
  }

  render() {
    return (
      <Form.Field>
        <Checkbox
          label="Avoid Disadvantaged Communities"
          checked={this.state.avoid_disadvantaged_commmunities}
          onChange={(e, props) =>
            this.setState({ avoid_disadvantaged_commmunities: props.checked }, () => this.handleValueChange())}
        />
      </Form.Field>
    )
  }
}

Filter.defaultValue = null

Filter.propTypes = {
  dataset: PropTypes.shape({}).isRequired,
  useDefaultValues: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  initValues: PropTypes.arrayOf(PropTypes.array).isRequired,
  isStatic: PropTypes.bool.isRequired,
}

export default {
  renderer: Intersects.renderer,
  table: Intersects.table,
  filter: Filter,
}
