import axios from 'axios'

import { fetchQueryResults, fetchRCAResults } from '../utils/io'
import { STEPS, updateLoadingStatus, updateActiveStep } from './page'

export const ACTIONS = {
  UPDATE_RESULTS: 'UPDATE_RESULTS',
}

export function updateResults(results = {}, action = 'replace') {
  // actions are `replace` and `merge`
  return {
    type: ACTIONS.UPDATE_RESULTS,
    results,
    action,
  }
}

export function runAnalysis(action, selectedSites, targets) {
  // TODO update function arguments to make it more readable
  let successResponseCount = 0
  return dispatch => {
    dispatch(updateLoadingStatus(true))
    switch (action) {
      // eslint-disable-next-line no-case-declarations
      case 'rca':
        // target for this action is the ncdjango service url
        targets.forEach(([ecoregion, target]) => {
          const tasks = []
          const outputs = []
          selectedSites.forEach(area => {
            axios
              .post(Urls['ncdjango:geoprocessing:processingjob-list'](), {
                job: 'zonal_stats',
                inputs: JSON.stringify({
                  rasters: [`service://${target.service}`],
                  feature: area.geom,
                  region: ecoregion,
                }),
              })
              .then(response => {
                tasks.push({
                  taskId: response.data.uuid,
                  shapeId: area.id,
                })
                if (tasks.length === selectedSites.length) {
                  fetchRCAResults(tasks, (status, output, shapeId) => {
                    if (status === 'success') {
                      outputs.push({
                        id: shapeId,
                        data: JSON.parse(output).results,
                      })
                      if (outputs.length === selectedSites.length) {
                        dispatch({
                          type: ACTIONS.UPDATE_RESULTS,
                          results: { [`rca_${ecoregion}_${target.value}`]: outputs },
                          action: 'merge',
                        })
                        successResponseCount += 1
                      }
                      if (successResponseCount === targets.length) {
                        dispatch(updateLoadingStatus(false))
                      }
                    } else {
                      dispatch(updateLoadingStatus(false))
                    }
                  })
                }
              })
              .catch(() => dispatch(dispatch(updateLoadingStatus(false))))
          })
        })
        break
      case 'meta':
        dispatch(updateResults({}, 'replace'))
      // falls through
      case 'analyze':
        // `targets` for `meta` and `analyze` actions is an array of `dataset_ids`
        axios
          .post(Urls['api:query'](), {
            action,
            selectedSites: JSON.stringify(selectedSites),
            selected_datasets: targets,
            proposition_id: window.PROPOSITION ? window.PROPOSITION.slug : '',
          })
          .then(response => {
            fetchQueryResults(response.data.task_ids, (status, results) => {
              if (status === 'error') {
                dispatch(updateLoadingStatus(false))
              } else {
                dispatch({
                  type: ACTIONS.UPDATE_RESULTS,
                  results,
                  action: 'merge',
                })
                successResponseCount += 1
                if (action === 'meta' || successResponseCount === targets.length) {
                  dispatch(updateLoadingStatus(false))
                }
                if (action === 'meta') {
                  dispatch(updateActiveStep(STEPS.ANALYZE))
                }
              }
            })
          })
          .catch(() => dispatch(dispatch(updateLoadingStatus(false))))
        break
    }
  }
}
