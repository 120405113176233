import React from 'react'

const Disclaimer = () => (
  <div>
    <b>DISCLAIMER OF WARRANTIES:</b>
    <p>
      YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK. THE SERVICE IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS.
      PROVIDER MAKES NO WARRANTY THAT THE SERVICE WILL MEET YOUR REQUIREMENTS OR WILL BE UNINTERRUPTED, TIMELY, SECURE,
      OR ERROR-FREE. PROVIDER EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT
      NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.
      ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICE IS DONE AT YOUR OWN DISCRETION AND
      RISK AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER OR LOSS OF DATA THAT RESULTS FROM THE
      DOWNLOAD OF ANY SUCH MATERIAL. NO CONTENT OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM PROVIDER
      OR THROUGH OR FROM THE SERVICE SHALL CREATE ANY WARRANTY.
    </p>
    <b>LIMITATION OF LIABILITY:</b>
    <p>
      YOU EXPRESSLY UNDERSTAND AND AGREE THAT PROVIDER SHALL NOT BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT, INCIDENTAL,
      SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL,
      USE, DATA OR OTHER LOSSES (EVEN IF PROVIDER HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), RESULTING FROM:
      (I) THE USE OR THE INABILITY TO USE THE SERVICE; UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR COMPUTER,
      TRANSMISSIONS OR DATA; (III) STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON THE SERVICE; OR (IV) ANY OTHER MATTER
      RELATING TO THE SERVICE.
    </p>
  </div>
)

export default Disclaimer
