import React from 'react'
import { fromJS } from 'immutable'
import Slider from 'rc-slider'
import { Form } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import { precision } from '../../utils/formatters'

import VerticalBarChart from '../../components/VerticalBarchart'
import RangeHandle from '../../components/RangeHandle'

const MAPPING_DATA = fromJS({
  'V.Low': {
    color: '#3462cf',
    label: 'Very Low',
    value: 0,
    valueRange: [-1, 0],
  },
  'Low': {
    color: '#8b97cc',
    label: 'Low',
    value: 0,
    valueRange: [0, 0.125],
  },
  'M.Low': {
    color: '#dadbc5',
    label: 'Moderately Low',
    value: 0,
    valueRange: [0.125, 0.25],
  },
  'M.High': {
    color: '#f7d59e',
    label: 'Moderately High',
    value: 0,
    valueRange: [0.25, 0.375],
  },
  'High': {
    color: '#e08865',
    label: 'High',
    value: 0,
    valueRange: [0.375, 0.5],
  },
  'V.High': {
    color: '#c44539',
    label: 'Very High',
    value: 0,
    valueRange: [0.5, 1],
  },
})

const META = {
  unit: 'acres',
  convertToPercent: true,
  tooltipContent: d => `${d.valueRange[0]} <= Climate Change Exposure <= ${d.valueRange[1]}`,
}

const renderer = ({ data }) => {
  if (!data) {
    return 'No data found.'
  }

  return (
    <VerticalBarChart
      meta={META}
      data={
        MAPPING_DATA.map(
          (v, k) => v.set('value', data[k] || 0),
        ).valueSeq().toJS()
      }
    />
  )
}

const table = (dataset, data, shapeMeta) => {
  const rows = [['', 'Area (area)', 'Percent (%)']]

  if (!data) {
    data = {}
  }

  MAPPING_DATA.entrySeq().forEach(([k, v]) => {
    rows.push([v.get('label'), precision(data[k] || 0, 2), precision((100 * (data[k] || 0)) / shapeMeta.area, 2)])
  })
  return rows
}

class Filter extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      minValue: (props.initValues[0] && props.initValues[0][2]) || -0.5,
      maxValue: (props.initValues[1] && props.initValues[1][2]) || 0.75,
    }

    this.handleValueChange = this.handleValueChange.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.useDefaultValues && !this.props.useDefaultValues) {
      this.setState({ minValue: -0.5, maxValue: 0.75 }, () =>
        this.props.onChange({ [this.props.dataset.dataset_id]: null }),
      )
    }
  }

  handleValueChange([minValue, maxValue]) {
    this.setState({ minValue, maxValue }, () => {
      this.props.onChange({
        [this.props.dataset.dataset_id]:
          this.state.minValue > -0.5 || this.state.maxValue < 0.75
            ? [
                [`${this.props.dataset.dataset_id}.cce`, '>=', this.state.minValue],
                [`${this.props.dataset.dataset_id}.cce`, '<=', this.state.maxValue],
              ]
            : null,
      })
    })
  }

  render() {
    let value
    if (this.props.isStatic) {
      value = [this.props.initValues[0][2], this.props.initValues[1][2]]
    } else {
      value = [this.state.minValue, this.state.maxValue]
    }
    return (
      <Form.Field className="rangeCCE">
        <Slider.Range
          min={-0.5}
          max={0.75}
          step={0.01}
          handle={RangeHandle}
          onChange={this.handleValueChange}
          value={value}
          marks={{
            '-0.25': 'Very Low',
            '0.03': 'Low',
            '0.16': 'M. Low',
            '0.31': 'M. High',
            '0.44': 'High',
            '0.62': 'Very High',
          }}
        />
      </Form.Field>
    )
  }
}

Filter.defaultValue = null

Filter.propTypes = {
  dataset: PropTypes.shape({
    dataset_id: PropTypes.string,
  }).isRequired,
  useDefaultValues: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  initValues: PropTypes.arrayOf(PropTypes.array).isRequired,
  isStatic: PropTypes.bool.isRequired,
}

export default { renderer, table, filter: Filter }
