import React from 'react'
import { fromJS } from 'immutable'
import Slider from 'rc-slider'
import { Form } from 'semantic-ui-react'

import PropTypes from 'prop-types'
import RangeHandle from '../../components/RangeHandle'
import VerticalBarChart from '../../components/VerticalBarchart'
import { precision } from '../../utils/formatters'

const MAPPING_DATA = fromJS({
  'V.Low': {
    color: '#454f89',
    label: 'Very Low',
    value: 0,
    valueRange: [-1, -0.75],
  },
  'Low': {
    color: '#9eaad7',
    label: 'Low',
    value: 0,
    valueRange: [-0.75, -0.5],
  },
  'M.Low': {
    color: '#ffffbe',
    label: 'Moderately Low',
    value: 0,
    valueRange: [-0.5, 0],
  },
  'M.High': {
    color: '#bfd67b',
    label: 'Moderately High',
    value: 0,
    valueRange: [0, 0.5],
  },
  'High': {
    color: '#7f9a51',
    label: 'High',
    value: 0,
    valueRange: [0.5, 0.75],
  },
  'V.High': {
    color: '#354d21',
    label: 'Very High',
    value: 0,
    valueRange: [0.75, 1],
  },
})

const META = {
  unit: 'acres',
  convertToPercent: true,
  tooltipContent: d => `${d.valueRange[0]} <= TI <= ${d.valueRange[1]}`,
}

const renderer = ({ data, meta }) => {
  if (!data) {
    return 'No data found.'
  }
  return (
    <VerticalBarChart
      meta={{ ...META, ...(meta || {}) }}
      data={
        MAPPING_DATA.map(
          (v, k) => v.set('value', data[k] || 0),
        ).valueSeq().toJS()
      }
    />
  )
}

const table = (dataset, data = {}, shapeMeta) => {
  const rows = [['', 'Area (area)', 'Percent (%)']]

  MAPPING_DATA.entrySeq().forEach(([k, v]) => {
    rows.push([v.get('label'), precision(data[k] || 0, 2), precision((100 * (data[k] || 0)) / shapeMeta.area, 2)])
  })
  return rows
}

class Filter extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      minValue: (props.initValues[0] && props.initValues[0][2]) || -1,
      maxValue: (props.initValues[1] && props.initValues[1][2]) || 1,
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.useDefaultValues && !this.props.useDefaultValues) {
      this.setState({ minValue: -1, maxValue: 1 }, () => this.props.onChange({ [this.props.dataset.dataset_id]: null }))
    }
  }

  handleValueChange = ([minValue, maxValue]) => {
    this.setState({ minValue, maxValue }, () => {
      this.props.onChange({
        [this.props.dataset.dataset_id]:
          this.state.minValue > -1 || this.state.maxValue < 1
            ? [
                [`${this.props.dataset.dataset_id}.ti`, '>=', this.state.minValue],
                [`${this.props.dataset.dataset_id}.ti`, '<=', this.state.maxValue],
              ]
            : null,
      })
    })
  }

  render() {
    let value
    if (this.props.isStatic) {
      value = [this.props.initValues[0][2], this.props.initValues[1][2]]
    } else {
      value = [this.state.minValue, this.state.maxValue]
    }
    return (
      <Form.Field className="rangeTI">
        <Slider.Range
          min={-1}
          max={1}
          step={0.01}
          handle={RangeHandle}
          onChange={this.handleValueChange}
          value={value}
          marks={{
            '-0.875': 'Very Low',
            '-0.625': 'Low',
            '-0.25': 'Moderately Low',
            '0.25': 'Moderately High',
            '0.625': 'High',
            '0.875': 'Very High',
          }}
        />
      </Form.Field>
    )
  }
}

Filter.defaultValue = null

Filter.propTypes = {
  dataset: PropTypes.shape({
    dataset_id: PropTypes.string,
  }).isRequired,
  useDefaultValues: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  initValues: PropTypes.arrayOf(PropTypes.array).isRequired,
  isStatic: PropTypes.bool.isRequired,
}

export default { renderer, table, filter: Filter }
