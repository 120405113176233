export const ACTIONS = {
  UPDATE_OVERLAYS_STATUS: 'UPDATE_OVERLAYS_STATUS',
  UPDATE_MAP_IMAGES_STATUS: 'UPDATE_MAP_IMAGES_STATUS',
  UPDATE_TARGET_OVERLAY: 'UPDATE_TARGET_OVERLAY',
  UPDATE_DRAW_STATUS: 'UPDATE_DRAW_STATUS',
  UPDATE_UPLOAD_STATUS: 'UPDATE_UPLOAD_STATUS',
  UPDATE_IDENTIFY_TARGET: 'UPDATE_IDENTIFY_TARGET',
  UPDATE_GEOMETRIES_SOURCE: 'UPDATE_GEOMETRIES_SOURCE',
  ADD_SHAPEFILE: 'ADD_SHAPEFILE',
  REMOVE_SHAPEFILE: 'REMOVE_SHAPEFILE',
  CLEAR_SHAPEFILES: 'CLEAR_SHAPEFILES',
}

export const GEOMETRY_SOURCES = {
  existing: 'existing',
  filter: 'filter',
  draw: 'draw',
  upload: 'upload',
}

export function updateOverlaysStatus() {
  return {
    type: ACTIONS.UPDATE_OVERLAYS_STATUS,
  }
}

export function updateMapImagesStatus(hasMapImages) {
  return {
    type: ACTIONS.UPDATE_MAP_IMAGES_STATUS,
    hasMapImages,
  }
}

export function updateDrawStatus(isDrawing) {
  return {
    type: ACTIONS.UPDATE_DRAW_STATUS,
    isDrawing,
  }
}

export function updateUploadStatus(isUploading) {
  return {
    type: ACTIONS.UPDATE_UPLOAD_STATUS,
    isUploading,
  }
}

export function updateIdentifyTarget(target) {
  return {
    type: ACTIONS.UPDATE_IDENTIFY_TARGET,
    target,
  }
}

export function updateGeometriesSource(source) {
  return {
    type: ACTIONS.UPDATE_GEOMETRIES_SOURCE,
    source,
  }
}

export function addShapefile(shapefile) {
  return {
    type: ACTIONS.ADD_SHAPEFILE,
    shapefile,
  }
}

export function removeShapefile(leafletId) {
  return {
    type: ACTIONS.REMOVE_SHAPEFILE,
    leafletId,
  }
}

export function clearShapefiles() {
  return {
    type: ACTIONS.CLEAR_SHAPEFILES,
  }
}
