import axios from 'axios/index'

export const ACTIONS = {
  LOAD_STATE: 'LOAD_STATE',
}

export function stateDidLoad(state, map) {
  return {
    type: ACTIONS.LOAD_STATE,
    state,
    map,
  }
}

export function loadState(hash, map) {
  return dispatch => {
    axios
      .get(Urls['api:get-shared-link'](hash))
      .then(response => {
        if (response.data) {
          dispatch(stateDidLoad(response.data, map))
        }
      })
      .catch(error => console.log(error))
  }
}
