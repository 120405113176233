import React from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Button, Checkbox, Form, Header, Menu, Message, Modal, Segment, Table } from 'semantic-ui-react'

import { REGION_LAYER_NAMES, getEcoregionAndTarget } from '../../utils/rca'
import { SPECIES_INFO, SPECIAL_HABITATS } from '../../species'
import { runAnalysis } from '../../actions/analysis'
import { updateMapImagesStatus } from '../../actions/map'
import { updateLoadingStatus } from '../../actions/page'
import { createReport } from '../../actions/report'
import LayoutContext from '../../context'
import { CATEGORIES_LABELS, LAYER_STYLES } from '../Map'
import renderers from '../renderers'

class Report extends React.Component {
  constructor(props) {
    super(props)

    this.categories = new Map()
    this.categoryDatasets = new Map()
    if (Object.keys(props.sitesRCAs).length) {
      this.categories.rca = 'Weighted Conservation Target Models'
      this.categoryDatasets.rca = {}
    }
    this.categoryNum = 0
    Object.entries(CATEGORIES_LABELS).forEach(([category, label]) => {
      this.categories[category] = label
      this.categoryDatasets[category] = {}
      this.categoryNum += 1
      if (category === 'env') {
        this.categories.ace = 'CDFW ACE III'
        this.categoryDatasets.ace = {}
        this.categoryNum += 1
      }
    })

    const aceDatasets = []
    Object.entries(props.datasets).forEach(([category, datasets]) => {
      datasets.sort((d1, d2) => (d1.label.toLowerCase() > d2.label.toLowerCase() ? 1 : -1))
      datasets.forEach(dataset => {
        if (dataset.has_query) {
          if (dataset.sub_categories && dataset.sub_categories[0] === 'CDFW ACE III') {
            if (dataset.sub_categories[1]) {
              dataset.label = `${dataset.sub_categories[1]} - ${dataset.label}`
            }
            aceDatasets.push(dataset)
          } else {
            this.categoryDatasets[category][dataset.dataset_id] = dataset
          }
        }
      })
    })
    aceDatasets.sort((d1, d2) => (d1.label.toLowerCase() > d2.label.toLowerCase() ? 1 : -1))
    aceDatasets.forEach(d => {
      this.categoryDatasets.ace[d.dataset_id] = d
    })

    this.state = {
      selectedDatasets: [],
      view: 'datasets',
      reportName: '',
      reportDesc: '',
      author: '',
      date: new Date().toLocaleDateString(),
    }
  }

  getTableSections = () => {
    const header = []
    const body = []

    Object.entries(this.categories)
      .filter(([, label]) => label !== 'none')
      .forEach(([category, label], categoryIdx) => {
        header.push(
          <Table.HeaderCell key={category}>
            <Checkbox
              label={label}
              disabled={this.props.isLoading}
              onChange={(e, data) => this.handleCategoryToggle(category, data.checked)}
            />
          </Table.HeaderCell>,
        )
        if (category !== 'rca' && this.categoryDatasets[category]) {
          Object.values(this.categoryDatasets[category]).forEach((dataset, idx) => {
            const row = body[idx] || Array(this.categoryNum).fill(null)
            row[categoryIdx] = (
              <Table.Cell key={dataset.dataset_id} className="borderless">
                <Checkbox
                  label={dataset.label}
                  checked={this.state.selectedDatasets.indexOf(dataset.dataset_id) > -1}
                  disabled={this.props.isLoading}
                  onChange={(e, data) => {
                    if (!this.props.isLoading) {
                      this.handleDatasetToggle(dataset.dataset_id, data.checked)
                    }
                  }}
                />
              </Table.Cell>
            )
            body.splice(idx, 1, row)
          })
        }
      })

    let idx = 0
    Object.entries(this.props.sitesRCAs).forEach(([ecoregion, targets]) => {
      targets.forEach(t => {
        const row = body[idx++] || Array(this.categoryNum).fill(null)
        row[0] = (
          <Table.Cell key={`rca_${ecoregion}_${t.value}`} className="borderless">
            <Checkbox
              label={t.label}
              checked={this.state.selectedDatasets.indexOf(`rca_${ecoregion}_${t.value}`) > -1}
              disabled={this.props.isLoading}
              onChange={(e, data) => {
                if (!this.props.isLoading) {
                  this.handleDatasetToggle(`rca_${ecoregion}_${t.value}`, data.checked)
                }
              }}
            />
          </Table.Cell>
        )
      })
    })
    return {
      header,
      body,
    }
  }

  handleCategoryToggle = (category, isChecked) => {
    const { selectedDatasets } = this.state
    if (isChecked) {
      const targets = []
      if (category === 'rca') {
        Object.entries(this.props.sitesRCAs).forEach(([ecoregion, ecoregionTargets]) => {
          ecoregionTargets.forEach(target => {
            if (!this.props.queryResults[`rca_${ecoregion}_${target.value}`]) {
              targets.push([ecoregion, target])
            }
            if (selectedDatasets.indexOf(`rca_${ecoregion}_${target.value}`) === -1) {
              selectedDatasets.push(`rca_${ecoregion}_${target.value}`)
            }
          })
        })
        if (targets.length) {
          this.props.runAnalysis('rca', this.context.map.prepareSelectedSitesForAnalysis(), targets)
        }
      } else {
        Object.values(this.categoryDatasets[category]).forEach(dataset => {
          if (!this.props.queryResults[dataset.dataset_id]) {
            targets.push(dataset.dataset_id)
          }
          if (selectedDatasets.indexOf(dataset.dataset_id) === -1) {
            selectedDatasets.push(dataset.dataset_id)
          }
        })
        if (targets.length) {
          this.props.runAnalysis('analyze', this.context.map.prepareSelectedSitesForAnalysis(), targets)
        }
      }
    } else if (category === 'rca') {
      Object.entries(this.props.sitesRCAs).forEach(([ecoregion, ecoregionTargets]) => {
        ecoregionTargets.forEach(target => {
          selectedDatasets.splice(selectedDatasets.indexOf(`rca_${ecoregion}_${target.value}`), 1)
        })
      })
    } else {
      Object.values(this.categoryDatasets[category]).forEach(dataset => {
        selectedDatasets.splice(selectedDatasets.indexOf(dataset.dataset_id), 1)
      })
    }
    this.setState({ selectedDatasets })
  }

  handleDatasetToggle = (datasetId, isChecked) => {
    const { selectedDatasets } = this.state
    if (isChecked) {
      if (!this.props.queryResults[datasetId]) {
        if (datasetId.indexOf('rca') === 0) {
          const [ecoregion, target] = getEcoregionAndTarget(datasetId)
          this.props.runAnalysis('rca', this.context.map.prepareSelectedSitesForAnalysis(), [
            [ecoregion, this.props.sitesRCAs[ecoregion].find(t => t.value === target)],
          ])
        } else {
          this.props.runAnalysis('analyze', this.context.map.prepareSelectedSitesForAnalysis(), [datasetId])
        }
      }
      selectedDatasets.push(datasetId)
    } else {
      selectedDatasets.splice(selectedDatasets.indexOf(datasetId), 1)
    }
    this.setState({ selectedDatasets })
  }

  startMapImageTasks = () => {
    const mapConfig = {}
    const basemapLayer = this.context.map.basemapControl.options.basemaps.find(
      bl => bl._leaflet_id === this.context.map.basemapControl.basemap._leaflet_id,
    )
    const baseLayers = [
      {
        type: 'tileLayer',
        args: [basemapLayer._url, basemapLayer.options],
      },
    ]

    Object.entries(this.props.sitesRCAs).forEach(([ecoregion, targets]) => {
      targets.forEach(target => {
        const isTerrestrial = target.value !== 'aquatic'
        const ecoregionBounds = this.context.map.overlays[
          isTerrestrial ? 'Ecoregions (USDA Section)' : 'Watersheds (HUC 4)'
        ].layer
          .getLayers()
          .find(layer => layer.feature.properties.value === ecoregion)
          .getBounds()

        mapConfig[`rca_${ecoregion}_${target.value}`] = {
          label: target.label,
          width: 500,
          height: 350,
          // eslint-disable-next-line no-underscore-dangle
          bounds: [ecoregionBounds._southWest, ecoregionBounds._northEast],
          layers: baseLayers.concat({
            type: 'tileLayer',
            args: [
              `${window.location.protocol}//${window.location.host}/${
                this.context.map.overlays[`${ecoregion}_${target.value}`].layer._url
              }`,
            ],
          }),
        }
      })
    })

    const regionMapConfig = []
    switch (this.props.regionType) {
      case 'ecoregion': {
        const allRegionLayers = this.context.map.overlays['Ecoregions (USDA Section)'].layer.getLayers()
        this.props.regionListIds.forEach(idx => {
          regionMapConfig.push({
            type: 'geoJSON',
            args: [
              allRegionLayers[idx].toGeoJSON(),
              {
                style: LAYER_STYLES.unselected,
              },
            ],
          })
        })
        break
      }
      case 'county': {
        const allRegionLayers = this.context.map.overlays.Counties.layer.getLayers()
        this.props.regionListIds.forEach(idx => {
          regionMapConfig.push({
            type: 'geoJSON',
            args: [
              allRegionLayers[idx].toGeoJSON(),
              {
                style: LAYER_STYLES.unselected,
              },
            ],
          })
        })
        break
      }
      case 'watersheds': {
        const allRegionLayers = this.context.map.overlays['Watersheds (HUC 4)'].layer.getLayers()
        this.props.regionListIds.forEach(idx => {
          regionMapConfig.push({
            type: 'geoJSON',
            args: [
              allRegionLayers[idx].toGeoJSON(),
              {
                style: LAYER_STYLES.unselected,
              },
            ],
          })
        })
        break
      }
      case 'custom':
        regionMapConfig.push({
          type: 'geoJSON',
          args: [
            this.context.map.customRegionLayer.toGeoJSON(),
            {
              style: LAYER_STYLES.unselected,
            },
          ],
        })
        break
    }

    Object.keys(this.props.selectedSites).forEach(layerId => {
      const layer = this.context.map.sitesLayer.getLayer(layerId)
      const layerBounds = layer.getBounds()
      const marker = this.context.map.selectedSitesMarkers[layerId]
      const siteLayerConfig = [
        {
          type: 'geoJSON',
          args: [
            layer.toGeoJSON().geometry,
            {
              style: LAYER_STYLES.default,
            },
          ],
        },
        {
          type: 'marker',
          args: [
            marker.getLatLng(),
            {
              icon: {
                iconUrl: marker.getElement().src,
                iconSize: [30, 45],
                iconAnchor: [15, 45],
              },
              pane: 'tooltipPane',
            },
          ],
        },
      ]
      regionMapConfig.push(...siteLayerConfig)
      mapConfig[layerId] = {
        width: 350,
        height: 350,
        bounds: [layerBounds._southWest, layerBounds._northEast],
        layers: baseLayers.concat(siteLayerConfig),
      }
    })

    const regionMapBounds = this.context.map.customRegionLayer.getBounds()
    mapConfig.region_map = {
      width: 600,
      height: 400,
      bounds: [regionMapBounds._southWest, regionMapBounds._northEast],
      layers: baseLayers.concat(regionMapConfig),
    }

    axios
      .post(Urls['api:generate-map'](), mapConfig)
      .then(() => {
        this.mapImageTasksStatus = 'ready'
        this.props.updateMapImagesStatus(true)
      })
      .catch(() => {
        this.mapImageTasksStatus = 'error'
      })
  }

  submitReportRequest = (reportType, data) => {
    if (this.interval) {
      clearInterval(this.interval)
    }
    if (this.mapImageTasksStatus === 'ready') {
      this.mapImageTasksStatus = null
      this.props.createReport(data, reportType)
    } else if (this.mapImageTasksStatus === 'error') {
      this.mapImageTasksStatus = null
      this.props.updateLoadingStatus(true)
    } else {
      this.interval = setInterval(() => this.submitReportRequest(reportType, data), 50)
    }
  }

  handleReportCreation = reportType => {
    if (this.props.hasMapImages) {
      this.mapImageTasksStatus = 'ready'
    } else {
      this.startMapImageTasks()
    }
    this.props.updateLoadingStatus(true)
    this.props.postReportCallback()

    const customModels = {}
    Object.entries(this.props.models).forEach(([ecoregion, customModel]) => {
      const model = {
        region: ecoregion,
        ecosystems_index: customModel.ecosystems_index,
      }
      model.species = customModel.species.map(sp => {
        const spInfo = SPECIES_INFO[ecoregion][sp.toLowerCase()]
        return {
          cname: spInfo.common_name,
          sname: spInfo.scientific_name,
        }
      })
      model.special_habitats = customModel.special_habitats.map(sf => SPECIAL_HABITATS[ecoregion][sf])
      customModels[ecoregion] = model
    })

    const data = {
      meta: this.props.queryResults.meta,
      abstracts: this.props.queryResults.abstracts,
      custom_models: customModels,
      sites_rcas: this.props.sitesRCAs,
      project_details: {
        name: this.state.reportName,
        author: this.state.author,
        description: this.state.reportDesc,
        date: this.state.date,
      },
      selected_sites: this.props.selectedSites,
      models: this.props.models,
    }

    Object.entries(data.meta).forEach(([shapeId, shapeMeta]) => {
      shapeMeta.label = {
        key: this.props.selectedSites[shapeId].mapId,
        value: this.props.selectedSites[shapeId].verbose,
      }
    })

    if (reportType === 'pdf') {
      data.datasets = {}

      this.state.selectedDatasets.forEach(dataset => {
        data.datasets[dataset] = this.props.queryResults[dataset]
      })

      this.submitReportRequest(reportType, data)
    } else if (reportType === 'xlsx') {
      data.tables = {}

      const datasets = Object.values(this.categoryDatasets).reduce((all, dataset) => {
        all[dataset.dataset_id] = dataset
        return all
      }, {})
      this.state.selectedDatasets.forEach(dataset_id => {
        data.tables[dataset_id] = {}
        Object.values(this.props.queryResults[dataset_id]).forEach(shape => {
          let renderer
          if (dataset_id.indexOf('rca') === 0) {
            renderer = renderers.rca
          } else {
            renderer = renderers[dataset_id]
          }
          data.tables[dataset_id][shape.id] = renderer.table(
            datasets[dataset_id],
            shape.data,
            this.props.queryResults.meta[shape.id],
            shape,
          )
        })
      })
      this.submitReportRequest(reportType, data)
    }
  }

  renderActionButtons = () => {
    switch (this.state.view) {
      case 'datasets':
        return [
          <Menu.Item key="project_details">
            <Button
              primary
              icon="angle double right"
              labelPosition="right"
              content="Continue to Project Details"
              disabled={this.props.isLoading || !this.state.selectedDatasets.length}
              onClick={() => !this.props.isLoading && this.setState({ view: 'project_details' })}
            />
          </Menu.Item>,
        ]
      case 'project_details':
        return [
          <Menu.Item key="datasets">
            <Button
              primary
              icon="angle double left"
              content="Back to Datasets"
              disabled={this.props.isLoading}
              onClick={() => this.setState({ view: 'datasets' })}
            />
          </Menu.Item>,
          <Menu.Item key="xlsx">
            <Button
              primary
              icon="file excel outline"
              content="Excel Spreadsheet"
              disabled={this.props.isLoading || !this.state.selectedDatasets.length}
              onClick={() => this.handleReportCreation('xlsx')}
            />
          </Menu.Item>,
          <Menu.Item key="pdf">
            <Button
              primary
              icon="file pdf outline"
              content="PDF"
              disabled={this.props.isLoading || !this.state.selectedDatasets.length}
              onClick={() => this.handleReportCreation('pdf')}
            />
          </Menu.Item>,
        ]
      default:
        return []
    }
  }

  render() {
    const { header, body } = this.getTableSections()
    return (
      <Modal
        size="fullscreen"
        open
        closeOnDimmerClick={!this.props.isLoading}
        closeIcon={!this.props.isLoading}
        onClose={this.props.postReportCallback}
      >
        <Modal.Content>
          <Modal.Description>
            <Segment basic disabled={this.props.isLoading}>
              <Menu className="borderless" borderless>
                <Menu.Item>
                  <Header>Download Analysis Report</Header>
                </Menu.Item>
                <Menu.Menu position="right">{this.renderActionButtons()}</Menu.Menu>
              </Menu>

              {this.state.view === 'datasets' ? (
                <Segment basic>
                  <Message positive>
                    <p>
                      Select the datasets to include in the report and then click on the{' '}
                      <i>Continue to Project Details</i> button in the upper right corner of the pop-up page.
                    </p>

                    <p>
                      Complete fields below and then choose the format you would like the report in using the buttons in
                      the upper right hand corner.
                    </p>
                  </Message>
                  <Table size="small">
                    <Table.Header>
                      <Table.Row>{header}</Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {body.map((row, rowNum) => (
                        /* eslint-disable-next-line react/no-array-index-key */
                            <Table.Row key={rowNum}>
                          {row.map(
                            (cell, cellNum) =>
                              /* eslint-disable-next-line react/no-array-index-key */
                              cell || <Table.Cell key={cellNum} className="borderless" />,
                          )}
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </Segment>
              ) : (
                <Segment basic>
                  <Form>
                    <p>
                      <b>Date:</b> {new Date().toLocaleDateString()}
                    </p>
                    <Form.Group widths="equal" inline>
                      <Form.Field disabled={this.props.isLoading}>
                        <label>Report Name:</label>
                        <input
                          value={this.state.reportName}
                          onChange={e => this.setState({ reportName: e.target.value })}
                        />
                      </Form.Field>
                      <Form.Field disabled={this.props.isLoading}>
                        <label>Author:</label>
                        <input value={this.state.author} onChange={e => this.setState({ author: e.target.value })} />
                      </Form.Field>
                    </Form.Group>
                    <Form.Field inline disabled={this.props.isLoading}>
                      <label>Report Description:</label>
                      <textarea
                        value={this.state.reportDesc}
                        onChange={e => this.setState({ reportDesc: e.target.value })}
                      />
                    </Form.Field>
                  </Form>
                </Segment>
              )}
            </Segment>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    )
  }
}

Report.contextType = LayoutContext

Report.propTypes = {
  // parent actions
  postReportCallback: PropTypes.func,
  // redux props
  regionType: PropTypes.string.isRequired,
  regionListIds: PropTypes.arrayOf(PropTypes.string),
  datasets: PropTypes.shape({}).isRequired,
  sitesRCAs: PropTypes.shape({}).isRequired,
  models: PropTypes.shape({}).isRequired,
  hasMapImages: PropTypes.bool.isRequired,
  queryResults: PropTypes.shape({
    meta: PropTypes.shape({}),
    abstracts: PropTypes.shape({}),
  }).isRequired,
  selectedSites: PropTypes.shape({}).isRequired,
  isLoading: PropTypes.bool.isRequired,
  // redux actions
  createReport: PropTypes.func.isRequired,
  runAnalysis: PropTypes.func.isRequired,
  updateLoadingStatus: PropTypes.func.isRequired,
  updateMapImagesStatus: PropTypes.func.isRequired,
}

Report.defaultProps = {
  regionListIds: [],
  postReportCallback: null,
}

const mapStateToProps = state => {
  const sitesRCAs = state.getIn(['inputs', 'rca', 'sitesTargets']).toJS()
  Object.entries(sitesRCAs).forEach(([ecoregion, targets]) => {
    targets.forEach(target => {
      target.label = `${REGION_LAYER_NAMES[ecoregion].label} - ${target.label}`
    })
  })

  const regionType = state.getIn(['inputs', 'region', 'region'])
  let regionListIds = []
  switch (regionType) {
    case 'ecoregion':
      regionListIds = Object.keys(state.getIn(['inputs', 'region', 'ecoregions']).toJS())
      break
    case 'county':
      regionListIds = Object.keys(state.getIn(['inputs', 'region', 'counties']).toJS())
      break
    case 'watershed':
      regionListIds = Object.keys(state.getIn(['inputs', 'region', 'watersheds']).toJS())
      break
  }

  return {
    datasets: state.getIn(['config', 'datasets']).toJS(),
    regionType,
    regionListIds,
    sitesRCAs,
    models: state.getIn(['config', 'models']).toJS(),
    hasMapImages: state.getIn(['map', 'hasMapImages']),
    queryResults: state.getIn(['analysis', 'queryResults']).toJS(),
    selectedSites: state.getIn(['inputs', 'sites', 'selectedSites']).toJS(),
    selectedSitesHash: state.getIn(['inputs', 'sites', 'selectedSitesHash']),
    isLoading: state.getIn(['page', 'isLoading']),
  }
}

export default connect(mapStateToProps, {
  createReport,
  runAnalysis,
  updateLoadingStatus,
  updateMapImagesStatus,
})(Report)
