import React from 'react'
import VerticalBarChart from '../../components/VerticalBarchart'
import { percent, precision } from '../../utils/formatters'

const MAPPING_DATA = {
  '0-20': {
    color: '#ffc6c6',
    label: '0-20',
    value: 0,
    valueRange: [0, 20],
  },
  '20-40': {
    color: '#ff9595',
    label: '20-40',
    value: 0,
    valueRange: [20, 40],
  },
  '40-60': {
    color: '#ff6363',
    label: '40-60',
    value: 0,
    valueRange: [40, 60],
  },
  '60-80': {
    color: '#ff3131',
    label: '60-80',
    value: 0,
    valueRange: [60, 80],
  },
  '80-100': {
    color: '#ff0000',
    label: '80-100',
    value: 0,
    valueRange: [80, 100],
  },
}

const META = {
  unit: 'acres',
  convertToPercent: true,
  tooltipContent: d => (d.valueRange ? `${d.valueRange[0]} <= number <= ${d.valueRange[1]}` : ''),
}

const renderer = ({ id, data }, { shapeMeta }) => {
  if (!data) {
    return 'No data found.'
  }

  let totalIntersectedArea = 0
  const mappedData = []
  Object.entries(MAPPING_DATA).forEach(([_, attrs]) => {
    let intersectedArea = 0
    for (let i = attrs.valueRange[0]; i <= attrs.valueRange[1]; i++) {
      intersectedArea += data[i] || 0
    }
    totalIntersectedArea += intersectedArea
    mappedData.push({ ...attrs, value: intersectedArea || 0 })
  })
  const intersectionPercent = percent((100 * totalIntersectedArea) / shapeMeta[id].area)
  const intersectionAcres = precision(totalIntersectedArea, 2)

  return (
    <div>
      <div className="resultHelp">
        {`${intersectionPercent} (${intersectionAcres.toLocaleString()} acres)`} of this shape intersects with this
        dataset.
      </div>
      <VerticalBarChart meta={META} data={mappedData} />
    </div>
  )
}

const table = (dataset, data, shapeMeta) => {
  const rows = [['Marxan Value', 'Area (acres)']]

  if (!data) {
    data = {}
  }

  Object.entries(MAPPING_DATA).forEach(([key, attrs]) => {
    let intersectedArea = 0
    for (let i = attrs.valueRange[0]; i <= attrs.valueRange[1]; i++) {
      intersectedArea += data[i] || 0
    }
    rows.push([attrs.label, precision(intersectedArea || 0, 2)])
  })
  return rows
}

export default { renderer, table }
