import React from 'react'
import Slider from 'rc-slider'

export default props => {
  delete props.dragging
  const style = {}
  if (props.index === 0) {
    style.width = `${props.offset}%`
    style.left = 0
  } else {
    style.width = `${100 - props.offset}%`
    style.left = `${props.offset + 1}%`
  }
  return (
    <span key={props.index}>
      <Slider.Handle {...props} />
      <div className="scrim" style={style} />
    </span>
  )
}
