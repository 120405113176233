export const REGION_LAYER_NAMES = {
  mojave: {
    service: 'RCA_WM_v16',
    label: 'Mojave',
    can_customize: true,
  },
  modoc: {
    service: 'RCA_MOD_v4',
    label: 'Modoc',
    can_customize: true,
  },
  greatvalley: {
    service: 'Great_Valley_Custom_040120',
    label: 'Great Valley',
    can_customize: true,
  },
  sacramento: {
    service: 'TNC_HUC12_Sacramento_Aquatic_Conservation_Value_EEMS_v4_2',
    label: 'Sacramento',
    can_customize: false,
  },
  sanjoaquin: {
    service: 'TNC_HUC12_San_Joaquin_Aquatic_Conservation_Value_EEMS_v1_1',
    label: 'San Joaquin',
    can_customize: false,
  },
  tularebuenavistalakes: {
    service: 'TNC_HUC12_Tulare_Buena_Vista_Lakes_Aquatic_Conservation_Value_EEMS_v1_1',
    label: 'Tulare-Buena Vista Lakes',
    can_customize: false,
  },
}

export const RCA_VECTORS = {
  cv_aquatic_rca_sac: 'sacramento_aquatic',
  cv_aquatic_rca_san_j: 'sanjoaquin_aquatic',
  cv_aquatic_rca_tulare_buena: 'tularebuenavistalakes_aquatic',
  cv_terr_rca_all_great_valley: 'greatvalley_all',
  cv_terr_rca_legal_great_valley: 'greatvalley_legal_te',
}

export const getEcoregionAndTarget = inp => {
  // inp is a string in the following format: `rca_<ecoregion>_<target>`
  const ecoregion_target = inp.split('_')
  const ecoregion = ecoregion_target[1]
  const target = ecoregion_target.slice(2).join('_')
  return [ecoregion, target]
}
