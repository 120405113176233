import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Button, Grid, Icon, Input, Label, List, Message, Modal, Segment } from 'semantic-ui-react'
import shp from 'shpjs'

import { mergeInputs } from '../../actions/inputs'
import { updateGeometriesSource, updateUploadStatus, GEOMETRY_SOURCES } from '../../actions/map'
import logException from '../../utils/logger'
import LayoutContext from '../../context'

class Upload extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showUploadDialog: false,
      latestUpload: { filename: '' },
      uploadMessages: {},
    }
  }

  resetUpload = () => {
    this.setState({
      showUploadDialog: true,
      latestUpload: { filename: '' },
      uploadMessages: {},
    })
  }

  handleFileUpload = event => {
    event.stopPropagation()
    event.preventDefault()

    this.props.updateUploadStatus(true)

    let files

    if (event.dataTransfer && event.dataTransfer.files.length) {
      files = event.dataTransfer.files
    } else if (event.target) {
      files = event.target.files
    }

    let zipFile
    let shpFile
    const dbfFiles = {}
    const prjFiles = {}
    for (let i = 0; i < files.length; i += 1) {
      const file = files[i]
      if (file.name.match(/\.zip/i)) {
        zipFile = file
        break
      } else if (file.name.match(/\.shp$/i)) {
        shpFile = file
      } else if (file.name.match(/\.dbf$/i)) {
        dbfFiles[file.name.slice(0, -4)] = file
      } else if (file.name.match(/\.prj$/i)) {
        prjFiles[file.name.slice(0, -4)] = file
      }
    }

    if (zipFile) {
      const reader = new FileReader()
      reader.onload = e => {
        shp(e.target.result)
          .then(geojson => {
            this.handleUploadFinish({
              status: 'success',
              filename: zipFile.name,
              geojson,
            })
          })
          .catch(errors => {
            this.handleUploadFinish({
              status: 'error',
              messages: { errors: ['Could not read the zipped shapefile.'] },
            })
            logException(errors)
          })
      }
      reader.readAsArrayBuffer(zipFile)
    } else if (shpFile) {
      const shpPromise = new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = e => {
          try {
            resolve(e.target.result)
          } catch (error) {
            reject(new Error('Could not open the selected file.'))
            logException(error)
          }
        }
        reader.readAsArrayBuffer(shpFile)
      })

      const prjFile = prjFiles[`${shpFile.name.slice(0, -4)}`]
      const prjPromise = new Promise(resolve => {
        if (prjFile) {
          const reader = new FileReader()
          reader.onload = e => {
            try {
              resolve({
                proj: e.target.result,
                warnings: [],
              })
            } catch (error) {
              resolve({
                proj: null,
                warnings: ['Could not fetch the projection data.'],
              })
              logException(error)
            }
          }
          reader.readAsText(prjFile)
        } else {
          resolve({
            proj: null,
            warnings: [
              `No projection file found. Assume WSG84.
                            If you see the wrong projection, try including the prj file.`,
            ],
          })
        }
      })

      const projectedShpPromise = new Promise((resolve, reject) =>
        Promise.all([shpPromise, prjPromise])
          .then(results => {
            try {
              resolve({
                shapes: shp.parseShp(results[0], results[1].proj),
                warnings: results[1].warnings,
              })
            } catch (e) {
              reject(new Error('Could not read the selected shapefile'))
              logException(e)
            }
          })
          .catch(error => reject(error)),
      )

      const dbfFile = dbfFiles[`${shpFile.name.slice(0, -4)}`]
      const dbfPromise = new Promise(resolve => {
        if (dbfFile) {
          const reader = new FileReader()
          reader.onload = e => {
            try {
              resolve({
                data: shp.parseDbf(e.target.result),
                warnings: [],
              })
            } catch (error) {
              resolve({
                data: [],
                warnings: ['The data for the selected file is corrupted.'],
              })
              logException(error)
            }
          }
          reader.readAsArrayBuffer(dbfFile)
        } else {
          resolve({
            data: [],
            warnings: ['Shapefile has no data. You can select the dbf file to load the data for this shapefile.'],
          })
        }
      })
      Promise.all([projectedShpPromise, dbfPromise])
        .then(results => {
          this.handleUploadFinish({
            status: 'success',
            filename: shpFile.name,
            geojson: shp.combine([results[0].shapes, results[1].data]),
            messages: { warnings: Array.prototype.concat(results[0].warnings, results[1].warnings) },
          })
        })
        .catch(errors => {
          this.handleUploadFinish({
            status: 'error',
            messages: { errors },
          })
        })
    } else {
      this.handleUploadFinish({
        status: 'error',
        messages: { errors: ['The selected file is not supported.'] },
      })
    }
  }

  handleUploadStart = () => {
    this.setState({ showUploadDialog: true })
  }

  handleUploadFinish = results => {
    if (results.status === 'success') {
      if (results.geojson.features.length) {
        const sampleShape = results.geojson.features[0]
        this.setState({
          uploadMessages: results.messages || {},
          latestUpload: {
            filename: results.filename,
            shapeType: sampleShape.geometry.type,
            layers: results.geojson.features.map(lyr =>
              Object.assign(lyr, { properties: Object.assign(lyr.properties, { shapefileName: results.filename }) }),
            ),
          },
        })
      } else {
        this.setState({
          uploadMessages: { warning: ['There is no shape in the selected shapefile.'] },
        })
      }
      this.setState((state, props) => ({
        uploadMessages: results.messages || {},
        latestUpload: Object.assign(state.latestUpload, { filename: results.filename }),
      }))
    } else {
      this.setState({ uploadMessages: results.messages })
    }
    this.props.updateUploadStatus(false)
  }

  handleAddToMapClick = () => {
    this.props.updateGeometriesSource(GEOMETRY_SOURCES.upload)
    this.context.map.addShapefile(this.state.latestUpload.layers)
    this.context.map.flyToBounds(this.context.map.customRegionLayer.getBounds(), { maxZoom: 12, duration: 0.1 })
    this.setState((state, props) => ({
      showUploadDialog: false,
      latestUpload: { filename: '' },
    }))
  }

  render() {
    return (
      <Segment basic>
        <Message positive size="small">
          <List size="large" bulleted>
            <List.Item>
              Click on <b>Add Shapefile</b> to access the shapefile from your desktop.
            </List.Item>
            <List.Item>
              Follow the instructions on the pop-up panel to add your file. If the file contains lines or points, you
              can adjust the buffer value in the provided input box.
            </List.Item>
            <List.Item>
              Click <b>Add to map</b> to finish the upload process from the pop-up panel.
            </List.Item>
          </List>
        </Message>
        <Segment basic textAlign="center">
          <Button primary content="Add Shapefile" icon="upload" onClick={this.handleUploadStart} />
        </Segment>
        {this.props.shapefiles.map(sf => (
          <div key={sf.leafletId}>
            {sf.label}&nbsp;
            <a href="#" onClick={() => this.context.map.removeShapefile(sf.leafletId)}>
              <Icon name="remove circle" color="red" />
            </a>
          </div>
        ))}
        <Modal open={this.state.showUploadDialog} closeIcon onClose={() => this.setState({ showUploadDialog: false })}>
          <Modal.Header>Upload Shapefile</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Segment basic loading={this.props.isUploading}>
                {this.state.latestUpload.filename ? (
                  <div className="fileUpload">
                    <Grid>
                      <Grid.Row columns={1}>
                        <Grid.Column>
                          <p>The selected file was processed successfully.</p>
                          <Button content="Cancel" onClick={this.resetUpload} />
                          <Button primary content="Add to the map" onClick={this.handleAddToMapClick} />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row columns={1}>
                        {this.state.latestUpload.shapeType.indexOf('Polygon') === -1 ? (
                          <Grid.Column>
                            <p>The shapefile contains non-polygon geometry.</p>
                            <p>All features will be buffered using the value below.</p>
                            <Input size="mini" labelPosition="right" style={{ width: '50%' }}>
                              <Label content="buffer" color="blue" />
                              <input
                                type="number"
                                step="0.1"
                                value={this.props.bufferValue}
                                onChange={e =>
                                  this.props.mergeInputs('sites', { bufferValue: parseFloat(e.target.value) })
                                }
                              />
                              <Label content="mile(s)" />
                            </Input>
                          </Grid.Column>
                        ) : null}
                      </Grid.Row>
                    </Grid>
                  </div>
                ) : (
                  <div
                    className="fileUpload"
                    onDrop={this.handleFileUpload}
                    onDragOver={e => {
                      e.preventDefault()
                      e.stopPropagation()
                    }}
                  >
                    Drag and drop your shapefile in this box.
                    <Segment basic>
                      <Button primary content="Upload Shapefile" size="tiny" onClick={() => this.filesInput.click()} />
                      <input
                        ref={c => {
                          this.filesInput = c
                        }}
                        type="file"
                        multiple
                        style={{ display: 'none' }}
                        onChange={this.handleFileUpload}
                      />
                    </Segment>
                  </div>
                )}
                {this.state.uploadMessages.errors && this.state.uploadMessages.errors.length ? (
                  <Message error size="mini">
                    <List bulleted>
                      {this.state.uploadMessages.errors.map((msg, idx) => (
                        <List.Item key={idx}>{msg}</List.Item>
                      ))}
                    </List>
                  </Message>
                ) : null}
                {this.state.uploadMessages.warnings && this.state.uploadMessages.warnings.length ? (
                  <Message warning size="mini">
                    <List bulleted>
                      {this.state.uploadMessages.warnings.map((msg, idx) => (
                        <List.Item key={idx}>{msg}</List.Item>
                      ))}
                    </List>
                  </Message>
                ) : null}
              </Segment>
            </Modal.Description>
          </Modal.Content>
        </Modal>
      </Segment>
    )
  }
}

Upload.contextType = LayoutContext

Upload.propTypes = {
  // redux props
  geometriesSource: PropTypes.string.isRequired,
  bufferValue: PropTypes.number.isRequired,
  isUploading: PropTypes.bool.isRequired,
  // redux actions
  updateGeometriesSource: PropTypes.func.isRequired,
  updateUploadStatus: PropTypes.func.isRequired,
  mergeInputs: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  bufferValue: state.getIn(['inputs', 'sites', 'bufferValue']),
  isUploading: state.getIn(['map', 'isUploading']),
  geometriesSource: state.getIn(['map', 'geometriesSource']),
  shapefiles: state.getIn(['map', 'shapefiles']).toJS(),
})

export default connect(mapStateToProps, {
  mergeInputs,
  updateUploadStatus,
  updateGeometriesSource,
})(Upload)
