import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Button, Grid, Image, Input, Label, List, Message, Segment } from 'semantic-ui-react'

import { mergeInputs } from '../../actions/inputs'
import { updateDrawStatus, updateGeometriesSource, GEOMETRY_SOURCES } from '../../actions/map'

import drawPolygonImage from '../../images/draw-polygon.svg'
import drawPolylineImage from '../../images/draw-polyline.svg'
import drawPointImage from '../../images/draw-point.svg'
import drawEditImage from '../../images/draw-edit.svg'
import drawDeleteImage from '../../images/draw-delete.svg'
import LayoutContext from '../../context'

class Draw extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      actionInProgress: '',
      shapeType: '',
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isDrawing && !this.props.isDrawing) {
      this.setState({ shapeType: '' })
    }
  }

  componentWillUnmount() {
    if (this.props.isDrawing) {
      this.handleCancelClick()
    }
  }

  handleDrawClick = shapeType => {
    switch (shapeType) {
      case 'polygon':
        this.context.map.drawControl._toolbars.draw._modes.polygon.handler.enable()
        break
      case 'polyline':
        this.context.map.drawControl._toolbars.draw._modes.polyline.handler.enable()
        break
      case 'point':
        this.context.map.drawControl._toolbars.draw._modes.marker.handler.enable()
        break
    }
    this.setState({ actionInProgress: 'drawing', shapeType }, () => this.props.updateDrawStatus(true))
  }

  handleEditClick = () => {
    this.props.updateDrawStatus(true)
    this.context.map.drawControl._toolbars.edit._modes.edit.handler.enable()
    this.setState({ actionInProgress: 'editing' })
  }

  handleDeleteClick = () => {
    this.props.updateDrawStatus(true)
    this.context.map.drawControl._toolbars.edit._modes.remove.handler.enable()
    this.setState({ actionInProgress: 'deleting' })
  }

  handleSaveClick = () => {
    if (this.state.actionInProgress === 'editing') {
      this.context.map.drawControl._toolbars.edit._modes.edit.handler.save()
      this.context.map.drawControl._toolbars.edit._modes.edit.handler.disable()
    } else if (this.state.actionInProgress === 'deleting') {
      this.context.map.drawControl._toolbars.edit._modes.remove.handler.save()
      this.context.map.drawControl._toolbars.edit._modes.remove.handler.disable()
    }
    this.setState({ actionInProgress: '', shapeType: '' }, () => {
      this.props.updateDrawStatus(false)
      this.props.updateGeometriesSource(
        this.context.map.customRegionLayer.getLayers().length ? GEOMETRY_SOURCES.draw : '',
      )
    })
  }

  handleCancelClick = () => {
    if (this.state.actionInProgress === 'drawing') {
      this.context.map.drawControl._toolbars.draw._modes.polygon.handler.disable()
      this.context.map.drawControl._toolbars.draw._modes.polyline.handler.disable()
      this.context.map.drawControl._toolbars.draw._modes.marker.handler.disable()
    } else if (this.state.actionInProgress === 'editing') {
      this.context.map.drawControl._toolbars.edit._modes.edit.handler.revertLayers()
      this.context.map.drawControl._toolbars.edit._modes.edit.handler.disable()
    } else if (this.state.actionInProgress === 'deleting') {
      this.context.map.drawControl._toolbars.edit._modes.remove.handler.revertLayers()
      this.context.map.drawControl._toolbars.edit._modes.remove.handler.disable()
    }
    this.props.updateDrawStatus(false)
    this.setState({
      actionInProgress: '',
      shapeType: '',
    })
  }

  render() {
    return (
      <Segment basic>
        <Message positive size="small">
          <List size="large" bulleted>
            <List.Item>
              Select one of the drawing tools (polygon, line, or point) and start drawing on the map.
            </List.Item>
            <List.Item>
              All lines and points will be buffered. You can adjust the buffer value in the sidebar at any point while
              drawing.
            </List.Item>
            <List.Item>
              You can edit <span style={{ color: 'red' }}>polygons</span> by clicking the <i>Edit</i> icon in the
              sidebar. sidebar.
            </List.Item>
            <List.Item>
              You can cancel any drawing by clicking the <i>Red Garbage Can</i> icon in the sidebar; clicking on the
              shape you wish to delete; and then clicking <i>Save</i> in the sidebar.
            </List.Item>
          </List>
        </Message>
        <Grid>
          {!this.props.isDrawing ? (
            <Grid.Row>
              <Grid.Column width="10">
                <Button size="tiny" compact primary onClick={() => this.handleDrawClick('polygon')}>
                  <Image src={drawPolygonImage} avatar />
                </Button>
                <Button size="tiny" compact primary onClick={() => this.handleDrawClick('polyline')}>
                  <Image src={drawPolylineImage} avatar />
                </Button>
                <Button size="tiny" compact primary onClick={() => this.handleDrawClick('point')}>
                  <Image src={drawPointImage} avatar />
                </Button>
              </Grid.Column>
              <Grid.Column
                width="6"
                style={{ display: !this.context.map.customRegionLayer.getLayers().length ? 'none' : '' }}
              >
                <Button size="tiny" compact primary onClick={this.handleEditClick}>
                  <Image src={drawEditImage} avatar />
                </Button>
                <Button size="tiny" compact color="red" onClick={this.handleDeleteClick}>
                  <Image src={drawDeleteImage} avatar />
                </Button>
              </Grid.Column>
            </Grid.Row>
          ) : (
            <Grid.Row>
              <Grid.Column width="10">
                <Grid>
                  <Grid.Row>
                    <Grid.Column width="11">
                      {this.state.shapeType === 'polyline' || this.state.shapeType === 'point' ? (
                        <Input size="mini" labelPosition="right" style={{ width: '50%' }}>
                          <Label content="buffer" color="blue" />
                          <input
                            type="number"
                            step="0.1"
                            value={this.props.bufferValue}
                            onChange={e => this.props.mergeInputs('sites', { bufferValue: parseFloat(e.target.value) })}
                          />
                          <Label content="mile(s)" />
                        </Input>
                      ) : null}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
              <Grid.Column width="6">
                {this.state.actionInProgress !== 'drawing' ? (
                  <Button.Group fluid>
                    <Button onClick={this.handleCancelClick}>Cancel</Button>
                    <Button.Or />
                    <Button primary onClick={this.handleSaveClick}>
                      Save
                    </Button>
                  </Button.Group>
                ) : (
                  <Button onClick={this.handleCancelClick}>Cancel</Button>
                )}
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      </Segment>
    )
  }
}

Draw.contextType = LayoutContext

Draw.propTypes = {
  // redux props
  bufferValue: PropTypes.number.isRequired,
  isDrawing: PropTypes.bool.isRequired,
  // redux actions
  mergeInputs: PropTypes.func.isRequired,
  updateDrawStatus: PropTypes.func.isRequired,
  updateGeometriesSource: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  bufferValue: state.getIn(['inputs', 'sites', 'bufferValue']),
  isDrawing: state.getIn(['map', 'isDrawing']),
})

export default connect(mapStateToProps, { mergeInputs, updateDrawStatus, updateGeometriesSource })(Draw)
