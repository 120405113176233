import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Image, Dropdown, Button, Input, Modal } from 'semantic-ui-react'

import databasin from '../images/logo_databasin.png'
import Workspace from './Workspace'

const Login = ({ isLoggedIn, email, isAdmin }) => (
  <>
    {isLoggedIn ? (
      <Dropdown item trigger={email}>
        <Dropdown.Menu>
          {isAdmin ? <Dropdown.Item as="a" href={Urls['accounts:admin']()} icon="users" text="Manage Users" /> : null}
          <Modal trigger={<Dropdown.Item icon="folder" text="Workspace" style={{ display: 'none' }} />} closeIcon>
            <Modal.Header>Your Workspace</Modal.Header>
            <Modal.Content>
              <Modal.Description>
                <Workspace />
              </Modal.Description>
            </Modal.Content>
          </Modal>
          <Dropdown.Item as="a" href={Urls['accounts:logout']()} icon="sign out" text="Sign out" />
        </Dropdown.Menu>
      </Dropdown>
    ) : (
      <>
        {process.env.NODE_ENV === 'development' && process.env.LOCAL_LOGIN ? (
          <Input type="text" placeholder="User Email" action>
            <input />
            <Button
              onClick={e => {
                window.location = `${window.origin}/accounts/force-login/${e.target.previousSibling.value}/?next=${window.location.pathname}`
              }}
            >
              Sign In
            </Button>
          </Input>
        ) : (
          <Button href={`${Urls['accounts:social:begin']('databasin')}?next=${window.location.pathname}`}>
            <Image src={databasin} avatar />
            Sign In
          </Button>
        )}
      </>
    )}
  </>
)

Login.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool.isRequired,
}

const mapStateToProps = state => ({
  ...state.get('account').toJS(),
})
export default connect(mapStateToProps)(Login)
