import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Accordion, Checkbox, Header, Icon, List } from 'semantic-ui-react'

import { REGION_LAYER_NAMES, RCA_VECTORS } from '../utils/rca'
import { updateIdentifyTarget } from '../actions/map'
import { updateModal } from '../actions/page'
import LayoutContext from '../context'
import { OVERLAYS } from './Map'

const carbonModal = {
  active: true,
  text:
    'The ecosystem carbon data layer provides an estimate of how much ' +
    'stored carbon exists in California’s ecosystems and where the carbon is ' +
    'located.  It provides a high-level “snapshot” of stored carbon at discrete ' +
    'moments in time, and is estimated using CARB’s statewide inventory ' +
    'methodology.  This approach serves a different purpose than project-level ' +
    'quantification, and should not be compared with project-level data (e.g., ' +
    'detailed and verified project-level data required for the California ' +
    'Compliance Offset Program, or project-level outcomes for California Climate ' +
    'Investments which evaluate change relative to a certain baseline over a ' +
    'project duration).',
}

class BaseLayers extends React.Component {
  static getDerivedStateFromProps(props) {
    return { ...props.map.overlays }
  }

  constructor(props) {
    super(props)

    this.state = {
      identifyTarget: null,
    }
  }

  renderSubCategories = (subCategories, accordionClass = '') =>
    Object.entries(subCategories)
      .sort(([cata, infoa], [catb, infob]) => {
        if (cata === 'root') {
          return -1
        }
        if (catb === 'root') {
          return 1
        }
        if (cata === 'Reference Layers') {
          return -1
        }
        if (catb === 'Reference Layers') {
          return 1
        }
        return cata.localeCompare(catb)
      })
      .map(([subCategory, info]) => {
        if (subCategory === 'root') {
          return (
            <List key="root">
              {info
                .sort((a, b) => a[0].name.localeCompare(b[0].name))
                .map(overlays => overlays.map(this.renderCategory))}
            </List>
          )
        }
        return (
          <Accordion.Accordion
            key={subCategory}
            panels={[
              {
                key: subCategory,
                title: { content: subCategory, className: accordionClass },
                content: {
                  content: this.renderSubCategories(info, accordionClass),
                },
              },
            ]}
            style={{ marginLeft: 15 }}
          />
        )
      })

  renderCategory = overlay => {
    const layerName = RCA_VECTORS[overlay.url] || overlay.name
    return (
      <List.Item key={overlay.name}>
        {overlay.externalUrl ? (
          <List.Content floated="right">
            <a href={overlay.externalUrl} target="_blank" rel="noopener noreferrer">
              <Icon
                name="external"
                title="View in Data Basin"
                style={{
                  float: 'right',
                  marginRight: 10,
                }}
              />
            </a>
          </List.Content>
        ) : null}
        <List.Content>
          {/* adds disclaimer to carbon - better approach is to attach the disclaimer to the overlay object */}
          <Checkbox
            label={overlay.name}
            checked={this.state[layerName] && this.state[layerName].isActive}
            onChange={() => {
              this.props.map.toggleOverlay(layerName)
              if (overlay.url === 'carbon' && !this.props.modalDisplayed) {
                this.props.updateModal(carbonModal)
              }
            }}
          />
          {overlay.url === 'carbon' ? (
            <a
              style={{ color: 'orange', pointer: 'cursor', marginLeft: '26px' }}
              onClick={() => this.props.updateModal(carbonModal)}
            >
              {'[DISCLAIMER]'}
            </a>
          ) : null}
        </List.Content>
      </List.Item>
    )
  }

  render() {
    const { identifyTarget } = this.props
    const panels = []

    if (window.PROPOSITION) {
      const categoryPanels = []
      const subCats = {}
      Object.values(window.PROPOSITION.datasets).forEach(datasetId => {
        const layer = Object.values(this.props.datasets)
          .flat()
          .find(ds => ds.dataset_id === datasetId)
        let { overlays } = layer
        if (layer.url) {
          overlays = overlays.map(overlay => ({ ...overlay, ...{ externalUrl: layer.url } }))
        }
        const categories = layer.scoring[window.PROPOSITION.slug].category
        if (categories) {
          categories.reduce((x, y, idx) => {
            if (!x[y]) {
              x[y] = {}
            }
            if (idx === categories.length - 1) {
              if (x[y].root) {
                x[y].root.push(overlays)
              } else {
                x[y].root = [overlays]
              }
            }
            return x[y]
          }, subCats)
        } else {
          categoryPanels.push(overlays.map(this.renderCategory))
        }
      })
      const renderedSubCats = this.renderSubCategories(subCats, 'dwr-blue')
      categoryPanels.push(
        ...renderedSubCats.map((rsc, idx) => (
          <List.Item key={idx} style={{ marginLeft: -15 }}>
            {rsc}
          </List.Item>
        )),
      )
      panels.push({
        key: window.PROPOSITION.id,
        title: { content: window.PROPOSITION.name, className: 'dwr-blue' },
        content: {
          content: <List style={{ marginLeft: 15 }}>{categoryPanels}</List>,
        },
      })
    }
    Object.entries(this.props.availableRCAs).forEach(([ecoregion, targets]) => {
      panels.push({
        key: ecoregion,
        title: `Weighted Conservation Target Models (${REGION_LAYER_NAMES[ecoregion].label})`,
        content: {
          content: (
            <List style={{ marginLeft: 15 }}>
              {targets.map(target => {
                const layerName = `${ecoregion}_${target.value}`
                const isIdentifyActive =
                  identifyTarget.length && identifyTarget[0] === ecoregion && identifyTarget[1] === target.value
                const nextIdentifyTarget = isIdentifyActive ? [] : [ecoregion, target.value]
                return (
                  <List.Item key={layerName}>
                    <List.Content floated="right">
                      {target.url ? (
                        <a href={target.url} target="_blank" rel="noopener noreferrer">
                          <Icon
                            name="external"
                            title="View in Data Basin"
                            style={{
                              position: 'relative',
                              marginRight: 10,
                              top: 2,
                            }}
                          />
                        </a>
                      ) : null}
                      <a onClick={() => this.props.updateIdentifyTarget(nextIdentifyTarget)}>
                        <Icon.Group>
                          <Icon
                            name="square outline"
                            size="large"
                            color={isIdentifyActive ? 'orange' : 'blue'}
                            title={
                              isIdentifyActive
                                ? 'Click this icon to turn off the Identify tool'
                                : `Select this icon and then click a point on the map within the ${ecoregion} ecoregion 
                                to view detailed information about ${target.label.toLowerCase()} at that point.`
                            }
                          />
                          <Icon
                            name="info"
                            color={isIdentifyActive ? 'orange' : 'blue'}
                            title={
                              isIdentifyActive
                                ? 'Click this icon to turn off the Identify tool'
                                : `Select this icon and then click a point on the map within the ${ecoregion} ecoregion 
                                to view detailed information about ${target.label.toLowerCase()} at that point.`
                            }
                          />
                        </Icon.Group>
                      </a>
                    </List.Content>
                    <List.Content>
                      <Checkbox
                        label={target.label}
                        checked={this.state[layerName] && this.state[layerName].isActive}
                        onChange={() => this.props.map.toggleOverlay(layerName)}
                        style={{ display: 'inherit' }}
                      />
                    </List.Content>
                  </List.Item>
                )
              })}
            </List>
          ),
        },
      })
    })
    panels.push(
      ...Object.entries(OVERLAYS)
        .filter(([category, categoryOverlays]) => categoryOverlays.length && category !== 'none')
        .map(([category, categoryOverlays]) => {
          const subCategories = {}
          const categoryPanels = categoryOverlays.map(({ sub_categories: subCategory, overlays }) => {
            if (subCategory) {
              subCategory.reduce((x, y, idx) => {
                if (!x[y]) {
                  x[y] = {}
                }
                if (idx === subCategory.length - 1) {
                  if (x[y].root) {
                    x[y].root.push(overlays)
                  } else {
                    x[y].root = [overlays]
                  }
                }
                return x[y]
              }, subCategories)
              return null
            }
            return overlays.map(this.renderCategory)
          })
          const renderedSubCategories = this.renderSubCategories(subCategories)
          categoryPanels.push(
            ...renderedSubCategories.map(rsc => (
              <List.Item key={category} style={{ marginLeft: -15 }}>
                {rsc}
              </List.Item>
            )),
          )
          return {
            key: category,
            title: category,
            content: {
              content: <List style={{ marginLeft: 15 }}>{categoryPanels}</List>,
            },
          }
        }),
    )
    return (
      <>
        <Header as="h2">Show/Hide layers</Header>
        <Accordion id="Layers" exclusive={false} panels={panels} />
      </>
    )
  }
}

BaseLayers.propTypes = {
  // parent props
  map: PropTypes.object.isRequired,
  // redux props
  availableRCAs: PropTypes.shape({}).isRequired,
  identifyTarget: PropTypes.arrayOf(PropTypes.string).isRequired,
  // redux actions
  updateIdentifyTarget: PropTypes.func.isRequired,
}

const Layers = props => (
  <LayoutContext.Consumer>{({ map }) => <BaseLayers map={map} {...props} />}</LayoutContext.Consumer>
)
const mapStateToProps = state => {
  const rcaNames = state.getIn(['inputs', 'rca', 'regionsTargets'])
  return {
    overlaysUpdatedOn: state.getIn(['map', 'overlaysUpdatedOn']),
    identifyTarget: state.getIn(['map', 'identifyTarget']).toJS(),
    availableRCAs: state
      .getIn(['config', 'targets'])
      .filter((v, k) => rcaNames.includes(k))
      .toJS(),
    datasets: state.getIn(['config', 'datasets']).toJS(),
    modalDisplayed: state.getIn(['page', 'modalDisplayed']),
  }
}

export default connect(mapStateToProps, {
  updateIdentifyTarget,
  updateModal,
})(Layers)
