import React from 'react'
import { fromJS } from 'immutable'

import VerticalBarChart from '../../components/VerticalBarchart'
import { precision } from '../../utils/formatters'

const MAPPING_DATA = fromJS({
  '1-11.99': {
    color: '#b7eef1',
    label: '1-11 Species',
    value: 0,
    valueRange: [-1, -0.75],
  },
  '12-32.99': {
    color: '#74b5e8',
    label: '12-32 Species',
    value: 0,
    valueRange: [-0.75, -0.5],
  },
  '33-61.99': {
    color: '#1e84e1',
    label: '33-61 Species',
    value: 0,
    valueRange: [-0.5, 0],
  },
  '62-108.99': {
    color: '#1846b8',
    label: '62-108 Species',
    value: 0,
    valueRange: [0, 0.5],
  },
  '109-424': {
    color: '#060a91',
    label: '109-424 Species',
    value: 0,
    valueRange: [0.5, 0.75],
  },
})

const META = {
  unit: 'acres',
  convertToPercent: true,
  tooltipContent: d => `${d.valueRange[0]} <= TI <= ${d.valueRange[1]}`,
}

const renderer = ({ data, meta }) => {
  if (!data) {
    return 'No data found.'
  }

  return (
    <VerticalBarChart
      meta={{ ...META, ...meta }}
      data={
        MAPPING_DATA.map(
          (v, k) => v.set('value', data[k] || 0),
        ).valueSeq().toJS()
      }
    />
  )
}

const table = (dataset, data = {}, shapeMeta) => {
  const rows = [['', 'Area (area)', 'Percent (%)']]

  MAPPING_DATA.entrySeq().forEach(([k, v]) => {
    rows.push([v.get('label'), precision(data[k] || 0, 2), precision((100 * (data[k] || 0)) / shapeMeta.area, 2)])
  })
  return rows
}

export default { renderer, table }
