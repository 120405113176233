import { fromJS, OrderedMap, Map } from 'immutable'

import { ACTIONS } from '../actions/inputs'

const INITIAL_STATE = fromJS({
  region: {
    region: 'california',
    hasCustomRegion: false,
    ecoregions: {},
    counties: {},
    watersheds: {},
  },
  filter: {
    queryParams: {},
    minPolygonSize: 0,
  },
  rca: {
    target: null,
    regionsTargets: {},
    sitesTargets: {},
  },
  sites: {
    selectedSites: OrderedMap(),
    selectedSitesHash: null,
    bufferValue: 0.1, // this value is in mile and used for buffering lines and points
  },
})

const updateSelectedSites = (state, sites, rcas) => {
  const sitesRCAsSet = Map().asMutable()
  if (sites.size) {
    rcas.forEach((rcaAttrs, rcaValue) => {
      const sitesEntries = sites.values()
      let site = sitesEntries.next()
      while (!site.done) {
        if (site.value.RCAs[rcaValue]) {
          sitesRCAsSet.set(rcaValue, rcaAttrs)
          break
        }
        site = sitesEntries.next()
      }
    })
  }
  return state
    .setIn(['sites', 'selectedSites'], sites)
    .setIn(['sites', 'selectedSitesHash'], sites.hashCode())
    .setIn(['rca', 'sitesTargets'], sitesRCAsSet)
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.RESET_INPUTS:
      return INITIAL_STATE
    case ACTIONS.MERGE_INPUTS:
      return state.mergeIn([action.key], fromJS(action.inputs))
    case ACTIONS.UPDATE_REGIONS:
      return state
        .mergeIn(['region'], fromJS(action.selectedRegions))
        .setIn(['rca', 'regionsTargets'], fromJS(action.rcas))
    case ACTIONS.UPDATE_SELECTED_SITES:
      return updateSelectedSites(state, action.selectedSites, action.rcas)
    default:
      return state
  }
}
