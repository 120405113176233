import axios from 'axios'
import L from 'leaflet'

import TARGETS from '../files/json/targets.json'

import { updateLoadingStatus } from './page'

export const ACTIONS = {
  UPDATE_STUDY_AREA_OPTIONS: 'UPDATE_STUDY_AREA_OPTIONS',
  UPDATE_DATASETS: 'UPDATE_DATASETS',
  UPDATE_TARGETS: 'UPDATE_TARGETS',
}

export function updateTargets(targets, models = {}) {
  return {
    type: ACTIONS.UPDATE_TARGETS,
    targets,
    models,
  }
}

export function loadDatasets(datasetIds) {
  return dispatch => {
    dispatch(updateLoadingStatus(true))
    axios
      .post(Urls['api:config'](), { dataset_ids: datasetIds })
      .then(response => {
        dispatch({
          type: ACTIONS.UPDATE_DATASETS,
          datasets: response.data,
        })
        if (Object.keys(response.data).length) {
          dispatch(updateLoadingStatus(false))
        } else {
          dispatch(updateLoadingStatus(true, true, 'No dataset is available for querying.'))
        }
      })
      .catch(() => dispatch(updateLoadingStatus(true, true, 'Could not load the datasets. Try refreshing the page')))
  }
}

export function loadConservationTargets() {
  return dispatch => {
    dispatch(updateLoadingStatus(true))
    axios
      .get(TARGETS)
      .then(response => {
        dispatch(updateTargets(response.data))
        dispatch(updateLoadingStatus(false))
      })
      .catch(() =>
        dispatch(updateLoadingStatus(true, true, 'Could not load the conservation targets. Try refreshing the page')),
      )
  }
}

function fetchCustomModelResults(taskId, callback) {
  axios
    .get(Urls['ncdjango:geoprocessing:processingjob-detail'](taskId))
    .then(response => {
      switch (response.data.status) {
        case 'success':
          callback(response.data.status, response.data.outputs)
          break
        case 'started':
        case 'pending':
          setTimeout(() => fetchCustomModelResults(taskId, callback), 1000)
          break
        case 'error':
        default:
          callback('error')
          break
      }
    })
    .catch(() => ({ status: 'error' }))
}

export function createCustomModel(inputs, map) {
  return (dispatch, getState) => {
    dispatch(updateLoadingStatus(true))
    axios
      .post(Urls['ncdjango:geoprocessing:processingjob-list'](), {
        job: 'eems_model',
        inputs: JSON.stringify(inputs),
      })
      .then(response => {
        fetchCustomModelResults(response.data.uuid, (status, results) => {
          if (status !== 'error') {
            // discard previous custom layers (there should be only one)
            const prevLegends = Object.entries(map.legendControl._entries).filter(
              ([, legend]) => legend.layer.name.search(/_custom/) > -1,
            )

            prevLegends.forEach(([idx, legend]) => {
              map.legendControl.removeLegend(idx)
              delete map.overlays[legend.layer.name]
              legend.layer.remove()
            })
            // construct custom model props
            const serviceUrl = JSON.parse(results).output
            const customTarget = {
              value: 'custom',
              service: `${serviceUrl}:data`,
              map_server: serviceUrl,
              label: 'Weighted and Modeled Values of Custom targets',
            }

            // create leaflet layer
            const layerName = `${inputs.region}_custom`
            const layer = L.tileLayer(`tiles/${serviceUrl}/{z}/{x}/{y}.png`)
            layer.name = layerName
            map.overlays[layerName] = {
              layer,
              isActive: false,
            }

            // add legend entry
            map.legendControl.addLegend({
              name: 'Custom targets',
              layer,
              elements: [
                {
                  label: 'Max',
                  html: '<img src="data:image/png;base64, iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH4wIHFxQAjNTK9QAAAEVJREFUOMvtz0ERACAMA8FCkw6+sIZvMHGPPoiAnctY+9wAp2EHC0osGHhh+8vTMKiCwcQLaTCre6GcNDh/YbdC02DB4AMG4AL4BWu3uQAAAABJRU5ErkJggg==" />',
                },
                {
                  html: '<img src="data:image/png;base64, iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH4wIHFxQPHGvXZAAAAExJREFUOMvl1EERACAIBdEPgtlMahVbiSX2wIwE2HlwwNY+JXBipgkNZjobnEELQ7Sw4OAoeuXuQvyGiQudFvqlgx+urObBwIXG/sMH0G4OmEeLK1oAAAAASUVORK5CYII=" />',
                },
                {
                  label: 'Min',
                  html: '<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH4wIHFxQiWbSLEQAAAD5JREFUOMtj3PRg/38GKgIWViYGqgIWFkYmKhvINOgNHHleZqa2l5mZmKntQmZqxzLLSPPyCDSQBjllkHsZAKuyBCvkFTv8AAAAAElFTkSuQmCC" />',
                },
              ],
            })

            const state = getState()
            const allTargets = state.getIn(['config', 'targets']).toJS()
            const regionTargets = allTargets[inputs.region]
            if (regionTargets[regionTargets.length - 1].value === 'custom') {
              regionTargets.splice(regionTargets.length - 1, 1, customTarget)
            } else {
              regionTargets.push(customTarget)
            }

            const models = state.getIn(['config', 'models']).toJS()
            models[inputs.region] = inputs

            dispatch(updateTargets(allTargets, models))
          }
          dispatch(updateLoadingStatus(false))
        })
      })
      .catch(() => dispatch(dispatch(updateLoadingStatus(false))))
  }
}
