import React from 'react'
import { fromJS } from 'immutable'

import VerticalBarChart from '../../components/VerticalBarchart'
import { precision } from '../../utils/formatters'

const MAPPING_DATA = fromJS({
  '0 - 0.175': {
    color: '#b7eef1',
    label: '0 - 0.175',
    value: 0,
    valueRange: [-1, -0.75],
  },
  '0.176 - 0.378': {
    color: '#74b5e8',
    label: '0.176 - 0.378',
    value: 0,
    valueRange: [-0.75, -0.5],
  },
  '0.379 - 0.582': {
    color: '#1e84e1',
    label: '0.379 - 0.582',
    value: 0,
    valueRange: [-0.5, 0],
  },
  '0.583 - 0.790': {
    color: '#1846b8',
    label: '0.583 - 0.790',
    value: 0,
    valueRange: [0, 0.5],
  },
  '0.791 - 1': {
    color: '#060a91',
    label: '0.791 - 1',
    value: 0,
    valueRange: [0.5, 0.75],
  },
})

const META = {
  unit: 'acres',
  convertToPercent: true,
  showTooltip: false,
}

const renderer = ({ data, meta }) => {
  if (!data) {
    return 'No data found.'
  }

  return (
    <VerticalBarChart
      meta={{ ...META, ...meta }}
      data={
        MAPPING_DATA.map(
          (v, k) => v.set('value', data[k] || 0),
        ).valueSeq().toJS()
      }
    />
  )
}

const table = (dataset, data = {}, shapeMeta) => {
  const rows = [['', 'Area (area)', 'Percent (%)']]

  MAPPING_DATA.entrySeq().forEach(([k, v]) => {
    rows.push([v.get('label'), precision(data[k] || 0, 2), precision((100 * (data[k] || 0)) / shapeMeta.area, 2)])
  })
  return rows
}

export default { renderer, table }
