import React from 'react'
import { OrderedMap } from 'immutable'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Button, Header, Icon, Input, Message, Segment, Table } from 'semantic-ui-react'

import { percent } from '../../utils/formatters'
import { REGION_LAYER_NAMES } from '../../utils/rca'
import { updateSelectedSites } from '../../actions/inputs'
import { updateIdentifyTarget } from '../../actions/map'
import LayoutContext from '../../context'
import { LAYER_STYLES } from '../Map'

class SelectSites extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      activeLabelId: null,
      activeLabelValue: null,
    }
  }

  handleLabelUpdate = layerId => {
    if (this.state.activeLabelValue) {
      const layer = this.context.map.sitesLayer.getLayer(layerId)
      layer.label = this.state.activeLabelValue
      const { selectedSites } = this.props
      const labels = selectedSites.get(layerId)
      labels.verbose = this.state.activeLabelValue
      selectedSites.set(parseInt(layerId, 10), labels)

      this.setState(
        {
          activeLabelId: null,
          activeLabelValue: null,
        },
        () => this.props.updateSelectedSites(selectedSites, this.props.originalRCAs),
      )
    }
  }

  handleDeselectAllSites = () => {
    const selectedSites = OrderedMap({})
    this.context.map.sitesLayer.setStyle(LAYER_STYLES.unselected)
    this.context.map.updateSelectedSitesAttrs(selectedSites)
    this.props.updateSelectedSites(selectedSites)
  }

  handleSelectAllSites = () => {
    const selectedSites = OrderedMap(
      this.context.map.sitesLayer.getLayers().reduce((layers, layer) => {
        layers.push([layer._leaflet_id, {}])
        return layers
      }, []),
    ).asMutable()
    this.context.map.sitesLayer.setStyle(LAYER_STYLES.selected)
    this.context.map.updateSelectedSitesAttrs(selectedSites)
    this.props.updateSelectedSites(selectedSites, this.props.originalRCAs)
  }

  render() {
    const geomsNum = this.context.map.sitesLayer.getLayers().length
    const hasGeometries = geomsNum > 0
    return (
      <Segment basic>
        <Header as="h2" textAlign="center">
          Select Sites for Analysis
        </Header>

        <Message positive>
          <p>
            Please select one or more sites for analysis by clicking on the highlighted polygons on the map. Selecting
            no more than six sites at a time is recommended. After you select sites, you may wish to create a custom
            label.
          </p>

          <p>
            Click <i>Run Analysis</i> below to analyze each site against the extensive list of preloaded datasets.
          </p>
        </Message>

        <p>
          {geomsNum < 2
            ? `${geomsNum} site is available for selection.`
            : `${geomsNum} sites are available for selection.`}
        </p>
        {this.props.showSelectAll ? (
          <React.Fragment>
            <div>
              <Button
                primary
                size="small"
                content="Deselect All Sites"
                disabled={!hasGeometries}
                onClick={this.handleDeselectAllSites}
              />
              <Button
                primary
                content="Select All Sites"
                disabled={!hasGeometries}
                onClick={this.handleSelectAllSites}
              />
            </div>
            <br />
          </React.Fragment>
        ) : null}

        {this.props.isIdentifyActive ? (
          <Message warning icon size="small">
            <Icon name="triangle exclamation" />
            <Message.Content>
              Identify tool is active. In order to select/deselect sites on the map, you need to deactivate it from the
              layers sidebar or by clicking on the button below.
              <Button
                primary
                content="Disable Identify"
                style={{ float: 'right' }}
                onClick={() => this.props.updateIdentifyTarget([])}
              />
            </Message.Content>
          </Message>
        ) : null}

        {this.props.selectedSites.size ? (
          <Table textAlign="center">
            <caption className="left aligned">
              <i>Click on labels to edit them.</i>
            </caption>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Id</Table.HeaderCell>
                <Table.HeaderCell>Label</Table.HeaderCell>
                <Table.HeaderCell>RCAs</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.props.selectedSites.entrySeq().map(([layerId, { mapId, verbose, RCAs }]) => (
                <Table.Row key={layerId}>
                  <Table.Cell>{mapId}</Table.Cell>
                  <Table.Cell>
                    {this.state.activeLabelId === layerId ? (
                      <Input
                        action
                        size="mini"
                        value={this.state.activeLabelValue}
                        onChange={(e, props) => this.setState({ activeLabelValue: props.value })}
                        onKeyUp={e => {
                          switch (e.key) {
                            case 'Enter':
                              this.handleLabelUpdate(layerId)
                              break
                            case 'Escape':
                              this.setState({
                                activeLabelId: null,
                                activeLabelValue: null,
                              })
                              break
                          }
                        }}
                      >
                        <input />
                        <Button
                          icon="close"
                          onClick={() =>
                            this.setState({
                              activeLabelId: null,
                              activeLabelValue: null,
                            })
                          }
                        />
                        <Button icon="checkmark" primary onClick={() => this.handleLabelUpdate(layerId)} />
                      </Input>
                    ) : (
                      <a
                        className="editableText"
                        onClick={() =>
                          this.setState({
                            activeLabelId: layerId,
                            activeLabelValue: verbose,
                          })
                        }
                      >
                        {verbose}
                      </a>
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {Object.entries(RCAs).map(
                      ([rcaValue, rcaPercentage]) =>
                        `${REGION_LAYER_NAMES[rcaValue].label}: ${percent(rcaPercentage * 100, 1)}`,
                    )}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        ) : (
          <p>Please select at least one site for analysis by clicking on its geometry on the map.</p>
        )}
      </Segment>
    )
  }
}

SelectSites.contextType = LayoutContext

SelectSites.propTypes = {
  // parent props
  showSelectAll: PropTypes.bool,
  // redux props
  originalRCAs: PropTypes.shape({}).isRequired,
  selectedSites: PropTypes.instanceOf(OrderedMap).isRequired,
  isIdentifyActive: PropTypes.bool.isRequired,
  // redux props
  updateSelectedSites: PropTypes.func.isRequired,
  updateIdentifyTarget: PropTypes.func.isRequired,
}

SelectSites.defaultProps = {
  showSelectAll: false,
}

const mapStateToProps = state => ({
  originalRCAs: state.getIn(['config', 'targets']),
  selectedSites: state.getIn(['inputs', 'sites', 'selectedSites']),
  selectedSitesHash: state.getIn(['inputs', 'sites', 'selectedSitesHash']),
  isIdentifyActive: state.getIn(['map', 'identifyTarget']).size > 0,
})

export default connect(mapStateToProps, {
  updateSelectedSites,
  updateIdentifyTarget,
})(SelectSites)
